import { Link as RouterLink, useParams } from "react-router-dom";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import { Flex } from "~/components/Flex";
import { Space } from "~/lib/types";
import { ComplianceNavIcon } from "./temporary/ComplianceNavIcon";
import { useSearchParams } from "react-router-dom";
import { ComplianceFramework } from "./framework/framework";
import { ComplianceControl } from "./control/control";

type Props = {
  space: Space;
  framework?: ComplianceFramework;
  control?: ComplianceControl;
  reports?: boolean;
  mb?: number | string; //margin-bottom exclusive until all compliance pages use grid for spacing
};

export function ComplianceBreadcrumbs({
  space,
  framework,
  control,
  reports,
  mb,
}: Props) {
  const { frameworkId, controlId } = useParams();
  const [searchParams] = useSearchParams();
  const frameworkMrn = searchParams.get("frameworkMrn") || "";

  const homeCrumb = frameworkId ? (
    <Link
      key="home-crumb"
      component={RouterLink}
      to={`/space/compliance?spaceId=${space.id}`}
      display="flex"
    >
      <HomeCrumb title="Compliance" />
    </Link>
  ) : (
    <HomeCrumb key="home-crumb" title={reports ? "Reports" : "Compliance"} />
  );

  const frameworkCrumb = framework ? (
    control ? (
      <Link
        key="framework-crumb"
        component={RouterLink}
        to={`/space/compliance/${frameworkId}?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`}
      >
        {framework.name}
      </Link>
    ) : (
      <Typography key="framework-crumb" sx={{ color: "text.primary" }}>
        {framework.name}
      </Typography>
    )
  ) : null;

  const controlCrumb = control
    ? [
        <Typography key="control-crumb" sx={{ color: "text.primary" }}>
          {control.title}
        </Typography>,
      ]
    : [];

  const breadcrumbs = [homeCrumb, frameworkCrumb, ...controlCrumb].flatMap(
    (x) => x ?? [],
  );

  return (
    <Breadcrumbs
      sx={{ mb: mb ? mb : 5, overflowWrap: "anywhere" }}
      separator="›"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}

const HomeCrumb = ({ title }: { title: string }) => {
  return (
    <Flex sx={{ placeItems: "center" }} key={1}>
      <ComplianceNavIcon
        sx={{ mr: 1, color: (theme) => theme.palette.primary.light }}
      />
      {title}
    </Flex>
  );
};
