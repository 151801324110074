import { Link, Route, Routes } from "react-router-dom";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";
import { SecurityPage } from "~/pages/security";
import { Policies } from "~/pages/security/policies/policies";
import { EnablePolicies } from "~/pages/security/policies/enable-policies";
import { PolicyPage } from "~/pages/policy";
import { ExceptionsWrapper } from "~/components/policy-gql/exceptions";
import { SecurityDashboard } from "~/pages/space/security";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Check } from "~/pages/space/security/Check";
import { SecurityPolicyAffectedAssetsAdaptor } from "~/pages/space/vulnerabilities/components/AffectedAssets/SecurityPolicyAffectedAssetsAdapter";
import { PolicyChecks } from "~/components/policy-gql/PolicyChecks";
import { PoliciesAddDetailPage } from "~/pages/security/policies/policies-add-detail";
import { PoliciesAddQueryPage } from "~/pages/security/policies/policies-add-query";
import { PolicyAddDetailChecksPage } from "~/pages/security/policies/policy-add-detail-checks";
import { PolicyAddDetailPropertiesPage } from "~/pages/security/policies/policy-add-detail-properties";
import { PolicyDetailPropertiesPage } from "~/pages/security/policies/policy-detail-properties";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type SecurityRoutesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SecurityRoutes({
  space,
  scope,
  availablePermissions,
}: SecurityRoutesProps) {
  return (
    <Routes>
      <Route
        element={
          <SecurityPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      >
        <Route index element={<SecurityDashboard space={space} />} />
        <Route path="policies">
          <Route
            index
            element={<Policies {...{ space, scope, availablePermissions }} />}
          />
          <Route path="add">
            <Route
              index
              element={<EnablePolicies {...{ space, availablePermissions }} />}
            />
            <Route
              path=":policyId"
              element={
                <PoliciesAddDetailPage {...{ space, availablePermissions }} />
              }
            >
              <Route index element={<PolicyAddDetailChecksPage />} />
              <Route path={"checks"} element={<PolicyAddDetailChecksPage />} />
              <Route
                path={"properties"}
                element={<PolicyAddDetailPropertiesPage />}
              />
            </Route>
            <Route
              path=":policyId/queries/:queryId"
              element={
                <PoliciesAddQueryPage {...{ space, availablePermissions }} />
              }
            />
          </Route>
          <Route
            path=":policyId"
            element={
              <PolicyPage
                space={space}
                scope={scope}
                query={window.location.search}
                availablePermissions={availablePermissions}
              />
            }
          >
            <Route index element={<PolicyChecks />} />
            <Route path="checks" element={<PolicyChecks />} />
            <Route
              path="assets"
              element={<SecurityPolicyAffectedAssetsAdaptor scope={scope} />}
            />
            <Route path="exceptions" element={<ExceptionsWrapper />} />
            <Route path="properties" element={<PolicyDetailPropertiesPage />} />
          </Route>
        </Route>
        <Route path="checks">
          <Route
            index
            element={
              <FirewatchPage
                {...{
                  space,
                  scope,
                  pageType: AggregateScoreType.Check,
                }}
              />
            }
          />
        </Route>
        <Route
          path="check"
          element={
            <Check
              availablePermissions={availablePermissions}
              {...{ space, scope }}
            />
          }
          handle={{
            crumb: () => <Link to="/Check">Check</Link>,
          }}
        />
      </Route>
    </Routes>
  );
}
