import { FC } from "react";
import { Grid, Button, SxProps, Theme } from "@mui/material";
import { LoadingButton } from "~/components/loading-button";

export type LoadMoreProps = {
  hasNextPage?: boolean;
  loading?: boolean;
  handleLoadMore: () => void;
  sx?: SxProps<Theme>;
};

export const LoadMore: FC<LoadMoreProps> = ({
  handleLoadMore,
  hasNextPage,
  loading,
  sx,
}) => {
  if (!hasNextPage) {
    return (
      <Grid style={{ textAlign: "center", marginBottom: 23 }} sx={{ ...sx }}>
        <Button disabled={true}>No more items</Button>
      </Grid>
    );
  }

  return (
    <Grid style={{ textAlign: "center", marginBottom: 23 }} sx={{ ...sx }}>
      <LoadingButton
        loading={Boolean(loading)}
        onClick={handleLoadMore}
        buttonText="Load more"
      />
    </Grid>
  );
};
