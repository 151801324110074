import { Skeleton } from "@mui/material";
import { Flex } from "~/components/Flex";

export const CasesSkeleton = () => {
  return (
    <Flex gap={2} flexDirection="column">
      {new Array(3).fill(null).map((_, index) => {
        return (
          <Skeleton
            key={index}
            variant="rectangular"
            width="100%"
            height={400}
          />
        );
      })}
    </Flex>
  );
};
