import {
  AssetGroupStatistics,
  IntegrationStatistics,
  useGetSpaceInventoryStatsQuery,
  useGetWorkspaceInventoryStatsQuery,
} from "~/operations";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { IntegrationType } from "~/operations";

export type AssetGroupStatsItem = AssetGroupStatistics;
export type AssetGroupStats = AssetGroupStatsItem[];

export type IntegrationStatsItem = IntegrationStatistics;
export type IntegrationStats = IntegrationStatsItem[];

export type UseInventoryProps = {
  scope: SpaceOrWorkspaceScope;
};

export type Inventory = {
  assetGroupStats: AssetGroupStats;
  assetsTotalCount: number;
  hasJiraIntegrationEnabled: boolean;
};

export function useInventory({ scope }: UseInventoryProps): Inventory {
  const spaceResult = useGetSpaceInventoryStatsQuery({
    variables: { spaceMrn: scope.mrn },
    skip: scope.type !== ScopeType.Space,
  });
  const workspaceResult = useGetWorkspaceInventoryStatsQuery({
    variables: { workspaceMrn: scope.mrn },
    skip: scope.type !== ScopeType.Workspace,
  });

  const spaceData = spaceResult.data?.space;
  const workspaceData =
    workspaceResult.data?.workspace.__typename === "Workspace"
      ? workspaceResult.data.workspace
      : undefined;
  const data = scope.type === ScopeType.Space ? spaceData : workspaceData;

  // https://www.figma.com/file/ks8K3oC02Li9Xautp2jPeU/4.-Registry%2C-Security%2C-Vulnerabilities?node-id=60%3A22425
  // "Below, there can be up to 5 most important integrations (as discussed in the call:
  // Cloud providers first, so K8s, AWS, GCP – then OS & Containers; possibly based on
  // # of assets in each; not when there’s 0 of a given type of course)"
  const sortByImportant = (a: AssetGroupStatsItem, b: AssetGroupStatsItem) => {
    const cloudGroup = ["k8s", "aws", "gcp", "azure", "vmware", "oci"];
    const osContainerGroup = ["os", "container"];
    const supplyChainGroup = ["terraform"];
    const saasGroup = [
      "ms365",
      "okta",
      "google-workspace",
      "saas_services",
      "gitlab",
      "github",
      "slack",
    ];
    const networkGroup = ["network_devices", "domains_and_hosts"];

    // least important first
    const groupsByImportance = [
      networkGroup,
      saasGroup,
      supplyChainGroup,
      osContainerGroup,
      cloudGroup,
    ];

    const aIdx = groupsByImportance.findIndex((g) => g.includes(a.groupType));
    const bIdx = groupsByImportance.findIndex((g) => g.includes(b.groupType));

    if (aIdx === bIdx) {
      return b.count - a.count;
    }

    return bIdx - aIdx;
  };

  const assetGroupStats = (data?.assetGroupStats || [])
    .filter((g) => g.count > 0)
    .sort(sortByImportant);
  const assetsTotalCount = assetGroupStats.reduce((totalCount, group) => {
    return totalCount + group.count;
  }, 0);

  const integrationStats = spaceData?.stats?.integrationsStatistics;
  const hasJiraIntegrationEnabled =
    integrationStats?.some(
      (ai) => ai.total > 0 && ai.type === IntegrationType.TicketSystemJira,
    ) || false;

  return { assetGroupStats, assetsTotalCount, hasJiraIntegrationEnabled };
}
