import { BoxProps, capitalize } from "@mui/material";
import { Flex } from "~/components/Flex";
import { getAuthors, ListName, TextCaption } from "~/components/ui-library";

export type CaptionProps = BoxProps & {
  trustLevel?: string | null;
  authors?: ListName[] | null;
  version?: string | null;
};

export const Caption = ({
  trustLevel,
  authors,
  version,
  ...restProps
}: CaptionProps) => {
  return (
    <Flex gap={2} {...restProps}>
      {trustLevel && (
        <TextCaption secondary bold>
          {capitalize(trustLevel.toLowerCase())}{" "}
          {authors && authors.length > 0 ? `— by ${getAuthors(authors)}` : ""}
        </TextCaption>
      )}
      {version && (
        <TextCaption secondary bold>
          Version {version}
        </TextCaption>
      )}
    </Flex>
  );
};
