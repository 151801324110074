import { Box, Grid } from "@mui/material";
import {
  ListFrameworksFilterState,
  useGetListFrameworksQuery,
} from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { Space } from "~/lib/types";
import { AvailableFrameworkCard } from "../AvailableFrameworks/AvailableFrameworkCard";
import { motion } from "framer-motion";

type AddFrameworkProps = {
  space: Space;
};

export function AddFramework({ space }: AddFrameworkProps) {
  const {
    data: { listFrameworks } = {},
    error,
    loading,
  } = useGetListFrameworksQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        filterState: ListFrameworksFilterState.Available,
      },
    },
  });

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          placeContent: "center",
          width: 1,
          pt: 10,
        }}
      >
        <LoadingFailed what="available frameworks" />;
      </Box>
    );
  }

  if (loading || !listFrameworks) {
    return (
      <Box
        sx={{
          display: "flex",
          placeContent: "center",
          width: 1,
          pt: 10,
        }}
      >
        <Loading what="available frameworks" />
      </Box>
    );
  }

  const frameworks =
    listFrameworks?.__typename === "FrameworksConnection"
      ? listFrameworks
      : undefined;

  const availableFrameworksData =
    frameworks?.edges?.map((edge) => edge.node) || [];

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      sx={{ width: 1 }}
    >
      <Grid container item spacing={3}>
        {availableFrameworksData.map((framework) => (
          <AvailableFrameworkCard
            framework={framework}
            key={framework.mrn}
            space={space}
          />
        ))}
      </Grid>
    </Box>
  );
}
