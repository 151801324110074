import React from "react";
import { Command } from "~/components/guides/components";
import { Box, Button, Divider, Grid, TextField, useTheme } from "@mui/material";
import { AddIcon, ChevronRightIcon } from "~/components/icons";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import { ZendeskFormInput } from "~/pages/integrations/hosted-integrations/forms/zendesk/types";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import { ToggleOption } from "~/pages/integrations/components/ToggleOption/ToggleOption";
import { Flex } from "~/components/Flex";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { Validations } from "~/lib/validations";

type ZendeskFormStep2Props = {
  control: Control<ZendeskFormInput>;
  errors: FieldErrors<ZendeskFormInput>;
  isValid: boolean;
  isUpdateFlow: boolean;
  showCustomFields: boolean;
};

export const ZendeskFormStep2 = ({
  control,
  isValid,
  isUpdateFlow,
  errors,
  showCustomFields,
}: ZendeskFormStep2Props) => {
  const theme = useTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customFields",
  });

  return (
    <>
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Metadata and custom fields
        </Command>
        <Box mt={3}>
          <Grid item xs={12}>
            <ToggleOption
              title="Use custom fields defined in Zendesk"
              description="Zendesk supports custom fields, which can be required to close tickets. For more information, check your Zendesk settings."
              formOptions={{
                name: "useCustomFields",
                control,
              }}
            />
          </Grid>
          {showCustomFields && (
            <Grid item xs={12}>
              {fields.map((item, index) => (
                <Flex gap={3} sx={{ mt: 3 }}>
                  <Controller
                    name={`customFields.${index}.id`}
                    control={control}
                    rules={{
                      validate: {
                        isNumber: (value) =>
                          Boolean(value.match(Validations.isNumber)) ||
                          "Not a valid number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        InputProps={{
                          sx: {
                            backgroundColor: (theme) =>
                              theme.palette.code.background,
                          },
                        }}
                        sx={{
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="XXXXXXXXXXXXXX"
                        error={Boolean(errors.customFields?.[index]?.id)}
                        helperText={
                          Boolean(errors.customFields?.[index]?.id) && (
                            <ValidationMessage
                              error={errors.customFields?.[index]?.id}
                            />
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`customFields.${index}.value`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        InputProps={{
                          sx: {
                            backgroundColor: (theme) =>
                              theme.palette.code.background,
                          },
                        }}
                        sx={{
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Field value..."
                        error={Boolean(errors.customFields?.[index]?.value)}
                        helperText={
                          Boolean(errors.customFields?.[index]?.value) && (
                            <ValidationMessage
                              error={errors.customFields?.[index]?.value}
                            />
                          )
                        }
                      />
                    )}
                  />
                  {fields.length === index + 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => append({ id: "", value: "" })}
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        mt: 1,
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => remove(index)}
                      sx={{
                        minWidth: "40px",
                        height: "40px",
                        mt: 1,
                      }}
                    >
                      <AddIcon
                        fontSize="small"
                        sx={{ transform: "rotate(-45deg)" }}
                      />
                    </Button>
                  )}
                </Flex>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
      <Box pb={4}>
        <Command
          number={4}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Configure preferences
        </Command>
        <Box mt={3}>
          <AutoConfigurationSection
            control={control}
            showAutoCreateSection={true}
          />
        </Box>
      </Box>
      <Box width={1}>
        <Divider />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Button
          sx={{ textTransform: "uppercase" }}
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          Finalize {isUpdateFlow ? "Update" : "Setup"}
        </Button>
      </Box>
    </>
  );
};
