import { useFieldArray, useFormContext } from "react-hook-form";
import {
  CreateWorkspaceFormInput,
  initialCondition,
} from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { isConditionComplete } from "~/pages/space/Workspaces/utils";

export function useQueryBuilder() {
  const { control, watch } = useFormContext<CreateWorkspaceFormInput>();

  const { fields, append, remove } = useFieldArray<CreateWorkspaceFormInput>({
    control,
    name: "selections",
  });

  const handleAddNewSelection = () => {
    append({
      conditions: [initialCondition],
    });
  };

  const selections = watch(`selections`) || [];

  const handleRemoveSelection = (conditionIndex: number) =>
    remove(conditionIndex);

  return {
    canAddSelection: selections.every((selection) =>
      selection.conditions.every(isConditionComplete),
    ),
    handleAddNewSelection,
    handleRemoveSelection,
    fields,
  };
}
