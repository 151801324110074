import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Space } from "~/lib/types";
import {
  ActionType,
  ClientIntegration,
  IntegrationType,
  TestIamActionsQuery,
  useTriggerActionLazyQuery,
} from "~/operations";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { createPath, parsePath } from "react-router-dom";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { UpdateFlowData } from "../../types";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { TabPanel } from "~/components/tab-panel";
import { AzureAutomatedForm } from "./Automation/Azure/Automated";
import { AzureManualForm } from "./Automation/Azure/Manual";
import { IamActions } from "~/lib/iam";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { isFeatureEnabled } from "~/login/features";

export function AzureIntegrationForm({
  space,
  updateFlow,
  availablePermissions,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
  availablePermissions: TestIamActionsQuery["testIamActions"];
}) {
  const [createdIntegrationMrn, setCreatedIntegrationMrn] = useState<
    ClientIntegration["mrn"] | undefined
  >(undefined);
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const nextDefaultIntegrationName = useGenerateIntegrationName({ space });
  const [defaultIntegrationName, _setDefaultIntegrationName] = useState<string>(
    () => nextDefaultIntegrationName || "new-azure-integration",
  );

  const hasAutomatedDeploymentPermissions = updateFlow
    ? availablePermissions.includes(
        IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
      )
    : availablePermissions?.includes(
        IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
      );

  const [TriggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: {
      input: { mrn: createdIntegrationMrn!, type: ActionType.RunScan },
    },
  });

  const handleTriggerScan = async () => {
    try {
      await TriggerClientIntegrationScan();
    } catch (e) {
      console.error(e);
    }
  };

  const integrationPath = `/space/integrations/add/mondoo/azure?spaceId=${space.id}`;

  const generateHref = (tab: string): string => {
    const parsedPath = parsePath(integrationPath);
    const pathname = `${parsedPath.pathname}/${tab}`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  const tabList: TabListItem[] = [
    {
      label: "Automated setup",
      to: generateHref("automated"),
      route: "/automated",
    },
    {
      label: "Manual setup",
      to: generateHref("manual"),
      route: "/manual",
    },
  ];

  // if updateFlow exists, we should default to the manual tab
  const initialTab = updateFlow ? "manual" : "automated";

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    initialTab,
  );

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Azure }} />
          <Fragment>
            <TabNavigation
              id={`${IntegrationType.Azure}-tabs`}
              tabList={tabList}
              currentTab={currentTab}
            />
            <TabPanel value={currentTab} index="/automated">
              <AzureAutomatedForm
                {...{
                  defaultIntegrationName,
                  space,
                  setView,
                  hasAutomatedDeploymentPermissions,
                }}
              />
            </TabPanel>
            <TabPanel value={currentTab} index="/manual">
              <AzureManualForm
                {...{
                  defaultIntegrationName,
                  space,
                  setView,
                  updateFlow,
                  setCreatedIntegrationMrn,
                }}
              />
            </TabPanel>
          </Fragment>
        </Fragment>
      ) : (
        <RecommendedPolicies
          {...{ space }}
          filterTypes={["azure"]}
          bonusMessage="azure-cloud"
          finalizeAction={handleTriggerScan}
        />
      )}
    </Fragment>
  );
}
