import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import {
  Finish,
  Intro,
  StepFive,
  StepFour,
  StepFourTwo,
  StepThree,
  StepTwo,
} from "~/components/guides/aws";
import { TitleAreaProps } from "~/components/guides/components";
import { useViewer } from "~/providers/viewer";
import { Loading } from "~/components/loading";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  open: boolean;
  scope: SpaceOrWorkspaceScope | undefined;
  onClose: TitleAreaProps["onClose"];
};

export function AwsGuide({ open, onClose, scope }: Props) {
  const [tab, setTab] = useState(0);
  // const fullWidth = useMediaQuery(theme.breakpoints.down("md")); // todo: bring this back when we figure out the problem

  const handlePrev = () => {
    const prevTab = tab > 0 ? tab - 1 : 0;
    setTab(prevTab);
  };

  const handleNext = () => {
    const nextTab = tab + 1;
    setTab(nextTab);
  };

  const navs = {
    next: handleNext,
    prev: handlePrev,
  };

  const showCurrentTab = () => {
    if (tab === 0) return <Intro nav={navs} />;
    if (tab === 1) return <StepTwo onClose={onClose} nav={navs} />;
    if (tab === 2) return <StepThree onClose={onClose} nav={navs} />;
    if (tab === 3) return <StepFour onClose={onClose} nav={navs} />;
    if (tab === 4) return <StepFourTwo onClose={onClose} nav={navs} />;
    if (tab === 5)
      return <StepFive onClose={onClose} nav={navs} scope={scope} />;
    if (tab === 6) return <Finish onClose={onClose} scope={scope} />;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={false}>
      {showCurrentTab()}
    </Dialog>
  );
}

export function AwsWaiter() {
  const { refetch, viewerSettings, firstSpace } = useViewer();
  const [startTime] = useState<number>(Date.now());
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  // periodically refetch the data to see if our default space has been created
  useEffect(() => {
    refetch();
    const interval = setInterval(() => {
      refetch();
      setCurrentTime(Date.now());
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  var deltaMs = currentTime - startTime;

  // if we have a last_space_id set, great we will use it
  if (viewerSettings?.last_space_id) {
    return (
      <Navigate
        replace
        to={`/space/overview?spaceId=${viewerSettings?.last_space_id}&guide=aws`}
      />
    );
  }
  // if we have -any- space, great we will use it
  else if (firstSpace()) {
    return (
      <Navigate
        replace
        to={`/space/overview?spaceId=${firstSpace()?.id}&guide=aws`}
      />
    );
  }
  // if this has taken too long, just go to the dashboard :shrug:
  else if (deltaMs > 15000) {
    return <Navigate replace to={`/dashboard`} />;
  }
  // otherwise keep waiting
  else {
    return <Loading />;
  }
}
