import { useRef, useState, useEffect } from "react";
import { Box, Button, lighten, useTheme } from "@mui/material";
import { Flex } from "~/components/Flex";
import TextCaption from "../ui-library/components/typography/TextCaption";

export type PropertyItemProps = {
  index: number;
  uid: string;
  mql: string;
};

export const PropertyItem = ({ index, uid, mql }: PropertyItemProps) => {
  const theme = useTheme();
  const mqlRef = useRef<HTMLElement | null>(null);
  const [isEllipsisActive, setEllipsisActive] = useState(false);
  const [isShowMore, setShowMore] = useState(false);

  useEffect(() => {
    try {
      const handleResize = () => {
        const isEllipsisActive =
          Number(mqlRef.current?.scrollWidth) >
          Number(mqlRef.current?.offsetWidth);

        if (isShowMore) {
          return;
        }

        setEllipsisActive(isEllipsisActive);
      };

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    } catch (error) {}
  }, [isShowMore]);

  return (
    <Flex
      alignItems="baseline"
      gap={2}
      pb={2}
      sx={{
        "> .MuiTypography-root, > .MuiBox-root": {
          p: 1,
          fontWeight: 600,
          borderRadius: 1,
          border: `1px solid ${theme.palette.background.light}`,
          backgroundColor: theme.palette.background.paper,
        },
        "> .MuiTypography-root": {
          "&:first-of-type": {
            border: 0,
            backgroundColor: "transparent",
          },
        },
        "> .MuiBox-root": {
          "&:last-child": {
            backgroundColor: theme.palette.background.lightest,
          },
        },
      }}
    >
      <TextCaption>{index + 1}.</TextCaption>
      <TextCaption>{uid}</TextCaption>
      <Box
        ref={mqlRef}
        sx={{
          fontSize: 12,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: isShowMore ? "normal" : "nowrap",
        }}
      >
        {mql}
        {isEllipsisActive && (
          <>
            <br />
            <Button
              variant="text"
              sx={{
                p: 0,
                width: "fit-content",
                textTransform: "none",
                background: "transparent",
                color: theme.palette.secondary.light,
                "&:hover": {
                  background: "transparent",
                  color: lighten(theme.palette.secondary.light, 0.2),
                },
              }}
              size="small"
              onClick={() => {
                setShowMore((v) => !v);
              }}
            >
              Show {isShowMore ? "less" : "more"}
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};
