import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  ExceptionGroup,
  GetAggregateScoresDocument,
  OrderDirection,
  useGetAggregateScoresQuery,
} from "~/operations";

import { useSort } from "~/pages/inventory/hooks/useSort";
import { Policy, usePolicyOutletContext } from "./policy-gql";
import { ChangeEvent, Fragment, useState } from "react";
import { FirewatchList } from "../FirewatchList";
import { INITIAL_PAGE_RANGE } from "../pagination";
import { ExceptionsModals } from "../exceptions/exceptions-modals";
import { mapSelectedEntitiesToString } from "../exceptions/utils";
import { useExceptions } from "../exceptions/use-exceptions";
import { SelectedEntity } from "../exceptions/types";
import { ExceptionsToolbar } from "../exceptions/exceptions-toolbar";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "../search/useSearch";
import { IamActions } from "~/lib/iam";
import { ScopeType } from "~/hooks/useScope";

type PolicyChecksProps = {};

export type ExceptionData = {
  policy?: Policy;
  policyEnabled?: boolean;
  selectedChecks: SelectedEntity[];
  handleCheck: (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    { mrn, exception, groupId }: SelectedEntity,
  ) => void;
  handleCheckAll: (checked: boolean) => void;
  exceptionGroups: ExceptionGroup[];
};

export function PolicyChecks({}: PolicyChecksProps) {
  const {
    policy,
    policyEnabled,
    space,
    scope,
    availablePermissions,
    exceptionGroups,
  } = usePolicyOutletContext();
  const { searchFilters, handleFilterQuery } = useSearch();
  const [selectedChecks, setSelectedChecks] = useState<Array<SelectedEntity>>(
    [],
  );
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: [
      AggregateScoreOrderField.Rank,
      AggregateScoreOrderField.Title,
      AggregateScoreOrderField.BlastRadius,
      AggregateScoreOrderField.RiskScore,
    ],
  });

  const { data, error, loading, fetchMore } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: 10,
      after: null,
      orderBy,
      filter: {
        scoreType: AggregateScoreType.Check,
        policyMrn: policy.mrn,
        queryTerms: searchFilters,
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedChecks([]);
    },
    onRemoveException: () => {
      setSelectedChecks([]);
    },
    scopeMrns: [space.mrn],
    queryMrns: mapSelectedEntitiesToString(selectedChecks),
    refetchQueries: [GetAggregateScoresDocument],
  });

  const aggregateScores =
    data?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? data.aggregateScores
      : undefined;

  const pageInfo = aggregateScores?.pageInfo;
  const totalCount = aggregateScores?.totalCount || 0;
  const edges = aggregateScores?.edges || [];

  // Determine if a user has the correct permissions that allow them to
  // add exceptions
  const mutateQueryPermission = availablePermissions?.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const hasEditPermission = mutateQueryPermission && policyEnabled;

  // handle action when a user checks a single query
  const handleCheck = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    { mrn }: SelectedEntity,
  ) => {
    if (checked) {
      const exception = findMatchingException(mrn);
      setSelectedChecks((prev) => [
        ...prev,
        {
          mrn,
          groupId: exception?.id || "",
          exception: exception
            ? {
                justification: exception.justification,
                action: exception.action,
              }
            : null,
        },
      ]);
    } else {
      setSelectedChecks(
        selectedChecks.filter((selection) => mrn !== selection.mrn),
      );
    }
  };

  const findMatchingException = (mrn: string) => {
    return exceptionGroups.find((group) => {
      return group.exceptions.some((exception) =>
        exception?.__typename !== "ControlsEdge" && exception?.mrn === mrn
          ? exception
          : null,
      );
    });
  };

  // handle action when a user clicks the indeterminate/select all
  // checkbox. If at least one query is selected, we deselect all the options.
  // If all are unselected, we will select all.
  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedChecks(
        edges.map((edge) => {
          const exception = findMatchingException(edge.node?.findingMrn || "");

          return {
            groupId: exception?.id || "",
            mrn: edge.node?.findingMrn || "",
            exception: exception
              ? {
                  justification: exception.justification,
                  action: exception.action,
                }
              : null,
          };
        }),
      );
    } else {
      setSelectedChecks([]);
    }
  };

  // Uncheck all the selected queries
  const unCheckAll = () => {
    setSelectedChecks([]);
  };

  const handleCancelClick = () => {
    unCheckAll();
  };

  return (
    <Fragment>
      <FilterBar
        searchId={""}
        placeholder={""}
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      />
      <FirewatchList
        space={space}
        scope={scope}
        loading={loading}
        data={edges?.slice(pageItems.from, pageItems.to)}
        pageType={AggregateScoreType.Check}
        field={orderBy.field}
        direction={orderBy.direction}
        handleSortClick={handleSortClick}
        paginationProps={{
          fetchMore,
          pageInfo,
          setPageItems,
          totalCount,
        }}
        allowExceptionSetting={
          hasEditPermission && scope.type === ScopeType.Space
        }
        exceptionData={{
          policy,
          policyEnabled,
          selectedChecks,
          handleCheck,
          handleCheckAll,
          exceptionGroups,
        }}
        noTags
      />
      <ExceptionsModals
        isSetExceptionModalOpen={isSettingException}
        isRemoveExceptionModalOpen={isRemovingException}
        onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
        onSetExceptionModalClose={handleSetExceptionModalClose}
        onSetExceptionModalSave={handleSetException}
        onRemoveExceptionModalSave={handleRemoveException}
        loading={exceptionsLoading}
        target="check"
        role="security"
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedChecks}
      />
      {selectedChecks.length > 0 && (
        <ExceptionsToolbar
          target="check"
          onCancel={handleCancelClick}
          onRemoveExceptionClick={handleRemoveExceptionModalOpen}
          onSetExceptionClick={handleSetExceptionModalOpen}
          selectedEntities={selectedChecks}
          totalCount={edges.length}
        />
      )}
    </Fragment>
  );
}
