import { Link as RouterLink } from "react-router-dom";
import { alpha, Box, Grid, Typography } from "@mui/material";
import {
  usePolicyScoreCard,
  UsePolicyScoreCardProps,
} from "../../hooks/usePolicyScoreCard";
import { PolicyResultDistribution } from "./PolicyResultDistribution";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  scope: SpaceOrWorkspaceScope;
  policyScorecard: UsePolicyScoreCardProps["policyScorecard"];
  isCicd?: UsePolicyScoreCardProps["isCicd"];
};

export function PolicyScoreCard({ scope, policyScorecard, isCicd }: Props) {
  const {
    byMondoo,
    color,
    grade,
    icon,
    policyName,
    policyHref,
    scoreStats,
    isPreviewMode,
  } = usePolicyScoreCard({
    scope,
    policyScorecard,
    isCicd,
  });

  return (
    <Grid
      container
      item
      component={RouterLink}
      to={policyHref}
      rowGap={3}
      sx={{
        py: 3,
        borderRadius: 2,
        backgroundColor: "background.paper",
        justifyContent: "space-between",
        boxShadow: (theme) => theme.shadows[1],
        opacity: isPreviewMode ? 0.4 : 1,
      }}
    >
      <Grid container item xs={12} columnGap={1} sx={{ px: 3 }}>
        <Grid item xs="auto">
          {icon}
        </Grid>
        <Grid item xs>
          <Typography fontWeight={700}>{policyName}</Typography>
          {byMondoo && (
            <Typography
              sx={{
                color: "#BDBDBD",
                fontSize: (theme) => theme.spacing(1.5),
                fontWeight: 700,
              }}
            >
              Official - by Mondoo
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={8}
        columnGap={3}
        sx={{
          pl: 2,
        }}
      >
        <Grid container item columns={10} sx={{ alignItems: "end" }}>
          {scoreStats.map((item) => (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={item.result}
            >
              <PolicyResultDistribution result={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
          pr: 3,
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: theme.spacing(6),
            height: theme.spacing(6),
            p: 0.5,
            borderRadius: 1,
            border: "1px solid",
            borderColor: color,
            background: alpha(color, 0.1),
          })}
        >
          <Typography
            sx={(theme) => ({
              color: color,
              fontSize: theme.spacing(4),
              fontWeight: 700,
            })}
          >
            {grade}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
