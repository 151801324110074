import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AssetUrlScoreDistribution,
  AssetUrlSegment,
  AssetUrlStatsCategory,
  AssetUrlStatsScope,
  GetAggregateScoresRefreshInfoDocument,
  useGetAggregateScoresRefreshInfoQuery,
  useGetAssetUrlStatsQuery,
  useRefreshAggregateScoresMutation,
} from "~/operations";
import * as d3 from "d3";
import { SharedPalette } from "~/types/material-ui";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";

export type AssetUrlStats = Omit<AssetUrlScoreDistribution, "__typename">;

export type GraphTarget = {
  height: number;
  depth: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
};

export type AssetUrlFilterItem = Omit<AssetUrlSegment, "__typename">;
export type AssetUrlFilter = AssetUrlFilterItem[];

export const isAssetUrlFilterItem = (
  value: any,
): value is AssetUrlFilterItem => {
  return ["key", "value"].every((p) => p in value);
};

export const isAssetUrlFilter = (value: any): value is AssetUrlFilter => {
  return Array.isArray(value) && value.every(isAssetUrlFilterItem);
};

export type ScoreDistribution = Omit<AssetUrlScoreDistribution, "__typename">;
export type StatsDistribution = Record<AssetUrlStatsCategory, number>;
export type ScoreKeys = AssetUrlStatsCategory;
export type StatusDomain = ScoreKeys[];

export const fullStatusDomain: StatusDomain = [
  AssetUrlStatsCategory.Critical,
  AssetUrlStatsCategory.High,
  AssetUrlStatsCategory.Medium,
  AssetUrlStatsCategory.Low,
  AssetUrlStatsCategory.Ok,
  AssetUrlStatsCategory.Unscored,
];

export type StatusColorKey = keyof SharedPalette;
export type StatusColorMap = Record<ScoreKeys, StatusColorKey>;

export const statusColorMap: StatusColorMap = {
  [AssetUrlStatsCategory.Critical]: "critical",
  [AssetUrlStatsCategory.High]: "high",
  [AssetUrlStatsCategory.Medium]: "medium",
  [AssetUrlStatsCategory.Low]: "low",
  [AssetUrlStatsCategory.Ok]: "none",
  [AssetUrlStatsCategory.Unscored]: "unknown",
};

export type StatusLabelMap = Record<ScoreKeys, string>;

export const statusLabelMap: StatusLabelMap = {
  [AssetUrlStatsCategory.Critical]: "critical",
  [AssetUrlStatsCategory.High]: "high",
  [AssetUrlStatsCategory.Medium]: "medium",
  [AssetUrlStatsCategory.Low]: "low",
  [AssetUrlStatsCategory.Ok]: "ok",
  [AssetUrlStatsCategory.Unscored]: "unscored",
};

export type StatusValueMap = Record<ScoreKeys, number>;

export const statusValueMap: StatusValueMap = {
  [AssetUrlStatsCategory.Critical]: 0,
  [AssetUrlStatsCategory.High]: 0,
  [AssetUrlStatsCategory.Medium]: 0,
  [AssetUrlStatsCategory.Low]: 0,
  [AssetUrlStatsCategory.Ok]: 0,
  [AssetUrlStatsCategory.Unscored]: 0,
};

export type StatusWeightMap = Record<ScoreKeys, number>;

export const statusWeightMap: StatusValueMap = {
  [AssetUrlStatsCategory.Critical]: 0,
  [AssetUrlStatsCategory.High]: 0.2,
  [AssetUrlStatsCategory.Medium]: 0.45,
  [AssetUrlStatsCategory.Low]: 0.8,
  [AssetUrlStatsCategory.Ok]: 1,
  [AssetUrlStatsCategory.Unscored]: 1,
};

export type DatumStats = Partial<StatusValueMap>;

export type Datum = {
  path: AssetUrlFilter;
  pathId: string;
  name: string;
  score: number;
  stats: DatumStats;
  value: number;
  hover?: boolean;
  arcVisible?: boolean;
  current?: GraphTarget;
  target?: GraphTarget;
};
export type Data = Datum[];

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export type UseAssetUrlStatsProps = {
  scope: SpaceOrWorkspaceScope | undefined;
  spaceScope: SpaceScope | undefined;
  statsScope: AssetUrlStatsScope;
  excludeStats?: AssetUrlStatsCategory[];
};

export type UseAssetUrlStats = {
  queryResult: ReturnType<typeof useGetAssetUrlStatsQuery>;
  data: Data;
  lastRefreshed: string;
  isRefreshing: boolean;
  onRefreshClick: () => void;
  activePath: AssetUrlFilter;
  setActivePath: Dispatch<SetStateAction<AssetUrlFilter>>;
  hasAssets: boolean;
  scopeParams: URLSearchParams;
  scopeId: string;
  statusDomain: StatusDomain;
};

export function useAssetUrlStats({
  statsScope,
  excludeStats = [],
  scope,
  spaceScope,
}: UseAssetUrlStatsProps): UseAssetUrlStats {
  const [hasAssets, setHasAssets] = useState(false);

  const statusDomain: StatusDomain = [...fullStatusDomain].filter((s) => {
    return !excludeStats.includes(s);
  });

  const queryResult = useGetAssetUrlStatsQuery({
    variables: {
      input: {
        scopeMrn: scope?.mrn || "",
        scope: statsScope,
        excludeCategories: excludeStats,
      },
    },
    skip: !scope?.mrn,
  });

  const {
    data: refreshInfoData,
    startPolling,
    stopPolling,
  } = useGetAggregateScoresRefreshInfoQuery({
    variables: {
      groupMrn: spaceScope?.mrn || "",
    },
    skip: !spaceScope?.mrn,
  });

  const [refreshAggregateScores, { loading: isRefreshing }] =
    useRefreshAggregateScoresMutation({
      refetchQueries: [GetAggregateScoresRefreshInfoDocument],
    });

  // After the refresh button is clicked, we start polling to check if the refresh is done
  // Once the refresh is done, we stop polling
  useEffect(() => {
    if (refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress) {
      startPolling(10000);
    } else {
      stopPolling();
    }
  }, [refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress]);

  // Determine if the space has any assets
  // If we have assets, and no data, we'll show a banner to the user
  // on the sunburst chart
  useEffect(() => {
    const rawStats = queryResult.data?.assetUrlStats?.stats || [];
    const nextHasAssets = rawStats
      .map(({ scoreDistribution }) => {
        const { __typename, ...scoreDistributionData } = scoreDistribution;
        return Object.values(scoreDistributionData).some((v) => v > 0);
      })
      .some((v) => v);
    setHasAssets(nextHasAssets);
  }, [queryResult.data]);

  const rawStats = queryResult.data?.assetUrlStats?.stats || [];

  // Map the `AssetUrlScoreDistribution` values to the `AssetUrlStatsCategory` enum values
  // instead of `AssetUrlScoreDistribution` keys. Exclude any specified `AssetUrlStatsCategory`
  // from the returned distribution.
  const parseStats = (
    rawStats: ScoreDistribution,
  ): Partial<StatsDistribution> => {
    return {
      ...(excludeStats.includes(AssetUrlStatsCategory.Critical)
        ? {}
        : { [AssetUrlStatsCategory.Critical]: rawStats.Critical }),
      ...(excludeStats.includes(AssetUrlStatsCategory.High)
        ? {}
        : { [AssetUrlStatsCategory.High]: rawStats.High }),
      ...(excludeStats.includes(AssetUrlStatsCategory.Medium)
        ? {}
        : { [AssetUrlStatsCategory.Medium]: rawStats.Medium }),
      ...(excludeStats.includes(AssetUrlStatsCategory.Low)
        ? {}
        : { [AssetUrlStatsCategory.Low]: rawStats.Low }),
      ...(excludeStats.includes(AssetUrlStatsCategory.Ok)
        ? {}
        : { [AssetUrlStatsCategory.Ok]: rawStats.Ok }),
      ...(excludeStats.includes(AssetUrlStatsCategory.Unscored)
        ? {}
        : { [AssetUrlStatsCategory.Unscored]: rawStats.Unscored }),
    };
  };

  const data = rawStats.map(({ assetUrl, scoreDistribution }) => {
    const path = assetUrl.map(({ __typename, ...url }) => url);
    const pathId = pathToId(path);
    const name = assetUrl.slice(-1)[0].value;
    const { __typename, ...rawStats } = scoreDistribution;
    const stats = parseStats(rawStats);
    const score = computeScore(stats);
    const value = d3.sum(Object.values(stats));
    return {
      path,
      pathId,
      name,
      score,
      stats,
      value,
    };
  });

  const [activePath, setActivePath] = useState<AssetUrlFilter>([]);

  const onRefreshClick: UseAssetUrlStats["onRefreshClick"] = async () => {
    await refreshAggregateScores({
      variables: { groupMrn: spaceScope?.mrn || "" },
    });
  };

  return {
    queryResult,
    data,
    lastRefreshed:
      refreshInfoData?.aggregateScoresRefreshInfo.lastUpdatedAt || "",
    isRefreshing:
      isRefreshing ||
      Boolean(refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress),
    onRefreshClick,
    activePath,
    setActivePath,
    hasAssets,
    statusDomain,
    scopeParams: scope?.params || new URLSearchParams(),
    scopeId: scope?.id || "",
  };
}

export function computeScore(stats: DatumStats): number {
  const total = d3.sum(Object.values(stats));
  if (stats.UNSCORED === total) return -1;
  const weightedScore = getEntries(stats).reduce((score, entry) => {
    if (!entry) return score;
    const [key, value = 0] = entry;
    return score + statusWeightMap[key] * value;
  }, 0);
  return weightedScore / total;
}

export function pathItemToId(pathItem: { key: string; value: string }) {
  return Object.values(pathItem).join("=");
}

export function pathToId(path: { key: string; value: string }[]) {
  return path.reduce((acc, curr) => {
    acc = acc + "/" + pathItemToId(curr);
    return acc;
  }, "");
}
