import { createSvgIcon } from "@mui/material";

export const IndentIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C4.44772 0 4 0.447714 4 0.999999V10.3333V12C4 12.5523 4.44772 13 5 13H7H19C19.5523 13 20 12.5523 20 12V11.3333C20 10.781 19.5523 10.3333 19 10.3333L8 10.3333C7.44772 10.3333 7 9.88562 7 9.33333L7 1C7 0.447715 6.55228 0 6 0H5Z"
      fill="currentColor"
    />
  </svg>,
  "indent",
);
