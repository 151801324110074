import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { LayoutSwitch, LayoutSwitchProps } from "../LayoutSwitch/LayoutSwitch";
import { useSearch } from "../search/useSearch";
import { Space } from "~/lib/types";
import { AdjustedAggregateScoreType } from "../FirewatchPage";
import { pageNameByType } from "../FirewatchPage/lib";
import { AggregateScoreType } from "~/operations";
import { useUpperSection } from "./hooks/useUpperSection";
import { Fragment } from "react";
import { breadcrumbsByType } from "~/components/FirewatchPage/lib/breadcrumbsByType";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SearchProps = {
  searchId: string;
  placeholder: string;
  searchFilters: ReturnType<typeof useSearch>["searchFilters"];
  handleFilterQuery: ReturnType<typeof useSearch>["handleFilterQuery"];
};

type PageProps = {
  space: Space;
  scope?: SpaceOrWorkspaceScope | undefined;
  views: LayoutSwitchProps["views"];
  pageType: AdjustedAggregateScoreType;
  noEntries: string | undefined;
};

export type UpperSectionProps = {
  space: Space;
  scope?: SpaceOrWorkspaceScope | undefined;
};

type FirewatchLayoutProps = {
  searchProps: SearchProps;
  pageProps: PageProps;
};

const availableUpperSections: AdjustedAggregateScoreType[] = [
  AggregateScoreType.Asset,
  AggregateScoreType.Software,
];

export function FirewatchLayout({
  searchProps: { searchId, placeholder, searchFilters, handleFilterQuery },
  pageProps: { space, pageType, views, noEntries },
}: FirewatchLayoutProps) {
  const { upperSection } = useUpperSection({
    space,
    pageType,
  });

  return (
    <>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbsByType({ space, type: pageType })}
      </Breadcrumbs>
      <Typography
        variant="h4"
        component="h1"
        sx={{ fontWeight: 700, textTransform: "uppercase", mb: 5 }}
      >
        {pageNameByType(pageType)}
      </Typography>
      <Grid container spacing={5}>
        {availableUpperSections.includes(pageType) && (
          <Fragment>{upperSection}</Fragment>
        )}

        <LayoutSwitch
          filterBar={
            <FilterBar
              searchId={searchId}
              placeholder={placeholder}
              searchFilters={searchFilters}
              handleFilterQuery={handleFilterQuery}
              mb={0}
            />
          }
          noEntries={noEntries}
          views={views}
        />
      </Grid>
    </>
  );
}
