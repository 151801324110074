import { Box, BoxProps } from "@mui/material";
import { Flex } from "~/components/Flex";
import {
  SaasIcon,
  OktaIcon,
  MS365Icon,
  VMWareIcon,
  GCPColorIcon,
  AwsColorIcon,
  ContainersIcon,
  AzureColorIcon,
  SlackColorIcon,
  KubernetesIcon,
  GitLabColorIcon,
  DomainsHostsIcon,
  GoogleWSColorIcon,
  TerraformColorIcon,
  NetworkDevicesIcon,
  FolderOpenFilledIcon,
  OperatingSystemsIcon,
  CloudformationColorIcon,
} from "../../../icons";
import { TabOption } from "../../../types";
import { WindowsIcon } from "~/components/icons/mondoo/integrations/windows";
import { GitHubIcon, MacOsIcon } from "~/components/icons";
import { TabVariant } from "./TabVariant";

type TabsVariantProps = BoxProps & {
  options: TabOption[];
  selectedTab: string;
  onTabChange: (value: string) => void;
};

export const TabsVariant = ({
  options,
  selectedTab,
  onTabChange,
  ...restProps
}: TabsVariantProps) => {
  return (
    <Flex flexWrap={"wrap"} gap={2} {...restProps}>
      {options.map(({ label, value, icon }) => {
        return (
          <TabVariant
            key={value}
            isActive={selectedTab === value}
            label={label}
            icon={icon}
            onClick={() => {
              onTabChange(value);
            }}
          />
        );
      })}
    </Flex>
  );
};

export const VariantIcon = ({ type }: { type: string | null }) => {
  const name = type?.toLowerCase();

  if (!name)
    return (
      <Box
        sx={{
          width: 40,
          height: 10,
          backgroundColor: "background.default",
          boxShadow: 2,
        }}
      />
    );

  const svgStyles = { fontSize: 40 };

  if (name.includes("k8s")) return <KubernetesIcon sx={svgStyles} />;
  else if (name.includes("cloudformation"))
    return <CloudformationColorIcon sx={svgStyles} />;
  else if (name.includes("aws")) return <AwsColorIcon sx={svgStyles} />;
  else if (name.includes("gcp")) return <GCPColorIcon sx={svgStyles} />;
  else if (name.includes("github")) return <GitHubIcon sx={svgStyles} />;
  else if (name.includes("gitlab")) return <GitLabColorIcon sx={svgStyles} />;
  else if (name.includes("azure")) return <AzureColorIcon sx={svgStyles} />;
  else if (name.includes("vmware")) return <VMWareIcon sx={svgStyles} />;
  else if (name.includes("macos")) return <MacOsIcon sx={svgStyles} />;
  else if (name.includes("os")) return <OperatingSystemsIcon sx={svgStyles} />;
  else if (name.includes("windows")) return <WindowsIcon sx={svgStyles} />;
  else if (name.includes("network_devices"))
    return <NetworkDevicesIcon sx={svgStyles} />;
  else if (name.includes("domains_and_hosts"))
    return <DomainsHostsIcon sx={svgStyles} />;
  else if (name.includes("saas_services")) return <SaasIcon sx={svgStyles} />;
  else if (name.includes("terraform"))
    return <TerraformColorIcon sx={svgStyles} />;
  else if (name.includes("google-workspace"))
    return <GoogleWSColorIcon sx={svgStyles} />;
  else if (name.includes("okta")) return <OktaIcon sx={svgStyles} />;
  else if (name.includes("ms365")) return <MS365Icon sx={svgStyles} />;
  else if (name.includes("slack")) return <SlackColorIcon sx={svgStyles} />;
  else if (name.includes("container")) return <ContainersIcon sx={svgStyles} />;
  else return <FolderOpenFilledIcon sx={svgStyles} />;
};

export default TabsVariant;
