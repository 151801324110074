import { Box } from "@mui/material";
import { ChartData, DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { Policies } from "../../components/Policies/types";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

type Props = {
  sizes: CardSizes;
  policies: Policies;
  handleGradeFilterClick?: (data: ChartData) => void;
  selectedGrades?: string[];
  assetMrn: string;
};

export function AssetPolicyOverviewCard({
  sizes,
  handleGradeFilterClick,
  selectedGrades,
  assetMrn,
}: Props) {
  const { policyGrades, isLoading } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.PolicyGradeDistribution],
  });

  return (
    <HubCard
      {...{
        id: "asset-policy-overview-card",
        loading: isLoading,
        sizes,
        title: "Policy Overview",
        icon: <NavDrawerPolicyIcon fontSize="small" />,
        PaperProps: { maxHeight: "264px" },
      }}
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DonutChart
          data={policyGrades}
          dataType="policies"
          onSectionClick={handleGradeFilterClick}
          selectedSections={selectedGrades}
        />
      </Box>
    </HubCard>
  );
}
