import { Box, Button, Grid, Grid2, Typography } from "@mui/material";
import {
  AggregateScoreType,
  useGetWorkspaceSelectionsQuery,
} from "~/operations";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { FormProvider, useForm } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { useEffect } from "react";
import { mapQueryBuilderAPIToFormValues } from "~/pages/space/Workspaces/utils";
import { Flex } from "~/components/Flex";
import {
  QueryBuilder,
  SelectionAssetsCount,
} from "~/pages/space/Workspaces/components";
import { DashboardHeader } from "~/pages/space/Dashboards/components/DashboardHeader";
import { InventoryOverviewCardSunburst } from "./InventoryOverviewCard";
import { IntegrationsOverview } from "../IntegrationsOverview";
import { Space } from "~/lib/types";
import {
  isSpaceOrWorkspaceScope,
  SpaceOrWorkspaceScope,
  useScope,
} from "~/hooks/useScope";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";
import { Link } from "react-router-dom";
import { ArrowDownwardIcon } from "~/components/icons";

type OverviewProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope | undefined;
};

export function HomeDashboard({ space }: OverviewProps) {
  const { activeScope, spaceScope, workspaceScope } = useScope();
  const { data: workspaceSelectionsData } = useGetWorkspaceSelectionsQuery({
    variables: {
      mrn: workspaceScope?.mrn || "",
    },
    skip: !workspaceScope?.mrn,
  });

  const { assetsGrades: spaceAssetGrades } = useMetrics({
    entityMrn: spaceScope?.mrn || "",
    metricMrns: [Metrics.SecurityAssetsStats],
  });

  const { assetsGrades: workspaceAssetGrades } = useMetrics({
    entityMrn: workspaceScope?.mrn || "",
    metricMrns: [Metrics.SecurityAssetsStats],
  });

  const totalAssetsCount = spaceAssetGrades.reduce(
    (prev, current) => prev + current.value,
    0,
  );

  const workspaceAssetsCount = workspaceAssetGrades.reduce(
    (prev, current) => prev + current.value,
    0,
  );

  const workspaceSelections =
    workspaceSelectionsData?.workspace?.__typename === "Workspace"
      ? workspaceSelectionsData?.workspace?.selections?.selections
      : [];

  const formMethods = useForm<CreateWorkspaceFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      selections: [],
    },
  });

  useEffect(() => {
    if (!workspaceScope) return;

    formMethods.reset({
      selections: mapQueryBuilderAPIToFormValues(workspaceSelections),
    });
  }, [workspaceSelections]);

  return (
    <Box>
      {activeScope?.type === "workspace" ? (
        <DashboardHeader
          title={activeScope.name}
          description={activeScope.description}
          additionalContent={
            <FormProvider {...formMethods}>
              <Grid2 size={12}>
                <Flex
                  sx={{ color: "text.primary", my: 3 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" fontWeight={700}>
                    Workspace Asset Selections
                  </Typography>
                  <Flex alignItems="center" gap={2}>
                    <SelectionAssetsCount
                      selectionCount={workspaceAssetsCount}
                      totalCount={totalAssetsCount}
                    />
                    <Button
                      component={Link}
                      color="primary"
                      endIcon={<ArrowDownwardIcon sx={{ rotate: "-90deg" }} />}
                      to={`/space/settings?${workspaceScope?.params}`}
                    >
                      Edit
                    </Button>
                  </Flex>
                </Flex>
                <QueryBuilder isReadonly />
              </Grid2>
            </FormProvider>
          }
        />
      ) : (
        <DashboardHeader
          title={activeScope?.name}
          description={activeScope?.description}
        />
      )}
      <Grid container spacing={3}>
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12} lg={6}>
            <InventoryOverviewCardSunburst
              scope={
                isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
              }
              spaceScope={spaceScope}
            />
          </Grid>
          <Grid container item spacing={3} xs={12} lg={6}>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                pageType={AggregateScoreType.Vulnerability}
                scope={
                  isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
                }
                space={space}
              />
            </Grid>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                pageType={AggregateScoreType.Check}
                scope={
                  isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
                }
                space={space}
              />
            </Grid>
          </Grid>
        </Grid>
        {activeScope?.type === "space" && (
          <IntegrationsOverview {...{ spaceScope }} />
        )}
      </Grid>
    </Box>
  );
}
