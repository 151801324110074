import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  InputAdornment,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from "@mui/material";
import { Fragment, ReactNode, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ExpandLessIcon, ExpandMoreIcon, SearchIcon } from "~/components/icons";

export type Scope = "regions" | "organizations" | "spaces" | "workspaces";

export type ScopeOption = {
  id: string;
  name: string;
  href: string;
};

export type ScopeButtonProps = {
  scope: Scope;
  icon: ReactNode;
  options: ScopeOption[];
  selected?: ScopeOption;
  onSelect: (option: ScopeOption) => void;
  query?: string;
  onQuery?: (query: string) => void;
};

export function ScopeButton({
  scope,
  icon,
  options,
  selected,
  onSelect,
  query,
  onQuery,
}: ScopeButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (option: ScopeOption) => {
    setOpen(false);
    onSelect(option);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const bottomRadius = open ? 0 : 8;

  return (
    <Fragment>
      <ButtonGroup
        className="scope-button"
        variant="contained"
        disableElevation
        size="small"
        ref={anchorRef}
        sx={{
          "& .MuiButtonGroup-grouped": {
            minWidth: 0,
            textTransform: "none",
            bgcolor: "rgba(0, 0, 0, 0.3)",
            border: "1px solid",
            borderColor: (theme) =>
              open ? theme.palette.primary.main : "transparent",
            fontWeight: 400,
            whiteSpace: "nowrap",
            transition: (theme) =>
              theme.transitions.create(["border-radius", "border-color"]),
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.4)",
            },
          },
          "& .MuiButtonGroup-firstButton": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: bottomRadius,
            borderRight: "none",
            "&::before": {
              content: "''",
              bgcolor: "rgba(255, 255, 255, 0.33)",
              width: "1px",
              position: "absolute",
              top: "8px",
              bottom: "8px",
              right: 0,
            },
          },
          "& .MuiButtonGroup-lastButton": {
            borderTopRightRadius: 8,
            borderBottomRightRadius: bottomRadius,
            borderLeft: "none",
          },
          ".MuiButton-startIcon": {
            mr: selected?.name ? undefined : 0,
          },
        }}
      >
        <Button
          component={selected?.href ? RouterLink : Button}
          to={selected?.href}
          onClick={selected?.href ? handleClick : handleToggle}
          startIcon={icon}
        >
          {selected?.name || <>&#8203;</>}
        </Button>
        <Button
          aria-controls={open ? `${scope}-button-menu` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={`Select from available ${scope}`}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{ px: 0.5 }}
        >
          {open ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        modifiers={[{ name: "flip", enabled: false }]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
            <Paper
              sx={{
                border: "1px solid",
                borderColor: "primary.main",
                borderTopLeftRadius: 0,
                mt: "-1px",
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ minWidth: "100px" }}>
                  {onQuery && (
                    <TextField
                      placeholder={`Search ${scope}...`}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                        sx: { bgcolor: "code.background", m: 2, mb: 0 },
                      }}
                      value={query}
                      onChange={(e) => onQuery(e.target.value)}
                    />
                  )}
                  <MenuList
                    id={`${scope}-button-menu`}
                    disablePadding
                    subheader={
                      <ListSubheader
                        children={scope}
                        disableGutters
                        sx={{
                          textTransform: "uppercase",
                          fontSize: 12,
                          fontWeight: 700,
                          lineHeight: "16px",
                          pb: 0.5,
                        }}
                      />
                    }
                    sx={{
                      maxHeight: "calc(100vh - 152px)",
                      overflow: "auto",
                      p: 2,
                      pt: 0,
                      mt: 2,
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        disableGutters
                        component={RouterLink}
                        to={option.href}
                        key={option.id}
                        selected={option.id === selected?.id}
                        onClick={() => handleMenuItemClick(option)}
                        sx={{ p: 1, borderRadius: 1 }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
