import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box, TextFieldProps } from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { CheckboxMenuItem } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/CheckboxMenuItem";
import * as React from "react";
import { RefObject, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";

type ValuesDropdownAutocompleteProps = {
  values: string[];
  name:
    | `selections.${number}.conditions.${number}.stringCondition.values`
    | `selections.${number}.conditions.${number}.intCondition.values`
    | `selections.${number}.conditions.${number}.ratingCondition.values`;
  options: Array<{ label: string; value: string }>;
  anchorRef: RefObject<HTMLButtonElement>;
  onDropdownSearchFieldKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onDropdownSearchFieldChange: TextFieldProps["onChange"];
  isValuesDropdownOpen: boolean;
  onValuesDropdownOpen: () => void;
  onValuesDropdownClose: () => void;
  onItemClick: (value: unknown) => void;
};

export const ValuesDropdownAutocomplete = ({
  values,
  name,
  options,
  anchorRef,
  onDropdownSearchFieldKeyUp,
  onDropdownSearchFieldChange,
  onItemClick,
  isValuesDropdownOpen,
  onValuesDropdownOpen,
  onValuesDropdownClose,
}: ValuesDropdownAutocompleteProps) => {
  const { register } = useFormContext<CreateWorkspaceFormInput>();

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isValuesDropdownOpen}
        onClick={
          isValuesDropdownOpen ? onValuesDropdownClose : onValuesDropdownOpen
        }
        data-testid={`${isValuesDropdownOpen ? "close" : "open"}-values-dropdown`}
      />
      <Box sx={{ maxWidth: "400px" }}>
        <BaseAutocomplete
          showKeyboardHint
          open={isValuesDropdownOpen}
          onClose={onValuesDropdownClose}
          onDropdownSearchFieldKeyUp={onDropdownSearchFieldKeyUp}
          anchorRef={anchorRef}
          onSearchInputChange={onDropdownSearchFieldChange}
          renderContent={() => (
            <BaseMenuList sx={{ mt: 2 }}>
              {options.map((option) => (
                <CheckboxMenuItem
                  label={option.label}
                  value={option.value}
                  isChecked={values.includes(option.value)}
                  onClick={onItemClick}
                  key={option.value}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
