import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Breadcrumbs,
  Link,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { PolicyMrnToId } from "../../lib/mrn";
import { HomeIcon } from "../icons";
import {
  ExceptionGroup,
  ExceptionType,
  LoadPolicyDocument,
  LoadPolicyQuery,
  ScoringSystem,
  TestIamActionsQuery,
  useAssignPolicyMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { PendingExceptionsTooltip } from "~/components/exceptions/pending-exceptions-tooltip";
import { usePolicyViewer } from "./hooks/usePolicyViewer";
import { PolicyHeader } from "./PolicyHeader";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type Policy = NonNullable<LoadPolicyQuery["policy"]>;

export type PolicyOutletContextType = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  policy: Policy;
  policyId: string;
  policyEnabled: boolean;
  exceptionGroups: ExceptionGroup[];
};

export type PolicyViewerProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  policy: Policy;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  onAssignChange: () => void;
};

export function PolicyViewer({
  space,
  scope,
  policy,
  availablePermissions,
  onAssignChange,
}: PolicyViewerProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    scoringSystem,
    setScoringSystem,
    showScoringSystem,
    setShowScoringSystem,
    scoreByMenuItems,
    hasProperties,
    generateHref,
  } = usePolicyViewer({ policy, scope });

  const [assignPolicy, { loading: assignLoading }] = useAssignPolicyMutation({
    variables: {
      input: {
        policyMrn: policy.mrn,
        assetMrn: space.mrn,
        scoringSystem,
      },
    },
    refetchQueries: [LoadPolicyDocument],
  });

  const { exceptionGroups, pendingExceptionsGroups } = useFetchExceptions({
    mrn: policy.mrn,
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const handleChangeScoringSystem = async (
    event: SelectChangeEvent<unknown>,
  ) => {
    setScoringSystem(event.target.value as ScoringSystem);
    // if policy is already assigned, update the assigned policy to use the new scoring
    if (policy.assigned) {
      try {
        await assignPolicy({
          variables: {
            input: {
              policyMrn: policy.mrn,
              assetMrn: space.mrn,
              scoringSystem: event.target.value as ScoringSystem,
            },
          },
        });
        enqueueSnackbar("Successfully updated policy scoring", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Failed to update policy scoring", {
          variant: "error",
        });
      }
    }
  };

  const tabList: TabListItem[] = [
    {
      label: "Checks",
      to: generateHref("checks"),
      route: "/checks",
    },
    {
      label: "Assets",
      to: generateHref("assets"),
      route: "/assets",
    },
    scope.type === ScopeType.Space
      ? {
          label: (
            <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
              Exceptions
              {pendingExceptionsGroups.length > 0 && (
                <PendingExceptionsTooltip />
              )}
            </Box>
          ),
          to: generateHref("exceptions"),
          route: "/exceptions",
        }
      : null,
    hasProperties
      ? {
          label: "Properties",
          to: generateHref("properties"),
          route: "/properties",
        }
      : null,
  ].flatMap((x) => x ?? []);

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "checks",
  );

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/security/policies"
      component={RouterLink}
      to={`/space/security/policies?${scope.params}`}
      display="flex"
    >
      Policies
    </Link>,
    <Typography key={2}>{policy.name}</Typography>,
  ];

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {policy && (
        <PolicyHeader
          {...{
            space,
            scope,
            policy,
            showScoringSystem,
            setShowScoringSystem,
            availablePermissions,
            onAssignChange,
            scoringSystem,
            scoreByMenuItems,
            handleChangeScoringSystem,
          }}
        />
      )}

      <Box>
        <TabNavigation {...{ id: "policy-tabs", tabList, currentTab }} />
        <Outlet
          context={{
            space,
            scope,
            availablePermissions,
            policy,
            policyId: PolicyMrnToId(policy.mrn),
            policyEnabled: policy.assigned,
            exceptionGroups,
          }}
        />
      </Box>
    </Box>
  );
}

export function usePolicyOutletContext() {
  return useOutletContext<PolicyOutletContextType>();
}
