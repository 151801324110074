import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadingFailedPage, LoadingPage } from "../components/loading";
import {
  LoadSpaceStatsQuery,
  TestIamActionsQuery,
  useLoadSpaceStatsQuery,
} from "~/operations";
import { AwsGuide } from "./aws-guide";
import { VastEmptyness } from "./vast-emptyness/vast-emptyness";
import { HomeDashboard } from "~/pages/space/Dashboards/components/HomeDashboard";
import { Space } from "~/lib/types";
import {
  isSpaceOrWorkspaceScope,
  SpaceOrWorkspaceScope,
  useScope,
} from "~/hooks/useScope";

type SpaceStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>;

export type DashboardContentProps = {
  spaceStats: SpaceStats;
  scope: SpaceOrWorkspaceScope | undefined;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  space: Space;
};

export function DashboardContent({
  spaceStats,
  scope,
  space,
}: DashboardContentProps) {
  const noData = spaceStats.riskdistribution.total === 0;

  if (noData) {
    const VastEmptynessOptions = {
      id: "overview",
      headline: "WELCOME TO MONDOO",
      tagline:
        "To begin scanning for vulnerabilities, let's integrate your infrastructure with Mondoo.",
      buttonText: "Start Scanning",
      href: `/space/integrations/add/mondoo/setup?${scope?.params}`,
      buttonText2: "Browse Integrations",
      hrefButton2: `/space/integrations/add?${scope?.params}`,
    };

    return <VastEmptyness options={VastEmptynessOptions} />;
  }

  return <HomeDashboard scope={scope} space={space} />;
}

type SpaceDashboardProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  space: Space;
};

export function SpaceDashboard({
  availablePermissions,
  space,
}: SpaceDashboardProps) {
  const { activeScope, spaceScope } = useScope();
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(searchParams.get("guide") === "aws"));
  }, []);

  const { loading, data, error } = useLoadSpaceStatsQuery({
    variables: { mrn: spaceScope?.mrn || "" },
    notifyOnNetworkStatusChange: false,
  });

  if (loading) {
    return <LoadingPage what="Space Stats" />;
  }

  if (error || !data?.space?.stats) {
    return <LoadingFailedPage what="Space Stats" />;
  }

  const handleClose = () => {
    // remove the guide=aws search param upon closing
    searchParams.delete("guide");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const stats = data.space.stats;

  document.title = `${activeScope?.name} · Mondoo`;

  return (
    <Fragment>
      <DashboardContent
        scope={isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined}
        spaceStats={stats}
        availablePermissions={availablePermissions}
        space={space}
      />
      <AwsGuide
        scope={isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined}
        open={open}
        onClose={handleClose}
      />
    </Fragment>
  );
}
