import { Flex } from "~/components/Flex";
import { Typography } from "@mui/material";

type Props = {
  tokenType: "API tokens" | "registration tokens";
};

export const NoTokensView = ({ tokenType }: Props) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{
        py: 10,
        px: 2,
        textAlign: "center",
        border: "3px dashed",
        borderColor: "background.light",
      }}
    >
      <Typography variant="h6" component="p" color="text.secondary">
        You do not currently have any {tokenType}.
      </Typography>
      <Typography color="text.secondary">
        Select the plus sign (+) to create one.
      </Typography>
    </Flex>
  );
};
