import { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  BoxProps,
  Chip,
  TextField,
} from "@mui/material";
import { useAssetSearchSuggestionsQuery } from "~/operations";
import { KeyValue } from "~/lib/types";
import { CancelIcon } from "./icons";
import { getSearchPlaceholder } from "~/lib/search-placeholder";
import { useColorMode } from "~/providers/color-mode";
import { AssetUrlFilter } from "~/hooks/useAssetUrlStats";

type AssetSearchProps = {
  scopeMrn: string;
  filters: string[];
  scoreFilter?: string[];
  assetUrlFilter?: AssetUrlFilter;
  onQuery: (query: string[]) => void;
  rootAssetMrn?: string | null; // for use in asset explorer tab to only return suggestions for parent asset
  searchPlaceholder?: string | null;
};

export const AssetSearch = ({
  scopeMrn,
  filters,
  scoreFilter = [],
  assetUrlFilter = [],
  onQuery,
  rootAssetMrn = null,
  searchPlaceholder = null,
}: AssetSearchProps) => {
  const [keyValues, setKeyValues] = useState<KeyValue[]>([]);
  const [value, setValue] = useState<string[]>([]);

  const suggestionsResult = useAssetSearchSuggestionsQuery({
    variables: {
      input: { scopeMrn, searchKey: "", relatedAssetMrn: rootAssetMrn },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const updatedKeyValues: KeyValue[] = [];
    filters.forEach((filter) => {
      try {
        const keyValTerm = JSON.parse(filter) as KeyValue;
        if (typeof keyValTerm === "object") {
          const key = Object.keys(keyValTerm)[0];
          const value = Object.values(keyValTerm)[0];
          updatedKeyValues.push({ key, value });
        } else {
          updatedKeyValues.push({ key: "search", value: filter });
        }
      } catch {
        updatedKeyValues.push({ key: "search", value: filter });
      }
    });
    scoreFilter.forEach((filter) => {
      updatedKeyValues.push({
        key: "score",
        value: filter,
      });
    });

    assetUrlFilter.forEach(({ key, value }) => {
      updatedKeyValues.push({
        key: "graph",
        value: [key, value].join(":"),
      });
    });

    setKeyValues(updatedKeyValues);
  }, [filters]);

  const placeholder =
    value.length === 0
      ? getSearchPlaceholder(searchPlaceholder)
      : `Search values for "${value[0]}"...`;

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} placeholder={placeholder} fullWidth />;
  };

  const renderTags = (
    value: string[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) =>
    value.map((v, index) => {
      const label = v;
      const tagProps = getTagProps({ index });
      const sx = {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: "background.paper",
        borderColor: "background.light",
      };
      return (
        <Chip
          label={label}
          {...tagProps}
          deleteIcon={<></>}
          onClick={tagProps.onDelete}
          variant="outlined"
          sx={sx}
        />
      );
    });

  const swapScoreValue = (array: KeyValue[]): KeyValue[] => {
    const copiedArrayValues = [...array];
    const found = copiedArrayValues.findIndex((x) => x.key === "score");
    if (found >= 0) {
      copiedArrayValues.splice(found, 1);
    }
    return copiedArrayValues;
  };

  const onChange: AutocompleteProps<string, true, false, true>["onChange"] = (
    _event,
    value,
    reason,
  ) => {
    if (value.length === 2) {
      setValue([]);
      // We are limiting ourselves to filtering by one score type
      // because we cannot filter on both grades AND unscored/errored yet
      // so we must intercept the score tag and replace it with the new score tag
      if (value[0] === "score") {
        const adjustedKeyValues = swapScoreValue(keyValues);
        updateKeyValues([
          ...adjustedKeyValues,
          { key: value[0], value: value[1] },
        ]);
      } else {
        updateKeyValues([...keyValues, { key: value[0], value: value[1] }]);
      }
      fetchSuggestions("");
    } else {
      if (reason === "createOption") {
        setValue([]);
        updateKeyValues([...keyValues, { key: "search", value: value[0] }]);
        fetchSuggestions("");
      } else {
        setValue(value);
        fetchSuggestions(value[0]);
      }
    }
  };

  const onDelete = (index: number) => {
    const kvs = [...keyValues];
    kvs.splice(index, 1);
    updateKeyValues(kvs);
    fetchSuggestions("");
  };

  const fetchSuggestions = (searchKey: string) => {
    return suggestionsResult.refetch({ input: { scopeMrn, searchKey } });
  };

  const updateKeyValues = (keyValues: KeyValue[]) => {
    setKeyValues(keyValues);
    const query = keyValues.map((kv) =>
      kv.key === "search"
        ? kv.value || ""
        : JSON.stringify({ [kv.key]: kv.value }),
    );
    onQuery(query);
  };

  const suggestions =
    suggestionsResult.data?.assetSearchSuggestions.suggestions;
  const options = suggestions?.map((s) => s.value) || [];

  return (
    <Box component="form" noValidate sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        id="asset-search-input"
        options={options}
        loading={suggestionsResult.loading}
        renderInput={renderInput}
        renderTags={renderTags}
        multiple
        value={value}
        onChange={onChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Prevent's default 'Enter' behavior.
            // this blocks the page from refreshing when pressing enter with no text present
            event.preventDefault();
          }
        }}
      />
      <Box>
        {keyValues.map((keyValue, index) => (
          <KeyValueChip
            key={index}
            keyValue={keyValue}
            onDelete={() => onDelete(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

type KeyValueChipProps = {
  keyValue: KeyValue;
  onDelete?: BoxProps["onClick"];
};

export function KeyValueChip({ keyValue, onDelete }: KeyValueChipProps) {
  const { key, value } = keyValue;
  const label = `${key} ${value}`;
  const { mode } = useColorMode();

  const chipSx = {
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid",
    borderColor: "background.light",
    backgroundColor: "background.light",
    marginTop: 1,
    marginRight: 1,
    paddingRight: 1,
    borderRadius: 1,
    typography: "body2",
    cursor: "pointer",
    fontSize: "13px",
    overflowWrap: "anywhere",
  };
  const keySx = {
    backgroundColor: "background.paper",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  const valSx = {
    backgroundColor: "background.light",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  return (
    <Box sx={chipSx} onClick={onDelete} aria-label={label}>
      <Box sx={keySx}>{key}</Box>
      <Box sx={valSx}>{value}</Box>
      <CancelIcon
        sx={{
          color: "text.primary",
          opacity: "0.26",
          ...(mode === "light" ? { ml: 1 } : {}),
        }}
      />
    </Box>
  );
}
