import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import { Fragment } from "react";
import { OrderDirection } from "~/operations";
import { DataTable } from "../data-table";
import { Pagination, PaginationProps } from "../pagination";
import { ExceptionData } from "../policy-gql/PolicyChecks";
import { Space } from "~/lib/types";
import { useGenericTable } from "./UseGenericTable";

export type GenericTableProps = {
  space: Space;
  children: React.ReactNode;
  items: number;
  tableName: string;
  validSortFields: string[];
  paginationProps?: PaginationProps;
  disableSorting?: boolean;
  allowExceptionSetting?: boolean;
  exceptionData?: ExceptionData;
  noTags?: boolean;
};

export function GenericTable({
  children,
  items,
  tableName,
  validSortFields,
  ...props
}: GenericTableProps) {
  const { tableHeaders, handleSortClick, orderBy } = useGenericTable({
    tableName,
    validSortFields,
  });
  return (
    <Fragment>
      <DataTable
        selectable={props.allowExceptionSetting}
        selection={props.exceptionData?.selectedChecks}
      >
        <TableHead>
          <TableRow>
            {props.allowExceptionSetting && props.exceptionData && (
              <TableCell
                width={50}
                sx={{
                  display: props.exceptionData.policyEnabled ? "" : "none",
                }}
              >
                <Checkbox
                  checked={
                    props.exceptionData.selectedChecks.length === (items || 0)
                  }
                  indeterminate={
                    props.exceptionData.selectedChecks.length > 0 &&
                    props.exceptionData.selectedChecks.length < (items || 0)
                  }
                  onChange={(_, checked) =>
                    props.exceptionData?.handleCheckAll(checked)
                  }
                />
              </TableCell>
            )}
            {tableHeaders.map((header) => {
              const { sortable } = header;
              const isSortable = sortable && !props.disableSorting;

              return (
                <TableCell
                  key={header.field}
                  align={header.options?.textAlign}
                  width={header.options?.width}
                  sx={header.options?.sx}
                >
                  {isSortable ? (
                    <TableSortLabel
                      onClick={() => handleSortClick(header.field)}
                      direction={
                        orderBy.direction === OrderDirection.Asc
                          ? "asc"
                          : "desc"
                      }
                      active={header.field === orderBy.field}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </DataTable>
      {props.paginationProps?.pageInfo && (
        <Pagination {...props.paginationProps} />
      )}
    </Fragment>
  );
}
