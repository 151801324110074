import { ConfigurationListSubheader } from "~/components/configuration-items";
import { Flex } from "~/components/Flex";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { ReactNode } from "react";

type CaseSettingsIntegrationBoxProps = {
  children: ReactNode;
  integrationName: string;
  href: string;
};

export const CaseSettingsIntegrationBox = ({
  children,
  integrationName,
  href,
}: CaseSettingsIntegrationBoxProps) => {
  return (
    <Box>
      <ConfigurationListSubheader component="div">
        <Flex
          justifyContent="space-between"
          flex="1 0 auto"
          alignItems="center"
        >
          <Typography component="span" variant="h6">
            {integrationName} Settings
          </Typography>
          <IconButton
            component={Link}
            to={href}
            aria-label="navigate to integration"
          >
            <ArrowForward color="primary" />
          </IconButton>
        </Flex>
      </ConfigurationListSubheader>
      {children}
    </Box>
  );
};
