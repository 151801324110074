import { alpha, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";

export type EmptySectionProps = {
  text: string;
  id?: string;
};

export function EmptySection({ text, id }: EmptySectionProps) {
  return (
    <Flex id={id} alignItems="center" justifyContent="center" py={12}>
      <Typography
        sx={{
          fontWeight: 700,
          color: (theme) => alpha(theme.palette.text.primary, 0.33),
        }}
      >
        {text}
      </Typography>
    </Flex>
  );
}
