export const mapDetectedByKeys = (key: string) => {
  switch (key.toLowerCase()) {
    case "affectedassets":
      return "Assets found";
    case "firstdetectedat":
      return "First found";
    case "lastupdatedat":
      return "Last updated";
    case "vendor":
      return "Vendor";
    default:
      return key;
  }
};
