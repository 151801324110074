import { ConfigurationListSubheader } from "~/components/configuration-items";
import { Flex } from "~/components/Flex";
import { getGraphQLErrors } from "~/components/ui-library";
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  Switch,
  Typography,
} from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { Space } from "~/lib/types";
import { SwitchProps } from "@mui/material/Switch/Switch";
import { useSnackbar } from "notistack";
import { CasesConfigurationInput, useLoadSpaceQuery } from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";

type CaseSettingsGeneralProps = {
  space: Space;
};

const AggregateWindowsValues = [
  { label: "No aggregation", value: 0 },
  { label: "1 hour", value: 1 },
  { label: "4 hours", value: 4 },
  { label: "8 hours", value: 8 },
  { label: "12 hours", value: 12 },
  { label: "24 hours", value: 24 },
];

type onCaseSettingsChange = {
  <Key extends keyof CasesConfigurationInput>(
    setting: Key,
    value: CasesConfigurationInput[Key],
  ): Promise<void>;
};

export const CaseSettingsGeneral = ({ space }: CaseSettingsGeneralProps) => {
  const { updateSpace } = useViewer();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useLoadSpaceQuery({
    variables: { spaceMrn: space.mrn },
  });

  if (loading) {
    return <LoadingPage what="Space" />;
  }

  if (error || !data?.space) {
    return <LoadingFailedPage what="Space" />;
  }

  const settings = data.space.settings;

  const handleCaseSettingChange: onCaseSettingsChange = async (
    setting,
    value,
  ) => {
    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings: {
              casesConfiguration: {
                ...settings?.casesConfiguration,
                [setting]: value,
              },
            },
          },
        },
      });

      enqueueSnackbar("Case settings successfully updated", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        getGraphQLErrors({
          error,
          errorMessage: "Failed to update case settings.",
        }),
        {
          variant: "error",
        },
      );
    }
  };

  const handleAutoCreateChange: SwitchProps["onChange"] = async (_, value) => {
    await handleCaseSettingChange("autoCreate", value);
  };

  const handleAggregationWindowChange: SelectProps<
    string | number
  >["onChange"] = async (event) => {
    await handleCaseSettingChange(
      "aggregationWindow",
      Number(event.target.value) || 0,
    );
  };

  return (
    <Box>
      <ConfigurationListSubheader component="div">
        <Flex
          justifyContent="space-between"
          flex="1 0 auto"
          alignItems="center"
        >
          <Typography component="span" variant="h6">
            General
          </Typography>
        </Flex>
      </ConfigurationListSubheader>
      <Paper sx={{ p: 3 }}>
        <Grid container xs={12} sx={{ pr: 2 }}>
          <Grid item xs>
            <Typography fontWeight={700} className="form-text">
              Automatically create cases on drift
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="form-text"
            >
              Automatically create a case when previously passing checks fail.
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleAutoCreateChange}
                  checked={settings?.casesConfiguration.autoCreate}
                  size="small"
                  disabled={false}
                  sx={{
                    "& .Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "primary.main",
                    },
                  }}
                />
              }
              label={settings?.casesConfiguration.autoCreate ? "On" : "Off"}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} sx={{ pr: 2, mt: 3 }}>
          <Grid item xs>
            <Typography fontWeight={700} className="form-text">
              Aggregation window
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="form-text"
            >
              Control how long Mondoo collects similar findings into a case
              before finalizing the case and opening a ticket/issue.
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              label=""
              control={
                <Select
                  value={settings?.casesConfiguration.aggregationWindow}
                  onChange={handleAggregationWindowChange}
                  inputProps={{
                    name: "role",
                    id: "member-role",
                  }}
                  sx={{
                    "&.MuiInputBase-root": {
                      bgcolor: "code.background",
                    },
                    width: "100%",
                  }}
                >
                  {AggregateWindowsValues.map((item) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })}
                </Select>
              }
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
