import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { IamActions } from "~/lib/iam";
import {
  useGetOrganizationScopeQuery,
  useGetSpaceScopeQuery,
  useGetWorkspaceScopeQuery,
} from "~/operations";
import { useViewer } from "~/providers/viewer";

export enum ScopeType {
  Region = "region",
  Organization = "organization",
  Space = "space",
  Workspace = "workspace",
}

export type Scope = {
  type: ScopeType;
  id: string;
  mrn: string;
  name: string;
  description: string;
  params: URLSearchParams;
  iamActions: string[];
};

export type RegionScope = Scope & { type: ScopeType.Region };
export type OrganizationScope = Scope & { type: ScopeType.Organization };
export type SpaceScope = Scope & { type: ScopeType.Space };
export type WorkspaceScope = Scope & { type: ScopeType.Workspace };
export type ActiveScope =
  | RegionScope
  | OrganizationScope
  | SpaceScope
  | WorkspaceScope;

// Useful when a component wants to enforce it can only be used within a Space or Workspace
// `export type ComponentProps = { activeScope: SpaceOrWorkspaceScope }`
export type SpaceOrWorkspaceScope = SpaceScope | WorkspaceScope;
export function isSpaceOrWorkspaceScope(
  scope?: Scope,
): scope is SpaceOrWorkspaceScope {
  return scope?.type === ScopeType.Space || scope?.type === ScopeType.Workspace;
}

export const sharedSpacesOrgId = "shared-spaces";

export type SpaceId = string;
export type OrgId = string;
export type SpaceMrn = string;
export type OrgMrn = string;
export type WorkspaceMrn = string;
export type WorkspaceId = string;

export function organizationMrnFromId(id: OrgId): OrgMrn {
  return `//captain.api.mondoo.app/organizations/${id}`;
}

export function organizationIdFromMrn(mrn: OrgMrn): SpaceId {
  return mrn.split("/").pop()!;
}

export function spaceMrnFromId(id: SpaceId): SpaceMrn {
  return `//captain.api.mondoo.app/spaces/${id}`;
}

export function spaceIdFromMrn(mrn: SpaceMrn): SpaceId {
  return mrn.split("/").pop()!;
}

// This isn't supported. Don't try to convert workspace id to mrn.
// Workspace mrn structure is likely to change in future.
// export function workspaceMrnFromId(id: WorkspaceId): WorkspaceMrn {
//   throw new Error("workspaceMrn can not safely be inferred from workspaceId");
// }

// Only used for convenient display purposes
export function workspaceIdFromMrn(mrn: WorkspaceMrn): WorkspaceId {
  return mrn.split("/").pop()!;
}

export type UseScopeContext = {
  regionScope?: RegionScope;
  organizationScope?: OrganizationScope;
  spaceScope?: SpaceScope;
  workspaceScope?: WorkspaceScope;
  activeScope?: ActiveScope;
};
export function useScope(): UseScopeContext {
  const [searchParams] = useSearchParams();
  const { selectedRegion } = useViewer(); // We might pull the region stuff out of `useViewer`

  const workspaceIdParam = searchParams.get("workspaceId");
  const workspaceMrn = workspaceIdParam;
  const workspaceId = workspaceMrn
    ? workspaceIdFromMrn(workspaceMrn)
    : undefined;

  const workspaceResult = useGetWorkspaceScopeQuery({
    variables: { mrn: workspaceMrn || "skip", actions: [] },
    skip: !workspaceMrn,
  });
  const workspaceData =
    workspaceResult.data?.workspace.__typename === "Workspace"
      ? workspaceResult.data.workspace
      : undefined;
  const workspaceIamActions = workspaceResult.data?.iamActions || [];

  const spaceIdParam = searchParams.get("spaceId");
  const spaceId = spaceIdParam;
  const spaceMrn = spaceId ? spaceMrnFromId(spaceId) : undefined;
  const spaceResult = useGetSpaceScopeQuery({
    variables: { mrn: spaceMrn || "skip", actions: IamActions.default() },
    skip: !spaceMrn,
  });
  const spaceData = spaceResult.data?.space;
  const spaceIamActions = spaceResult.data?.iamActions || [];

  const organizationIdParam = searchParams.get("organizationId");
  const organizationId = organizationIdParam || spaceData?.organization?.id;
  const organizationMrn = organizationId
    ? organizationMrnFromId(organizationId)
    : undefined;
  const organizationResult = useGetOrganizationScopeQuery({
    variables: {
      mrn: organizationMrn || "skip",
      actions: IamActions.default(),
    },
    skip: !organizationMrn,
  });
  const organizationData = spaceData?.shared
    ? {
        id: sharedSpacesOrgId,
        mrn: organizationMrnFromId(sharedSpacesOrgId),
        name: "Shared Spaces",
        description:
          "Spaces that have been shared with you from other organizations.",
      }
    : organizationResult.data?.organization;
  const organizationIamActions = organizationResult.data?.iamActions || [];

  const regionName = selectedRegion.name;
  const regionData = {
    id: regionName,
    mrn: regionName,
    name: regionName,
    description: `${regionName} region`,
  };
  // Regions don't have mrns which means they don't have a permissions boundary
  const regionIamActions: string[] = [];

  // Workspace:    some/path?spaceId=space&workspaceId=workspace
  // Space:        some/path?spaceId=space
  // Organization: some/path?organizationId=org
  // Region:       some/path
  const workspaceParams = new URLSearchParams(
    spaceId && workspaceMrn
      ? {
          spaceId,
          workspaceId: workspaceMrn,
        }
      : {},
  );
  const spaceParams = new URLSearchParams(spaceId ? { spaceId } : {});
  const organizationParams = new URLSearchParams(
    organizationId ? { organizationId } : {},
  );
  const regionParams = new URLSearchParams();

  const regionScope: RegionScope | undefined = useMemo(
    () => ({
      type: ScopeType.Region,
      id: regionData.id,
      mrn: regionData.mrn,
      name: regionData.name,
      description: regionData.description,
      iamActions: regionIamActions,
      params: regionParams,
    }),
    [regionData, regionIamActions],
  );

  const organizationScope: OrganizationScope | undefined = useMemo(
    () =>
      organizationData
        ? {
            type: ScopeType.Organization,
            id: organizationData.id,
            mrn: organizationData.mrn,
            name: organizationData.name,
            description: organizationData.description,
            iamActions: organizationIamActions,
            params: organizationParams,
          }
        : undefined,
    [organizationData],
  );

  const spaceScope: SpaceScope | undefined = useMemo(
    () =>
      spaceData
        ? {
            type: ScopeType.Space,
            id: spaceData.id,
            mrn: spaceData.mrn,
            name: spaceData.name,
            description: spaceData.description,
            iamActions: spaceIamActions,
            params: spaceParams,
          }
        : undefined,
    [spaceData],
  );

  const workspaceScope: WorkspaceScope | undefined = useMemo(
    () =>
      workspaceData
        ? {
            type: ScopeType.Workspace,
            id: workspaceIdFromMrn(workspaceData.mrn),
            mrn: workspaceData.mrn,
            name: workspaceData.name,
            description: workspaceData.description || "",
            iamActions: workspaceIamActions,
            params: workspaceParams,
          }
        : undefined,
    [workspaceData],
  );

  const activeScope =
    workspaceIdParam && spaceIdParam
      ? workspaceScope
      : spaceIdParam
        ? spaceScope
        : organizationIdParam
          ? organizationScope
          : regionScope;

  return {
    regionScope,
    organizationScope,
    spaceScope,
    workspaceScope,
    activeScope,
  };
}
