import { Route, Routes } from "react-router-dom";
import { Redirect } from "~/routes";

export function MVDRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<Redirect from="mvd" to="vulns" state={{ source: "mvd" }} />}
      />
      <Route path="advisory">
        <Route
          path=":id/*"
          element={<Redirect from="mvd" to="vulns" state={{ source: "mvd" }} />}
        />
      </Route>

      <Route path="cve">
        <Route
          path=":id/*"
          element={<Redirect from="mvd" to="vulns" state={{ source: "mvd" }} />}
        />
      </Route>
    </Routes>
  );
}
