import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { formatRFC3339 } from "date-fns";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { Flex } from "~/components/Flex";
import { Code } from "~/components/code";
import {
  CreateServiceAccountMutationResult,
  useCreateServiceAccountMutation,
} from "~/operations";
import { AddButton } from "~/components/add-button";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getError } from "~/lib/handle-error";
import { Space, Org } from "~/lib/types";
import { mapKeys, snakeCase } from "lodash";

interface SpaceLevel {
  space: Space;
  org?: never;
}

interface OrgLevel {
  space?: never;
  org: Org;
}

type Props = SpaceLevel | OrgLevel;

type CreatedServiceAccountResponse = NonNullable<
  CreateServiceAccountMutationResult["data"]
>;
type CreatedServiceAccountData =
  CreatedServiceAccountResponse["createServiceAccount"];

type FormInput = {
  name: string;
  description: string;
  permissions: {
    viewer: boolean;
    editor: boolean;
    owner: boolean;
    agent: boolean;
  };
};

const defaultValues = {
  name: "",
  description: "",
  permissions: {
    viewer: false,
    editor: false,
    owner: false,
    agent: false,
  },
};

export function GenerateServiceAccountsForm({ space, org }: Props) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [createdServiceAccountData, setCreatedServiceAccountData] = useState<
    CreatedServiceAccountData | undefined
  >(undefined);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormInput>({ defaultValues, mode: "onChange" });
  const scope = org ? "organization" : "space";

  useEffect(() => {
    if (createdServiceAccountData?.certificate) {
      setFormDisabled(true);
    }
  }, [createdServiceAccountData]);

  useLayoutEffect(() => {
    if (createdServiceAccountData?.certificate) {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    }
  }, [createdServiceAccountData]);

  const [createServiceAccount] = useCreateServiceAccountMutation({
    onCompleted(data) {
      setCreatedServiceAccountData(data.createServiceAccount);
    },
  });

  const onSubmit = async (data: FormInput) => {
    try {
      const roles = Object.entries(data.permissions)
        .filter(([_role, value]) => value === true)
        .map(([role, _value]) => {
          return {
            mrn: `//iam.api.mondoo.app/roles/${role}`,
          };
        });

      await createServiceAccount({
        variables: {
          input: {
            name: data.name,
            scopeMrn: space ? space.mrn : org.mrn,
            description: data.description,
            roles,
            validUntil: null,
            labels: [],
          },
        },
      });

      enqueueSnackbar("Successfully generated Service Account", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const handleCloseForm = () => {
    if (org) {
      navigate(
        `/organization/settings/serviceaccounts?organizationId=${org.id}`,
      );
    } else if (space) {
      navigate(`/space/settings/serviceaccounts?spaceId=${space.id}`);
    }
  };

  const generateFileName = () => {
    let id = space ? space.id : org.id;
    const date = formatRFC3339(new Date());
    return `${id}-${date}.json`;
  };

  const formatCreatedServiceAccountData = (
    d: CreatedServiceAccountData,
  ): string => {
    const data = mapKeys(d, (_value, key) => snakeCase(key));
    return JSON.stringify(data);
  };

  // Create download link for service account data
  const json = createdServiceAccountData
    ? formatCreatedServiceAccountData(createdServiceAccountData)
    : undefined;
  const file = json
    ? new Blob([json], { type: "application/json" })
    : undefined;
  const href = file ? URL.createObjectURL(file) : undefined;

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h5" fontWeight={700} textTransform="uppercase">
          Generate a new Service Account
        </Typography>
        <AddButton id="generate-form" onClick={handleCloseForm} close />
      </Flex>

      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography
            variant="h5"
            component="p"
            fontWeight={700}
            sx={{ mr: 3 }}
          >
            Information
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          component="fieldset"
          disabled={formDisabled}
          sx={{ m: 0, p: 0, border: "none" }}
        >
          <Box mb={3}>
            <Typography sx={{ ...formLabelStyle }}>Name</Typography>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={formDisabled}
                  placeholder="Give your service account a unique name ..."
                  fullWidth
                  sx={(theme) => ({ ...formTextFieldStyle(theme) })}
                />
              )}
            />
          </Box>
          <Box mb={5}>
            <Typography sx={{ ...formLabelStyle }}>Description</Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={formDisabled}
                  placeholder="Describe what the service account is used for ..."
                  fullWidth
                  multiline
                  rows={3}
                  sx={(theme) => ({ ...formTextFieldStyle(theme) })}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Grid container alignItems="center" mb={3}>
              <Grid item>
                <Typography
                  variant="h5"
                  component="p"
                  fontWeight={700}
                  sx={{ mr: 3 }}
                >
                  Permissions
                </Typography>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
            </Grid>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Controller
                name="permissions.viewer"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Viewer</Typography>}
                    description={`View most content in ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.editor"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Editor</Typography>}
                    description={`View, create, update, and delete assets, reports and members. Cannot delete the ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.owner"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Owner</Typography>}
                    description={`Full access to all data in ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.agent"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Agent</Typography>}
                    description="Ability to fetch and run polices and querypacks and report its results."
                  />
                )}
              />
            </Grid>
          </Box>

          <Box
            sx={{
              mb: 5,
              pb: 5,
              textAlign: "right",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || !isDirty || formDisabled}
            >
              {formDisabled
                ? "Service Account Generated "
                : "Generate Service Account"}
            </Button>
          </Box>
        </Box>
      </form>
      <Box mb={5}>
        {createdServiceAccountData && (
          <Fragment>
            <Typography variant="h5" component="p" fontWeight={700}>
              Credentials
            </Typography>
            <Grid container columnGap={4}>
              <Grid item md>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  Use the following plain .json credentials to connect the
                  service to Mondoo. You can also download the credentials as
                  .json file. Use Base64 instead if you plan to use the service
                  account as environment variable in a CI/CD service.
                </Typography>
              </Grid>
              <Grid item md="auto">
                {/* Only display the download button if the credentials are downloaded and packaged */}
                {href && (
                  <Button
                    variant="text"
                    color="primary"
                    href={href}
                    download={generateFileName()}
                  >
                    {" "}
                    Download Credentials{" "}
                  </Button>
                )}
              </Grid>
            </Grid>

            <Typography sx={{ ...formLabelStyle }}>Plain .JSON</Typography>
            <Code className="bash" copyButton>
              {formatCreatedServiceAccountData(createdServiceAccountData)}
            </Code>

            <Typography sx={{ ...formLabelStyle }}>Base64</Typography>
            <Code className="bash" copyButton>
              {window.btoa(
                formatCreatedServiceAccountData(createdServiceAccountData),
              )}
            </Code>
            <Box mt={8.25} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleCloseForm}
              >
                Finalize
              </Button>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

const FormCheckbox = ({
  field,
  label,
  description,
  disabled,
}: {
  field: ControllerRenderProps<FormInput, any>;
  label: FormControlLabelProps["label"];
  description: string;
  disabled: boolean;
}) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <FormControlLabel
        control={
          <Checkbox {...field} disabled={disabled} checked={field.value} />
        }
        {...{ label }}
      />
      <Typography fontSize={12} color="text.secondary" sx={{ mt: -1, ml: 4 }}>
        {description}
      </Typography>
    </Grid>
  );
};

const formLabelStyle = { mb: 1, fontWeight: 700, color: "text.secondary" };
const formTextFieldStyle = (theme: Theme) => ({
  background: theme.palette.code.background,
  borderRadius: 1,
  color: "text.primary",
});
