import { ReactElement, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  AutocompleteProps,
  Box,
  Button,
  Checkbox,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Flex } from "~/components/Flex";
import { Loading, LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  ServiceAccountOrderField,
  TestIamActionsQuery,
  useServiceAccountForwardPaginationQuery,
  useSetRolesMutation,
  ServiceAccountOrder,
  ServiceAccount,
  useDeleteServiceAccountsMutation,
  OrderDirection,
} from "~/operations";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";

import { pluralize } from "~/lib/pluralize";
import {
  EditRoleDialog,
  formatRoleName,
  PermissionFormInput,
} from "~/components/edit-role-dialog";
import { IamActions } from "~/lib/iam";
import { Org, Space } from "~/lib/types";
import { TokenSearch } from "~/pages/organization/settings/components/token-search";
import {
  TokenTableRow,
  TokenTableRowProps,
} from "~/pages/space-settings/api-tokens/token-table-row";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { AddButton } from "~/components/add-button";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { APITokenToDelete } from "~/pages/space-settings/api-tokens/settings-api-tokens";
import { FormatAbbrDateYear } from "~/lib/date";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";

interface OrgLevel {
  org: Org;
  space?: never;
}

interface SpaceLevel {
  org?: never;
  space: Space;
}

type Props = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
} & (OrgLevel | SpaceLevel);

export function ServiceAccounts({ space, org, availablePermissions }: Props) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);

  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteSingleDialogOpen, setDeleteSingleDialogOpen] =
    useState<boolean>(false);

  // Params
  const [direction, setDirection] = useState<OrderDirection>(
    (searchParams.get("orderBy") as OrderDirection) || OrderDirection.Desc,
  );
  const [field, setField] = useState<ServiceAccountOrderField>(
    (searchParams.get("field") as ServiceAccountOrderField) ||
      ServiceAccountOrderField.CreatedAt,
  );
  const [searchFilters, setSearchFilters] = useState<string[]>(
    () => searchParams.get("queryTerms")?.split(",") || [],
  );

  // Editing and Deleting
  const [selection, setSelection] = useState<APITokenToDelete[]>([]);
  const [itemToDelete, setItemToDelete] = useState<APITokenToDelete>();
  const [serviceAccountToEdit, setServiceAccountToEdit] =
    useState<ServiceAccount | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  // toggle the edit dialog form based on whether or not we have
  // a Service Account available to edit
  useEffect(() => {
    if (serviceAccountToEdit) {
      setEditDialogOpen(true);
    } else {
      setEditDialogOpen(false);
    }
  }, [serviceAccountToEdit]);

  useEffect(() => {
    const nextSearchFilters = searchParams.get("queryTerms");
    if (nextSearchFilters) {
      setSearchFilters(nextSearchFilters.split(","));
    } else {
      setSearchFilters([]);
    }
  }, [searchParams]);

  // Get Service Accounts
  const { loading, data, error, refetch, fetchMore } =
    useServiceAccountForwardPaginationQuery({
      variables: {
        spaceMrn: space?.mrn || "",
        scopeMrn: org?.mrn,
        queryTerms: searchFilters,
        orderBy: {
          field: field,
          direction: direction,
        },
        first: 50,
      },
      initialFetchPolicy: "cache-and-network",
    });

  const [setRoles] = useSetRolesMutation();

  const [deleteServiceAccount] = useDeleteServiceAccountsMutation({
    onCompleted: () => refetch(),
  });

  if (loading && !data) {
    return <LoadingPage what="Service Accounts" />;
  }

  if (error) {
    return <LoadingFailedPage what="Service Accounts" />;
  }

  const hasCreateAgentPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_CREATEAGENT,
  );
  const hasDeleteAgentsPermissions = availablePermissions?.includes(
    IamActions.AGENTMANAGER_DELETEAGENTS,
  );
  const hasSetAgentMembershipPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP,
  );

  const handleSort = (id: string) => {
    const newDirection =
      direction === OrderDirection.Desc
        ? OrderDirection.Asc
        : OrderDirection.Desc;
    const newField = id as ServiceAccountOrderField;

    const newOrder: ServiceAccountOrder = {
      field: newField,
      direction: newDirection,
    };

    setDirection(newDirection);
    setField(newField);
    refetch({ orderBy: newOrder });
  };

  const handleAddTokenClick = async () => {
    if (space) {
      navigate(`add?spaceId=${space.id}`);
    } else if (org) {
      navigate(`add?organizationId=${org.id}`);
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(undefined);
    setDeleteSingleDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const handleSingleItemDelete = async (
    params: APITokenToDelete | undefined,
  ) => {
    if (!params?.mrn) return;
    setIsDeleting(true);
    try {
      await deleteServiceAccount({
        variables: {
          input: {
            scopeMrn: space ? space.mrn : org.mrn,
            mrns: [params.mrn],
          },
        },
      });
      enqueueSnackbar("Successfully deleted Service Account", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`Failed to remove Service Account`, { variant: "error" });
    }
    setDeleteSingleDialogOpen(false);
    setIsDeleting(false);
  };

  const completeBatchEdit = async () => {
    if (selection.length < 1) return resetEditing();
    const total = selection.length;

    try {
      await deleteServiceAccount({
        variables: {
          input: {
            scopeMrn: space ? space.mrn : org.mrn,
            mrns: selection.map((s) => s.mrn),
          },
        },
      });
      enqueueSnackbar(
        `Successfully removed ${total} Service ${pluralize("account", total)}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        `Failed to remove Service ${pluralize("account", total)}`,
        {
          variant: "error",
        },
      );
    }

    resetEditing();
  };

  const handleSelectAll = () => {
    if (selection.length > 0) {
      return setSelection([]);
    }

    const allPageItems =
      data?.serviceAccounts?.edges?.slice(pageItems.from, pageItems.to) || [];

    const tmp = allPageItems
      .flatMap((edge) => edge ?? [])
      .map((edge) => ({
        mrn: String(edge.node?.mrn),
        date: FormatAbbrDateYear(edge.node?.createdAt),
        name: edge.node?.creator?.email,
        reason: edge.node?.description,
      }));

    setSelection(tmp);
  };

  const resetEditing = () => {
    setDeleteDialogOpen(false);
    setSelection([]);
  };

  const handleDeleteClick = () => {
    if (selection.length === 1) {
      const item = selection[0];
      setItemToDelete({
        mrn: item.mrn,
        date: item.date,
        name: item.name,
        reason: item.reason,
      });
      setDeleteSingleDialogOpen(true);
      return;
    }
    setDeleteDialogOpen(true);
  };

  const handleCancelClick = () => {
    setSelection([]);
  };

  const handleEditPermissions: TokenTableRowProps["handleEditPermissions"] = (
    serviceAccount,
  ) => {
    if (serviceAccount.__typename !== "ServiceAccount") {
      setServiceAccountToEdit(null);
    } else {
      setServiceAccountToEdit(serviceAccount);
    }
  };

  const onPermissionEditSubmit: SubmitHandler<PermissionFormInput> = async (
    data,
  ) => {
    if (!serviceAccountToEdit) {
      throw new Error("Uh oh, there's no Service Account to edit");
    }
    const roles = Object.entries(data)
      .filter(([_role, value]) => value === true)
      .map(([role, _value]) => {
        return {
          mrn: `//iam.api.mondoo.app/roles/${formatRoleName(
            role as keyof PermissionFormInput,
          )}`,
        };
      });

    try {
      const { data } = await setRoles({
        variables: {
          input: {
            spaceMrn: space?.mrn || "",
            scopeMrn: org?.mrn,
            updates: [
              {
                entityMrn: serviceAccountToEdit.mrn,
                roles,
              },
            ],
          },
        },
        onCompleted: () => refetch(),
      });
      const errors = Object.values(data?.setRoles?.errors || {});
      if (errors.length > 0) {
        throw new Error(errors.map((e) => e).join(", "));
      }
      enqueueSnackbar(`Successfully updated permissions`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to update permissions", {
        variant: "error",
      });
    } finally {
      setServiceAccountToEdit(null);
    }
  };

  const onTagDelete = (value: string) => {
    const queryTerms = searchParams.get("queryTerms");

    if (queryTerms) {
      const qtArray = queryTerms.split(",");
      if (qtArray.length <= 1) {
        searchParams.delete("queryTerms");
      } else {
        const found = qtArray.indexOf(value);
        qtArray.splice(found, 1);
        searchParams.set("queryTerms", qtArray.join(","));
      }

      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  const handleFilterQuery: AutocompleteProps<
    string,
    true,
    false,
    true
  >["onChange"] = (_event, query, reason) => {
    if (reason === "clear") {
      searchParams.delete("queryTerms");
    } else {
      searchParams.set("queryTerms", query.join(","));
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  return (
    <Box>
      {/* Bulk Delete and Search Component */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <TokenSearch
          onDelete={onTagDelete}
          {...{
            searchFilters,
            handleFilterQuery,
            placeholder: "service account",
          }}
        />
        {hasCreateAgentPermission && (
          <Box sx={{ pl: 3, pt: 1 }}>
            <AddButton
              id="add-api-token"
              onClick={handleAddTokenClick}
              aria-label="Add API Token Token"
            />
          </Box>
        )}
      </Box>
      {data?.serviceAccounts?.totalCount === 0 ? (
        <NoTokensView />
      ) : (
        <Box>
          {data && loading ? (
            <Flex alignItems="center" justifyContent="center" sx={{ py: 5 }}>
              <Loading what="Service Accounts" />
            </Flex>
          ) : (
            <DataTable
              selectable={hasDeleteAgentsPermissions}
              selection={selection}
              id="service-accounts-list"
            >
              <TableHead>
                <TableRow>
                  {hasDeleteAgentsPermissions && (
                    <TableCell>
                      <Checkbox
                        checked={
                          (data?.serviceAccounts?.edges?.length || 0) > 0 &&
                          selection.length ===
                            data?.serviceAccounts?.edges?.length
                        }
                        indeterminate={
                          selection.length > 0 &&
                          selection.length <
                            (data?.serviceAccounts?.edges?.length || 0)
                        }
                        onChange={handleSelectAll}
                        disabled={data?.serviceAccounts?.edges?.length === 0}
                      />
                    </TableCell>
                  )}

                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header.id}
                      sx={{ ...header.options }}
                      {...(header.colSpan ? { colSpan: header.colSpan } : {})}
                      {...(header.sortable
                        ? {
                            sortDirection: "desc",
                          }
                        : {})}
                    >
                      {header.sortable ? (
                        <TableSortLabel
                          onClick={() => handleSort(header.id)}
                          direction={
                            direction === OrderDirection.Desc ? "desc" : "asc"
                          }
                          active={field === header.id}
                        >
                          {header.label}
                        </TableSortLabel>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  ))}
                  <TableCell width={50}>
                    {/* Intentionally left blank */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.serviceAccounts?.edges
                  ?.slice(pageItems.from, pageItems.to)
                  .map((edge) => {
                    return (
                      <TokenTableRow
                        key={edge?.node?.mrn}
                        {...{
                          edge,
                          hasDeleteAgentsPermissions,
                          hasSetAgentMembershipPermission,
                          setDeleteDialogOpen: setDeleteSingleDialogOpen,
                          setItemToDelete,
                          isSelectable: hasDeleteAgentsPermissions,
                          selection,
                          setSelection,
                          setEditDialogOpen,
                          handleEditPermissions,
                        }}
                      />
                    );
                  })}
              </TableBody>
            </DataTable>
          )}
          <Pagination
            fetchMore={fetchMore}
            pageInfo={data?.serviceAccounts?.pageInfo}
            totalCount={data?.serviceAccounts?.totalCount || 0}
            setPageItems={setPageItems}
          />
          {selection.length > 0 && (
            <SelectionToolbar>
              <Typography>
                Selected {selection.length} of{" "}
                {data?.serviceAccounts?.totalCount} service accounts
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </SelectionToolbar>
          )}
        </Box>
      )}

      <EditRoleDialog
        open={editDialogOpen}
        roles={serviceAccountToEdit?.roles}
        onConfirm={onPermissionEditSubmit}
        onClose={() => setServiceAccountToEdit(null)}
        scope={org ? "organization" : "space"}
      />

      {/* Bulk Delete confirmation dialog */}
      <ConfirmationDialog
        isOpen={deleteDialogOpen}
        title={`ARE YOU SURE YOU WANT TO DELETE ${
          selection.length
        } SERVICE ${pluralize("ACCOUNT", selection.length)}?`}
        confirmButtonText="DELETE"
        content={
          <>
            <Typography>
              These service accounts might serve crucial automation purposes in
              your space, which are specified by the user who created them
              within their respective descriptions.
            </Typography>
            <Typography>
              If external services (such as CI/CD pipelines use these service
              accounts), deleting the token can cause the pipeline to fail. Once
              you delete a service account, there's no way to recover it.
            </Typography>
          </>
        }
        onConfirm={completeBatchEdit}
        onClose={handleCancelDelete}
        loading={isDeleting}
      />

      {/* Single token Delete confirm dialog */}
      <ConfirmationDialog
        isOpen={deleteSingleDialogOpen}
        confirmButtonText="DELETE"
        content={
          <>
            <Typography>
              This service account was created{" "}
              {itemToDelete?.name && `by ${itemToDelete.name}`}{" "}
              {itemToDelete?.date &&
                `on ${FormatAbbrDateYear(itemToDelete.date)}`}{" "}
              for this reason: “{itemToDelete?.reason}”
            </Typography>
            <Typography>
              If external services (such as CI/CD pipelines use this service
              account), deleting the token can cause the pipeline to fail. Once
              you delete a service account, there's no way to recover it.
            </Typography>
          </>
        }
        onConfirm={() => handleSingleItemDelete(itemToDelete)}
        onClose={handleCancelDelete}
        loading={isDeleting}
      />
    </Box>
  );
}

//////// Table Configuration

type Header = {
  id: string;
  label: string | ReactElement;
  colSpan?: number;
  sortable?: boolean;
  options?: SxProps;
};

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Service Account",
    sortable: true,
  },
  { id: "ROLE", label: "Role" },
  { id: "CREATED_AT", label: "Created" },
  {
    id: "LAST_USED",
    label: "Last Used",
    sortable: true,
    options: { width: 140 },
  },
];

const NoTokensView = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{
        py: 10,
        px: 2,
        textAlign: "center",
        border: "3px dashed",
        borderColor: "background.light",
      }}
    >
      <Typography variant="h6" component="p" color="text.secondary">
        You do not currently have any Service Accounts
      </Typography>
      <Typography color="text.secondary">
        Generate one to see it listed here
      </Typography>
    </Flex>
  );
};
