import { CaseModalTitle } from "~/components/cases/components/CreateCaseModal/CaseModalTitle";
import { ReactNode, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { useSnackbar } from "notistack";
import { useJiraTicketContext } from "~/hooks/useJiraTicketContext";
import {
  ClientIntegrationType,
  CreateCaseInput,
  TicketRefType,
  useGetCaseContentQuery,
} from "~/operations";
import { SubmitHandler, useForm } from "react-hook-form";
import { CaseTarget } from "~/components/cases/types";
import { SelectedEntity } from "~/components/exceptions/types";
import { Space } from "~/lib/types";
import { CreateCaseJiraModal } from "~/components/cases/components/CreateCaseModal/CreateCaseJiraModal";
import { CreateCaseEmailModal } from "~/components/cases/components/CreateCaseModal/CreateCaseEmailModal";
import { DynamicIntegrationIcon } from "~/pages/integrations/components/dynamic-integration-icon";
import { Flex } from "~/components/Flex";
import { useEmailTicketContext } from "~/hooks/useEmailTicketContext";
import { useTicketIntegrations } from "~/components/cases/hooks/useTicketIntegrations";
import { CreateCaseZendeskModal } from "~/components/cases/components/CreateCaseModal/CreateCaseZendeskModal";
import { CreateCaseGithubModal } from "~/components/cases/components/CreateCaseModal/CreateCaseGithubModal";
import { CreateCaseGitlabModal } from "~/components/cases/components/CreateCaseModal/CreateCaseGitlabModal";
import { useAzureTicketContext } from "~/hooks/useAzureTicketContext";
import { CreateCaseAzureModal } from "~/components/cases/components/CreateCaseModal/CreateCaseAzureModal";

export type CreateCasesFormInput = {
  jira: CreateCasesJiraFormInput;
  email: CreateCasesEmailFormInput;
  zendesk: CreateCasesZendeskFormInput;
  github: CreateCasesGithubIssuesFormInput;
  gitlab: CreateCasesGitlabIssuesFormInput;
  azure: CreateCasesAzureDevOpsFormInput;
};

const createCaseFormDefaultValues: CreateCasesFormInput = {
  jira: {
    title: "",
    projectId: "",
    notes: "",
  },
  email: {
    title: "",
    recipient: "",
    notes: "",
  },
  zendesk: {
    title: "",
    notes: "",
  },
  github: {
    title: "",
    repo: "",
    owner: "",
    notes: "",
  },
  gitlab: {
    title: "",
    project: "",
    notes: "",
  },
  azure: {
    title: "",
    projectId: "",
    notes: "",
  },
};

type CreateCasesJiraFormInput = {
  title: string;
  projectId: string;
  notes: string;
};

type CreateCasesEmailFormInput = {
  recipient: string;
  title: string;
  notes: string;
};

export type CreateCasesZendeskFormInput = {
  title: string;
  notes: string;
};

export type CreateCasesGithubIssuesFormInput = {
  title: string;
  owner: string;
  repo: string;
  notes: string;
};

export type CreateCasesGitlabIssuesFormInput = {
  title: string;
  project: string;
  notes: string;
};

type CreateCasesAzureDevOpsFormInput = {
  title: string;
  projectId: string;
  notes: string;
};

type CreateCaseModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (params: CreateCaseInput) => Promise<void>;
  loading: boolean;
  target: CaseTarget;
  selectedAssets: SelectedEntity[];
  space: Space;
  hasCreateCasePermissions: boolean;
};

export const CreateCaseModal = ({
  onClose,
  onSave,
  open,
  loading,
  selectedAssets,
  space,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedIntegration,
    ticketingIntegrations,
    selectedIntegrationMrn,
    setSelectedIntegrationMrn,
    saveSelectedIntegration,
  } = useTicketIntegrations({
    spaceMrn: space.mrn,
  });

  const {
    saveSelectedProject,
    loadSelectedProject,
    projects,
    isProjectsEmpty,
    fetchProjects,
  } = useJiraTicketContext({
    scopeMrn: selectedIntegration?.mrn || "",
    skip:
      !open ||
      !selectedIntegration?.mrn ||
      selectedIntegration?.type !== ClientIntegrationType.TicketSystemJira,
  });

  const { recipients, isRecipientsEmpty } = useEmailTicketContext({
    scopeMrn: selectedIntegration?.mrn || "",
    skip:
      !open ||
      !selectedIntegration?.mrn ||
      selectedIntegration?.type !== ClientIntegrationType.TicketSystemEmail,
  });

  const {
    saveSelectedProject: saveSelectedAzureProject,
    loadSelectedProject: loadSelectedAzureProject,
    projects: azureProjects,
    isProjectsEmpty: isAzureProjectsEmpty,
    fetchProjects: fetchAzurePRojects,
  } = useAzureTicketContext({
    scopeMrn: selectedIntegration?.mrn || "",
    skip:
      !open ||
      !selectedIntegration?.mrn ||
      selectedIntegration?.type !==
        ClientIntegrationType.TicketSystemAzureDevops,
  });

  const { data: jiraCaseContentData, loading: jiraCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.Jira,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !== ClientIntegrationType.TicketSystemJira,
    });

  const { data: emailCaseContentData, loading: emailCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.Email,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !== ClientIntegrationType.TicketSystemEmail,
    });

  const { data: zendeskCaseContentData, loading: zendeskCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.Zendesk,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !== ClientIntegrationType.TicketSystemZendesk,
    });

  const { data: githubCaseContentData, loading: githubCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.Github,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !== ClientIntegrationType.TicketSystemGithub,
    });

  const { data: gitlabCaseContentData, loading: gitlabCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.Gitlab,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !== ClientIntegrationType.TicketSystemGitlab,
    });

  const { data: azureCaseContentData, loading: azureCaseContentLoading } =
    useGetCaseContentQuery({
      variables: {
        input: {
          type: TicketRefType.AzureDevops,
          references: selectedAssets.map((r) => ({
            findingMrn: r.mrn,
            scopeMrn: String(r.scopeMrn),
          })),
        },
      },
      skip:
        !open ||
        selectedIntegration?.type !==
          ClientIntegrationType.TicketSystemAzureDevops,
    });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    getValues,
    getFieldState,
  } = useForm<CreateCasesFormInput>({
    mode: "onChange",
    defaultValues: {
      jira: createCaseFormDefaultValues.jira,
      email: createCaseFormDefaultValues.email,
      zendesk: createCaseFormDefaultValues.zendesk,
      github: createCaseFormDefaultValues.github,
      gitlab: createCaseFormDefaultValues.gitlab,
      azure: createCaseFormDefaultValues.azure,
    },
  });

  useEffect(() => {
    const savedSelectedProject = loadSelectedProject();

    reset({
      ...getValues(),
      jira: {
        title: jiraCaseContentData?.caseContent?.title,
        projectId: savedSelectedProject ? savedSelectedProject.key : "",
      },
    });
  }, [jiraCaseContentData, projects]);

  useEffect(() => {
    reset({
      ...getValues(),
      email: {
        recipient: "",
        title: emailCaseContentData?.caseContent.title,
      },
    });
  }, [emailCaseContentData, selectedIntegrationMrn, recipients]);

  useEffect(() => {
    reset({
      ...getValues(),
      zendesk: {
        title: zendeskCaseContentData?.caseContent.title,
      },
    });
  }, [zendeskCaseContentData, selectedIntegrationMrn]);

  useEffect(() => {
    if (
      selectedIntegration?.configurationOptions?.__typename !==
      "GithubTicketingConfigurationOptions"
    )
      return;

    reset({
      ...getValues(),
      github: {
        owner:
          selectedIntegration?.configurationOptions?.defaultRepoOwner || "",
        repo: selectedIntegration?.configurationOptions?.defaultRepoName || "",
        title: githubCaseContentData?.caseContent.title,
      },
    });
  }, [githubCaseContentData, selectedIntegrationMrn, selectedIntegration]);

  useEffect(() => {
    if (
      selectedIntegration?.configurationOptions?.__typename !==
      "GitlabTicketingConfigurationOptions"
    )
      return;

    reset({
      ...getValues(),
      gitlab: {
        project:
          selectedIntegration?.configurationOptions?.gitlabDefaultProject || "",
        title: gitlabCaseContentData?.caseContent.title,
      },
    });
  }, [gitlabCaseContentData, selectedIntegrationMrn, selectedIntegration]);

  useEffect(() => {
    const savedSelectedProject = loadSelectedAzureProject();

    reset({
      ...getValues(),
      azure: {
        title: azureCaseContentData?.caseContent?.title,
        projectId: savedSelectedProject ? savedSelectedProject.name : "",
      },
    });
  }, [azureCaseContentData, azureProjects]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit: SubmitHandler<CreateCasesFormInput> = async (
    formData,
  ) => {
    const { jira, email, zendesk, github, gitlab, azure } = formData;
    const references = selectedAssets.map((r) => ({
      findingMrn: r.mrn,
      scopeMrn: String(r.scopeMrn),
    }));
    try {
      switch (selectedIntegration?.type) {
        case ClientIntegrationType.TicketSystemJira:
          return await onSave({
            references,
            ticketConfig: {
              jira: {
                projectKey: jira.projectId,
                issueType: "Task",
              },
            },
            title: jira.title,
            notes: jira.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });
        case ClientIntegrationType.TicketSystemEmail:
          return await onSave({
            references,
            ticketConfig: {
              email: {
                emailAddress: email.recipient,
              },
            },
            title: email.title,
            notes: email.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });
        case ClientIntegrationType.TicketSystemZendesk:
          return await onSave({
            references,
            ticketConfig: {},
            title: zendesk.title,
            notes: zendesk.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });
        case ClientIntegrationType.TicketSystemGithub:
          return await onSave({
            references,
            ticketConfig: {
              github: {
                owner: github.owner,
                repo: github.repo,
              },
            },
            title: github.title,
            notes: github.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });

        case ClientIntegrationType.TicketSystemGitlab:
          return await onSave({
            references,
            ticketConfig: {
              gitlab: {
                project: gitlab.project,
              },
            },
            title: gitlab.title,
            notes: gitlab.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });

        case ClientIntegrationType.TicketSystemAzureDevops:
          return await onSave({
            references,
            ticketConfig: {
              azureDevops: {
                projectName: azure.projectId,
              },
            },
            title: azure.title,
            notes: azure.notes,
            integrationMrn: selectedIntegration?.mrn || "",
          });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to create case`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      data-test-id="create-case-dialog"
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <CaseModalTitle onClose={handleClose} />
        <DialogContent sx={{ pb: 5, px: 5 }}>
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemJira && (
            <Typography color="text.secondary" mb={3}>
              Mondoo shares the case with the ticketing or email integration you
              choose. To learn more, read the{" "}
              <Link
                sx={{ color: "inherit", textDecoration: "underline" }}
                href="https://mondoo.com/docs/platform/cases/overview/"
                target="_blank"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>
          )}
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, mt: 1, fontWeight: 700 }}
          >
            Integration
          </Typography>
          <Select
            fullWidth
            disabled={!hasCreateCasePermissions}
            sx={{
              "&.MuiInputBase-root": {
                bgcolor: "code.background",
              },
            }}
            displayEmpty
            onChange={(e) => {
              saveSelectedIntegration(e.target.value);
              setSelectedIntegrationMrn(e.target.value);
            }}
            value={selectedIntegrationMrn}
            renderValue={(selected: ReactNode) => {
              const selectedRecipient = ticketingIntegrations?.find(
                (int) => int.value === selected,
              );

              if (!selectedRecipient) {
                return <>Select an integration</>;
              }

              const selectedRecipientTitle = (
                <Flex gap={1}>
                  {selectedRecipient.type && (
                    <DynamicIntegrationIcon type={selectedRecipient.type} />
                  )}
                  {selectedRecipient.label}
                </Flex>
              );

              if (ticketingIntegrations?.length === 0) {
                return <>No integrations available – create one first</>;
              }

              if (!selected || !selectedRecipientTitle) {
                return <>Select a recipient</>;
              }

              return <>{selectedRecipientTitle}</>;
            }}
          >
            {ticketingIntegrations?.map((integration) => (
              <MenuItem value={integration.value} key={integration.value}>
                <Flex gap={1}>
                  {integration.type && (
                    <DynamicIntegrationIcon type={integration.type} />
                  )}
                  {integration.label}
                </Flex>
              </MenuItem>
            ))}
          </Select>
          <Box width={1} py={5}>
            <Divider />
          </Box>
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemJira && (
            <CreateCaseJiraModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              control={control}
              formState={formState}
              isProjectsEmpty={isProjectsEmpty}
              projects={projects}
              saveSelectedProject={saveSelectedProject}
              fetchProjects={fetchProjects}
            />
          )}
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemEmail && (
            <CreateCaseEmailModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              control={control}
              formState={formState}
              isRecipientsEmpty={isRecipientsEmpty}
              recipients={recipients}
            />
          )}
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemZendesk && (
            <CreateCaseZendeskModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              formState={formState}
            />
          )}
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemGithub && (
            <CreateCaseGithubModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              formState={formState}
              errors={formState.errors}
              control={control}
              getFieldState={getFieldState}
              disabled={false}
            />
          )}
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemGitlab && (
            <CreateCaseGitlabModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              formState={formState}
              errors={formState.errors}
              control={control}
              getFieldState={getFieldState}
              disabled={false}
            />
          )}
          {selectedIntegration?.type ===
            ClientIntegrationType.TicketSystemAzureDevops && (
            <CreateCaseAzureModal
              hasCreateCasePermissions={hasCreateCasePermissions}
              register={register}
              control={control}
              formState={formState}
              isProjectsEmpty={isAzureProjectsEmpty}
              projects={azureProjects}
              saveSelectedProject={saveSelectedAzureProject}
              fetchProjects={fetchAzurePRojects}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !formState.isValid ||
              !hasCreateCasePermissions ||
              !selectedIntegration
            }
            loading={
              loading ||
              jiraCaseContentLoading ||
              emailCaseContentLoading ||
              zendeskCaseContentLoading ||
              githubCaseContentLoading ||
              gitlabCaseContentLoading ||
              azureCaseContentLoading
            }
            buttonText="Create Case"
            data-testid="modal-create-case-button"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
