import { TableRow } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { DetailRow, DetailRowProps } from "../data-table";
import { useState } from "react";

type GenericAccordionRowProps = {
  rowContent: React.ReactNode;
  AccordionProps: Omit<DetailRowProps, "children">;
  accordionContent: React.ReactNode;
};

export function GenericAccordionRow({
  rowContent,
  accordionContent,
  AccordionProps,
}: GenericAccordionRowProps) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  return (
    <Fragment>
      <TableRow onClick={toggleOpen} className="generic-table-row">
        {rowContent}
      </TableRow>
      <DetailRow {...AccordionProps} open={open}>
        {accordionContent}
      </DetailRow>
    </Fragment>
  );
}
