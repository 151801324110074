import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import { Banner } from "~/components/Banner";
import {
  AggregateScoreType,
  useGetActivePoliciesCountQuery,
} from "~/operations";
import { SecurityOverviewCardSunburst } from "../../../security";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { isSpaceOrWorkspaceScope, useScope } from "~/hooks/useScope";
import { PolicyOverviewCard } from "./PolicyOverviewCard";
import { PolicyDistributionCard } from "./PolicyDistributionCard";
import { AssetOverviewCard } from "./AssetOverviewCard";
import { ChecksPassedCard } from "./ChecksPassedCard";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

type DashboardProps = {
  space: Space;
};

export function SecurityDashboard({ space }: DashboardProps) {
  const [showBanner, setShowBanner] = useState(false);
  const { activeScope, spaceScope } = useScope();

  const {
    policyGrades,
    policyCategories,
    assetsGrades,
    passedChecks,
    isLoading: isMetricsLoading,
  } = useMetrics({
    entityMrn: activeScope?.mrn || "",
    metricMrns: [
      Metrics.PolicyGradeDistribution,
      Metrics.PolicyCategoryDistribution,
      Metrics.SecurityAssetsStats,
      Metrics.ChecksResultDistribution,
    ],
  });

  const { data } = useGetActivePoliciesCountQuery({
    variables: {
      input: {
        scopeMrn: activeScope?.mrn || "",
      },
    },
    skip: !activeScope?.mrn,
  });

  useEffect(() => {
    if (
      data?.activePolicies?.totalCount &&
      data.activePolicies.totalCount > 0
    ) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [data?.activePolicies?.totalCount]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* move color to Banner component */}
        <Banner
          type="info"
          showBanner={showBanner}
          title="Get the most out of Mondoo by enabling security policies!"
          message="Your dashboard will be filled with a handy overview of the health of your entire fleet as you scale up your business."
          buttonText="ENABLE POLICIES"
          to={`/space/security/policies/add?${activeScope?.params?.toString()}&categories=security`}
        />
      </Grid>
      <Grid item mb={2}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 700 }}>
          OVERVIEW
        </Typography>
      </Grid>
      <Grid container item spacing={3} xs={12} sx={{ minHeight: 660 }}>
        <Grid item xs={12} lg={6}>
          <SecurityOverviewCardSunburst
            scope={
              isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
            }
            spaceScope={spaceScope}
          />
        </Grid>
        <Grid container item spacing={3} xs={12} lg={6}>
          <Grid item xs={12}>
            <FirewatchDashboardCard
              space={space}
              scope={
                isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
              }
              pageType={AggregateScoreType.Check}
            />
          </Grid>
          <Grid item xs={12}>
            <FirewatchDashboardCard
              scope={
                isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
              }
              space={space}
              pageType={AggregateScoreType.Policy}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4} order={{ xs: 1, sm: 1, lg: 1 }}>
          <PolicyOverviewCard
            policyGrades={policyGrades}
            scope={
              isSpaceOrWorkspaceScope(activeScope) ? activeScope : undefined
            }
            isLoading={isMetricsLoading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          order={{ xs: 2, sm: 3, lg: 2 }}
        >
          <PolicyDistributionCard
            policyCategories={policyCategories}
            isLoading={isMetricsLoading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} order={{ xs: 3, sm: 2, lg: 3 }}>
          <AssetOverviewCard
            assetsGrades={assetsGrades}
            isLoading={isMetricsLoading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          order={{ xs: 4, sm: 4, lg: 4 }}
        >
          <ChecksPassedCard
            passedChecks={passedChecks}
            isLoading={isMetricsLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
