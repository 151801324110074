import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType, EntityInfoAsset } from "..";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";

type GetLinkByTypeV2Params = {
  type: AdjustedAggregateScoreType;
  entity?: EntityInfoAsset;
  findingMrn: string;
  queryParams: URLSearchParams;
};

export const getLinkByType = ({
  type,
  entity,
  findingMrn,
  queryParams,
}: GetLinkByTypeV2Params) => {
  const searchParams = new URLSearchParams(document.location.search);
  const id = findingMrn.split("/").pop();
  switch (type) {
    case AggregateScoreType.Asset:
      const assetId = entity?.id;
      if (!assetId) return "";
      const link = `/space/inventory/${assetId}/vulnerabilities?${queryParams}`;
      const riskFactorMrn = searchParams.get("riskFactorMrn");
      if (riskFactorMrn) {
        return `${link}&riskFactorMrn=${encodeURIComponent(riskFactorMrn)}`;
      }
      return link;
    case AggregateScoreType.Vulnerability:
    case "NewestRelevantVulnerabilities":
      return `/space/vulns/cve/${id}?${queryParams}`;
    case AggregateScoreType.Advisory:
      return `/space/vulns/advisory/${id}?${queryParams}`;
    case AggregateScoreType.Policy:
      return `/space/security/policies/${PolicyMrnToURIEncodedId(
        findingMrn,
      )}?${queryParams}`;
    case AggregateScoreType.Check:
      return `/space/security/check?${queryParams}&findingMrn=${findingMrn}`;
    case AggregateScoreType.Software:
      return `/space/vulnerabilities/software/${id}?${queryParams}&genericFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    case AggregateScoreType.VersionedSoftware:
      return `/space/vulnerabilities/software/${id}?${queryParams}&versionedFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    default:
      return "";
  }
};
