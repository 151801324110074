import { HubCard } from "~/components/hub-card";
import { NavDrawerFleetIcon } from "~/components/icons";
import { Sunburst } from "~/components/sunburst/sunburst";
import { useAssetUrlStats } from "~/hooks/useAssetUrlStats";
import { AssetUrlStatsScope } from "~/operations";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";

type Props = {
  scope: SpaceOrWorkspaceScope | undefined;
  spaceScope: SpaceScope | undefined;
};

export function SecurityOverviewCardSunburst({ scope, spaceScope }: Props) {
  const assetUrlStats = useAssetUrlStats({
    scope,
    spaceScope,
    statsScope: AssetUrlStatsScope.Security,
  });

  return (
    <HubCard
      {...{
        loading: assetUrlStats.queryResult.loading,
        title: "Security Overview",
        icon: <NavDrawerFleetIcon />,
        sx: { height: "100%" },
      }}
    >
      <Sunburst assetUrlStats={assetUrlStats} />
    </HubCard>
  );
}
