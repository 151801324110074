import { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFrameworkCard } from "../hooks/useFrameworkCard";

type FrameworkCardDataProps = {
  data: ReturnType<typeof useFrameworkCard>["data"];
  get: ReturnType<typeof useFrameworkCard>["get"];
  setShowEditTools: ReturnType<typeof useFrameworkCard>["setShowEditTools"];
};

export function ActiveFrameworkCardData({
  data,
  get,
  setShowEditTools,
}: FrameworkCardDataProps) {
  return (
    <Fragment>
      <Grid
        item
        container
        xs={8}
        sx={{
          height: 80,
          px: 0,
          py: 2,
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        {data.frameworkStats.map((stat) => (
          <Grid
            item
            key={stat.key}
            xs={3}
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              fontSize={11}
              fontWeight={600}
              lineHeight="16px"
              color="text.secondary"
              sx={{ pb: 1, textTransform: "uppercase" }}
            >
              {stat.key}
            </Typography>
            <Typography
              className={"integrations-value"}
              fontSize={24}
              lineHeight="15px"
              fontWeight={800}
            >
              {/* if looking at controls, we reach into */}
              {/* {typeof value === "object" && value.__typename === "ControlsStats"
                ? value.totalControls.toLocaleString()
                : value.toLocaleString()} */}
              {stat.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          py: 2,
          pr: 3,
        }}
      >
        <Box>{data.displayInteractions}</Box>
      </Grid>
    </Fragment>
  );
}
