import { useCallback, useEffect, useState } from "react";
import { MinimalOrg, SpaceData } from "~/lib/types";
import {
  useLoadOrganizationForSpaceLazyQuery,
  useLoadOrganizationLazyQuery,
  useLoadSpaceLazyQuery,
} from "~/operations";

type useOrgAndSpaceDataProps = {
  spaceId?: string | null;
  orgId?: string | null;
};

export function useOrgAndSpaceData({
  spaceId,
  orgId,
}: useOrgAndSpaceDataProps) {
  const [orgData, setOrgData] = useState<MinimalOrg | undefined>();
  const [spaceData, setSpaceData] = useState<SpaceData | undefined>();

  const [loadOrgDataFromOrgId, { data: orgDataFromOrgId }] =
    useLoadOrganizationLazyQuery({
      variables: { mrn: `//captain.api.mondoo.app/organizations/${orgId}` },
    });

  const [loadOrganizationForSpace, { data: orgDataForSpace }] =
    useLoadOrganizationForSpaceLazyQuery({
      variables: { spaceMrn: `//captain.api.mondoo.app/spaces/${spaceId}` },
    });

  const [loadSpace, { data: spaceDataFromSpaceId }] = useLoadSpaceLazyQuery({
    variables: { spaceMrn: `//captain.api.mondoo.app/spaces/${spaceId}` },
  });

  useEffect(() => {
    const nextOrgData =
      orgDataFromOrgId?.organization ||
      orgDataForSpace?.space?.organization ||
      undefined;
    const nextSpaceData = spaceDataFromSpaceId?.space || undefined;

    setOrgData(nextOrgData);
    setSpaceData(nextSpaceData);
  }, [orgDataFromOrgId, orgDataForSpace, spaceDataFromSpaceId]);

  useEffect(() => {
    returnOrgAndSpaceData();
  }, [orgId, spaceId]);

  const returnOrgAndSpaceData = useCallback(() => {
    if (orgId) {
      try {
        loadOrgDataFromOrgId();
      } catch (error) {
        console.error(error);
      }
    }

    if (spaceId && !orgId) {
      try {
        loadOrganizationForSpace();
      } catch (error) {
        console.error(error);
      }
    }

    if (spaceId) {
      try {
        loadSpace();
      } catch (error) {
        console.error(error);
      }
    }
  }, [orgId, spaceId]);

  return {
    orgData,
    spaceData,
  };
}
