import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { AssetSearch } from "../asset-search";
import { ViewSwitcher, ViewType } from "../view-switcher";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { Asset } from "~/pages/inventory/asset/types";
import { AssetForwardPaginationQuery, OrderDirection } from "~/operations";
import { Action, ActionMenu } from "../action-menu";
import { ExplorerAccordion } from "./explorer-accordion";
import { pluralize } from "~/lib/pluralize";
import { SplitDownArrowIcon } from "../icons";
import { Loading } from "../loading";

export type Assets = NonNullable<AssetForwardPaginationQuery["assets"]>;
export type AssetsEdge = NonNullable<Assets["edges"]>[0];
export type AssetsPageInfo = Assets["pageInfo"];
export type RelatedAssetsCount = NonNullable<
  NonNullable<Asset["relatedAssets"]>["count"]
>[0];

export function AssetExplorerTab({}) {
  let navigate = useNavigate();
  const { asset, scope, space } = useAssetOutlet();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [actions, setActions] = useState<Action[]>([]);
  const [sortBy, setSortBy] = useState<OrderDirection>(OrderDirection.Asc);
  const [groups, setGroups] = useState<RelatedAssetsCount[]>(() => {
    return (
      asset?.relatedAssets?.count?.filter(
        (x) => x.isParent === false && x.count > 0,
      ) || []
    );
  });

  if (!scope || !asset || !asset.relatedAssets || !asset.relatedAssets.count) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 8,
        }}
      >
        <Loading />
      </Box>
    );
  }

  useEffect(() => {
    const actionList = ALL_GROUP_TYPES?.map((groupType) => {
      const filteredGroupTypes = groups?.map((g) => g.type);
      return {
        label: formatGroupType(groupType),
        action: (e: SyntheticEvent, checked: boolean) =>
          handleFilterClick(e, checked, groupType),
        checked: filteredGroupTypes?.includes(groupType),
      } as Action;
    });
    if (actionList) {
      setActions(actionList);
    } else {
      setActions([]);
    }
  }, [groups]);

  useEffect(() => {
    const nextSearchFilters = searchParams.get("queryTerms");
    if (nextSearchFilters) {
      setSearchFilters(nextSearchFilters.split(","));
    } else {
      setSearchFilters([]);
    }
  }, [searchParams]);

  const onAssetSearchQuery = (searchFilters: string[]) => {
    let updatedFilters = searchFilters.filter(
      (x, index) => searchFilters.indexOf(x) === index,
    );

    if (updatedFilters.length < 1) {
      searchParams.delete("queryTerms");
    } else {
      searchParams.set("queryTerms", updatedFilters.join(","));
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  const totalCount = asset?.relatedAssets?.count
    .filter((x) => !x.isParent)
    .reduce((agg, x) => agg + x.count, 0);

  const ALL_GROUP_TYPES = asset?.relatedAssets?.count
    .filter((x) => x.isParent === false && x.count > 0)
    .map((group) => group.type);

  const ALL_GROUPS = asset?.relatedAssets?.count.filter(
    (x) => x.isParent === false && x.count > 0,
  );

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as OrderDirection);
  };

  const handleFilterClick = (
    _e: SyntheticEvent,
    _checked: boolean,
    groupType: string,
  ) => {
    const foundIndex = groups?.findIndex((g) => g.type === groupType);
    if (foundIndex !== undefined && foundIndex >= 0) {
      setGroups((prev) => {
        prev.splice(foundIndex, 1);
        const array = [...prev];
        return array;
      });
    } else {
      const found = ALL_GROUPS.find((g) => g.type === groupType);
      setGroups((prev) => {
        const array = [...prev, found].flatMap((f) => f ?? []);
        array.sort((a, b) =>
          formatGroupType(a.type).localeCompare(formatGroupType(b.type)),
        );

        return array;
      });
    }
  };

  // return pluralized and cleaned version of k8s type
  // duplicated below
  const formatGroupType = (type: string) => {
    const regex = new RegExp("(^k8s.)|(^aws.)");
    return type.replace(regex, "") + "s";
  };

  return (
    <Box
      sx={{
        pb: 2,
        px: 3,
        mt: 3,
        borderRadius: 1,
        border: "1px solid",
        borderColor: "background.light",
      }}
    >
      <Box
        sx={{
          mx: -3,
          px: 3,
          py: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Typography color="text.secondary">Child Assets</Typography>
      </Box>
      <Box my={3} sx={{ display: "flex" }}>
        <AssetSearch
          scopeMrn={scope.mrn}
          filters={searchFilters}
          onQuery={onAssetSearchQuery}
          rootAssetMrn={asset.mrn}
        />

        <Box ml={2} mt={0.75}>
          {/* 6px offset top to measure even with search box */}
          <ActionMenu
            id="explorer"
            actions={actions}
            buttonIcon={<SplitDownArrowIcon fontSize="small" />}
            buttonSize="large"
            listProps={{ title: "Asset Types", checkbox: true }}
          />
        </Box>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            mb: {
              xs: 2,
              sm: 0,
            },
          }}
        >
          <Typography color="text.secondary">
            Showing {totalCount} {pluralize("Asset", totalCount)} Total
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <FormControl
            fullWidth
            sx={{
              mr: 3,
              maxWidth: {
                xs: "unset",
                sm: 192,
              },
            }}
          >
            <InputLabel id="asset-explorer-sort-by-select-label">
              Sort by
            </InputLabel>
            <Select
              labelId="asset-explorer-sort-by-select"
              id="asset-explorer-sort-by-select"
              value={sortBy}
              label="Sort by"
              onChange={handleSortChange}
            >
              <MenuItem value={OrderDirection.Desc}>
                <Typography>
                  Score{" "}
                  <Typography
                    variant="caption"
                    component="span"
                    color="text.secondary"
                  >
                    (Desc.)
                  </Typography>
                </Typography>
              </MenuItem>
              <MenuItem value={OrderDirection.Asc}>
                <Typography>
                  Score{" "}
                  <Typography
                    variant="caption"
                    component="span"
                    color="text.secondary"
                  >
                    (Asc.)
                  </Typography>
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <ViewSwitcher
            views={[ViewType.Keyboard, ViewType.List]}
            defaultView={ViewType.Keyboard}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        {groups?.map((group) => (
          <ExplorerAccordion
            key={group.type}
            assetMrn={asset.mrn}
            queryTerms={searchFilters}
            {...{ group, space, sortBy }}
          />
        ))}
      </Box>
    </Box>
  );
}
