import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { RemediatedShieldIcon } from "~/components/icons/mondoo/dashboard";
import { GridSegment } from "../GridSegment";
import { GridData } from "../GridSegment/GridSegment";

type Props = {
  sizes?: CardSizes;
  remediationSeverity: GridData[];
  isLoading: boolean;
};

export function RemediatedCard({
  sizes,
  remediationSeverity,
  isLoading,
}: Props) {
  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Total Vulnerabilities Remediated",
        icon: <RemediatedShieldIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
      data-testid="remediated-vulnerabilities"
    >
      <Grid container spacing={2} sx={{ pt: 2 }}>
        {remediationSeverity
          ?.filter((x) => x !== null)
          .map((x) => (
            <GridSegment data={x} key={x.title} color segments={5} />
          ))}
      </Grid>
    </HubCard>
  );
}
