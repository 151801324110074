import { NumberDot } from "~/components/guides/components";
import { Flex } from "~/components/Flex";
import { Grid2, Typography, useTheme } from "@mui/material";
import { QueryBuilder } from "~/pages/space/Workspaces/components/QueryBuilder";

type CreateWorkspaceStepOneProps = {};

export const CreateWorkspaceStepOne = ({}: CreateWorkspaceStepOneProps) => {
  const theme = useTheme();

  return (
    <Flex alignItems="flex-start">
      <NumberDot
        number={1}
        backgroundColor={theme.palette.background.lightest}
      />
      <Flex flexDirection="column" gap={1} width="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Typography
            variant="h5"
            component="p"
            fontWeight={700}
            sx={{
              fontSize: { xs: 16, sm: 24 },
            }}
          >
            Select assets to include
          </Typography>
          {/*<SelectionAssetsCount selectionCount={1250} totalCount={2158} />*/}
        </Flex>
        <Typography variant="body2" color="text.secondary" component="p">
          Define the criteria by which assets should be included in or excluded
          from your new workspace. Policy and Vulnerability behavior match your
          space settings.
        </Typography>
        <Grid2 mt={5} size={12}>
          <QueryBuilder />
        </Grid2>
      </Flex>
    </Flex>
  );
};
