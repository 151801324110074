import { AdjustedAggregateScoreType } from "~/components/FirewatchPage";
import { AggregateScoreType } from "~/operations";
import { AffectedAssetsUpperSection } from "../components/UpperSections";
import { Space } from "~/lib/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type UseUpperSection = {
  space: Space;
  scope?: SpaceOrWorkspaceScope | undefined;
  pageType: AdjustedAggregateScoreType;
};

export function useUpperSection({ space, scope, pageType }: UseUpperSection) {
  const upperSectionByType = (type: AdjustedAggregateScoreType) => {
    switch (type) {
      case AggregateScoreType.Asset:
        return <AffectedAssetsUpperSection scope={scope} space={space} />;
      case AggregateScoreType.Software:
        // return <SoftwareUpperSection space={space} />;
        // Filtering is not yet ready on the backend, so we are hiding it for the first iteration
        return null;

      default:
        return "";
    }
  };

  const upperSection = upperSectionByType(pageType);

  return { upperSection };
}
