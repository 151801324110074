import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Banner } from "~/components/Banner";
import { useMetrics, usePlatformVulnerabilityConfiguration } from "~/hooks";
import { useState } from "react";
import { useViewer } from "~/providers/viewer";
import {
  AggregateScoreType,
  SpaceSettingsInput,
  useLoadSpaceQuery,
} from "~/operations";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { VulnerableAssetsCardSunburst } from "~/pages/space/Dashboards/components/VulnerabilitiesDashboard/VulnerableAssetsCard";
import { VulnerabilitiesDonutChart } from "./VulnerabilitiesDonutChart";
import { AdvisoriesDonutChart } from "./AdvisoriesDonutChart";
import { CvesDonutChart } from "./CVEsDonutChart";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";
import { Space } from "~/lib/types";
import { Metrics } from "~/utils/arrow";
import { MttrPerScoreCard } from "./MttrPerScoreCard";
import { RemediatedCard } from "./RemediatedCard";

type DashboardProps = {
  scope: SpaceOrWorkspaceScope | undefined;
  spaceScope: SpaceScope | undefined;
  space: Space;
};

export function VulnerabilitiesDashboard({
  scope,
  spaceScope,
  space,
}: DashboardProps) {
  const { updateSpace } = useViewer();
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const [vulnConfigUpdating, setVulnConfigUpdating] = useState(false);
  const {
    vulnerableAssetsSeverity,
    advisoriesSeverity,
    cvesSeverity,
    mttrSeverity,
    remediationSeverity,
    isLoading: isMetricsLoading,
  } = useMetrics({
    entityMrn: scope?.mrn || "",
    metricMrns: [
      Metrics.VulnerableAssetsSeverity,
      Metrics.AdvisoriesSeverity,
      Metrics.CVEsSeverity,
      Metrics.MTTRSeverity,
      Metrics.RemediationSeverity,
    ],
  });

  const { data, loading, error } = useLoadSpaceQuery({
    variables: { spaceMrn: space.mrn },
  });

  if (loading) {
    return <LoadingPage what="Space" />;
  }

  if (error || !data?.space) {
    return <LoadingFailedPage what="Space" />;
  }

  const settings = data.space.settings;
  const garbageCollectAssetsConfiguration = {
    enable: settings?.garbageCollectAssetsConfiguration.enable,
    afterDays: settings?.garbageCollectAssetsConfiguration.afterDays,
  };
  const terminatedAssetsConfiguration = {
    cleanup: settings?.terminatedAssetsConfiguration.cleanup,
  };
  const unusedServiceAccountsConfiguration = {
    cleanup: settings?.unusedServiceAccountsConfiguration.cleanup,
  };
  const platformVulnerabilityConfiguration = {
    enable: settings?.platformVulnerabilityConfiguration.enable,
  };

  const settingsInput: SpaceSettingsInput = {
    garbageCollectAssetsConfiguration,
    terminatedAssetsConfiguration,
    unusedServiceAccountsConfiguration,
    platformVulnerabilityConfiguration,
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope?.params?.toString()}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/space/vulnerabilities">Dashboard</Typography>,
  ];

  const handleEnableVulnerabilityScanning = async () => {
    const settings = {
      ...settingsInput,
      platformVulnerabilityConfiguration: { enable: true },
    };

    setVulnConfigUpdating(true);

    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: () => {
          setVulnConfigUpdating(false);
        },
      });
    } catch (error) {
      setVulnConfigUpdating(false);
    }
  };

  return (
    <>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid container spacing={3}>
        {scope?.type === "space" && (
          <Banner
            type="info"
            showBanner={pvcIsEnabled === false}
            title="Get the most out of Mondoo by turning on vulnerability scanning!"
            message="Your dashboard will be filled with a handy overview of the health of your entire fleet as you scale up your business."
            buttonText="ENABLE VULNERABILITY SCANNING"
            buttonLoading={vulnConfigUpdating}
            onClick={handleEnableVulnerabilityScanning}
          />
        )}
        <Grid item mb={2}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 700 }}>
            OVERVIEW
          </Typography>
        </Grid>
        <Grid container item spacing={3} xs={12} sx={{ height: 1 }}>
          <Grid item xs={12} lg={6}>
            <VulnerableAssetsCardSunburst
              scope={scope}
              spaceScope={spaceScope}
            />
          </Grid>
          <Grid container item spacing={3} xs={12} lg={6}>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                scope={scope}
                pageType={AggregateScoreType.Vulnerability}
              />
            </Grid>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                scope={scope}
                pageType={"NewestRelevantVulnerabilities"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6} lg={4} order={{ xs: 1 }}>
            <VulnerabilitiesDonutChart
              scope={scope}
              vulnerableAssetsSeverity={vulnerableAssetsSeverity}
              isLoading={isMetricsLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} order={{ xs: 2 }}>
            <AdvisoriesDonutChart
              scope={scope}
              advisoriesSeverity={advisoriesSeverity}
              isLoading={isMetricsLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 3, md: 4, lg: 3 }}>
            <CvesDonutChart
              scope={scope}
              cvesSeverity={cvesSeverity}
              isLoading={isMetricsLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 4, md: 3, lg: 4 }}>
            <MttrPerScoreCard
              mttrSeverity={mttrSeverity}
              isLoading={isMetricsLoading}
            />
          </Grid>
          <Grid item xs={12} lg={8} order={{ xs: 5 }}>
            <RemediatedCard
              remediationSeverity={remediationSeverity}
              isLoading={isMetricsLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
