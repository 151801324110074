import { forwardRef } from "react";
import {
  Tooltip,
  IconButton as MuiIconButton,
  SxProps,
  CircularProgress,
} from "@mui/material";
import { DynamicButtonIcon, IconButtonType } from "./DynamicButtonIcon";
import { Flex } from "~/components/Flex";

type IconButtonProps = {
  title: string;
  iconType: IconButtonType;
  loading?: boolean;
  IconStyles?: SxProps;
} & React.ComponentProps<typeof MuiIconButton>;

export const CircularIconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(function CircularIconButton(
  { title, iconType, IconStyles, loading = false, ...buttonProps },
  ref,
) {
  return (
    <Tooltip title={title} placement="top" arrow>
      {loading ? (
        <Flex center sx={{ p: 1 }}>
          <CircularProgress size={16} />
        </Flex>
      ) : (
        <MuiIconButton
          ref={ref}
          {...buttonProps}
          data-testid={`circular-icon-button-${iconType}`}
        >
          <DynamicButtonIcon iconId={iconType} sx={IconStyles} />
        </MuiIconButton>
      )}
    </Tooltip>
  );
});
