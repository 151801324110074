import { Box, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { Flex } from "~/components/Flex";
import { MoonIcon } from "~/components/ui-library";
import { BlockIcon, VisibilityOffIcon } from "~/components/icons";

export enum DistributionState {
  Disabled = "DISABLED",
  OutOfScope = "OUT_OF_SCOPE",
  Snoozed = "SNOOZED",
}

type ExceptionCountIndicatorProps = {
  state: DistributionState;
  count: number;
};

export const ExceptionCountIndicator = ({
  state,
  count,
}: ExceptionCountIndicatorProps) => {
  const theme = useTheme();

  const getDistributionIcon = () => {
    const fontSize = 20;
    const color = theme.palette.text.disabled;
    switch (state) {
      case DistributionState.OutOfScope:
        return <VisibilityOffIcon sx={{ fontSize, color }} />;
      case DistributionState.Snoozed:
        return <MoonIcon sx={{ fontSize, color }} />;
      case DistributionState.Disabled:
        return <BlockIcon sx={{ fontSize, color }} />;
      default:
        return <Box />;
    }
  };

  const getDistributionTitle = () => {
    switch (state) {
      case DistributionState.OutOfScope:
        return "Out of scope";
      case DistributionState.Snoozed:
        return "Snoozed";
      case DistributionState.Disabled:
        return "Disabled";
      default:
        return "";
    }
  };

  const distributionIcon = getDistributionIcon();
  const title = getDistributionTitle();

  return (
    <Flex alignItems="center" gap={1}>
      <Tooltip title={title} arrow placement="top">
        <Paper
          sx={{
            borderRadius: "50%",
            display: "flex",
            p: "6px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {distributionIcon}
        </Paper>
      </Tooltip>
      <Typography fontWeight={500}>{count}</Typography>
    </Flex>
  );
};
