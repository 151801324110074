import React, { Fragment, MouseEvent, useState } from "react";
import {
  alpha,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { FrameworkStateButton } from "~/pages/compliance/framework/components/FrameworkStateMenu/FrameworkStateButton";
import {
  ComplianceFrameworkMutationAction,
  ComplianceFrameworkState,
} from "~/operations";
import { useFrameworkState } from "~/pages/compliance/framework/components/FrameworkStateMenu/hooks";
import { Flex } from "~/components/Flex";
import { Action } from "~/components/action-menu";
import { useFrameworkStateActions } from "~/pages/compliance/framework/components/FrameworkStateMenu/hooks/useFrameworkStateActions";

type FrameworkStateMenuProps = {
  state: ComplianceFrameworkState;
  onStateChange: (input: ComplianceFrameworkMutationAction) => void;
};

type ExtendedAction = Action & {
  description: string;
  color: string;
};

export const FrameworkStateMenu = ({
  state,
  onStateChange,
}: FrameworkStateMenuProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getColor, getIcon, getLabel, getDescription } = useFrameworkState({
    state,
  });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { actions } = useFrameworkStateActions({
    state,
    onClose: handleClose,
    onStateChange,
    getIcon,
    getLabel,
    getDescription,
    getColor,
  });

  return (
    <Fragment>
      <FrameworkStateButton
        onClick={handleClick}
        label={getLabel(state)}
        Icon={getIcon(state)}
        color={getColor(state)}
      />
      <Popper
        id="framework-state-switcher"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={350}>
              <Paper sx={{ minWidth: 150, maxWidth: 270 }}>
                <Fragment>
                  <MenuList>
                    {actions.map((action: ExtendedAction) => (
                      <MenuItem
                        sx={{
                          "&:hover": {
                            backgroundColor: `${alpha(action.color, 0.1)}`,
                          },
                        }}
                        onClick={action.action}
                        key={action.label}
                        {...(action.dataName
                          ? { "data-name": action.dataName }
                          : {})}
                      >
                        <Flex>
                          <Flex flexDirection="column">
                            <Flex alignItems="center" sx={{ gap: 1 }}>
                              {action.icon}
                              {action.label}
                            </Flex>
                            <Typography
                              color="text.secondary"
                              whiteSpace="normal"
                              fontSize={12}
                            >
                              {action.description}
                            </Typography>
                          </Flex>
                        </Flex>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Fragment>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
};
