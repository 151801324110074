import { Route, Routes } from "react-router-dom";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { QueryPackPage } from "~/pages/inventory/query-pack";
import { QueryPackQueryPage } from "~/pages/inventory/query-pack-query";
import { QueryPacksPage } from "~/pages/inventory/query-packs";
import { QueryPacksAddPage } from "~/pages/inventory/query-packs-add";
import { QueryPacksAddDetailPage } from "~/pages/inventory/query-packs-add-detail";
import { QueryPacksAddQueryPage } from "~/pages/inventory/query-packs-add-query";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type QueryPacksRoutesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function QueryPacksRoutes({
  space,
  scope,
  availablePermissions,
}: QueryPacksRoutesProps) {
  return (
    <Routes>
      <Route
        index
        element={<QueryPacksPage {...{ space, scope, availablePermissions }} />}
      />
      <Route path="/add">
        <Route
          index
          element={<QueryPacksAddPage {...{ space, availablePermissions }} />}
        />
        <Route path=":policyId">
          <Route
            index
            element={
              <QueryPacksAddDetailPage {...{ space, availablePermissions }} />
            }
          />
          <Route
            path="queries/:queryId"
            element={
              <QueryPacksAddQueryPage {...{ space, availablePermissions }} />
            }
          />
        </Route>
      </Route>
      <Route path="/:policyId">
        <Route
          index
          element={
            <QueryPackPage {...{ space, scope, availablePermissions }} />
          }
        />
        <Route
          path="queries/:queryId"
          element={
            <QueryPackQueryPage {...{ space, scope, availablePermissions }} />
          }
        />
      </Route>
    </Routes>
  );
}
