import { SxProps } from "@mui/material";
import { sort } from "d3";

export type GenericTableHeader = {
  /**
   * IDs are used to identify the column in the table
   */
  id: string;
  /**
   * Field is used to identify the column for filtering and sorting
   */
  field: string;
  /**
   * Label is the text that will be displayed in the table header
   */
  label: React.ReactNode;
  /**
   * ColSpan is used to merge columns
   * @default 1
   */
  colSpan?: number;
  /**
   * Sortable is used to determine if the column is sortable
   */
  sortable?: boolean;
  /**
   * Options is used to set the text alignment and width of the column
   */
  // TODO: roll all options into SXProps
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
    colSpan?: number;
    sx?: SxProps;
  };
};

export const getGenericTableHeaders = (
  tableName: string,
): GenericTableHeader[] => {
  const GENERIC_COLUMNS = {
    CreatedAt: {
      id: "CREATED_AT",
      field: "CREATED_AT",
      label: "Created At",
      sortable: true,
    },
    Description: {
      id: "DESCRIPTION",
      field: "DESCRIPTION",
      label: "Description",
    },
    DetectedAsset: {
      id: "DETECTED_ASSET",
      field: "TITLE",
      label: "Asset",
      sortable: true,
    },
    LastUpdated: {
      id: "LAST_UPDATED",
      field: "LAST_UPDATED",
      label: "Last Updated",
      sortable: false,
      options: { width: 180 },
    },
    Name: {
      id: "NAME",
      field: "NAME",
      label: "Name",
    },
  };

  const DefaultHeaders: GenericTableHeader[] = [
    GENERIC_COLUMNS.Name,
    GENERIC_COLUMNS.CreatedAt,
  ];

  const detectedAssetsColumns: GenericTableHeader[] = [
    GENERIC_COLUMNS.DetectedAsset,
    GENERIC_COLUMNS.LastUpdated,
  ];

  switch (tableName) {
    case "DETECTED_ASSETS":
      return detectedAssetsColumns;
    default:
      return DefaultHeaders;
  }
};
