import { createPortal } from "react-dom";
import { BoxProps } from "@mui/material";
import { Flex } from "~/components/Flex";
import ChipOnDelete from "../../buttons/ChipOnDelete";

type SelectedFiltersPortalProps = BoxProps & {
  refEle?: null | HTMLElement;
  appliedValues: { [key in string]: any };
  onDelete: (value: Record<"filterName" | "valueToRemove", string>) => void;
};

const SelectedFiltersPortal = ({
  refEle,
  appliedValues,
  onDelete,
  ...restProps
}: SelectedFiltersPortalProps) => {
  if (!refEle) {
    return null;
  }

  return createPortal(
    <Flex flexWrap="wrap" gap={2} {...restProps}>
      {Object.keys(appliedValues).map((filterName) => {
        const filterValue = appliedValues[filterName];

        if (Array.isArray(filterValue)) {
          return filterValue.map((value) => {
            return (
              <ChipOnDelete
                key={value}
                name={filterName}
                value={value}
                onDelete={() => {
                  onDelete({ filterName, valueToRemove: value });
                }}
              />
            );
          });
        }

        return (
          <ChipOnDelete
            key={filterValue}
            name={filterName}
            value={filterValue}
            onDelete={() => {
              onDelete({ filterName, valueToRemove: filterValue });
            }}
          />
        );
      })}
    </Flex>,
    refEle,
  );
};

export default SelectedFiltersPortal;
