import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { HomeIcon } from "~/components/icons";
import {
  CatalogType,
  TestIamActionsQuery,
  useGetContentQuery,
} from "~/operations";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import { LoadingPage } from "~/components/loading";
import { policyIcon } from "./utils/policyIcon";
import { pluralize } from "~/lib/pluralize";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { EmptyState } from "~/components/empty-state/empty-state";
import { Caption } from "../security/policies/components/Caption";
import { ScopeType, SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";

export type QueryPacksPageProps = {
  space: SpaceScope;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function QueryPacksPage({
  space,
  scope,
  availablePermissions,
}: QueryPacksPageProps) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { data, loading, error, fetchMore } = useGetContentQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        assignedOnly: true,
        catalogType: CatalogType.Querypack,
        limit: 250,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/space/inventory/query-packs">Query Packs</Typography>,
  ];

  document.title = "Query Packs · Inventory · Mondoo";

  const pageInfo = data?.content?.pageInfo;
  const totalCount = data?.content?.totalCount || 0;
  const activeQueryPacks = data?.content?.edges.flatMap((e) => e.node) || [];

  const queryPackDetailHref = (mrn: string) => {
    return `/space/inventory/query-packs/${PolicyMrnToURIEncodedId(mrn)}?${scope.params}`;
  };

  const fetchMoreQueryPacks: ReturnType<
    typeof useGetContentQuery
  >["fetchMore"] = () => {
    return fetchMore({
      variables: {
        input: {
          scopeMrn: scope.mrn,
          assignedOnly: true,
          catalogType: CatalogType.Querypack,
        },
      },
    });
  };

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="query-packs-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          textTransform="uppercase"
          sx={{ mb: 3 }}
        >
          Query Packs
        </Typography>
        {scope.type === ScopeType.Space && (
          <Flex id="query-packs-actions" alignItems="center" gap={4}>
            <AddButton
              id="add-query-pack-button"
              href={`/space/inventory/query-packs/add?${scope.mrn}`}
              aria-label="Add Query Pack"
            />
          </Flex>
        )}
      </Box>

      {loading && <LoadingPage what="Active Query Packs" />}
      {!loading && totalCount > 0 && (
        <Box component={Paper}>
          <List disablePadding>
            {activeQueryPacks
              .slice(pageItems.from, pageItems.to)
              .map((pack) => {
                const queryCount = pack.statistics.queries;
                return (
                  <ListItem
                    key={pack.mrn}
                    sx={{
                      p: 3,
                      "&:not(:last-child)": {
                        borderBottom: (theme) =>
                          `1px solid ${theme.palette.background.lighter}`,
                      },
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.background.light,
                      },
                    }}
                    component={RouterLink}
                    to={queryPackDetailHref(pack.mrn)}
                  >
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs="auto"
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "left",
                        }}
                      >
                        {policyIcon(pack.name, "large")}
                      </Grid>
                      <Grid item xs={10} sm container>
                        <Grid item xs={10} sm>
                          <Typography
                            sx={{ pl: 3, fontWeight: 700 }}
                            className="query-pack-name"
                          >
                            {pack.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={0} pl={3} mt={1}>
                          <Caption
                            trustLevel={pack.trustLevel}
                            version={pack.version}
                            authors={pack.authors}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        sx={{
                          display: { xs: "none", sm: "flex" },
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          sx={{ pr: 1, fontWeight: 400 }}
                        >
                          {queryCount} {pluralize("Query", queryCount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
          <Pagination
            totalCount={totalCount}
            pageInfo={pageInfo}
            setPageItems={setPageItems}
            sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
          />
        </Box>
      )}
      {!loading && totalCount === 0 && <EmptyState contentType="query-packs" />}
    </Box>
  );
}
