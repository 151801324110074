import { Route, Routes } from "react-router-dom";
import { SpaceAdvisoryPage } from "~/pages/space-advisory";
import { SpaceVulnerabilityPage } from "~/pages/space-vulnerability";
import { Space } from "~/lib/types";
import { MondooVulnerabilityDatabase } from "~/components/vulnerabilities/MondooVulnerabilityDatabase/MondooVulnerabilityDatabase";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: string[];
};

export function VulnsRoutes({ space, scope, availablePermissions }: Props) {
  return (
    <Routes>
      <Route index element={<MondooVulnerabilityDatabase scope={scope} />} />
      <Route path="advisory">
        <Route
          path=":id/*"
          element={
            <SpaceAdvisoryPage
              scope={scope}
              space={space}
              availablePermissions={availablePermissions}
            />
          }
        />
      </Route>

      <Route path="cve">
        <Route
          path=":id/*"
          element={
            <SpaceVulnerabilityPage
              space={space}
              scope={scope}
              availablePermissions={availablePermissions}
            />
          }
        />
      </Route>
      <Route path="software">
        <Route
          path=":id/*"
          element={
            <SpaceVulnerabilityPage
              space={space}
              scope={scope}
              availablePermissions={availablePermissions}
            />
          }
        />
      </Route>
    </Routes>
  );
}
