import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { SelectedEntity } from "~/components/exceptions/types";
import { Space } from "~/lib/types";
import {
  Asset,
  CheckScoresEdge,
  CheckScoresException,
} from "../../asset/types";
import {
  MqueryDocs,
  MqueryState,
  QueryImpact,
  RenderedAssetQueryData,
  ReviewStatus,
  ScoreResultType,
  ScoreState,
  useGetCheckRowDataLazyQuery,
} from "~/operations";
import { PolicyQueryAssessmentResults } from "~/components/policy-query-assessment-results";
import { Code } from "~/components/code";
import { Markdown } from "~/components/markdown";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { DetailRow } from "~/components/data-table";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { BlockIcon, ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { MoonIcon } from "~/components/ui-library";
import { ErrorBoundary } from "~/components/error-boundary";
import { Loading, LoadingFailed } from "~/components/loading";
import { CheckResultByText } from "~/components/impact/ByText/ByText";
import { RiskFactorsCell } from "~/components/Cells";

type AssetReportQueryRowProps = {
  space: Space;
  asset: Asset;
  queryEdge: CheckScoresEdge;
  isFocused?: boolean;
  isSelectable?: boolean;
  isChecked?: boolean;
  handleOpen?: (queryEdge: CheckScoresEdge) => void;
  handleClose?: (queryEdge: CheckScoresEdge) => void;
  handleCheck?: (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    exception: SelectedEntity,
  ) => void;
};

const Headline = styled(Typography)`
  padding-bottom: 0.3em;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export function ChecksTableRow({
  space,
  asset,
  queryEdge,
  isFocused = false,
  isSelectable = false,
  isChecked = false,
  handleOpen,
  handleClose,
  handleCheck,
}: AssetReportQueryRowProps) {
  const [open, setOpen] = React.useState<boolean>(isFocused);
  const navigate = useNavigate();

  const [getData, { data, error, loading }] = useGetCheckRowDataLazyQuery({
    variables: {
      entityMrn: asset.mrn,
      first: 1,
      filter: {
        checkMrn: queryEdge.node?.mrn || "",
      },
      renderedAssetQueryInput: {
        assetMrn: asset.mrn,
        queryMrn: queryEdge.node?.mrn || "",
      },
    },
  });

  const getCheckRowData = async () => {
    try {
      await getData();
    } catch (error) {
      console.error(error);
    }
  };

  const notFoundErrorMessage =
    data?.renderedAssetQueryData?.__typename === "NotFoundError"
      ? data.renderedAssetQueryData.message
      : null;

  const renderedData =
    data?.renderedAssetQueryData?.__typename === "RenderedAssetQueryData"
      ? data.renderedAssetQueryData
      : null;

  const checkScores =
    data?.checkScores?.__typename === "CheckScoresConnection"
      ? data.checkScores
      : null;

  const checkData = checkScores?.edges?.[0]?.node;

  const { results } = useFormattedResults(renderedData, queryEdge.node?.state);

  React.useEffect(() => {
    if (isFocused) {
      setOpen(isFocused);
      getCheckRowData();
    }
  }, [isFocused]);

  const handleClick = async () => {
    if (open) {
      setOpen(false);
      handleClose?.(queryEdge);
    } else {
      setOpen(true);
      handleOpen?.(queryEdge);
      getCheckRowData();
    }
  };

  const handlePendingReviewClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    exception: CheckScoresException,
  ) => {
    e.stopPropagation();
    navigate(
      `/space/inventory/${asset.id}/exceptions?spaceId=${space.id}&exceptionId=${exception.id}`,
    );
  };

  const {
    impactRating,
    state,
    resultType,
    riskScore,
    queryState,
    exception,
    mrn,
    title,
    tags,
    riskFactors,
  } = queryEdge.node || {};

  const { mquery } = checkData || {};

  const isModified = queryState !== MqueryState.Enabled;
  const isSnoozed = queryState === MqueryState.Snoozed;
  const isDisabled = queryState === MqueryState.Disabled;
  const isPendingException =
    exception?.reviewStatus === ReviewStatus.NotReviewed;

  const descText = mquery?.docs?.desc ? (
    <Markdown source={mquery?.docs?.desc} />
  ) : null;
  const auditText = mquery?.docs?.audit ? (
    <Markdown source={mquery?.docs.audit} />
  ) : null;

  // extract remediation text from nested docs structure
  const getRemediationText = (remediations: MqueryDocs["remediations"]) => {
    if (
      remediations &&
      "entries" in remediations &&
      remediations.entries.length > 0
    ) {
      return remediations.entries.map((remedation) => (
        <Markdown key={remedation.id} source={remedation.desc} />
      ));
    }
    return null;
  };

  const remediationText = getRemediationText(mquery?.docs?.remediations);
  const className = [isChecked ? "selected" : ""].join(" ");

  return (
    <React.Fragment key={mrn}>
      {/* Accordion Title Row */}
      <TableRow id={mrn} onClick={handleClick} className={className}>
        {isSelectable && (
          <TableCell>
            <Checkbox
              onChange={(e, checked) =>
                handleCheck?.(e, checked, {
                  mrn: mrn || "",
                  exception,
                  groupId: exception?.id,
                })
              }
              checked={isChecked}
              onClick={(e) => e.stopPropagation()}
            />
          </TableCell>
        )}

        <TableCell className="risk-cell">
          <Box sx={{ display: "flex", gap: 1 }}>
            <CheckResultByText
              riskScore={riskScore || 0}
              scoreResult={resultType || ScoreResultType.Unknown}
              scoreState={state || ScoreState.Open}
              impactRating={impactRating || QueryImpact.Critical}
            />
          </Box>
        </TableCell>

        <TableCell
          sx={{ opacity: isModified ? 0.5 : 1, overflowWrap: "anywhere" }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              {isSnoozed && (
                <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
              )}
              {isDisabled && (
                <Chip label="Disabled" icon={<BlockIcon />} size="small" />
              )}
              {isPendingException && (
                <NavigateToExceptionButton
                  onClick={(e) => {
                    handlePendingReviewClick(e, exception);
                  }}
                />
              )}
              <Typography
                className="mquery-title"
                fontSize={14}
                display="inline-block"
              >
                {title}
              </Typography>
            </Box>
            <List sx={{ p: 0 }}>
              <Typography variant="caption" color="text.secondary">
                {tags?.map((tag) => tag.value).join(", ")}
              </Typography>
            </List>
          </Box>
        </TableCell>

        <RiskFactorsCell riskFactors={riskFactors} />

        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Accordion Content Row */}
      <DetailRow
        id={`${mrn}-expand`}
        colSpan={isSelectable ? 5 : 4}
        open={open}
      >
        {error && (
          <Box sx={{ pt: 2, pb: 5 }}>
            <LoadingFailed what="result data" />
          </Box>
        )}
        {loading && (
          <Box sx={{ pt: 2, pb: 5 }}>
            <Loading what="result data" />
          </Box>
        )}
        {mquery && (
          <List>
            <ListItem disableGutters key="query">
              <ListItemText
                primary={
                  <>
                    <Headline variant="h6">Query</Headline>
                    <Code copyButton className="javascript">
                      {mquery.mql.trim()}
                    </Code>
                  </>
                }
              />
            </ListItem>
            {(results || notFoundErrorMessage) && (
              <ListItem disableGutters key="result">
                <ListItemText
                  primary={
                    <>
                      <Headline variant="h6">Result</Headline>
                      {notFoundErrorMessage && (
                        <Code copyButton className="ini">
                          {"[Query Error]\n" + notFoundErrorMessage}
                        </Code>
                      )}
                      {results}
                    </>
                  }
                />
              </ListItem>
            )}
            {descText && (
              <ListItem disableGutters key="description">
                <ListItemText
                  primary={
                    <>
                      <Headline variant="h6">Description</Headline>
                      {descText}
                    </>
                  }
                />
              </ListItem>
            )}
            {auditText && (
              <ListItem disableGutters key="audit">
                <ListItemText
                  primary={
                    <>
                      <Headline variant="h6">Audit</Headline>
                      {auditText}
                    </>
                  }
                />
              </ListItem>
            )}
            {remediationText && (
              <ErrorBoundary
                key={`${mrn}-remediation`}
                FallbackComponent={RowErrorFallback}
              >
                <ListItem disableGutters key="remediation">
                  <ListItemText
                    primary={
                      <>
                        <Headline variant="h6">Remediation</Headline>
                        {remediationText}
                      </>
                    }
                  />
                </ListItem>
              </ErrorBoundary>
            )}
          </List>
        )}
      </DetailRow>
    </React.Fragment>
  );
}

const RowErrorFallback = ({ error }: { error: Error }) => {
  console.error(error.message);
  return (
    <TableRow>
      <TableCell colSpan={5} sx={{ border: "none" }}></TableCell>
    </TableRow>
  );
};

export const useFormattedResults = (
  renderedData: RenderedAssetQueryData | null,
  scoreState?: ScoreState,
) => {
  const [results, setResults] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const { data, assessment } = renderedData || {};

  const getStatusfromScoreState = () => {
    switch (scoreState) {
      case ScoreState.Open:
        return "fail";
      case ScoreState.Closed:
        return "excellent";
      default:
        return "fail";
    }
  };

  useEffect(() => {
    getResults();
  }, [renderedData]);

  const getResults = () => {
    let results = undefined;
    if (assessment) {
      results = (
        <PolicyQueryAssessmentResults
          assessment={assessment}
          status={getStatusfromScoreState()}
        />
      );
    } else if (data) {
      try {
        results = (
          <Code className="plaintext">{JSON.stringify(data, null, 2)}</Code>
        );
      } catch {
        results = (
          <Code className="plaintext">
            An error occurred while processing results
          </Code>
        );
      }
    }

    setResults(results);
  };

  return { results };
};
