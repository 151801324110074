import { Routes, Route } from "react-router-dom";
import { AgentPage } from "~/pages/integration-managed-agent";
import { IntegrationSetupPage } from "~/pages/integration-setup";
import { AwsIntegrationPage } from "~/pages/integrations/cloud-security/aws/integration-aws-account";
import { K8AddPolicies } from "~/pages/integrations/kubernetes/k8-add-policies";
import { Space } from "~/lib/types";
import { PageLayout } from "../page-layout";
import { Integrations } from "~/pages/integrations/integrations";
import { K8IntegrationPage } from "~/pages/integrations/kubernetes/k8-integration";
import { K8UpdateConfiguration } from "~/pages/integrations/kubernetes/k8-update-configuration";
import { AgentsManagedPage } from "~/pages/integration-managed-agents";
import {
  HostedIntegrationList,
  HostedIntegrationPage,
  HostedIntegrationEdit,
} from "~/pages/integrations/hosted-integrations";
import { IamActions } from "~/lib/iam";

type Props = {
  space: Space;
  availablePermissions: string[];
};

export function IntegrationsRoutes({ space, availablePermissions }: Props) {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route
          index
          element={<Integrations {...{ space, availablePermissions }} />}
        />

        <Route
          path=":integrationTypeId"
          element={
            <HostedIntegrationList {...{ space, availablePermissions }} />
          }
        />

        <Route
          path=":integrationTypeId/:integrationId"
          element={
            <HostedIntegrationPage {...{ space, availablePermissions }} />
          }
        />
        <Route
          path=":integrationTypeId/:integrationId/edit"
          element={
            <HostedIntegrationEdit {...{ space, availablePermissions }} />
          }
        />

        {/* not in hosted list yet */}
        <Route
          path="managed"
          element={
            <AgentsManagedPage
              space={space}
              availablePermissions={availablePermissions}
            />
          }
        />

        <Route
          path="managed/:integrationId/*"
          element={<AgentPage {...{ space, availablePermissions }} />}
        />

        <Route
          path="aws/:integrationId/*"
          element={<AwsIntegrationPage {...{ space, availablePermissions }} />}
        />

        <Route
          path="kubernetes/:integrationId/*"
          element={<K8IntegrationPage {...{ space, availablePermissions }} />}
        />
        <Route
          path="kubernetes/:integrationId/edit"
          element={<K8UpdateConfiguration {...{ space }} />}
        />

        <Route path="add">
          {/* path="info/:type" => <IntegrationAbout/> cannot be contained in Page Layout  */}
          <Route
            index
            element={<Integrations {...{ space, availablePermissions }} />}
          />
          <Route
            path=":appName/:integrationTypeId/*"
            element={
              <IntegrationSetupPage {...{ space, availablePermissions }} />
            }
          />
          <Route
            path={`mondoo/kubernetes/:integrationId`}
            element={<K8AddPolicies {...{ space }} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
