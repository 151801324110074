import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ShieldOutlinedIcon } from "~/components/icons";
import { GridSegment } from "../GridSegment";
import { GridData } from "../GridSegment/GridSegment";

type Props = {
  sizes?: CardSizes;
  passedChecks: GridData[];
  isLoading: boolean;
};

export function ChecksPassedCard({ passedChecks, sizes, isLoading }: Props) {
  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Total Checks Passed",
        icon: <ShieldOutlinedIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
      data-testid="total-checks-passed-card"
    >
      <Grid container spacing={3} sx={{ pt: 2 }}>
        {passedChecks.map((item) => (
          <GridSegment key={item.title} data={item} color />
        ))}
      </Grid>
    </HubCard>
  );
}
