import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { motion, Variants } from "framer-motion";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
} from "react-hook-form";
import { Flex } from "~/components/Flex";

type OperatorListProps<T extends FieldValues> = {
  field: ControllerRenderProps<T, any>;
  control: Control<any>;
  disabled?: boolean;
  radioName: string;
  allowCaption: string;
  denyCaption: string;
  textareaCaption: string;
  isAllowSelected: boolean;
};

const operatorListVariants: Variants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

export function AllowDenyLists<T extends FieldValues>({
  field,
  control,
  disabled,
  radioName,
  allowCaption,
  denyCaption,
  textareaCaption,
  isAllowSelected,
}: OperatorListProps<T>) {
  return (
    <Grid
      container
      component={motion.div}
      variants={operatorListVariants}
      sx={{
        "& .form-text": {
          color: disabled ? "text.disabled" : "inherit",
        },
      }}
    >
      <Grid xs={6}>
        <Grid item xs={12} sx={{ pl: 5 }}>
          <Controller
            name={radioName}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                {/* Allow */}
                <Box>
                  <FormControlLabel
                    value="allow"
                    control={<Radio />}
                    label="Allow list"
                    disabled={disabled}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ pl: 3.5, mt: -1 }}
                  >
                    {allowCaption}
                  </Typography>
                </Box>
                {/* Deny */}
                <Box>
                  <FormControlLabel
                    value="deny"
                    control={<Radio />}
                    label="Deny list"
                    disabled={disabled}
                  />

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ pl: 3.5, mt: -1 }}
                  >
                    {denyCaption}
                  </Typography>
                </Box>
              </RadioGroup>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} sx={{ pl: 2 }}>
        <Flex flexDirection="column" sx={{ height: "100%" }}>
          <TextField
            multiline
            {...field}
            label={`${isAllowSelected ? "Allow list" : "Deny list"}`}
            sx={{ flexGrow: 1 }}
            InputProps={{
              sx: {
                backgroundColor: "code.background",
                borderRadius: 1,
                minHeight: "100%",
              },
            }}
          />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ px: 2, py: 0.5 }}
          >
            {textareaCaption}
          </Typography>
        </Flex>
      </Grid>
    </Grid>
  );
}
