import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import { useFrameworkContext } from "../framework";
import { FilterBar } from "../../filter-bar";
import { ComplianceTable, Header } from "../../compliance-table";
import { CompletionBar } from "../../completion-bar";
import { FormatRelativeDate, FormatTime } from "~/lib/date";
import {
  ComplianceAssetOrderField,
  ExceptionMutationAction,
  ExceptionType,
  Maybe,
  MqueryAssetScoresDocument,
  OrderDirection,
  TestIamActionsQuery,
  useGetComplianceFrameworkAssetsQuery,
} from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { useSearch } from "~/components/search/useSearch";
import { GroupingButton } from "~/components/grouping-button";
import { useGroupingButton } from "~/components/grouping-button/useGroupingButton";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { EmptyState } from "~/components/empty-state/empty-state";
import { isFeatureEnabled } from "~/login/features";
import { IamActions } from "~/lib/iam";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";

type AssetsType = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Assets({ availablePermissions }: AssetsType) {
  const { space, framework } = useFrameworkContext();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { toggleSelectedGroupingButton, selectedGroupingButton } =
    useGroupingButton();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    data: { complianceFramework } = {},
    error,
    loading,
    fetchMore,
  } = useGetComplianceFrameworkAssetsQuery({
    variables: {
      input: {
        frameworkMrn: framework.mrn,
        scopeMrn: space.mrn,
      },
      assetsInput: {
        orderBy: {
          field:
            (searchParams.get("field") as ComplianceAssetOrderField) ||
            ComplianceAssetOrderField.Name,
          direction:
            (searchParams.get("direction") as OrderDirection) ||
            OrderDirection.Asc,
        },
        query: searchFilters.join(" "),
        groups: selectedGroupingButton
          ? [{ groupType: selectedGroupingButton }]
          : undefined,
      },
    },
  });

  const { assets, assetGroupStatistics } = complianceFramework || {};

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    handleSetScope,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedAssets([]);
    },
    onRemoveException: () => {
      setSelectedAssets([]);
    },
    // TBD when backend is ready
    scopeMrns: [],
    queryMrns: [],
    refetchQueries: [MqueryAssetScoresDocument],
  });

  const assetsWithExceptions = (assets?.edges || []).map((asset) => ({
    ...asset,
    node: {
      ...asset.node,
      exception: null as Maybe<{
        id: string;
        justification?: Maybe<string>;
        action: ExceptionMutationAction;
      }>,
    },
  }));

  const targetPaginatedGroup = assetsWithExceptions
    .slice(pageItems.from, pageItems.to)
    .map((edge) => ({
      mrn: edge.node.asset?.mrn as string,
      exception: edge.node.exception,
      groupId: edge.node?.exception?.id,
    }));

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const isExceptionsEnabled =
    isFeatureEnabled("Assets Exceptions") && hasApplyExceptionPermission;

  const tableHeaders: Header[] = [
    isExceptionsEnabled
      ? {
          id: "SELECT",
          label: (
            <Checkbox
              checked={isGroupChecked(targetPaginatedGroup)}
              indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
              onChange={(event) =>
                onGroupCheckChange(event, targetPaginatedGroup)
              }
            />
          ),
          sortable: false,
        }
      : undefined,
    {
      id: "NAME",
      label: "Asset Name",
    },
    {
      id: "PLATFORM",
      label: "Platform",
    },
    {
      id: "COMPLETION",
      label: "Completion",
    },
    {
      id: "LAST_UPDATED",
      label: "Last Updated",
    },
  ].flatMap((h) => h ?? []);

  if (loading && !assets) {
    return <Loading what="assets" />;
  }

  if (error) {
    return <LoadingFailed what="assets" />;
  }

  if (!assets) {
    return <EmptyState contentType="framework-assets" space={space} />;
  }

  const handleAssetClick = (assetId: string | undefined) => {
    if (!assetId) return;

    navigate(`/space/inventory/${assetId}/overview?spaceId=${space.id}`);
  };

  const groups = assetGroupStatistics?.filter((group) => group.count > 0) || [];

  return (
    <Box>
      {groups.length > 0 && (
        <Grid container spacing={2} sx={{ my: 5 }}>
          {groups.map((group) => {
            return (
              <Grid item key={group.groupType} xs={12} sm={6} md={4}>
                <GroupingButton
                  {...{ group }}
                  onClick={() => toggleSelectedGroupingButton(group.groupType)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <FilterBar
        searchId="compliance-framework-assets"
        placeholder="compliance_assets"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      />
      <ComplianceTable
        tableHeaders={tableHeaders}
        defaultActiveColumn={ComplianceAssetOrderField.Name}
      >
        {assetsWithExceptions
          ?.filter((x) => x.node !== null)
          .slice(pageItems.from, pageItems.to)
          .map((edge) => {
            const asset = edge.node?.asset;
            const completion = edge.node?.compliancePercentage || 0;

            return (
              <TableRow
                onClick={() => handleAssetClick(asset?.id)}
                key={asset?.id}
                sx={{
                  "& .MuiTableCell-root": { fontWeight: 400 },
                }}
              >
                {isExceptionsEnabled && (
                  <TableCell>
                    <Checkbox
                      onClick={(e) => handleNodeClick(e)}
                      onChange={(e, changed) =>
                        handleNodeChange(e, changed, {
                          groupId: edge.node?.exception?.id,
                          mrn: asset?.mrn as string,
                          exception: !edge?.node?.exception
                            ? null
                            : {
                                justification:
                                  edge.node.exception?.justification,
                                action: edge.node.exception?.action,
                              },
                        })
                      }
                      checked={Boolean(
                        selectedAssets.find(
                          (selectedCheck) => asset?.mrn === selectedCheck.mrn,
                        ),
                      )}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ width: "40%" }}>{asset?.name}</TableCell>
                <TableCell sx={{ width: "20%" }}>
                  {asset?.platform?.name}
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <CompletionBar
                    {...{
                      percent: completion,
                      height: theme.spacing(1),
                      tooltip: `Completion: ${completion}%`,
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: (theme) => theme.spacing(1.25),
                    width: "15%",
                  }}
                >
                  {FormatRelativeDate(String(asset?.updatedAt)) +
                    " - " +
                    FormatTime(String(asset?.updatedAt))}
                </TableCell>
              </TableRow>
            );
          })}
      </ComplianceTable>
      {selectedAssets.length > 0 && (
        <ExceptionsToolbar
          target="asset"
          onCancel={handleCancelClick}
          onRemoveExceptionClick={handleRemoveExceptionModalOpen}
          onSetExceptionClick={handleSetExceptionModalOpen}
          selectedEntities={selectedAssets}
          totalCount={assets?.edges?.length || 0}
          onSetScopeClick={handleSetScope}
        />
      )}
      <ExceptionsModals
        isSetExceptionModalOpen={isSettingException}
        isRemoveExceptionModalOpen={isRemovingException}
        onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
        onSetExceptionModalClose={handleSetExceptionModalClose}
        onSetExceptionModalSave={handleSetException}
        onRemoveExceptionModalSave={handleRemoveException}
        loading={exceptionsLoading}
        target="asset"
        role="security"
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedAssets}
      />
      <Pagination
        fetchMore={fetchMore}
        pageInfo={assets.pageInfo}
        setPageItems={setPageItems}
        totalCount={assets.totalCount}
      />
    </Box>
  );
}
