import { Box, Button, Paper, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";
import { Link as RouterLink } from "react-router-dom";
import { Space } from "~/lib/types";
import FirstIntegrationImage from "~/images/first-integration.png";

type CreateFirstCaseProps = {
  space: Space;
};

export const CreateFirstCase = ({ space }: CreateFirstCaseProps) => {
  return (
    <Paper
      sx={{ width: "100%", display: "flex", justifyContent: "center", p: 5 }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        sx={{ maxWidth: "700px" }}
      >
        <Box
          component="img"
          src={FirstIntegrationImage}
          alt="first integration"
          sx={{ width: 335, height: 225, borderRadius: "4px" }}
        />
        <Typography textAlign="center" pt={5} fontSize={24} fontWeight={700}>
          Time to create your first case!
        </Typography>
        <Typography mt={2} color="text.secondary" textAlign="center">
          You're ready to get started with cases! When you see a finding you
          want your team to fix, create a new case to track progress.
        </Typography>
        <Button
          component={RouterLink}
          to={`/space/security?spaceId=${space.id}`}
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          GO TO SECURITY
        </Button>
      </Flex>
    </Paper>
  );
};
