import { cloneElement } from "react";
import { AdvisoryIcon, MondooIcon } from "~/components/icons";
import { MsftDefenderIcon } from "~/components/icons/mondoo/integrations";

export function mapDetectedByIcon(source: string): React.ReactNode {
  let icon = <AdvisoryIcon />;
  switch (source) {
    case "VEX_SOURCE_MSDEFENDER":
      icon = <MsftDefenderIcon />;
      break;
    case "VEX_SOURCE_MONDOO":
    case "VEX_SOURCE_CNSPEC":
      icon = <MondooIcon />;
      break;
    case "VEX_SOURCE_FALCON_SPOTLIGHT":
      // icon= <FalconIcon />;
      break;
    case "VEX_SOURCE_SENTINEL_ONE":
      // icon= <SentinelOneIcon />;
      break;
    default:
      break;
  }

  return cloneElement(icon, { sx: { fontSize: 24, mr: 1 } });
}
