import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box } from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { MenuItemsGroup } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/MenuItemsGroup";
import { useEffect, useRef, useState } from "react";
import {
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import { Path, useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";

type GroupItemValue = {
  label: string;
  value:
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField
    | WorkspaceSelectionConditionRatingField;
};

export type KeysDropdownAutocomplete = {
  name: `selections.${number}.conditions.${number}.formKey`;
  groups: Array<{ label: string; values: Array<GroupItemValue> }>;
  onChange: (
    name: Path<CreateWorkspaceFormInput>,
    value:
      | WorkspaceSelectionConditionStringField
      | WorkspaceSelectionConditionIntField
      | WorkspaceSelectionConditionRatingField,
  ) => void;
};

export const KeysDropdownAutocomplete = ({
  name,
  groups,
  onChange,
}: KeysDropdownAutocomplete) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { register } = useFormContext<CreateWorkspaceFormInput>();

  useEffect(() => {
    register(name);
  }, [register]);

  const onItemClick = (value: unknown) => {
    onChange(
      name,
      value as
        | WorkspaceSelectionConditionStringField
        | WorkspaceSelectionConditionIntField
        | WorkspaceSelectionConditionRatingField,
    );
    setIsOpen(false);
  };

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClick={() => setIsOpen((open) => !open)}
        data-testid={`${isOpen ? "close" : "open"}-keys-dropdown`}
      />
      <Box sx={{ maxWidth: "400px", position: "relative" }}>
        <BaseAutocomplete
          showKeyboardHint
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorRef={anchorRef}
          renderContent={() => (
            <BaseMenuList>
              {groups.map((group) => (
                <MenuItemsGroup
                  groupTitle={group.label}
                  onClick={onItemClick}
                  items={group.values}
                  key={group.label}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
