import { format, formatDistanceToNow, getYear } from "date-fns";

export * from "date-fns";

const thisYear = getYear(new Date());

// June 30, 8:50am
export const FormatDate = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;
  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  const m = new Date(str);
  if (getYear(m) < thisYear || getYear(m) > thisYear)
    return format(m, "MMMM dd, yyyy");
  return format(m, "MMMM dd, hh:mma");
};

// 11/30/2021, 8:50am
export const FormatDateShort = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;
  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  const m = new Date(str);
  if (getYear(m) < thisYear || getYear(m) > thisYear)
    return format(m, "MM/dd/yyyy, hh:mma");
  return format(m, "MM/dd, hh:mma");
};

// June 30, 2021
export const FormatDateYear = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;
  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  const m = new Date(str);
  return format(m, "MMMM dd, yyyy");
};

// Feb 6, 2021
export const FormatAbbrDateYear = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;
  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  const m = new Date(str);
  return format(m, "MMM dd, yyyy");
};

// June 30
export const FormatDay = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;

  const m = new Date(str);
  if (getYear(m) < thisYear || getYear(m) > thisYear)
    return format(m, "MMMM dd, yyyy");
  return format(m, "MMMM dd");
};

// An hour ago
export const FormatRelativeDate = (str: string | null): string => {
  if (str == null) return "";
  if (str == "") return str;

  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  return formatDistanceToNow(new Date(str), { addSuffix: true });
};

export const FormatRelativeDateAbbreviated = (
  str: string | null = null,
): string => {
  if (str == null) return "";
  if (str == "") return str;

  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  return FormatRelativeDate(str)
    .replaceAll(" minutes", "m")
    .replaceAll(" hours", "h")
    .replaceAll(" days", "d");
};

// 08:37:12
export const FormatTime = (str: string | null): string => {
  if (str == null) return "";
  if (str == "") return str;

  return format(new Date(str), "hh:mm:ssaaa");
};

export const Format = (
  str: string | null = null,
  formatStr = "MM/dd/yy, hh:mm:ssaaa",
) => {
  if (str == null) return "";
  if (str == "") return str;

  return format(new Date(str), formatStr);
};

// 22d ago or Never
export const formatLastUsed = (lastUsed: string | null = null) => {
  if (!lastUsed) return "Never";

  const formatted = FormatRelativeDateAbbreviated(lastUsed);
  return formatted;
};

// 03/27/24, 11:27pm
export const FormatDateAndTime = (str: string | null): string => {
  if (str == null) return "";
  if (str == "") return str;

  return format(new Date(str), "MM/dd/yy, hh:mma");
};
