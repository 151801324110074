import { Box, Divider } from "@mui/material";
import { Flex } from "~/components/Flex";
import { TextTitle } from "~/components/ui-library";

type SectionHeadingProps = {
  heading: string;
};

export const SectionHeading = ({ heading }: SectionHeadingProps) => {
  return (
    <Flex center gap={3} my={4} className="section-header">
      <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
        {heading}
      </TextTitle>
      <Box width={1}>
        <Divider />
      </Box>
    </Flex>
  );
};
