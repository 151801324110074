import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Typography,
  Link,
  Breadcrumbs,
  Box,
  CircularProgress,
  List,
  Paper,
  Button,
  ListItem,
  Grid,
} from "@mui/material";
import { Space } from "~/lib/types";
import { FileUploadIcon, HomeIcon } from "~/components/icons";
import { TestIamActionsQuery } from "~/operations";
import { AddButton } from "~/components/add-button";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/ui-library";
import { Facets } from "./components/Facets";
import { Pagination } from "./components/Pagination";
import { RegistriesByCategory } from "./components/RegistriesByCategory";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { Upload } from "~/components/upload";
import useRegistries from "./hooks/useRegistries";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { Caption } from "./components/Caption";
import { PolicyAssignButton } from "./components/PolicyAssignButton";
import { usePolicyPermissions } from "./hooks/usePolicyPermissions";

export type EnablePoliciesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function EnablePolicies({
  space,
  availablePermissions,
}: EnablePoliciesProps) {
  const {
    loading,
    error,
    filtersLoading,
    filtersErrors,
    filterOptions,
    sortByOptions,
    categoryOptions,
    registries,
    paginatedRegistries,
    facets,
    state,
    filters,
    onFacetsChange,
    onPaginationChange,
    onAddPolicyMutation,
    onChangeAssignHandler,
  } = useRegistries({ space });
  const navigate = useNavigate();
  const { policyAssignPermission, policyUploadPermission } =
    usePolicyPermissions({ availablePermissions });

  const handlePolicyAssignChange = async () => {
    try {
      await onChangeAssignHandler();
    } catch (error) {
      console.error("Failed to update policy assignment");
    }
  };

  const policyAddDetailHref = (mrn: string) => {
    return `/space/security/policies/add/${PolicyMrnToURIEncodedId(mrn)}?spaceId=${space.id}`;
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/security/policies"
      component={RouterLink}
      to={`/space/security/policies?spaceId=${space.id}`}
    >
      Policies
    </Link>,
    <Typography key="/space/overview/security/policies/add">
      Enable Policies
    </Typography>,
  ];

  document.title = "Enable Policies · Policies · Security · Mondoo";

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="add-policies-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          textTransform="uppercase"
          sx={{ mb: 3 }}
        >
          Enable Policies
        </Typography>
        <Flex id="add-policies-actions" alignItems="center" gap={4}>
          {policyUploadPermission && (
            <Upload
              title="Upload Policy"
              placeholder="Drag and drop a policy or click"
              successMessage="Successfully added policy bundle"
              errorMessage="Failed to upload policy bundle"
              spaceMrn={space.mrn}
              addFileMutation={onAddPolicyMutation}
              button={
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FileUploadIcon />}
                >
                  Upload Custom
                </Button>
              }
            />
          )}
          <AddButton
            id="cancel-add-policy-button"
            href={`/space/security/policies?spaceId=${space.id}`}
            aria-label="Cancel Add Policy"
            close
          />
        </Flex>
      </Box>

      {state.isReady ? (
        <>
          {categoryOptions.length > 0 && (
            <RegistriesByCategory
              categoryOptions={categoryOptions}
              categories={facets.categories}
              onFacetsChange={onFacetsChange}
            />
          )}
          <Box mt={5}>
            <Facets
              filtersLoading={filtersLoading}
              filtersErrors={filtersErrors}
              filterOptions={filterOptions}
              filters={filters}
              sortByOptions={sortByOptions}
              facets={facets}
              state={state}
              onFacetsChange={onFacetsChange}
            />
          </Box>
          {loading && (
            <Flex center>
              <CircularProgress />
            </Flex>
          )}
          {error && (
            <Box>
              <Text>Errors</Text>
              <Text>{String(error)}</Text>
            </Box>
          )}
          {paginatedRegistries.length > 0 && (
            <List
              id="available-policies-list"
              component={Paper}
              sx={(theme) => ({
                py: 0,
                overflow: "hidden",
                borderRadius: theme.spacing(0.5),
                boxShadow: (theme) => theme.shadows[1],
              })}
            >
              {paginatedRegistries.map((pack) => {
                return (
                  <ListItem
                    key={pack.mrn}
                    className="available-policies-list-item"
                    sx={{
                      p: 3,
                      cursor: "pointer",
                      "&:not(:last-child)": {
                        borderBottom: (theme) =>
                          `1px solid ${theme.palette.background.lighter}`,
                      },
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.background.light,
                      },
                    }}
                    onClick={() => navigate(policyAddDetailHref(pack.mrn))}
                  >
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs="auto"
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "left",
                        }}
                      >
                        {policyIcon(pack.name, "large")}
                      </Grid>
                      <Grid item xs={10} sm container>
                        <Grid item xs={10} sm>
                          <Typography
                            sx={{ pl: 3, fontWeight: 700 }}
                            className="item-name"
                          >
                            {pack.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={0} pl={3} mt={1}>
                          <Caption
                            trustLevel={pack.trustLevel}
                            version={pack.version}
                            authors={pack.authors}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        sx={{
                          display: { xs: "none", sm: "flex" },
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {policyAssignPermission && (
                          <PolicyAssignButton
                            spaceMrn={space.mrn}
                            policyMrn={pack.mrn}
                            assigned={pack.assigned}
                            action={pack.action}
                            onChange={handlePolicyAssignChange}
                            advanced
                          />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          )}
          <Pagination
            page={state.page}
            count={registries.length}
            rowsPerPage={state.rowsPerPage}
            onPaginationChange={onPaginationChange}
          />
        </>
      ) : null}
    </Box>
  );
}
