import {
  Box,
  styled,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Flex } from "~/components/Flex";
import {
  Text,
  Flags,
  MoonIcon, // TODO::UILIBRARY - change name to SnoozeIcon
} from "~/components/ui-library";
import { BlockOutlined } from "@mui/icons-material";
import { TimelineGroup } from "~/components/exceptions/exceptions";
import { ExpandMoreIcon } from "~/components/icons";

type ExceptionTimelineProps = {
  timelines: TimelineGroup[];
  onLoadMore?: () => void;
};

export const ExceptionTimeline = ({
  timelines,
  onLoadMore,
}: ExceptionTimelineProps) => {
  return (
    <Box>
      <Box>
        {timelines.map((timeline, index) => (
          <AccordionWrapper
            key={timeline.title}
            className="highlight accordion-wrapper"
          >
            <AccordionContainer
              {...timeline?.accordionProps}
              className="accordion-container"
            >
              <AccordionSummaryContainer>
                <Flex flex={1} justifyContent="space-between">
                  <Flex alignItems="center" gap={2}>
                    <Flex center gap={1}>
                      <Text className="accordion-summary-title" bold>
                        {timeline.title}
                      </Text>
                      <ExpandMoreIcon className="expanded-more-icon" />
                    </Flex>
                    {timeline?.flags?.map((name: string) => (
                      <Flags key={name} name={name} />
                    ))}
                  </Flex>
                  <Flex alignItems="center" gap={8}>
                    <Flex
                      center
                      gap={1}
                      {...(timeline.snoozed === 0 && {
                        sx: { color: "text.disabled" },
                      })}
                    >
                      <MoonIcon />
                      <Text bold>{timeline.snoozed}</Text>
                    </Flex>
                    <Flex
                      center
                      gap={1}
                      {...(timeline.disabled === 0 && {
                        sx: { color: "text.disabled" },
                      })}
                    >
                      <BlockOutlined />
                      <Text bold>{timeline.disabled}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </AccordionSummaryContainer>
              <AccordionDetails>
                <Flex flexDirection="column" gap={3}>
                  {timeline?.exceptionRows}
                </Flex>
              </AccordionDetails>
            </AccordionContainer>
            <TimelineContainer className="timeline-container">
              <TimelineDot className="timelineDot" />
              {/* Divider line should end at the 
                  dot of the last item */}
              {timelines?.length > 1 && index !== timelines?.length - 1 && (
                <DividerContainer orientation="vertical" />
              )}
            </TimelineContainer>
          </AccordionWrapper>
        ))}
      </Box>
      {onLoadMore && (
        <Button color="primary" onClick={() => onLoadMore()} sx={{ mb: 0.8 }}>
          LOAD MORE
        </Button>
      )}
    </Box>
  );
};

const AccordionWrapper = styled(Flex)(({ theme }) => ({
  flexDirection: "row-reverse",
  alignItems: "baseline",
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: 0,
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: `${theme.spacing(1.5)} 0`,
  },
  ".timeline-container": {
    marginTop: theme.spacing(2.5),
    marginBottom: `-${theme.spacing(2.8)}`,
  },
  "&.highlight": {
    ".timeline-container": {
      marginTop: theme.spacing(2.7),
      marginBottom: `-${theme.spacing(2)}`,
      ".timelineDot": {
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    ".accordion-summary-title": {
      fontSize: theme.spacing(3),
    },
  },
}));

const AccordionContainer = styled(Accordion)({
  backgroundColor: "transparent",
  boxShadow: "none",
  "&::before": {
    opacity: 0,
  },
});

const AccordionSummaryContainer = styled(AccordionSummary)({
  ".expanded-more-icon": {
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  ".Mui-expanded .expanded-more-icon": {
    transform: "rotate(180deg)",
  },
});

const TimelineContainer = styled(Flex)(({ theme }) => ({
  minWidth: theme.spacing(2),
  alignSelf: "stretch",
  flexDirection: "column",
  alignItems: "center",
}));

const TimelineDot = styled(Box)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: "100%",
  flexShrink: 0,
  backgroundColor: theme.palette.background.lightest,
  zIndex: 1,
}));

const DividerContainer = styled(Divider)(({ theme }) => ({
  borderRightWidth: 2,
  borderColor: theme.palette.background.lightest,
}));
