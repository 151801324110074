import { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import {
  AssetOrderField,
  OrderDirection,
  ScoreType,
  useAssetForwardPaginationQuery,
} from "~/operations";
import { Loading, LoadingFailed } from "../loading";
import { ListView } from "./list-view";
import { KeyboardView } from "./keyboard-view";
import { Space } from "~/lib/types";
import { ExpandMoreIcon } from "../icons";
import { AssetsEdge, RelatedAssetsCount } from "./asset-explorer-tab";

type ExplorerAccordionProps = {
  group: RelatedAssetsCount;
  key: string;
  space: Space;
  assetMrn: string;
  sortBy: OrderDirection;
  queryTerms?: string[];
};

export function ExplorerAccordion({
  group,
  space,
  assetMrn,
  sortBy,
  queryTerms = [],
}: ExplorerAccordionProps) {
  const [open, setOpen] = useState<boolean>(true);
  const [searchParams, _setSearchParams] = useSearchParams();
  const view = searchParams.get("view");

  const { data, loading, error, refetch, fetchMore } =
    useAssetForwardPaginationQuery({
      variables: {
        scopeMrn: space.mrn,
        relatedToAssetMrn: assetMrn,
        assetTypes: [group.type],
        scoreRange: [],
        scoreType: ScoreType.Unknown,
        platformName: [],
        platformKind: [],
        platformRuntime: [],
        labels: [],
        eol: null,
        reboot: null,
        exploitable: null,
        updated: null,
        orderBy: {
          field: AssetOrderField.Score,
          direction: sortBy,
        },
        queryTerms: queryTerms,
        first: 100,
      },
    });

  const pageInfo = data?.assets?.pageInfo;
  const items = data?.assets?.edges || [];
  const totalCount = data?.assets?.totalCount || 0;

  useEffect(() => {
    refetch();
  }, [sortBy]);

  const formatGroupType = (type: string) => {
    const regex = new RegExp("(^k8s.)|(^aws.)");
    return type.replace(regex, "") + "s";
  };

  const displayContent = (
    error: ApolloError | undefined,
    loading: boolean,
    items: AssetsEdge[],
  ) => {
    if (error) {
      return <LoadingFailed />;
    } else if (loading) {
      return <Loading />;
    } else if (view === "list") {
      return (
        <ListView {...{ items, pageInfo, space, fetchMore, totalCount }} />
      );
    }
    return (
      <KeyboardView {...{ items, pageInfo, space, fetchMore, totalCount }} />
    );
  };

  if (data?.assets?.edges && data.assets.edges.length < 1) {
    return <Box />;
  }

  return (
    <Box mb={3}>
      <Accordion
        defaultExpanded
        onChange={(_e, expanded) => setOpen(expanded)}
        sx={{ backgroundColor: "background.light" }}
      >
        <AccordionSummary sx={{ px: 4, py: 0.5 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              fontWeight={700}
              sx={{ mr: 0.5, textTransform: "uppercase" }}
            >
              {formatGroupType(group.type)}
            </Typography>
            <Typography color="text.secondary" fontWeight={700}>
              {group.count > items.length
                ? `Showing ${items.length} of ${group.count}`
                : group.count}
            </Typography>
            <ExpandMoreIcon
              className="expand-icon"
              sx={{
                transform: open ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform .25s",
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "background.paper",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            padding: 0,
          }}
        >
          {displayContent(error, loading, items)}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
