import { useNavigate } from "react-router-dom";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import {
  DialogContainer,
  GradientButton,
  TitleArea,
  TitleAreaProps,
} from "../components";
import exploreCat from "./media/explore-cat.png";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type FinishProps = {
  scope: SpaceOrWorkspaceScope | undefined;
  onClose: TitleAreaProps["onClose"];
};

export const Finish = ({ onClose, scope }: FinishProps) => {
  const navigate = useNavigate();

  const close = () => {
    navigate(`/space/inventory?${scope?.params}`, {
      replace: true,
    });
  };
  return (
    <DialogContainer>
      <TitleArea onClose={onClose} />
      <DialogContent sx={{ p: 0 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box textAlign="center">
            <Box
              component="img"
              src={exploreCat}
              sx={{
                height: "auto",
                width: {
                  xs: 270,
                  sm: 320,
                },
                pb: {
                  xs: 3,
                  sm: 0,
                },
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              fontWeight={700}
              sx={{
                pb: 2,
                textTransform: "uppercase",
                fontSize: {
                  xs: 16,
                  sm: 20,
                  md: 24,
                },
              }}
            >
              You're all set. Go forth and explore, Mondoonaut!
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                lineHeight: "18.75px",
                fontSize: {
                  xs: 12,
                  sm: 16,
                },
              }}
            >
              If you need help, select the documentation icon on the Mondoo
              Console toolbar.
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 0, pt: 6, justifyContent: "center" }}>
        <GradientButton onClick={close}>Explore Mondoo</GradientButton>
      </DialogActions>
    </DialogContainer>
  );
};
