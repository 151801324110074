import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { ShowDetails } from "~/components/ShowDetails/ShowDetails";

type OverviewHeaderProps = {
  title: ReactNode | string;
  description?: ReactNode | string;
  additionalContent?: ReactNode;
};

export const DashboardHeader = ({
  title,
  description,
  additionalContent,
}: OverviewHeaderProps) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
      >
        {title}
      </Typography>

      <Box sx={{ color: "text.secondary" }}>
        <ShowDetails
          children={description}
          additionalContent={additionalContent}
        />
      </Box>
    </Box>
  );
};
