import { Flex } from "~/components/Flex";
import { Typography } from "@mui/material";
import { NavDrawerFleetIcon } from "~/components/icons";

type SelectionAssetsCountProps = {
  selectionCount: number;
  totalCount: number;
};

export const SelectionAssetsCount = ({
  selectionCount,
  totalCount,
}: SelectionAssetsCountProps) => {
  return (
    <Flex>
      <Flex flexDirection="row" gap={1}>
        <Typography fontWeight={700} color="text.primary">
          {selectionCount}
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            {" "}
            / {totalCount}
          </Typography>
        </Typography>
        <NavDrawerFleetIcon sx={{ color: "text.primary" }} />
      </Flex>
    </Flex>
  );
};
