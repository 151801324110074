import { AddIcon } from "~/components/icons";
import { Button } from "@mui/material";
import { MouseEvent } from "react";

type AddConditionButtonProps = {
  isOpen: boolean;
  anchorRef?: React.RefObject<HTMLButtonElement>;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const OpenDropdownButton = ({
  isOpen,
  anchorRef,
  onClick,
  ...rest
}: AddConditionButtonProps) => {
  return (
    <Button
      variant="outlined-contained"
      color="primary"
      ref={anchorRef}
      onClick={onClick}
      sx={{
        minWidth: "40px",
        height: "40px",
        p: 0,
        ...(isOpen
          ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
          : {}),
      }}
      {...rest}
    >
      <AddIcon fontSize="small" sx={{ rotate: isOpen ? "45deg" : "0deg" }} />
    </Button>
  );
};
