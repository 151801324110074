import { Box } from "@mui/material";
import { ChartData, DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ExplorerIcon } from "~/components/icons/mondoo/explorer";

type Props = {
  sizes?: CardSizes;
  assetsGrades: ChartData[];
  isLoading: boolean;
};

export function AssetOverviewCard({ sizes, assetsGrades, isLoading }: Props) {
  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Asset Overview",
        icon: <ExplorerIcon fontSize="small" />,
      }}
      PaperProps={{ height: 264 }}
      data-testid="asset-overview-card"
    >
      <Box sx={{ mt: 2 }}>
        <DonutChart data={assetsGrades} dataType="assets" />
      </Box>
    </HubCard>
  );
}
