import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, TableCell, Typography } from "@mui/material";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Tag } from "~/operations";
import { Flex } from "~/components/Flex";
import { Format } from "~/lib/date";

type CaseTitleCellProps = {
  title: string;
  createdAt: string | undefined;
  assets: number | undefined;
  isOpen?: boolean;
  "data-testid"?: string;
  tags: Array<Tag>;
};

export const CaseTitleCell = ({
  createdAt,
  title,
  assets,
  tags,
  ...props
}: CaseTitleCellProps) => {
  const tagsWithCreated: Tag[] = [
    {
      __typename: "Tag",
      key: "Created",
      value: `${Format(createdAt)}`,
    },
    ...tags,
  ];

  return (
    <TableCell data-testid={props["data-testid"]}>
      <Flex alignItems="center" flex="1 0 auto">
        <Flex flexDirection="column" flex="1 0 auto">
          <OverflowHelper>
            <Flex gap={1}>
              <Typography>{title}</Typography>
            </Flex>
          </OverflowHelper>
          <Box display="flex" gap={1} mt={1}>
            {tagsWithCreated?.map((tag) => (
              <FirewatchChip key={tag.key} tag={tag} />
            ))}
          </Box>
        </Flex>
      </Flex>
    </TableCell>
  );
};
