import { Typography, Box, Breadcrumbs, Link } from "@mui/material";
import { HomeIcon } from "~/components/icons";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Flex } from "~/components/Flex";
import { AddButton } from "~/components/add-button";
import { CreateSpace } from "./space/Workspaces/components/CreateSpace";

export type CreateSpaceProps = {};

export function CreateSpacePage({}: CreateSpaceProps) {
  const [searchParams] = useSearchParams();

  const orgId = searchParams.get("organizationId");

  const breadcrumbs = [
    <Link
      key="/organization/overview"
      component={RouterLink}
      to={`/organization/overview?organizationId=${orgId}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/organization/spaces"
      component={RouterLink}
      to={`/organization/spaces?organizationId=${orgId}`}
      display="flex"
    >
      Spaces
    </Link>,
    <Typography key="/organization/spaces/new">Create New</Typography>,
  ];

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="create-new-space-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Flex flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 1 }}
          >
            Create New Space
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Create a new space to store your infrastructure, manage access, or
            create boundaries between your data sources.
          </Typography>
        </Flex>
        <Flex id="create-new-space-actions" alignItems="center" gap={4}>
          <AddButton
            id="space"
            href={`/organization/spaces?organizationId=${orgId}`}
            aria-label="Create New Organization"
            close
          />
        </Flex>
      </Box>
      <CreateSpace />
    </Box>
  );
}
