import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import { useControlContext } from "../control";
import { MoonIcon } from "~/components/ui-library";
import { CompletionBar } from "../../completion-bar";
import { ComplianceTable, Header } from "../../compliance-table";
import { FilterBar } from "../../filter-bar";
import {
  ComplianceAssetOrderField,
  ExceptionMutationAction,
  ExceptionType,
  GetComplianceControlAssetsDocument,
  OrderDirection,
  TestIamActionsQuery,
  useGetComplianceControlAssetsQuery,
} from "~/operations";
import { useSearch } from "~/components/search/useSearch";
import { EmptyState } from "~/components/empty-state/empty-state";
import { useGroupingButton } from "~/components/grouping-button/useGroupingButton";
import { GroupingButton } from "~/components/grouping-button";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { IamActions } from "~/lib/iam";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { Chip } from "~/components/chip";
import { BlockIcon } from "~/components/icons";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { OutOfScopeChip } from "~/components/exceptions/out-of-scope-chip";
import { FormatRelativeDate, FormatTime } from "~/lib/date";
import { Loading, LoadingFailed } from "~/components/loading";

type AssetsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Assets({ availablePermissions }: AssetsProps) {
  const { space, controlMrn, frameworkMrn, control } = useControlContext();
  const { handleFilterQuery, searchFilters } = useSearch();
  const { toggleSelectedGroupingButton, selectedGroupingButton } =
    useGroupingButton();
  const theme = useTheme();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const navigate = useNavigate();

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const {
    data: { complianceControl } = {},
    error,
    loading,
    fetchMore,
  } = useGetComplianceControlAssetsQuery({
    variables: {
      input: {
        controlMrn,
        frameworkMrn,
        scopeMrn: space.mrn,
      },
      assetsInput: {
        after: null,
        first: null,
        orderBy: {
          direction: OrderDirection.Asc,
          field: ComplianceAssetOrderField.Name,
        },
        query: searchFilters.join(" "),
        groups: selectedGroupingButton
          ? [{ groupType: selectedGroupingButton }]
          : undefined,
      },
    },
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalClose,
    handleRemoveExceptionForScopes,
    handleSetExceptionForScopes,
    handleSetScopeForMultipleScopes,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedAssets([]);
    },
    onRemoveException: () => {
      setSelectedAssets([]);
    },
    // TBD when backend is ready
    scopeMrns: mapSelectedEntitiesToString(selectedAssets),
    controlMrns: [controlMrn],
    multipleScopes: true,
    refetchQueries: [
      {
        query: GetComplianceControlAssetsDocument,
        variables: {
          input: {
            controlMrn,
            frameworkMrn,
            scopeMrn: space.mrn,
          },
          assetsInput: {
            after: null,
            first: null,
            orderBy: {
              direction: OrderDirection.Asc,
              field: ComplianceAssetOrderField.Name,
            },
            query: searchFilters.join(" "),
            groups: selectedGroupingButton
              ? [{ groupType: selectedGroupingButton }]
              : undefined,
          },
        },
      },
    ],
  });

  const { assets } = complianceControl || {};
  const { assetGroupStatistics } = control || {};

  const targetPaginatedGroup = (assets?.edges || [])
    .slice(pageItems.from, pageItems.to)
    .map((edge) => ({
      mrn: edge.node?.asset?.mrn as string,
      exception: edge.node?.exception,
      groupId: edge.node?.exception?.id,
    }));

  if (loading && !assets) {
    return <Loading what="assets" />;
  }

  if (error) {
    return <LoadingFailed what="assets" />;
  }

  const assetsEdges = assets?.edges ?? [];
  const assetsArray = assetsEdges.map((edge) => edge?.node);
  const groups = assetGroupStatistics?.filter((group) => group.count > 0) || [];

  const tableHeaders: Header[] = [
    hasApplyExceptionPermission
      ? {
          id: "SELECT",
          label: (
            <Checkbox
              checked={isGroupChecked(targetPaginatedGroup)}
              indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
              onChange={(event) =>
                onGroupCheckChange(event, targetPaginatedGroup)
              }
            />
          ),
          sortable: false,
        }
      : undefined,
    {
      id: "ASSET_NAME",
      label: "Asset Name",
    },
    {
      id: "PLATFORM",
      label: "Platform",
    },
    {
      id: "COMPLETION",
      label: "Completion",
    },
    {
      id: "LAST_UPDATED",
      label: "Last Updated",
    },
    {
      id: "CHILD_ASSETS",
      label: "# Child Assets",
      options: {
        textAlign: "right",
      },
    } as Header,
  ].flatMap((h) => h ?? []);

  if (!assetsArray.length) {
    return <EmptyState contentType="control-assets" space={space} />;
  }

  const handleAssetClick = (assetId: string | undefined) => {
    if (!assetId) return;

    navigate(`/space/inventory/${assetId}/overview?spaceId=${space.id}`);
  };

  return (
    <Box>
      {groups.length > 0 && (
        <Grid container spacing={2} sx={{ my: 5 }}>
          {groups.map((group) => {
            return (
              <Grid item key={group.groupType} xs={12} sm={6} md={4}>
                <GroupingButton
                  {...{ group }}
                  onClick={() => toggleSelectedGroupingButton(group.groupType)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <FilterBar
        searchId="compliance-control-assets"
        placeholder="compliance_assets"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      />
      <ComplianceTable
        tableHeaders={tableHeaders}
        defaultActiveColumn={ComplianceAssetOrderField.Name}
        paginationProps={{
          fetchMore,
          totalCount: assets?.totalCount || 0,
          pageInfo: assets?.pageInfo,
          setPageItems: setPageItems,
        }}
      >
        {assetsArray.slice(pageItems.from, pageItems.to).map((asset) => {
          const updatedAt = asset?.asset?.updatedAt;
          const formattedUpdatedAt = updatedAt
            ? `${FormatRelativeDate(updatedAt)} - ${FormatTime(updatedAt)}`
            : "";
          const isModified =
            asset?.exception &&
            asset?.exception?.action !== ExceptionMutationAction.Enable;
          const isDisabled =
            asset?.exception?.action === ExceptionMutationAction.Disable;
          const isSnoozed =
            asset?.exception?.action === ExceptionMutationAction.Snooze;
          const isOutOfScope =
            asset?.exception?.action === ExceptionMutationAction.OutOfScope;

          return (
            <TableRow
              onClick={() => handleAssetClick(asset?.asset?.id)}
              key={asset?.asset?.id}
              sx={{
                "& .MuiTableCell-root": { fontWeight: 400 },
              }}
            >
              {hasApplyExceptionPermission && (
                <TableCell sx={{ opacity: isModified ? 0.5 : 1 }}>
                  <Checkbox
                    onClick={(e) => handleNodeClick(e)}
                    onChange={(e, changed) =>
                      handleNodeChange(e, changed, {
                        groupId: asset?.exception?.id,
                        mrn: asset?.asset?.mrn as string,
                        exception: !asset?.exception
                          ? null
                          : {
                              justification: asset?.exception?.justification,
                              action: asset?.exception?.action,
                            },
                      })
                    }
                    checked={Boolean(
                      selectedAssets.find(
                        (selectedCheck) =>
                          asset?.asset?.mrn === selectedCheck.mrn,
                      ),
                    )}
                  />
                </TableCell>
              )}
              <TableCell sx={{ width: "30%", opacity: isModified ? 0.5 : 1 }}>
                <Box display="flex" alignItems="center" gap={1}>
                  {isSnoozed && (
                    <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
                  )}
                  {isDisabled && (
                    <Chip label="Disabled" icon={<BlockIcon />} size="small" />
                  )}
                  {isOutOfScope && (
                    <OutOfScopeChip
                      author={asset?.exception?.author.name}
                      date={asset?.exception?.createdAt}
                    />
                  )}
                  {asset?.asset?.name}
                </Box>
              </TableCell>
              <TableCell sx={{ width: "15%", opacity: isModified ? 0.5 : 1 }}>
                {asset?.asset?.platform?.name}
              </TableCell>
              <TableCell sx={{ width: "15%", opacity: isModified ? 0.5 : 1 }}>
                <CompletionBar
                  percent={asset?.compliancePercentage || 0}
                  height={theme.spacing(1)}
                  tooltip={`Completion: ${asset?.compliancePercentage}%`}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: "20%",
                  fontSize: (theme) => theme.spacing(1.25),
                  opacity: isModified ? 0.5 : 1,
                }}
              >
                {formattedUpdatedAt}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  width: "15%",
                  maxWidth: 150,
                  opacity: isModified ? 0.5 : 1,
                }}
              >
                {asset?.relatedAssets || 0}
              </TableCell>
            </TableRow>
          );
        })}
      </ComplianceTable>
      {selectedAssets.length > 0 && (
        <ExceptionsToolbar
          onCancel={handleCancelClick}
          selectedEntities={selectedAssets}
          totalCount={assets?.totalCount || 0}
          target="asset"
          onSetScopeClick={handleSetScopeForMultipleScopes}
        />
      )}
      <ExceptionsModals
        isSetExceptionModalOpen={isSettingException}
        isRemoveExceptionModalOpen={isRemovingException}
        onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
        onSetExceptionModalClose={handleSetExceptionModalClose}
        onSetExceptionModalSave={handleSetExceptionForScopes}
        onRemoveExceptionModalSave={handleRemoveExceptionForScopes}
        loading={exceptionsLoading}
        target="asset"
        role="compliance"
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedAssets}
      />
    </Box>
  );
}
