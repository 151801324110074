import { Box, Grid, Typography } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { HealingIcon } from "~/components/icons";
import { CveRiskFactors } from "~/components/vulnerabilities";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  CaseStatus,
  GetCasesCountDocument,
  Mquery,
  MqueryDocsFieldsFragment,
  MqueryVariant,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useGetCasesCountQuery,
  useGetTicketingIntegrationsQuery,
  useLoadMqueryQuery,
  useLoadPolicyQuery,
  Variant,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { Flex } from "~/components/Flex";
import { TabsVariant, Text, VariantIcon } from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { Code } from "~/components/code";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  Stats,
  StatsProps,
} from "~/components/DetailsPage/components/Stats/Stats";
import { BlockOutlined, FlagOutlined, Radar } from "@mui/icons-material";
import { PolicyIdToMrn } from "~/lib/mrn";
import { CheckPolicyInfoTags } from "~/pages/space/security/components/Check/CheckPolicyInfoTags/CheckPolicyInfoTags";
import { ScoreBlock } from "~/pages/space/security/components/Check/ScoreBlock";
import { Header } from "~/components/DetailsPage/components/Header";
import { CreateCaseMenuButton } from "~/components/cases/components/CreateCaseMenu";
import { CreateCaseButton } from "~/components/cases/components/CreateCaseButton";
import { useCases } from "~/components/cases/hooks";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { CheckAffectedAssetsAdapter } from "~/pages/space/vulnerabilities/components/AffectedAssets/CheckAffectedAssetsAdapter";
import { CheckBreadcrumbs } from "~/pages/space/security/components/Check/CheckBreadcrumbs";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";
import { PropertyItem } from "~/components/PropertyItem";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";

type CheckProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type State = {
  docs?: MqueryDocsFieldsFragment | null;
  impact?: Mquery["impact"] | MqueryVariant["impact"] | null;
  mql?: Mquery["mql"] | MqueryVariant["mql"] | null;
  properties?: Mquery["properties"] | MqueryVariant["properties"] | null;
  filterID:
    | NonNullable<NonNullable<Mquery["variantQueries"]>[0]>["filterID"]
    | null;
};

export function Check({ space, scope, availablePermissions }: CheckProps) {
  const [searchParams] = useSearchParams();
  const policyId = searchParams.get("policyId") || "";
  const findingMrn = searchParams.get("findingMrn") || "";
  const { controlId, frameworkId } = useParams();
  const { state: routerState } = useLocation();

  const [selectedVariant, setSelectedVariant] = useState<State | null>(null);

  // In case we navigate here from policy checks list -> policyId is there, otherwise we don't wanna make request
  const { data: policyData } = useLoadPolicyQuery({
    variables: { input: { mrn: PolicyIdToMrn(policyId), spaceMrn: space.mrn } },
    skip: !policyId,
  });

  const {
    data: checkData,
    loading: checkLoading,
    error: checkError,
  } = useLoadMqueryQuery({
    variables: {
      input: {
        mrn: findingMrn,
      },
    },
  });

  const {
    data: aggData,
    loading: aggLoading,
    error: aggError,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope.mrn || "",
      filter: {
        findingMrn: findingMrn,
        scoreType: AggregateScoreType.Check,
      },
    },
    skip: !scope.mrn,
  });

  const aggScores = aggData?.aggregateScores;
  const checkScore = (
    aggScores?.__typename === "AggregateScoresConnection"
      ? aggScores?.edges?.flatMap((edge) => edge?.node ?? [])
      : []
  )?.pop();

  const blastRadius = checkScore?.blastRadius;

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: blastRadius?.assets?.toString() || "---",
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: blastRadius?.affected?.toString() || "---",
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      label: "Remediated",
      value:
        blastRadius?.assets && blastRadius?.affected
          ? (blastRadius?.assets - blastRadius?.affected).toString()
          : "---",
      icon: <HealingIcon fontSize="inherit" />,
    },
    {
      label: "Exceptions",
      value: (
        (blastRadius?.snoozed || 0) + (blastRadius?.disabled || 0)
      ).toString(),
      icon: <BlockOutlined fontSize="inherit" />,
    },
  ];

  const [state, setState] = useState<State>({
    docs: null,
    impact: null,
    mql: null,
    properties: null,
    filterID: null,
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: findingMrn || "",
    scoreType: AggregateScoreType.Check,
  });

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasListCasePermissions,
    hasIntegrationCreatePermission,
    hasCreateCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions,
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        findingMrns: [findingMrn],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip:
      !findingMrn || !hasListCasePermissions || scope.type !== ScopeType.Space,
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions || scope.type !== ScopeType.Space,
  });

  const {
    handleNodeClick,
    handleCancelClick,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
  } = useExceptionsSelection();

  useEffect(() => {
    setState({
      docs: (selectedVariant || checkData?.mquery)?.docs,
      impact: (selectedVariant || checkData?.mquery)?.impact,
      mql: (selectedVariant || checkData?.mquery)?.mql,
      properties: (selectedVariant || checkData?.mquery)?.properties,
      filterID: selectedVariant?.filterID || null,
    });
  }, [selectedVariant, checkData]);

  useLayoutEffect(() => {
    if (checkData?.mquery?.variantQueries) {
      setSelectedVariant(checkData.mquery.variantQueries[0]);
    }
  }, [checkData]);

  const onVariantsChange = (variantFilterId: string) => {
    const selected =
      check?.variantQueries?.find(
        (variantQuery) => variantFilterId === variantQuery?.filterID,
      ) || null;
    setSelectedVariant(selected);
  };

  const createVariantButtons = (variants: Variant[]) => {
    return variants
      .map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      }))
      .sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
  };

  if (checkLoading || aggLoading || riskFactorsLoading)
    return <LoadingPage what="check details" />;

  if (checkError || aggError) return <LoadingFailedPage what="check details" />;

  const check = checkData?.mquery;

  const remediations = state.docs?.remediations;
  const hasRemediations =
    remediations?.__typename === "Remediations" &&
    remediations.entries.length > 0;
  const hasProperties = state.properties && state.properties.length > 0;
  const hasMql = Boolean(state.mql);
  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <CheckBreadcrumbs
          policyId={policyId}
          controlId={controlId}
          frameworkId={frameworkId}
          frameworkMrn={routerState?.frameworkMrn}
          policyData={policyData}
          control={routerState?.control}
          check={checkData}
          scopeParams={scope.params}
        />
      </Grid>

      <Grid item xs={12}>
        <Header
          id="check-title"
          title={check?.title || ""}
          riskFactors={riskFactors}
          rightSection={
            !routerState?.control &&
            scope.type === ScopeType.Space &&
            (hasTicketingIntegration ? (
              hasCreateCasePermissions && (
                <CreateCaseMenuButton
                  onAllAssetsItemClick={() => {
                    setSelectedAssets([
                      {
                        mrn: findingMrn,
                        scopeMrn: space.mrn,
                        groupId: "ALL_ASSETS",
                      },
                    ]);
                    handleCreateCaseModalOpen();
                  }}
                />
              )
            ) : (
              <CreateCaseButton
                onClick={handleCreateCaseIntegrationModalOpen}
              />
            ))
          }
        />
      </Grid>

      <Flex flexDirection="column">
        {policyId && (
          <CheckPolicyInfoTags
            version={policyData?.policy?.version}
            groups={policyData?.policy?.groups
              ?.map((group) => group.title)
              ?.join(",")}
          />
        )}

        <Box mt={4} data-testid="check-variants">
          {check?.variants && check.variants.length > 0 && (
            <TabsVariant
              options={createVariantButtons(check?.variants)}
              selectedTab={selectedVariant?.filterID || ""}
              onTabChange={onVariantsChange}
              mb={3}
            />
          )}
        </Box>
      </Flex>

      <Grid id="description" item xs={12}>
        <SectionHeading heading="Description" />
        {hasListCasePermissions &&
          scope.type === ScopeType.Space &&
          (casesData?.cases.totalCount || 0 > 0) && (
            <CasesSummary
              count={casesData?.cases.totalCount || 0}
              space={space}
            />
          )}
        <Typography textTransform="uppercase" fontWeight={700} mb={1}>
          Summary
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs>
            <Stats id="check-asset-stats" stats={stats} />
            {check?.docs && (
              <ShowMoreSummary
                id="advisory-summary"
                text={check.docs.desc}
                maxHeight={250}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm="auto"
            sx={{ display: "inline-block" }}
          >
            <ScoreBlock
              hasScore={Boolean(checkScore)}
              mainScore={checkScore?.riskScore}
              blastRadius={checkScore?.blastRadius}
              riskFactors={checkScore?.riskFactors}
              hasError={aggScores?.__typename !== "AggregateScoresConnection"}
            />
          </Grid>
        </Grid>
      </Grid>

      {riskFactorsWithDocs.length > 0 && (
        <Grid id="risk-factors" item xs={12}>
          <SectionHeading heading="Risk Assessment" />
          <Box className="section-content">
            <CveRiskFactors
              id="cve-risk-factors"
              riskFactors={riskFactorsWithDocs}
            />
          </Box>
        </Grid>
      )}

      {(hasProperties || hasMql) && (
        <Grid container>
          <Grid item xs={12}>
            <SectionHeading heading="Queries" />
          </Grid>
          {hasProperties && (
            <Grid item xs={12}>
              <Flex alignItems="center" justifyContent="space-between" my={4}>
                <Text bold>PROPERTIES</Text>
              </Flex>
              {state.properties?.map((property, key) => {
                if (!property) return;

                const { uid, mql } = property;

                return (
                  <PropertyItem key={key} index={key} uid={uid} mql={mql} />
                );
              })}
            </Grid>
          )}
          {state.mql && (
            <Grid item xs={12}>
              <Flex alignItems="center" justifyContent="space-between" my={3}>
                <Text bold>MQL</Text>
              </Flex>
              <Box>
                <Code copyButton className="coffeescript">
                  {state.mql}
                </Code>
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      {hasRemediations && (
        <Grid item xs={12}>
          <SectionHeading heading="Remediation" />
          <Box>
            {remediations.entries.map((remediation) => (
              <Markdown key={remediation.id} source={remediation.desc} />
            ))}
          </Box>
        </Grid>
      )}

      {/* <AdvisoryContentDetails advisoryId={advisory.id} /> */}

      {/* <AdvisoryRemediation advisoryId={advisory.id} spaceMrn={space.mrn} /> */}

      {check?.mrn && (
        <CheckAffectedAssetsAdapter
          checkMrn={check.mrn}
          scope={scope}
          selectionProps={
            scope.type === ScopeType.Space
              ? {
                  selectedEntities: selectedAssets,
                  hasSelectPermissions: hasCreateCasePermissions,
                  onCheckboxClick: handleNodeClick,
                  getIsRowSelected: (rowAssetMrn) => {
                    return Boolean(
                      selectedAssets.find(
                        (asset) => asset.scopeMrn === rowAssetMrn,
                      ),
                    );
                  },
                  onCheckAll: (checked, edges) => {
                    if (checked) {
                      setSelectedAssets(
                        (edges || []).map((edge) => ({
                          groupId: "",
                          scopeMrn: edge?.mrn || "",
                          mrn: findingMrn,
                          exception: null,
                        })),
                      );
                    } else {
                      setSelectedAssets([]);
                    }
                  },
                  onCheckboxChange: async (
                    _: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                    asset,
                  ) => {
                    const nextSelected = selectedAssets.filter(
                      (s) => s.scopeMrn !== asset?.mrn,
                    );
                    if (checked) {
                      nextSelected.push({
                        groupId: "",
                        mrn: findingMrn,
                        scopeMrn: asset?.mrn || "",
                        exception: null,
                      });
                    }

                    setSelectedAssets(nextSelected);
                  },
                  renderToolbar: (totalCount) => {
                    return (
                      selectedAssets.filter((a) => a.groupId !== "ALL_ASSETS")
                        .length > 0 && (
                        <ExceptionsToolbar
                          target="asset"
                          onCreateCaseClick={
                            hasTicketingIntegration
                              ? hasCreateCasePermissions
                                ? handleCreateCaseModalOpen
                                : undefined
                              : handleCreateCaseIntegrationModalOpen
                          }
                          onCancel={handleCancelClick}
                          selectedEntities={selectedAssets}
                          totalCount={totalCount}
                        />
                      )
                    );
                  },
                }
              : undefined
          }
        />
      )}
      {scope.type === ScopeType.Space && (
        <>
          <CreateCasesIntegrationModal
            open={isCreateCaseIntegrationModalOpen}
            onClose={handleCreateCaseIntegrationModalClose}
            space={space}
            redirectTo={`${location.pathname}?${searchParams}`}
            hasIntegrationCreatePermission={hasIntegrationCreatePermission}
          />
          <CreateCaseModal
            open={isCreateCaseModalOpen}
            target="asset"
            loading={checkLoading || createCaseLoading}
            onClose={() => {
              handleCreateCaseModalClose();
              setSelectedAssets([]);
            }}
            onSave={handleCreateCase}
            selectedAssets={selectedAssets}
            space={space}
            hasCreateCasePermissions={hasCreateCasePermissions}
          />
        </>
      )}
    </Grid>
  );
}
