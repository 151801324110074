import {
  alpha,
  Box,
  Button,
  Paper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { groupBy, partition } from "lodash";
import { Clamp } from "~/components/clamp/clamp";
import { KubernetesIcon } from "~/components/icons/mondoo/kubernetes";
import { OperatingSystemsIcon } from "~/components/icons/mondoo/operating-systems";
import { DomainsHostsIcon } from "~/components/icons/mondoo/domains-hosts";
import { NetworkDevicesIcon } from "~/components/icons/mondoo/network-devices";
import { SaasIcon } from "~/components/icons/mondoo/saas";
import { ContainersIcon } from "~/components/icons/mondoo/containers";
import { AzureColorIcon } from "~/components/icons/mondoo/azure-color";
import { OktaIcon } from "~/components/icons/mondoo/okta";
import { SlackColorIcon } from "~/components/icons/mondoo/slack-color";
import { MS365Icon } from "~/components/icons/mondoo/ms365-color";
import { GoogleWSColorIcon } from "~/components/icons/mondoo/google-workspace-color";
import { OCIColorIcon } from "~/components/icons/mondoo/oci-color";
import { GCPColorIcon } from "~/components/icons/mondoo/gcp-color";
import { AwsColorIcon } from "~/components/icons/mondoo/aws-color";
import { FolderOpenFilled } from "~/components/icons/mondoo/folder-open-filled";
import {
  GitHubIcon,
  InfrastructureAsCodeIcon,
  CloudflareIcon,
  VMWareIcon,
} from "~/components/icons";
import { GitLabColorIcon } from "../icons/mondoo/gitlab-color";
import { TerraformColorIcon } from "../icons/mondoo/terraform-color";
import { LoadSpaceStatsQuery } from "~/operations";
import { useGroupingButton } from "./useGroupingButton";
import { AtlassianIcon } from "../icons/mondoo/atlassian";
import { formatGroupNames } from "~/lib/format-asset-list-names";

type AssetGroupStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>["assetGroupStatistics"][0];

export type GroupingButtonProps = {
  group: AssetGroupStats;
  onClick?: () => void;
};

export function GroupingButton({ group, onClick }: GroupingButtonProps) {
  const { selectedGroupingButton } = useGroupingButton();

  return (
    <Paper
      onClick={onClick}
      component={Button}
      disableRipple
      fullWidth
      sx={{
        p: 2,
        height: "100%",
        cursor: "pointer",
        justifyContent: "flex-start",
        background: (theme) =>
          Boolean(selectedGroupingButton === group.groupType)
            ? alpha(theme.palette.primary.main, 0.2)
            : "background.paper",
        opacity: Boolean(
          selectedGroupingButton && group.groupType !== selectedGroupingButton,
        )
          ? 0.4
          : 1,
        boxShadow: (theme) =>
          Boolean(selectedGroupingButton === group.groupType)
            ? `0px 0px 0px 1px ${theme.palette.primary.main}`
            : theme.shadows[1],
        transition: "all .35s ease-in-out",
        "&:hover, &:focus-visible": {
          background: (theme) => alpha(theme.palette.primary.main, 0.2),
          opacity: 1,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 2,
          }}
        >
          {getGroupIcon(group.groupType)}
        </Box>
        <Box sx={{ textAlign: "left" }}>
          <Typography fontWeight={700}>
            {formatType(group.groupType)}
          </Typography>
          <Clamp lines={2}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ textTransform: "uppercase" }}
            >
              {formatGroupNames(group)}
            </Typography>
          </Clamp>
        </Box>
      </Box>
    </Paper>
  );
}

export const getGroupIcon = (
  type: string | null,
  sx: SxProps<Theme> = { fontSize: 40 },
) => {
  if (!type) return <EmptyIcon />;

  switch (type.toLowerCase()) {
    case "k8s":
      return <KubernetesIcon sx={sx} />;
    case "atlassian":
      return <AtlassianIcon sx={sx} />;
    case "aws":
      return <AwsColorIcon sx={sx} />;
    case "gcp":
      return <GCPColorIcon sx={sx} />;
    case "github":
      return <GitHubIcon sx={sx} />;
    case "gitlab":
      return <GitLabColorIcon sx={sx} />;
    case "azure":
      return <AzureColorIcon sx={sx} />;
    case "vmware":
      return <VMWareIcon sx={sx} />;
    case "os":
      return <OperatingSystemsIcon sx={sx} />;
    case "network_devices":
      return <NetworkDevicesIcon sx={sx} />;
    case "domains_and_hosts":
      return <DomainsHostsIcon sx={sx} />;
    case "saas_services":
      return <SaasIcon sx={sx} />;
    case "terraform":
      return <TerraformColorIcon sx={sx} />;
    case "google-workspace":
      return <GoogleWSColorIcon sx={sx} />;
    case "okta":
      return <OktaIcon sx={sx} />;
    case "ms365":
      return <MS365Icon sx={sx} />;
    case "slack":
      return <SlackColorIcon sx={sx} />;
    case "oci":
      return <OCIColorIcon sx={sx} />;
    case "container":
      return <ContainersIcon sx={sx} />;
    case "iac":
      return <InfrastructureAsCodeIcon sx={sx} />;
    case "cloudflare":
      return <CloudflareIcon sx={sx} />;
    default:
      return <FolderOpenFilled sx={sx} />;
  }
};

// accept the type of grouping and format it to match the mocks
// by using title casing
export const formatType = (type: AssetGroupStats["groupType"]) => {
  switch (type.toLowerCase()) {
    case "k8s":
      return "Kubernetes";
    case "atlassian":
      return "Atlassian";
    case "aws":
      return "AWS";
    case "gcp":
      return "GCP";
    case "azure":
      return "Azure";
    case "vmware":
      return "VMware";
    case "os":
      return "Operating Systems";
    case "github":
      return "GitHub";
    case "gitlab":
      return "GitLab";
    case "network_devices":
      return "Network Devices";
    case "domains_and_hosts":
      return "Domains and Hosts";
    case "saas_services":
      return "SaaS Services";
    case "terraform":
      return "Terraform";
    case "google-workspace":
      return "Google Workspace";
    case "slack":
      return "Slack";
    case "oci":
      return "OCI";
    case "okta":
      return "Okta";
    case "container":
      return "Containers";
    case "ms365":
      return "Microsoft 365";
    case "iac":
      return "Infrastructure as Code";
    case "cloudflare":
      return "Cloudflare";
    default:
      // "other"
      return "Uncategorized Assets";
  }
};

// fallback incase we ever somehow send an unknown
// grouping type
const EmptyIcon = () => {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        backgroundColor: "background.default",
        boxShadow: 2,
      }}
    />
  );
};
