import { Flex } from "~/components/Flex";
import { ButtonProps, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { KeysDropdownAutocomplete } from "../AutocompleteDropdown/KeysDropdownAutocomplete";
import { IndentIcon } from "~/components/icons";
import { ConditionValue, ConditionValues } from ".";
import { useConditionRow } from "~/pages/space/Workspaces/components/QueryBuilder/Condition/hooks";
import { OperatorDropdown } from "~/pages/space/Workspaces/components/QueryBuilder/OperatorDropdown/OperatorDropdown";
import {
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingOperator,
  WorkspaceSelectionConditionStringOperator,
} from "~/operations";

export type ConditionRowProps = {
  selectionIndex: number;
  conditionIndex: number;
  onRemove: ButtonProps["onClick"];
  isReadonly: boolean;
};

export const ConditionRow = ({
  conditionIndex,
  selectionIndex,
  onRemove,
  isReadonly,
}: ConditionRowProps) => {
  const { control } = useFormContext<CreateWorkspaceFormInput>();

  const {
    showIncludeLabel,
    showConditionOperator,
    showConditionKeyLabel,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions,
    showStringOperatorDropdown,
    showIntOperatorDropdown,
    showRatingOperatorDropdown,
    selectedConditionKeyLabel,
    conditionValuesFieldName,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isConditionValuesLoading,
    isValuesDropdownOpen,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
  } = useConditionRow({
    selectionIndex,
    conditionIndex,
    isReadonly,
  });

  return (
    <Flex
      gap={2}
      alignItems="flex-start"
      sx={{
        "&:hover .delete-condition-button": {
          visibility: "visible",
        },
      }}
    >
      {showIncludeLabel && (
        <Typography sx={{ fontWeight: 700, p: 1 }}>INCLUDE:</Typography>
      )}
      {showConditionOperator && (
        <Flex alignItems="center" gap={2} ml={8}>
          <IndentIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          <Controller
            control={control}
            name={`selections.${selectionIndex}.conditions.${conditionIndex}.operator`}
            render={({ field }) => (
              <OperatorDropdown
                type="condition"
                defaultValue={WorkspaceSelectionConditionOperator.And}
                isReadonly={isReadonly}
                {...field}
              />
            )}
          />
        </Flex>
      )}
      {showConditionKeyLabel ? (
        <ConditionValue
          value={selectedConditionKeyLabel || ""}
          onClick={handleConditionKeyRemove}
          isReadonly={isReadonly}
        />
      ) : (
        conditionValues.length === 0 &&
        !isReadonly && (
          <KeysDropdownAutocomplete
            name={`selections.${selectionIndex}.conditions.${conditionIndex}.formKey`}
            groups={conditionKeys}
            onChange={handleKeyChange}
          />
        )
      )}
      {showStringOperatorDropdown && (
        <>
          <Controller
            control={control}
            name={`selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.operator`}
            render={({ field }) => (
              <OperatorDropdown
                type="string"
                isReadonly={isReadonly}
                defaultValue={WorkspaceSelectionConditionStringOperator.Equal}
                {...field}
              />
            )}
          />
        </>
      )}
      {showIntOperatorDropdown && (
        <Controller
          control={control}
          name={`selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.operator`}
          render={({ field }) => (
            <OperatorDropdown
              type="numeric"
              isReadonly={isReadonly}
              defaultValue={WorkspaceSelectionConditionNumericOperator.Equal}
              {...field}
            />
          )}
        />
      )}
      {showRatingOperatorDropdown && (
        <Controller
          control={control}
          name={`selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.operator`}
          render={({ field }) => (
            <OperatorDropdown
              type="rating"
              isReadonly={isReadonly}
              defaultValue={WorkspaceSelectionConditionRatingOperator.Equal}
              {...field}
            />
          )}
        />
      )}
      <ConditionValues
        values={conditionValues}
        options={conditionValuesOptions}
        showRemoveConditionButton={conditionIndex !== 0 && !isReadonly}
        onRemove={onRemove}
        isKeySelected={isKeySelected}
        onValueRemove={handleConditionValueRemove}
        name={conditionValuesFieldName}
        isReadonly={isReadonly}
        isLoading={isConditionValuesLoading}
        onDropdownSearchFieldKeyUp={handleDropdownSearchFieldKeyUp}
        onDropdownSearchFieldChange={handleDropdownSearchFieldChange}
        onItemClick={handleValuesItemClick}
        isValuesDropdownOpen={isValuesDropdownOpen}
        onValuesDropdownOpen={handleValuesDropdownOpen}
        onValuesDropdownClose={handleValuesDropdownClose}
      />
    </Flex>
  );
};
