import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MondooDatabaseFilterComponent } from "./MondooDatabaseFilterComponent";
import { DatasetSearchBar } from "../../search";
import {
  Box,
  Breadcrumbs,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  OrderDirection,
  useGetMondooVulnerabilityDatabaseQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import { Clamp } from "~/components/clamp/clamp";
import { DataTable } from "~/components/data-table";
import { AdvisoryVendorIcon } from "~/components/icons/mondoo/utils/advisoryVendorIcon";
import { LoadMore } from "~/components/load-more";
import { ClickableTableRow } from "~/components/report";
import { ScoreHorizontalBar, ScoreTag } from "~/components/score-card";
import { FormatDay } from "~/lib/date";
import { Header } from "~/components/FirewatchPage/FirewatchPageColumns";
import { Loading, LoadingFailed } from "~/components/loading";
import { NoEntries } from "~/components/no-entries";
import { useMondooVulnerabilityDatabase } from "./useMondooVulnerabilityDatabase";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type AdvisoryVulnExploitProps = {
  scope: SpaceOrWorkspaceScope;
};

export function MondooVulnerabilityDatabase({
  scope,
}: AdvisoryVulnExploitProps) {
  const { orderBy, query, severity, entryType, handle, databaseUrl } =
    useMondooVulnerabilityDatabase({});
  const { data, error, loading, fetchMore } =
    useGetMondooVulnerabilityDatabaseQuery({
      variables: {
        first: 10,
        orderBy,
        queryTerms: query,
        type: entryType,
        severity,
      },
    });

  if (error) {
    return <LoadingFailed what="Mondoo Vulnerability Database" />;
  }

  if (loading || !data) {
    return <Loading what="Mondoo Vulnerability Database" />;
  }

  const { edges, totalCount, pageInfo } = data.mvd || {};

  const loadMore = () => {
    fetchMore({ variables: { after: pageInfo?.endCursor } });
  };

  const title = (
    <Typography variant="body1" sx={{ mb: 2 }}>
      Entries
      <Typography
        component="span"
        sx={{ fontSize: 11, color: "text.secondary", ml: 2 }}
      >
        {loading ? "Loading..." : `${totalCount} total`}
      </Typography>
    </Typography>
  );

  const loadingView = (
    <Box textAlign="center" py={10}>
      <Loading what="Database Data" />
    </Box>
  );

  const errorView = (
    <Box textAlign="center" py={10}>
      <LoadingFailed what="Database Data" />
    </Box>
  );

  const emptyView = <NoEntries />;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={1}>Mondoo Vulnerability Database</Typography>,
  ];

  return (
    <Fragment>
      {breadcrumbs && (
        <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
      )}
      <Box pb={4}>
        <Typography component="h1" variant="h4">
          Mondoo Vulnerability Database
        </Typography>
        <Typography variant="body1">
          The latest security advisories and vulnerabilities.
        </Typography>
      </Box>
      <Box mb={2}>
        <DatasetSearchBar
          onQuery={handle.query}
          onChange={() => {}}
          placeholder="Search database..."
          value={query}
        />
        <MondooDatabaseFilterComponent
          filters={{
            entryType,
            severity,
          }}
          onChange={handle.filterChange}
        />
      </Box>
      <Box>
        <Fragment>
          {title}
          {loading ? (
            loadingView
          ) : error ? (
            errorView
          ) : edges?.length === 0 ? (
            emptyView
          ) : (
            <Fragment>
              <DataTable>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => {
                      const { sortable } = header;

                      return (
                        <TableCell
                          key={header.field}
                          align={header.options?.textAlign}
                          width={header.options?.width}
                          colSpan={header.options?.colSpan}
                          sx={header.options?.sx}
                        >
                          {sortable ? (
                            <TableSortLabel
                              onClick={() => handle.sortClick(header.field)}
                              direction={
                                orderBy.direction === OrderDirection.Asc
                                  ? "asc"
                                  : "desc"
                              }
                              active={header.field === orderBy.field}
                            >
                              {header.label}
                            </TableSortLabel>
                          ) : (
                            header.label
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {edges?.map((item) => {
                    const { node } = item;
                    if (!node) return null;
                    const { id, cvssScore, publishedAt, title } = node;
                    const unscored = cvssScore.type === 16;
                    const href = databaseUrl(
                      scope.params,
                      `${entryType.toLowerCase()}/${id}`,
                    );
                    return (
                      <ClickableTableRow
                        key={id}
                        component={RouterLink}
                        to={href}
                      >
                        <TableCell>
                          <AdvisoryVendorIcon id={id} />
                        </TableCell>
                        <TableCell className="list-view-id" sx={{ width: 150 }}>
                          {id}
                        </TableCell>
                        <TableCell>
                          <Clamp>{title}</Clamp>
                        </TableCell>
                        <TableCell>{FormatDay(publishedAt)}</TableCell>
                        <TableCell align="center">
                          <ScoreTag
                            score={cvssScore}
                            status={true}
                            unscored={unscored}
                            eol={false}
                            displaydot={false}
                          />
                          <ScoreHorizontalBar
                            score={cvssScore.value}
                            unscored={unscored}
                          />
                        </TableCell>
                      </ClickableTableRow>
                    );
                  })}
                </TableBody>
              </DataTable>
              <LoadMore
                handleLoadMore={loadMore}
                hasNextPage={pageInfo?.hasNextPage}
                loading={loading}
                sx={{ mt: 3 }}
              />
            </Fragment>
          )}
        </Fragment>
      </Box>
    </Fragment>
  );
}

const tableHeaders: Header[] = [
  {
    id: "ID",
    field: "ID",
    label: "ID",
    sortable: true,
    options: { colSpan: 2, width: 200 },
  },
  {
    id: "TITLE",
    field: "TITLE",
    label: "Title",
    sortable: true,
    options: {},
  },
  {
    id: "PUBLISHED",
    field: "PUBLISHED",
    label: "Published",
    sortable: true,
    options: {},
  },
  {
    id: "SCORE",
    field: "SCORE",
    label: "Score",
    sortable: true,
    options: {},
  },
];
