import { Flex } from "~/components/Flex";
import { ExceptionCountIndicator } from "~/pages/compliance/components/ExceptionCountIndicator";
import { DistributionState } from "~/pages/compliance/components/ExceptionCountIndicator/ExceptionCountIndicator";

type DistributionProps = {
  outOfScopeCount?: number;
  snoozedCount: number;
  disabledCount: number;
};

export function Distribution({
  outOfScopeCount = 0,
  snoozedCount,
  disabledCount,
}: DistributionProps) {
  const distributionData: Record<DistributionState, number> = {
    [DistributionState.OutOfScope]: outOfScopeCount || 0,
    [DistributionState.Snoozed]: snoozedCount || 0,
    [DistributionState.Disabled]: disabledCount || 0,
  };

  return (
    <Flex gap={2}>
      {Object.entries(distributionData).map(([state, distribution]) => (
        <ExceptionCountIndicator
          state={state as DistributionState}
          count={distribution}
        />
      ))}
    </Flex>
  );
}
