import { MenuList, SxProps } from "@mui/material";
import { ReactNode } from "react";

type BaseMenuListProps = { children: ReactNode; sx?: SxProps };

export const BaseMenuList = ({ children, sx = [] }: BaseMenuListProps) => {
  return (
    <MenuList
      disablePadding
      sx={[
        {
          maxHeight: "calc(100vh - 152px)",
          overflow: "auto",
          pt: 0,
          mb: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </MenuList>
  );
};
