// accept the content object and convert it into a single

import { pluralize } from "./pluralize";
import { groupBy, partition } from "lodash";
import { LoadSpaceStatsQuery } from "~/operations";

type AssetGroupStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>["assetGroupStatistics"][0];

// accept the content object and convert it into a single
// comma separated string
export const formatGroupNames = (group: AssetGroupStats) => {
  if (!group.statistics) return;

  const { statistics } = group;

  if (group.groupType === "k8s") {
    const arr = Object.values(statistics)
      .filter((data) => data.count > 0)
      .filter(
        (data) =>
          data.type.assetType === "k8s.cluster" ||
          data.type.assetType === "_alias.k8s_object_",
      )
      .map((data) => {
        return `${data.count} ${pluralize(data.type.shortName, data.count)}`;
      });

    return arr.join(", ");
  }
  if (group.groupType === "aws") {
    // Split accounts and resources and get their total counts
    const [accounts, resources] = partition(
      Object.values(statistics)
        .filter((data) => data.count > 0)
        .filter((data) => data.type.assetType.startsWith("aws")),
      (data) => data.type.assetType === "aws.account",
    ).map((data) => data.reduce((t, c) => t + c.count, 0));

    // Format the string i.e. "1 account, 2 resources"
    const arr = [
      { count: accounts, title: "account" },
      { count: resources, title: "resource" },
    ]
      .flatMap((object) =>
        object.count > 0
          ? [`${object.count} ${pluralize(object.title, object.count)}`]
          : [],
      )
      .join(", ");

    return arr;
  }
  if (group.groupType === "iac") {
    const filteredStats = statistics.filter((data) => data.count > 0);
    return filteredStats.length + " assets";
  }
  if (group.groupType === "gcp") {
    // Filter out empty counts and non-gcp assets
    const filteredStats = statistics
      .filter((data) => data.count > 0)
      .filter((data) => data.type.assetType.startsWith("gcp"));

    // Split accounts, organizations, and resources and get their total counts
    const groups = groupBy(filteredStats, (data) => {
      switch (data.type.assetType) {
        case "gcp.organization":
          return "org";
        case "gcp.project":
          return "project";
        default:
          return "resource";
      }
    });

    // Format the string i.e. "1 organization, 2 projects, 3 resources"
    return Object.entries(groups)
      .map(([key, value]) => ({
        count: value.reduce((t, c) => t + c.count, 0),
        title: key,
      }))
      .flatMap((object) =>
        object.count > 0
          ? [`${object.count} ${pluralize(object.title, object.count)}`]
          : [],
      )
      .join(", ");
  }

  if (group.groupType === "azure") {
    const includedAssetTypes = ["azure.subscription", "azure.compute_vm"];
    const objs = Object.values(statistics).filter((data) => data.count > 0);
    const arr = objs
      .filter((data) => includedAssetTypes.includes(data.type.assetType))
      .map(
        (data) => `${data.count} ${pluralize(data.type.shortName, data.count)}`,
      );
    const othersCount = objs
      .filter((data) => !includedAssetTypes.includes(data.type.assetType))
      .reduce((cur, data) => cur + data.count, 0);
    if (othersCount > 0) {
      arr.push(`${othersCount} ${pluralize("resource", othersCount)}`);
    }
    return arr.join(", ");
  }

  // collect all of the asset types in listAssetTypes so that we can filter
  // out what should not be shown in 'statistics'
  const valuesToDisplay = group.listsAssetTypes.map((x) => x.assetType);

  const arr = Object.values(statistics)
    .filter(
      (data) => data.count > 0 && valuesToDisplay.includes(data.type.assetType),
    )
    .map((data) => {
      return `${data.count} ${pluralize(data.type.shortName, data.count)}`;
    });

  return arr.join(", ");
};
