import { FormatDateAndTime } from "~/lib/date";
import { mapDetectedByVendor } from "./mapDetectedByVendor";

export const mapDetectedByValues = (
  key: string,
  value: string | number | null | undefined,
) => {
  if (value === null || !value) return "";
  switch (key.toLowerCase()) {
    case "firstdetectedat":
    case "lastupdatedat":
      return FormatDateAndTime(String(value));
    case "vendor":
      return mapDetectedByVendor(String(value));
    default:
      return value;
  }
};
