import { BaseOperatorDropdown } from "~/pages/space/Workspaces/components/QueryBuilder/OperatorDropdown/BaseOperatorDropdown";
import { MenuItem } from "@mui/material";
import { useOperatorDropdown } from "./hooks";
import { forwardRef } from "react";

type OperatorDropdownProps = {
  type: "condition" | "numeric" | "string" | "rating";
  defaultValue?: string | undefined;
  isReadonly: boolean;
};

export const OperatorDropdown = forwardRef(
  (
    { type, defaultValue, isReadonly, ...rest }: OperatorDropdownProps,
    _ref,
  ) => {
    const { operators, operatorsLabelMap } = useOperatorDropdown({ type });

    return (
      <BaseOperatorDropdown
        defaultValue={defaultValue}
        isReadonly={isReadonly}
        renderContent={() =>
          operators.map((operator) => (
            <MenuItem key={operator} value={operator}>
              {operatorsLabelMap[operator]}
            </MenuItem>
          ))
        }
        {...rest}
      />
    );
  },
);
