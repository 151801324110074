import { useSnackbar } from "notistack";
import { useState, SyntheticEvent } from "react";
import {
  ComplianceFrameworkMutationAction,
  GetListFrameworksDocument,
  useApplyFrameworkMutation,
  useDownloadFrameworkLazyQuery,
} from "~/operations";
import { CircularIconButton } from "../components/CircularIconButton";
import { IconButtonType } from "../components/DynamicButtonIcon";
import { Space } from "~/lib/types";
import { ComplianceFramework } from "../types";

export function useAvailableFramework({
  framework,
  space,
}: {
  framework: ComplianceFramework;
  space: Space;
}) {
  const [canExport, setCanExport] = useState<boolean>(true);
  const [activateFrameworkDialogOpen, setActivateFrameworkDialogOpen] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [applyFramework] = useApplyFrameworkMutation({
    refetchQueries: [GetListFrameworksDocument],
  });

  const [
    downloadFramework,
    { loading: downloadFrameworkLoading, error: downloadFrameworkError },
  ] = useDownloadFrameworkLazyQuery({
    variables: {
      input: { mrn: framework.mrn, scopeMrn: space.mrn },
    },
  });

  if (downloadFrameworkError && canExport) {
    enqueueSnackbar("Framework download failed", { variant: "error" });
    setCanExport(false);
  }

  const handleDownloadFramework = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setCanExport(true);

    downloadFramework().then(({ data }) => {
      if (
        canExport &&
        data != undefined &&
        data.downloadFramework != undefined
      ) {
        const yamlData = data.downloadFramework;
        // only allow one download per click
        setCanExport(false);
        // a bunch of weird magic to make the download work
        // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
        const file = new Blob([yamlData.yaml], {
          type: "text/yaml",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);

        // generates a filename that should sort properly on the file system
        function makeFileName(): string {
          const now = new Date();
          const fileDate = parseInt((now.getTime() / 1000).toString());
          const fileName = `${framework.title}_${fileDate}.mql.yaml`;

          return fileName;
        }

        element.download = makeFileName();
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // this timeout is here to avoid a render warning, so this library does not render during render
        window.setTimeout(function () {
          enqueueSnackbar(
            "Success! If you do not see your framework download, make sure your browser allows downloads from this site.",
            { variant: "success" },
          );
        }, 0);
      }
    });
  };

  const handleActivateFramework = () => {
    setActivateFrameworkDialogOpen(true);
  };

  const activateFramework = async (
    state: ComplianceFrameworkMutationAction,
  ) => {
    if (!framework) return;

    try {
      await applyFramework({
        variables: {
          input: {
            action: state,
            frameworkMrn: framework.mrn,
            scopeMrn: space.mrn,
          },
        },
      });
      enqueueSnackbar("Framework added", { variant: "success" });
      setActivateFrameworkDialogOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to add framework", { variant: "error" });
    }
  };

  const handleCancelActivateFramework = () => {
    setActivateFrameworkDialogOpen(false);
  };

  const downloadFrameworkButton = () => {
    return (
      <CircularIconButton
        title="Download Framework"
        iconType={IconButtonType.Download}
        onClick={handleDownloadFramework}
        IconStyles={{ fontSize: 16 }}
      />
    );
  };

  return {
    activateFrameworkDialogOpen,
    activateFramework,
    downloadFrameworkButton,
    handleActivateFramework,
    handleCancelActivateFramework,
  };
}
