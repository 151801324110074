import { Flex } from "~/components/Flex";
import { BarChartIcon } from "~/components/ui-library";
import { Typography } from "@mui/material";

type CheckPolicyInfoTagsProps = {
  version: string | undefined;
  groups: string | undefined;
};

export const CheckPolicyInfoTags = ({
  version,
  groups,
}: CheckPolicyInfoTagsProps) => {
  return (
    <Flex gap={3} mt={1} data-testid="policy-info-tags">
      {/*{groups && (
        <Flex alignItems="center">
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            {groups}
          </Typography>
          <BarChartIcon fontSize="inherit" />
        </Flex>
      )}*/}
      {version && (
        <Flex alignItems="center" data-testid="version-tag">
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            Version {version}
          </Typography>
        </Flex>
      )}
      <Flex gap={1} alignItems="center" data-testid="active-tag">
        <BarChartIcon fontSize="inherit" />
        <Typography variant="caption" color="text.secondary" fontWeight={700}>
          Active
        </Typography>
      </Flex>
    </Flex>
  );
};
