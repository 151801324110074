import { SmallDonutCard, SmallDonutData } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { AdvisoriesIcon } from "~/components/icons";

export function AdvisoriesDonutChart({
  scope,
  isLoading,
  advisoriesSeverity,
}: DonutChartCardProps & {
  advisoriesSeverity: SmallDonutData;
  isLoading: boolean;
}) {
  return (
    <SmallDonutCard
      to={`advisories?${scope?.params?.toString()}`}
      loading={isLoading}
      data={advisoriesSeverity}
      data-testid="advisories-donut-chart"
      title="Advisories"
      icon={<AdvisoriesIcon />}
    />
  );
}
