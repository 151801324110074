import { ScoreSource } from "~/operations";

type ScoreSources = Omit<ScoreSource, "name" | "__typename">;

type UseDetectedByProps = { detectedBy: ScoreSource[] };

export function useDetectedBy({ detectedBy }: UseDetectedByProps) {
  // loop through the detectedBy array and determine which source has the earliest firstDetectedAt date
  // only return the source with the earliest date
  const firstFound = detectedBy.reduce((earliest, current) => {
    const earliestDate = new Date(earliest.firstDetectedAt);
    const currentDate = new Date(current.firstDetectedAt);
    return currentDate < earliestDate ? current : earliest;
  }, detectedBy[0]);

  // order the sources by the keys in this order: vendor, version, firstDetectedAt, lastUpdatedAt, affectedAssets and then whatever is left
  const orderSources = (sources: ScoreSources) => {
    const order = [
      "vendor",
      "version",
      "firstDetectedAt",
      "lastUpdatedAt",
      "affectedAssets",
    ];

    // sort the sources by the order array
    const orderedSources = Object.keys(sources)
      .sort((a, b) => {
        const aIndex = order.indexOf(a);
        const bIndex = order.indexOf(b);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        return aIndex - bIndex;
      })
      .map((key) => ({
        key,
        value: sources[key as keyof ScoreSources],
      }));

    return orderedSources;
  };

  return { firstFound, orderSources };
}
