import { ReactNode } from "react";
import { Flex } from "~/components/Flex";

type RemediationFilterContainerProps = {
  children: ReactNode;
};

export const RemediationFilterContainer = ({
  children,
}: RemediationFilterContainerProps) => {
  return (
    <Flex
      sx={{
        display: "grid",
        gridAutoRows: "auto",
        gridTemplateColumns: "repeat(auto-fill, minmax(190px, auto))",
      }}
      gap={1}
      data-testid="packages-list"
    >
      {children}
    </Flex>
  );
};
