import { Routes, Route } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { JobsProvider } from "~/providers/jobs";
import { Space } from "~/lib/types";
import { Projects } from "~/pages/cicd/projects";
import { Jobs } from "~/pages/cicd/jobs";
import { Job } from "~/pages/cicd/job";
import { OverviewTab } from "~/components/asset-overview-tab";
import { ResourcesExplorer } from "~/pages/resources-explorer/asset-resources-tab";
import { PageLayout } from "../page-layout";
import { AssetChecksTab } from "~/pages/inventory/asset/asset-checks-tab";
import { VulnerabilitiesTab } from "~/pages/inventory/components/Vulnerabilities";
import { AdvisoriesTab } from "~/pages/inventory/components/Advisories";
import { AssetPoliciesTab } from "~/pages/inventory/asset/asset-policies-tab";
import { AssetSoftwareTab } from "~/pages/inventory/components/AssetSoftware";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function CicdRoutes({ space, availablePermissions }: Props) {
  return (
    <Routes>
      <Route
        index
        element={<Projects {...{ space, availablePermissions }} />}
      />

      <Route
        path="jobs/*"
        element={
          <JobsProvider>
            <Routes>
              <Route element={<PageLayout />}>
                <Route
                  index
                  element={<Jobs {...{ space, availablePermissions }} />}
                />
              </Route>

              <Route path="/:id" element={<Job {...{ space }} />}>
                <Route index element={<OverviewTab isCicd />} />
                <Route path="overview" element={<OverviewTab isCicd />} />
                <Route path="policies" element={<AssetPoliciesTab isCicd />} />
                <Route path="checks" element={<AssetChecksTab isCicd />} />
                <Route path="software" element={<AssetSoftwareTab />} />
                <Route
                  path="vulnerabilities"
                  element={<VulnerabilitiesTab isCicd />}
                />
                <Route path="advisories" element={<AdvisoriesTab />} />
                <Route path="resources" element={<ResourcesExplorer />} />
              </Route>
            </Routes>
          </JobsProvider>
        }
      />
    </Routes>
  );
}
