import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { DeleteIcon, OpenInNewIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import { Flex } from "~/components/Flex";

type AwsSeverlessDeleteConfirmDialogProps = {
  open: boolean;
  isDeleting: boolean;
  onConfirm: () => void;
  onClose: () => void;
  integrationName: string;
  stackUrl: string;
};

export function AwsServerlessDeleteConfirmDialog({
  isDeleting,
  onConfirm,
  onClose,
  open,
  integrationName,
  stackUrl,
}: AwsSeverlessDeleteConfirmDialogProps) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      {/* Dialog title */}
      <DialogTitle
        variant="h5"
        fontWeight={700}
        sx={{ px: 5, py: 5, textTransform: "uppercase" }}
      >
        Remove integration "{integrationName}"
      </DialogTitle>
      {/* Dialog content */}
      <DialogContent sx={{ px: 5 }}>
        <Typography variant="body2">
          To fully remove the integration, go to AWS CloudFormation and delete
          the 'mondoo' CloudFormation stack. To learn how, read the{" "}
          <Link
            target="_blank"
            href="https://mondoo.com/docs/platform/infra/cloud/aws/lambda/integration-lambda/#remove-an-integration"
          >
            Mondoo documentation.
          </Link>
        </Typography>
        <Flex justifyContent="center" sx={{ my: 5 }}>
          <Button
            target="_blank"
            href={stackUrl}
            variant="contained"
            color="primary"
            endIcon={<OpenInNewIcon />}
          >
            go to aws cloudformation stack
          </Button>
        </Flex>
        <Typography variant="body2">
          Once you delete the Mondoo Stack in the AWS Console, you can delete
          the integration here.
        </Typography>
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "center", gap: 2, p: 5 }}
      >
        <Button
          onClick={() => onClose()}
          variant="outlined"
          sx={{
            color: "secondary.light",
            width: "112px",
            border: (theme) =>
              `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isDeleting}
          buttonText="Delete"
          onClick={() => onConfirm()}
          variant="outlined"
          startIcon={<DeleteIcon />}
          sx={{ color: "error.light", width: "112px" }}
        />
      </DialogActions>
    </Dialog>
  );
}
