import { useState } from "react";
import { debounce, toString } from "lodash";
import { useToken } from "~/providers/token";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { TimerOutlinedIcon } from "~/components/icons";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";

export function TokenCustomizer({}) {
  const {
    tokenTimeLeft,
    expiresIn,
    setExpiresIn,
    tokenDescription,
    setTokenDescription,
  } = useToken();
  const [open, setOpen] = useState<boolean>(false);
  const [expiryError, setExpiryError] = useState<boolean>(false);

  //convert time left seconds to MM:SS format
  const timeLeft = () => {
    let minutes = Math.floor((tokenTimeLeft % 3600) / 60)
      .toString()
      .padStart(2, "0");
    let seconds = Math.floor(tokenTimeLeft % 60)
      .toString()
      .padStart(2, "0");

    return `${minutes}:${seconds}`;
  };

  const setNewExpiration: TextFieldProps["onChange"] = (event) => {
    const value = toString(event.target.value);
    // check if the value is not a number
    if (isNaN(parseInt(value))) {
      setExpiryError(true);
      return;
    } else {
      // reset the error
      setExpiryError(false);
    }
    // only update the expiration if there is
    if (value.length > 0) {
      let newValue = parseInt(value);
      // the minimum value allowed is 60 seconds
      if (newValue < 60) {
        newValue = 60;
      }
      setExpiresIn(newValue);
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "end", sm: "start" },
            mb: { xs: 1, sm: 0 },
          }}
        >
          <TimerOutlinedIcon sx={{ mr: 1.25 }} />
          <Typography>Token expires in {timeLeft()}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Link
            onClick={() => setOpen(!open)}
            sx={{ color: "text.primary", textDecoration: "underline" }}
          >
            {open ? "Close Customization Panel" : "Customize Token"}
          </Link>
        </Grid>
      </Grid>
      <Accordion
        expanded={open}
        sx={{
          background: "transparent",
          boxShadow: "none",
          "&:before": { display: "none" },
        }}
      >
        <AccordionSummary sx={{ display: "none" }}></AccordionSummary>
        <AccordionDetails sx={{ p: 0, pt: 3 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} sx={{ mb: { xs: 2 } }}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
                Description
              </Typography>
              <Typography>Optional description for your token</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: { xs: 3 } }}>
              <TextField
                label="Description"
                defaultValue={tokenDescription}
                onChange={debounce(
                  (e) => setTokenDescription(e.target.value),
                  750,
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: { xs: 2 } }}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
                Expiry
              </Typography>
              <Typography>
                Tokens are issued with a short expiration time in seconds
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { sm: "right" } }}>
              <TextField
                label="Expiry"
                defaultValue={expiresIn}
                inputProps={{ min: 60, type: "number" }}
                onChange={debounce((e) => setNewExpiration(e), 750)}
                error={expiryError}
                helperText={
                  expiryError && (
                    <ValidationMessage
                      error={{
                        type: "validate",
                        message: "Must be a number",
                      }}
                    />
                  )
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
