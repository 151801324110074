import { Box, Typography, useTheme } from "@mui/material";
import {
  statusColorMap,
  statusLabelMap,
  UseAssetUrlStats,
} from "~/hooks/useAssetUrlStats";

export type SunburstFooterProps = {
  assetUrlStats: UseAssetUrlStats;
};

export function SunburstFooter({ assetUrlStats }: SunburstFooterProps) {
  const theme = useTheme();

  const { statusDomain } = assetUrlStats;

  return (
    assetUrlStats.data.length > 0 && (
      <Box
        className="sunburst-footer"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 1,
          width: "100%",
        }}
      >
        <Box
          className="sunburst-legend"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1.5,
          }}
        >
          {statusDomain.map((s) => (
            <Box
              key={s}
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: theme.palette[statusColorMap[s]].main,
                }}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: theme.palette[statusColorMap[s]].main,
                }}
              >
                {statusLabelMap[s]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    )
  );
}
