import {
  ComponentsPropsList,
  createTheme,
  ThemeOptions,
} from "@mui/material/styles";
import { alpha } from "@mui/material";

export function createMondooTheme(themeOptions: ThemeOptions) {
  // Step 1: Create the theme
  const theme = createTheme(themeOptions);

  // Step 2: use the theme to populate defaultprops and overrides
  return createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          code: {
            padding: theme.spacing(0.25, 0.5),
            backgroundColor: theme.palette.code.background,
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiButton: {
        defaultProps: {
          color: "inherit",
        },
        styleOverrides: {
          root: ({
            ownerState,
          }: {
            ownerState: ComponentsPropsList["MuiButton"] &
              Record<string, unknown>;
          }) => ({
            ...(ownerState.variant === "text" &&
              ownerState.color === "primary" && {
                color: theme.palette.primary.light,
              }),
            ...(ownerState.variant === "text" &&
              ownerState.color === "secondary" && {
                color: theme.palette.secondary.light,
              }),

            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                color: theme.palette.primary.light,
                borderColor: theme.palette.primary.light,
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "secondary" && {
                color: theme.palette.secondary.light,
                borderColor: theme.palette.secondary.light,
              }),
            variants: [
              {
                // TODO:: match variant name with UX theme when it's added to shared palette
                props: { variant: "outlined-contained" },
                style: {
                  border: "1px solid",
                  backgroundColor: alpha(theme.palette.primary.main, 0.5),
                  borderColor: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                },
              },
            ],
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "unset",
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.light,
            boxShadow: theme.palette.tables.tableHead.shadow,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              background: theme.palette.background.light,
            },
          },
        },
      },
      MuiTableCell: {
        defaultProps: {
          root: {
            borderBottom: theme.palette.tables.tableCell.borderBottom,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: "none",
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          textColor: "inherit",
          indicatorColor: "primary",
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto, "San Francisco", sans-serif',
          },
        },
      },
    },
  });
}
