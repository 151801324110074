import { SmallDonutCard, SmallDonutData } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { CVEIcon } from "~/components/icons";

export function CvesDonutChart({
  scope,
  isLoading,
  cvesSeverity,
}: DonutChartCardProps & { cvesSeverity: SmallDonutData; isLoading: boolean }) {
  return (
    <SmallDonutCard
      to={`cves?${scope?.params?.toString()}`}
      loading={isLoading}
      data={cvesSeverity}
      data-testid="cves-donut-chart"
      title="CVES"
      icon={<CVEIcon />}
    />
  );
}
