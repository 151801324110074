import React from "react";
import { Flex } from "~/components/Flex";
import { SoftwareVersionLabel } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionLabel";
import { SoftwareVersionTooltip } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionTooltip";
import { SoftwareVersionUnitBase } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionUnitBase";
import { MultipleSoftwareVersionsTooltip } from "~/pages/space/software/Software/components/SoftwareVersions/MultipleSoftwareVersionsTooltip";
import { RiskFactorStats, VersionDistributionEntry } from "~/operations";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareVersionRestProps = {
  width: number;
  color: string;
  version: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  versions: VersionDistributionEntry[];
  edges: AggregateScoresEdges;
  scope: SpaceOrWorkspaceScope;
};

export const SoftwareVersionRest = ({
  color,
  version,
  riskFactors,
  versions,
  edges,
  scope,
}: SoftwareVersionRestProps) => {
  return (
    <Flex alignSelf="flex-end" flexDirection="column">
      <SoftwareVersionLabel color={color} version={version} />
      <SoftwareVersionTooltip
        content={
          <MultipleSoftwareVersionsTooltip
            edges={edges}
            riskFactors={riskFactors}
            versions={versions}
            scope={scope}
          />
        }
      >
        <SoftwareVersionUnitBase color={color} />
      </SoftwareVersionTooltip>
    </Flex>
  );
};
