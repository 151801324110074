import { Box } from "@mui/material";
import { ChartData, DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  sizes?: CardSizes;
  scope?: SpaceOrWorkspaceScope;
  policyGrades: ChartData[];
  isLoading: boolean;
};

export function PolicyOverviewCard({
  scope,
  sizes,
  policyGrades,
  isLoading,
}: Props) {
  return (
    <Flex gap={2} flexDirection="row">
      <HubCard
        to={`policies?${scope?.params?.toString()}`}
        {...{
          loading: isLoading,
          sizes,
          title: "Policy Overview",
          icon: <NavDrawerPolicyIcon fontSize="small" />,
        }}
        PaperProps={{ height: 264 }}
        data-testid="policy-overview-card"
      >
        <Box sx={{ mt: 2 }}>
          <DonutChart data={policyGrades} dataType="policies" />
        </Box>
      </HubCard>
    </Flex>
  );
}
