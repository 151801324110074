// Use action ids
//
// ```
// import { IamActions } from './store/iam'
// console.log(IamActions.VULNSCANNER_GETREPORTPLATFORM)
// console.log(IamActions.scanner())
// ```
//
// Request if the current user has the ability to perform an action
//
// import { TestIamActions, IamActions } from '../store/iam'
// TestIamActions("//captain.api.mondoo.app/organizations/wizardly-cohen-974982", IamActions.agent()).then((data) => {
//   console.log("actions:", data)
// }).catch( (err) => {
//   console.log("error:", err )
// })

// scanner
const VULNSCANNER_GETREPORT = "mondoo.scanner.VulnScanner.GetReport";
const VULNSCANNER_LISTREPORTS = "mondoo.scanner.VulnScanner.ListReports";
const VULNSCANNER_DELETEREPORT = "mondoo.scanner.VulnScanner.DeleteReport";

const ScannerActions = [
  VULNSCANNER_GETREPORT,
  VULNSCANNER_LISTREPORTS,
  VULNSCANNER_DELETEREPORT,
];

// agents
const AGENTMANAGER_REMOVEUNUSEDSERVICEACCOUNTS =
  "mondoo.agents.AgentManager.RemoveUnusedServiceAccounts";
const AGENTMANAGER_UPDATEAPITOKEN = "mondoo.agents.AgentManager.UpdateAPIToken";
const AGENTMANAGER_UPDATEAGENT = "mondoo.agents.AgentManager.UpdateAgent";
const AGENTMANAGER_REVOKEREGISTRATIONTOKEN =
  "mondoo.agents.AgentManager.RevokeRegistrationToken";
const AGENTMANAGER_SETSERVICEACCOUNTTIMESTAMP =
  "mondoo.agents.AgentManager.SetServiceAccountTimestamp";
const AGENTMANAGER_GETSERVICEACCOUNT =
  "mondoo.agents.AgentManager.GetServiceAccount";
const AGENTMANAGER_DELETESERVICEACCOUNTS =
  "mondoo.agents.AgentManager.DeleteServiceAccounts";
const AGENTMANAGER_DELETEAPITOKEN = "mondoo.agents.AgentManager.DeleteAPIToken";
const AGENTMANAGER_LISTAPITOKENS = "mondoo.agents.AgentManager.ListAPITokens";
const AGENTMANAGER_PINGPONG = "mondoo.agents.AgentManager.PingPong";
const AGENTMANAGER_LISTAGENTS = "mondoo.agents.AgentManager.ListAgents";
const AGENTMANAGER_DELETEAGENT = "mondoo.agents.AgentManager.DeleteAgent";
const AGENTMANAGER_SETAGENTERRORS = "mondoo.agents.AgentManager.SetAgentErrors";
const AGENTMANAGER_LISTSERVICEACCOUNTS =
  "mondoo.agents.AgentManager.ListServiceAccounts";
const AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP =
  "mondoo.agents.AgentManager.SetServiceAccountMembership";
const AGENTMANAGER_GETAGENT = "mondoo.agents.AgentManager.GetAgent";
const AGENTMANAGER_EXCHANGEREGISTRATIONTOKEN =
  "mondoo.agents.AgentManager.ExchangeRegistrationToken";
const AGENTMANAGER_LISTREGISTRATIONTOKENS =
  "mondoo.agents.AgentManager.ListRegistrationTokens";
const AGENTMANAGER_GENERATEREGISTRATIONTOKEN =
  "mondoo.agents.AgentManager.GenerateRegistrationToken";
const AGENTMANAGER_UNREGISTERAGENT =
  "mondoo.agents.AgentManager.UnRegisterAgent";
const AGENTMANAGER_CREATESERVICEACCOUNT =
  "mondoo.agents.AgentManager.CreateServiceAccount";
const AGENTMANAGER_DELETESERVICEACCOUNT =
  "mondoo.agents.AgentManager.DeleteServiceAccount";
const AGENTMANAGER_GENERATEAPITOKEN =
  "mondoo.agents.AgentManager.GenerateAPIToken";
const AGENTMANAGER_GETSPACEAGENTSTATISTICS =
  "mondoo.agents.AgentManager.GetSpaceAgentStatistics";
const AGENTMANAGER_CREATEAGENT = "mondoo.agents.AgentManager.CreateAgent";
const AGENTMANAGER_DELETEAGENTS = "mondoo.agents.AgentManager.DeleteAgents";
const AGENTMANAGER_UPDATESERVICEACCOUNT =
  "mondoo.agents.AgentManager.UpdateServiceAccount";
const AGENTMANAGER_GETPUBLICKEY = "mondoo.agents.AgentManager.GetPublicKey";
const AGENTMANAGER_VERIFYREGISTRATIONTOKEN =
  "mondoo.agents.AgentManager.VerifyRegistrationToken";
const AGENTMANAGER_GETSERVICEACCOUNTMEMBERSHIP =
  "mondoo.agents.AgentManager.GetServiceAccountMembership";
const AGENTMANAGER_REGISTERAGENT = "mondoo.agents.AgentManager.RegisterAgent";
const AGENTMANAGER_REMOVERUNNERSERVICEACCOUNTS =
  "mondoo.agents.AgentManager.RemoveRunnerServiceAccounts";
const AGENTMANAGER_HEALTHCHECK = "mondoo.agents.AgentManager.HealthCheck";

const AgentActions = [
  AGENTMANAGER_REMOVEUNUSEDSERVICEACCOUNTS,
  AGENTMANAGER_UPDATEAPITOKEN,
  AGENTMANAGER_UPDATEAGENT,
  AGENTMANAGER_REVOKEREGISTRATIONTOKEN,
  AGENTMANAGER_SETSERVICEACCOUNTTIMESTAMP,
  AGENTMANAGER_GETSERVICEACCOUNT,
  AGENTMANAGER_DELETESERVICEACCOUNTS,
  AGENTMANAGER_DELETEAPITOKEN,
  AGENTMANAGER_LISTAPITOKENS,
  AGENTMANAGER_PINGPONG,
  AGENTMANAGER_LISTAGENTS,
  AGENTMANAGER_DELETEAGENT,
  AGENTMANAGER_SETAGENTERRORS,
  AGENTMANAGER_LISTSERVICEACCOUNTS,
  AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP,
  AGENTMANAGER_GETAGENT,
  AGENTMANAGER_EXCHANGEREGISTRATIONTOKEN,
  AGENTMANAGER_LISTREGISTRATIONTOKENS,
  AGENTMANAGER_GENERATEREGISTRATIONTOKEN,
  AGENTMANAGER_UNREGISTERAGENT,
  AGENTMANAGER_CREATESERVICEACCOUNT,
  AGENTMANAGER_DELETESERVICEACCOUNT,
  AGENTMANAGER_GENERATEAPITOKEN,
  AGENTMANAGER_GETSPACEAGENTSTATISTICS,
  AGENTMANAGER_CREATEAGENT,
  AGENTMANAGER_DELETEAGENTS,
  AGENTMANAGER_UPDATESERVICEACCOUNT,
  AGENTMANAGER_GETPUBLICKEY,
  AGENTMANAGER_VERIFYREGISTRATIONTOKEN,
  AGENTMANAGER_GETSERVICEACCOUNTMEMBERSHIP,
  AGENTMANAGER_REGISTERAGENT,
  AGENTMANAGER_REMOVERUNNERSERVICEACCOUNTS,
  AGENTMANAGER_HEALTHCHECK,
];

// assets
const ASSETS_GETASSET = "mondoo.policy.AssetStore.GetAsset";
const ASSETS_LISTASSETS = "mondoo.policy.AssetStore.ListAssets";
const ASSETS_DELETEASSET = "mondoo.policy.AssetStore.DeleteAsset";
const ASSETS_DELETEASSETS = "mondoo.policy.AssetStore.DeleteAssets";
const ASSETS_SETASSETANNOTATIONS =
  "mondoo.policy.AssetStore.SetAssetAnnotations";
const ASSETS_LISTCICDPROJECT = "mondoo.policy.AssetStore.ListCicdProject";
const ASSETS_GETCICDPROJECT = "mondoo.policy.AssetStore.GetCicdProject";
const ASSETS_DELETECICDPROJECT = "mondoo.policy.AssetStore.DeleteCicdProject";

const AssetActions = [
  ASSETS_GETASSET,
  ASSETS_LISTASSETS,
  ASSETS_DELETEASSET,
  ASSETS_DELETEASSETS,
  ASSETS_SETASSETANNOTATIONS,
  ASSETS_LISTCICDPROJECT,
  ASSETS_GETCICDPROJECT,
  ASSETS_DELETECICDPROJECT,
];

// captain
const CAPTAIN_GETSPACE = "mondoo.captain.Captain.GetSpace";
const CAPTAIN_IDSUGGESTIONSPACE = "mondoo.captain.Captain.IDSuggestionSpace";
const CAPTAIN_CREATEORGANIZATION = "mondoo.captain.Captain.CreateOrganization";
const CAPTAIN_LISTORGANIZATIONS = "mondoo.captain.Captain.ListOrganizations";
const CAPTAIN_SETORGANIZATIONMEMBERSHIP =
  "mondoo.captain.Captain.SetOrganizationMembership";
const CAPTAIN_LISTORGANIZATIONMEMBERSHIPS =
  "mondoo.captain.Captain.ListOrganizationMemberships";
const CAPTAIN_SETUSERSETTING = "mondoo.captain.Captain.SetUserSetting";
const CAPTAIN_LISTSPACES = "mondoo.captain.Captain.ListSpaces";
const CAPTAIN_DELETESPACE = "mondoo.captain.Captain.DeleteSpace";
const CAPTAIN_DELETEORGANIZATION = "mondoo.captain.Captain.DeleteOrganization";
const CAPTAIN_REMOVESPACEMEMBERSHIP =
  "mondoo.captain.Captain.RemoveSpaceMembership";
const CAPTAIN_UPDATESPACE = "mondoo.captain.Captain.UpdateSpace";
const CAPTAIN_UPDATEORGANIZATION = "mondoo.captain.Captain.UpdateOrganization";
const CAPTAIN_LISTSPACEMEMBERSHIPS =
  "mondoo.captain.Captain.ListSpaceMemberships";
const CAPTAIN_SETINTEGRATION = "mondoo.captain.Captain.SetIntegration";
const CAPTAIN_REMOVEORGANIZATIONMEMBERSHIP =
  "mondoo.captain.Captain.RemoveOrganizationMembership";
const CAPTAIN_LISTUSERSETTINGS = "mondoo.captain.Captain.ListUserSettings";
const CAPTAIN_CREATESPACE = "mondoo.captain.Captain.CreateSpace";
const CAPTAIN_GETORGANIZATION = "mondoo.captain.Captain.GetOrganization";
const CAPTAIN_IDSUGGESTIONORGANIZATION =
  "mondoo.captain.Captain.IDSuggestionOrganization";
const CAPTAIN_SETSPACEMEMBERSHIP = "mondoo.captain.Captain.SetSpaceMembership";
const CAPTAIN_GETUSER = "mondoo.captain.Captain.GetUser";
const CAPTAIN_DELETEUSERSETTING = "mondoo.captain.Captain.DeleteUserSetting";

const CaptainActions = [
  CAPTAIN_GETSPACE,
  CAPTAIN_IDSUGGESTIONSPACE,
  CAPTAIN_CREATEORGANIZATION,
  CAPTAIN_LISTORGANIZATIONS,
  CAPTAIN_SETORGANIZATIONMEMBERSHIP,
  CAPTAIN_LISTORGANIZATIONMEMBERSHIPS,
  CAPTAIN_SETUSERSETTING,
  CAPTAIN_LISTSPACES,
  CAPTAIN_LISTSPACES,
  CAPTAIN_SETINTEGRATION,
  CAPTAIN_DELETESPACE,
  CAPTAIN_DELETEORGANIZATION,
  CAPTAIN_REMOVESPACEMEMBERSHIP,
  CAPTAIN_UPDATESPACE,
  CAPTAIN_UPDATEORGANIZATION,
  CAPTAIN_LISTSPACEMEMBERSHIPS,
  CAPTAIN_REMOVEORGANIZATIONMEMBERSHIP,
  CAPTAIN_LISTUSERSETTINGS,
  CAPTAIN_CREATESPACE,
  CAPTAIN_GETORGANIZATION,
  CAPTAIN_IDSUGGESTIONORGANIZATION,
  CAPTAIN_SETSPACEMEMBERSHIP,
  CAPTAIN_SETSPACEMEMBERSHIP,
  CAPTAIN_GETUSER,
  CAPTAIN_DELETEUSERSETTING,
];

// vadvisor
const ADVISORYDATABASE_LISTCVES = "mondoo.vuln.AdvisoryDatabase.ListCVEs";
const ADVISORYDATABASE_GETCVE = "mondoo.vuln.AdvisoryDatabase.GetCVE";
const ADVISORYDATABASE_GETSOURCE = "mondoo.vuln.AdvisoryDatabase.GetSource";
const ADVISORYDATABASE_GETADVISORY = "mondoo.vuln.AdvisoryDatabase.GetAdvisory";
const ADVISORYDATABASE_LISTADVISORIES =
  "mondoo.vuln.AdvisoryDatabase.ListAdvisories";
const EXTENDEDHUB_GETWORSTSCOREANDCVEVULNCOUNTSFORASSET =
  "mondoo.extendedhub.AdvisoryDatabase.GetWorstCountsForAsset";

const AdvisoryDBActions = [
  ADVISORYDATABASE_LISTCVES,
  ADVISORYDATABASE_GETCVE,
  ADVISORYDATABASE_GETSOURCE,
  ADVISORYDATABASE_GETADVISORY,
  ADVISORYDATABASE_LISTADVISORIES,
  EXTENDEDHUB_GETWORSTSCOREANDCVEVULNCOUNTSFORASSET,
];

// iam
const IAM_TESTIAMACTIONS = "mondoo.iam.IAM.TestIamActions";

const IamServiceActions = [IAM_TESTIAMACTIONS];

// invitations
const INVITATIONMANAGER_DECLINE =
  "mondoo.invitations.InvitationManager.Decline";
const INVITATIONMANAGER_CANCEL = "mondoo.invitations.InvitationManager.Cancel";
const INVITATIONMANAGER_RESEND = "mondoo.invitations.InvitationManager.Resend";
const INVITATIONMANAGER_CREATE = "mondoo.invitations.InvitationManager.Create";
const INVITATIONMANAGER_LIST = "mondoo.invitations.InvitationManager.List";
const INVITATIONMANAGER_ACCEPT = "mondoo.invitations.InvitationManager.Accept";

const InvitationActions = [
  INVITATIONMANAGER_DECLINE,
  INVITATIONMANAGER_CANCEL,
  INVITATIONMANAGER_RESEND,
  INVITATIONMANAGER_CREATE,
  INVITATIONMANAGER_LIST,
  INVITATIONMANAGER_ACCEPT,
];

// audit log
const ACTION_MONDOO_AUDITLOG_AUDITLOGMANAGER_LISTLOGENTRIES =
  "mondoo.auditlog.AuditLogManager.ListLogs";

const AuditLogActions = [ACTION_MONDOO_AUDITLOG_AUDITLOGMANAGER_LISTLOGENTRIES];

// policy
const ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION =
  "mondoo.policy.ExtendedHub.ApplyExceptionMutation";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONREVIEWMUTATION =
  "mondoo.policy.ExtendedHub.ApplyExceptionReviewMutation";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE =
  "mondoo.policy.ExtendedHub.CreateCase";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES =
  "mondoo.policy.ExtendedHub.ListCases";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_DELETECASES =
  "mondoo.policy.ExtendedHub.DeleteCases";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE =
  "mondoo.policy.ExtendedHub.UpdateCase";
const ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATEWORKSPACE =
  "mondoo.policy.ExtendedHub.CreateWorkspace";

const ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE =
  "cnspec.policy.PolicyHub.SetBundle";
const ACTION_CNQUERY_EXPLORER_QUERYHUB_SETBUNDLE =
  "cnquery.explorer.QueryHub.SetBundle";
const ACTION_CNQUERY_EXPLORER_QUERYHUB_DELETEQUERYPACK =
  "cnquery.explorer.QueryHub.DeleteQueryPack";
const POLICY_MARKETPLACE_GETPOLICYFILTERS =
  "mondoo.policy.Marketplace.GetPolicyFilters";
const POLICY_DISTRIBUTOR_GETEXECUTIONJOB =
  "mondoo.policy.Distributor.GetExecutionJob";
const POLICY_COLLECTOR_DUMPASSETDATA = "mondoo.policy.Collector.DumpAssetData";
const POLICY_COLLECTOR_GETASSETDATA = "mondoo.policy.Collector.GetAssetData";
const POLICY_MARKETPLACE_VALIDATEPOLICYBUNDLE =
  "mondoo.policy.Marketplace.ValidatePolicyBundle";
const POLICY_MARKETPLACE_GETPOLICY = "mondoo.policy.Marketplace.GetPolicy";
const POLICY_RESOLVER_RESOLVE = "mondoo.policy.Resolver.Resolve";
const POLICY_DISTRIBUTOR_UNASSIGN = "mondoo.policy.Distributor.Unassign";
const CNSPEC_POLICY_POLICYRESOLVER_ASSIGN =
  "cnspec.policy.PolicyResolver.Assign";
const CNSPEC_POLICY_POLICYRESOLVER_UNASSIGN =
  "cnspec.policy.PolicyResolver.Unassign";
const POLICY_MARKETPLACE_LISTPUBLIC = "mondoo.policy.Marketplace.ListPublic";
const POLICY_DISTRIBUTOR_ASSIGN = "mondoo.policy.Distributor.Assign";
const POLICY_COLLECTOR_DUMPASSETSCORES =
  "mondoo.policy.Collector.DumpAssetScores";
const POLICY_AUTHORIZATION_ISPUBLIC = "mondoo.policy.Authorization.IsPublic";
const POLICY_MARKETPLACE_VALIDATEPOLICY =
  "mondoo.policy.Marketplace.ValidatePolicy";
const POLICY_MARKETPLACE_GETPOLICYBUNDLE =
  "mondoo.policy.Marketplace.GetPolicyBundle";
const POLICY_MARKETPLACE_LIST = "mondoo.policy.Marketplace.List";
const POLICY_MARKETPLACE_SETPOLICYBUNDLE =
  "mondoo.policy.Marketplace.SetPolicyBundle";
const POLICY_DISTRIBUTOR_ENSUREASSET = "mondoo.policy.Distributor.EnsureAsset";
const POLICY_COLLECTOR_GETCOLLECTORJOB =
  "mondoo.policy.Collector.GetCollectorJob";
const POLICY_COLLECTOR_STORESCORES = "mondoo.policy.Collector.StoreScores";
const POLICY_DISTRIBUTOR_DELETEASSET = "mondoo.policy.Distributor.DeleteAsset";
const POLICY_DISTRIBUTOR_UPDATEASSETJOBS =
  "mondoo.policy.Distributor.UpdateAssetJobs";
const POLICY_REPORTSSTORE_LISTASSETSCORES =
  "mondoo.policy.ReportsStore.ListAssetScores";
const POLICY_MARKETPLACE_DELETEPOLICY =
  "mondoo.policy.Marketplace.DeletePolicy";
const POLICY_COLLECTOR_STOREASSETDATA =
  "mondoo.policy.Collector.StoreAssetData";
const POLICY_REPORTSSTORE_GETREPORT = "mondoo.policy.ReportsStore.GetReport";
const POLICY_REPORTSSTORE_GETSCORE = "mondoo.policy.ReportsStore.GetScore";
const POLICY_MARKETPLACE_GETSCHEMA = "mondoo.policy.Marketplace.GetSchema";
const POLICY_DISTRIBUTOR_GETASSIGNEDPOLICIES =
  "mondoo.policy.Distributor.GetAssignedPolicies";

const PolicyActions = [
  ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONREVIEWMUTATION,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_DELETECASES,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATEWORKSPACE,
  POLICY_MARKETPLACE_GETPOLICYFILTERS,
  POLICY_DISTRIBUTOR_GETEXECUTIONJOB,
  POLICY_COLLECTOR_DUMPASSETDATA,
  POLICY_COLLECTOR_GETASSETDATA,
  POLICY_MARKETPLACE_VALIDATEPOLICYBUNDLE,
  POLICY_MARKETPLACE_GETPOLICY,
  POLICY_RESOLVER_RESOLVE,
  POLICY_DISTRIBUTOR_UNASSIGN,
  CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
  CNSPEC_POLICY_POLICYRESOLVER_UNASSIGN,
  POLICY_MARKETPLACE_LISTPUBLIC,
  POLICY_DISTRIBUTOR_ASSIGN,
  POLICY_COLLECTOR_DUMPASSETSCORES,
  POLICY_AUTHORIZATION_ISPUBLIC,
  POLICY_MARKETPLACE_VALIDATEPOLICY,
  POLICY_MARKETPLACE_GETPOLICYBUNDLE,
  POLICY_MARKETPLACE_LIST,
  POLICY_MARKETPLACE_SETPOLICYBUNDLE,
  ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  ACTION_CNQUERY_EXPLORER_QUERYHUB_DELETEQUERYPACK,
  ACTION_CNQUERY_EXPLORER_QUERYHUB_SETBUNDLE,
  POLICY_DISTRIBUTOR_ENSUREASSET,
  POLICY_COLLECTOR_GETCOLLECTORJOB,
  POLICY_COLLECTOR_STORESCORES,
  POLICY_DISTRIBUTOR_DELETEASSET,
  POLICY_DISTRIBUTOR_UPDATEASSETJOBS,
  POLICY_REPORTSSTORE_LISTASSETSCORES,
  POLICY_MARKETPLACE_DELETEPOLICY,
  POLICY_COLLECTOR_STOREASSETDATA,
  POLICY_REPORTSSTORE_GETREPORT,
  POLICY_REPORTSSTORE_GETSCORE,
  POLICY_MARKETPLACE_GETSCHEMA,
  POLICY_DISTRIBUTOR_GETASSIGNEDPOLICIES,
];

// integrations
const INTEGRATIONS_INTEGRATIONSMANAGER_LIST =
  "mondoo.integrations.IntegrationsManager.List";
const INTEGRATIONS_INTEGRATIONSMANAGER_GET =
  "mondoo.integrations.IntegrationsManager.Get";
const INTEGRATIONS_INTEGRATIONSMANAGER_CREATE =
  "mondoo.integrations.IntegrationsManager.Create";
const INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE =
  "mondoo.integrations.IntegrationsManager.Update";
const INTEGRATIONS_INTEGRATIONSMANAGER_DELETE =
  "mondoo.integrations.IntegrationsManager.Delete";
const INTEGRATIONS_INTEGRATIONSMANAGER_DELETEINTEGRATIONS =
  "mondoo.integrations.IntegrationsManager.DeleteIntegrations";
const INTEGRATIONS_INTEGRATIONSMANAGER_STATS =
  "mondoo.integrations.IntegrationsManager.Stats";
const INTEGRATIONS_INTEGRATIONSMANAGER_GETTOKENFORINTEGRATION =
  "mondoo.integrations.IntegrationsManager.GetTokenForIntegration";
const INTEGRATIONS_INTEGRATIONSMANAGER_SENDPING =
  "mondoo.integrations.IntegrationsManager.SendPing";
const INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION =
  "mondoo.integrations.IntegrationsManager.TriggerAction";
const INTEGRATIONS_INTEGRATIONSMANAGER_PERFORMDISCOVERY =
  "mondoo.integrations.IntegrationsManager.PerformDiscovery";
const INTEGRATIONS_INTEGRATIONSMANAGER_LISTDISCOVERYRESULTS =
  "mondoo.integrations.IntegrationsManager.ListDiscoveryResults";
const INTEGRATIONS_INTEGRATIONSMANAGER_SUPPRESSINTEGRATIONMESSAGE =
  "mondoo.integrations.IntegrationsManager.SuppressIntegrationMessage";
const ROLE_DEPLOYMENT_MANAGER_MRN =
  "//iam.api.mondoo.app/roles/deployment-manager";

const IntegrationsActions = [
  INTEGRATIONS_INTEGRATIONSMANAGER_LIST,
  INTEGRATIONS_INTEGRATIONSMANAGER_GET,
  INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
  INTEGRATIONS_INTEGRATIONSMANAGER_DELETEINTEGRATIONS,
  INTEGRATIONS_INTEGRATIONSMANAGER_STATS,
  INTEGRATIONS_INTEGRATIONSMANAGER_GETTOKENFORINTEGRATION,
  INTEGRATIONS_INTEGRATIONSMANAGER_SENDPING,
  INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  INTEGRATIONS_INTEGRATIONSMANAGER_PERFORMDISCOVERY,
  INTEGRATIONS_INTEGRATIONSMANAGER_LISTDISCOVERYRESULTS,
  INTEGRATIONS_INTEGRATIONSMANAGER_SUPPRESSINTEGRATIONMESSAGE,
];

// billing
const BILLING_BILLINGMANAGER_DELETEBILLINGACCOUNT =
  "mondoo.billing.BillingManager.DeleteBillingAccount";
const BILLING_BILLINGMANAGER_GETCURRENTSUBSCRIPTION =
  "mondoo.billing.BillingManager.GetCurrentSubscription";
const BILLING_BILLINGMANAGER_LISTSUBSCRIPTIONS =
  "mondoo.billing.BillingManager.ListSubscriptions";
const BILLING_BILLINGMANAGER_CHANGESUBSCRIPTION =
  "mondoo.billing.BillingManager.ChangeSubscription";
const BILLING_BILLINGMANAGER_CREATEBILLINGSESSION =
  "mondoo.billing.BillingManager.CreateBillingSession";
const BILLING_BILLINGMANAGER_SETBILLINGACCOUNT =
  "mondoo.billing.BillingManager.SetBillingAccount";
const BILLING_BILLINGMANAGER_GETBILLINGACCOUNT =
  "mondoo.billing.BillingManager.GetBillingAccount";

const BillingActions = [
  BILLING_BILLINGMANAGER_DELETEBILLINGACCOUNT,
  BILLING_BILLINGMANAGER_GETCURRENTSUBSCRIPTION,
  BILLING_BILLINGMANAGER_LISTSUBSCRIPTIONS,
  BILLING_BILLINGMANAGER_CHANGESUBSCRIPTION,
  BILLING_BILLINGMANAGER_CREATEBILLINGSESSION,
  BILLING_BILLINGMANAGER_SETBILLINGACCOUNT,
  BILLING_BILLINGMANAGER_GETBILLINGACCOUNT,
];

// all iam actions
export const IamActions = {
  // advisory db
  ADVISORYDATABASE_LISTCVES,
  ADVISORYDATABASE_GETCVE,
  ADVISORYDATABASE_GETSOURCE,
  ADVISORYDATABASE_GETADVISORY,
  ADVISORYDATABASE_LISTADVISORIES,

  // scanner
  VULNSCANNER_GETREPORT,
  VULNSCANNER_LISTREPORTS,
  VULNSCANNER_DELETEREPORT,

  // agents
  AGENTMANAGER_REMOVEUNUSEDSERVICEACCOUNTS,
  AGENTMANAGER_UPDATEAPITOKEN,
  AGENTMANAGER_UPDATEAGENT,
  AGENTMANAGER_REVOKEREGISTRATIONTOKEN,
  AGENTMANAGER_SETSERVICEACCOUNTTIMESTAMP,
  AGENTMANAGER_GETSERVICEACCOUNT,
  AGENTMANAGER_DELETESERVICEACCOUNTS,
  AGENTMANAGER_DELETEAPITOKEN,
  AGENTMANAGER_LISTAPITOKENS,
  AGENTMANAGER_PINGPONG,
  AGENTMANAGER_LISTAGENTS,
  AGENTMANAGER_DELETEAGENT,
  AGENTMANAGER_SETAGENTERRORS,
  AGENTMANAGER_LISTSERVICEACCOUNTS,
  AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP,
  AGENTMANAGER_GETAGENT,
  AGENTMANAGER_EXCHANGEREGISTRATIONTOKEN,
  AGENTMANAGER_LISTREGISTRATIONTOKENS,
  AGENTMANAGER_GENERATEREGISTRATIONTOKEN,
  AGENTMANAGER_UNREGISTERAGENT,
  AGENTMANAGER_CREATESERVICEACCOUNT,
  AGENTMANAGER_DELETESERVICEACCOUNT,
  AGENTMANAGER_GENERATEAPITOKEN,
  AGENTMANAGER_GETSPACEAGENTSTATISTICS,
  AGENTMANAGER_CREATEAGENT,
  AGENTMANAGER_DELETEAGENTS,
  AGENTMANAGER_UPDATESERVICEACCOUNT,
  AGENTMANAGER_GETPUBLICKEY,
  AGENTMANAGER_VERIFYREGISTRATIONTOKEN,
  AGENTMANAGER_GETSERVICEACCOUNTMEMBERSHIP,
  AGENTMANAGER_REGISTERAGENT,
  AGENTMANAGER_REMOVERUNNERSERVICEACCOUNTS,
  AGENTMANAGER_HEALTHCHECK,

  // assets
  ASSETS_GETASSET,
  ASSETS_LISTASSETS,
  ASSETS_DELETEASSET,
  ASSETS_DELETEASSETS,
  ASSETS_SETASSETANNOTATIONS,
  ASSETS_LISTCICDPROJECT,
  ASSETS_GETCICDPROJECT,
  ASSETS_DELETECICDPROJECT,

  // captain
  CAPTAIN_GETSPACE,
  CAPTAIN_IDSUGGESTIONSPACE,
  CAPTAIN_CREATEORGANIZATION,
  CAPTAIN_LISTORGANIZATIONS,
  CAPTAIN_SETORGANIZATIONMEMBERSHIP,
  CAPTAIN_LISTORGANIZATIONMEMBERSHIPS,
  CAPTAIN_SETUSERSETTING,
  CAPTAIN_LISTSPACES,
  CAPTAIN_SETINTEGRATION,
  CAPTAIN_DELETESPACE,
  CAPTAIN_DELETEORGANIZATION,
  CAPTAIN_REMOVESPACEMEMBERSHIP,
  CAPTAIN_UPDATESPACE,
  CAPTAIN_UPDATEORGANIZATION,
  CAPTAIN_LISTSPACEMEMBERSHIPS,
  CAPTAIN_REMOVEORGANIZATIONMEMBERSHIP,
  CAPTAIN_LISTUSERSETTINGS,
  CAPTAIN_CREATESPACE,
  CAPTAIN_GETORGANIZATION,
  CAPTAIN_IDSUGGESTIONORGANIZATION,
  CAPTAIN_SETSPACEMEMBERSHIP,
  CAPTAIN_GETUSER,
  CAPTAIN_DELETEUSERSETTING,

  // invitations
  INVITATIONMANAGER_CREATE,
  INVITATIONMANAGER_LIST,
  INVITATIONMANAGER_ACCEPT,
  INVITATIONMANAGER_CANCEL,
  INVITATIONMANAGER_DECLINE,
  INVITATIONMANAGER_RESEND,

  // iam
  IAM_TESTIAMACTIONS,

  // auditlog
  ACTION_MONDOO_AUDITLOG_AUDITLOGMANAGER_LISTLOGENTRIES,

  // policy
  ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONREVIEWMUTATION,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_DELETECASES,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE,
  ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATEWORKSPACE,
  POLICY_MARKETPLACE_GETPOLICYFILTERS,
  POLICY_DISTRIBUTOR_GETEXECUTIONJOB,
  POLICY_COLLECTOR_DUMPASSETDATA,
  POLICY_COLLECTOR_GETASSETDATA,
  POLICY_MARKETPLACE_VALIDATEPOLICYBUNDLE,
  POLICY_MARKETPLACE_GETPOLICY,
  POLICY_RESOLVER_RESOLVE,
  POLICY_DISTRIBUTOR_UNASSIGN,
  CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
  CNSPEC_POLICY_POLICYRESOLVER_UNASSIGN,
  POLICY_MARKETPLACE_LISTPUBLIC,
  POLICY_DISTRIBUTOR_ASSIGN,
  POLICY_COLLECTOR_DUMPASSETSCORES,
  POLICY_AUTHORIZATION_ISPUBLIC,
  POLICY_MARKETPLACE_VALIDATEPOLICY,
  POLICY_MARKETPLACE_GETPOLICYBUNDLE,
  POLICY_MARKETPLACE_LIST,
  ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  ACTION_CNQUERY_EXPLORER_QUERYHUB_SETBUNDLE,
  ACTION_CNQUERY_EXPLORER_QUERYHUB_DELETEQUERYPACK,
  POLICY_MARKETPLACE_SETPOLICYBUNDLE,
  POLICY_DISTRIBUTOR_ENSUREASSET,
  POLICY_COLLECTOR_GETCOLLECTORJOB,
  POLICY_COLLECTOR_STORESCORES,
  POLICY_DISTRIBUTOR_DELETEASSET,
  POLICY_DISTRIBUTOR_UPDATEASSETJOBS,
  POLICY_REPORTSSTORE_LISTASSETSCORES,
  POLICY_MARKETPLACE_DELETEPOLICY,
  POLICY_COLLECTOR_STOREASSETDATA,
  POLICY_REPORTSSTORE_GETREPORT,
  POLICY_REPORTSSTORE_GETSCORE,
  POLICY_MARKETPLACE_GETSCHEMA,
  POLICY_DISTRIBUTOR_GETASSIGNEDPOLICIES,

  // integrations
  INTEGRATIONS_INTEGRATIONSMANAGER_LIST,
  INTEGRATIONS_INTEGRATIONSMANAGER_GET,
  INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
  INTEGRATIONS_INTEGRATIONSMANAGER_DELETEINTEGRATIONS,
  INTEGRATIONS_INTEGRATIONSMANAGER_STATS,
  INTEGRATIONS_INTEGRATIONSMANAGER_GETTOKENFORINTEGRATION,
  INTEGRATIONS_INTEGRATIONSMANAGER_SENDPING,
  INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  INTEGRATIONS_INTEGRATIONSMANAGER_PERFORMDISCOVERY,
  INTEGRATIONS_INTEGRATIONSMANAGER_LISTDISCOVERYRESULTS,
  INTEGRATIONS_INTEGRATIONSMANAGER_SUPPRESSINTEGRATIONMESSAGE,
  // automated integrations
  ROLE_DEPLOYMENT_MANAGER_MRN,

  // billing
  BILLING_BILLINGMANAGER_DELETEBILLINGACCOUNT,
  BILLING_BILLINGMANAGER_GETCURRENTSUBSCRIPTION,
  BILLING_BILLINGMANAGER_LISTSUBSCRIPTIONS,
  BILLING_BILLINGMANAGER_CHANGESUBSCRIPTION,
  BILLING_BILLINGMANAGER_CREATEBILLINGSESSION,
  BILLING_BILLINGMANAGER_SETBILLINGACCOUNT,
  BILLING_BILLINGMANAGER_GETBILLINGACCOUNT,

  // functions
  scanner: () => ScannerActions,
  agent: () => AgentActions,
  asset: () => AssetActions,
  captain: () => CaptainActions,
  iam: () => IamServiceActions,
  advisorydb: () => AdvisoryDBActions,
  invitations: () => InvitationActions,
  auditlog: () => AuditLogActions,
  policy: () => PolicyActions,
  integrations: () => IntegrationsActions,
  billing: () => BillingActions,

  default: () => {
    return [
      ...CaptainActions,
      ...InvitationActions,
      ...AssetActions,
      ...AuditLogActions,
      ...AgentActions,
      ...PolicyActions,
      ...IntegrationsActions,
      ...BillingActions,
    ];
  },
};
