import { Typography } from "@mui/material";
import { HubCard } from "~/components/hub-card";
import { Space } from "~/lib/types";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
} from "~/operations";
import { usePlatformVulnerabilityConfiguration } from "~/hooks";
import { NoEntriesTextOnly } from "~/components/NoEntriesTextOnly";
import {
  AdjustedAggregateScoreType,
  AggregateScoresEdges,
} from "../FirewatchPage/types";
import { FirewatchList } from "../FirewatchList";
import { useFirewatchDashboardCard } from "./hooks/UseFirewatchDashboardCard";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  scope?: SpaceOrWorkspaceScope;
  space: Space;
  pageType: Exclude<AdjustedAggregateScoreType, "ALL">;
};

export function FirewatchDashboardCard({ scope, space, pageType }: Props) {
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const {
    title,
    icon,
    noEntriesText,
    successCard,
    disableSorting,
    titleTo,
    onlyAffectedFindings,
  } = useFirewatchDashboardCard({
    pageType,
    space,
  });

  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "RANK", "BLAST_RADIUS", "TITLE"],
  });

  const { data, error, loading } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope?.mrn || space.mrn,
      first: 5,
      after: null,
      filter: {
        maxRiskScore: onlyAffectedFindings ? 99 : undefined,
        scoreType:
          pageType === "NewestRelevantVulnerabilities"
            ? AggregateScoreType.Vulnerability
            : pageType,
      },
      orderBy,
    },
  });

  const getContent = () => {
    if (!pvcIsEnabled && pageType !== AggregateScoreType.Check) {
      return <NoEntriesTextOnly text={noEntriesText} />;
    }

    if (loading) {
      return <Typography>Loading...</Typography>;
    }

    if (error) {
      return <Typography>Error: {error.message}</Typography>;
    }

    const { edges, totalCount } = data?.aggregateScores as {
      edges: AggregateScoresEdges;
      totalCount: number;
    };

    const success = !loading && totalCount === 0;
    if (success) {
      return successCard;
    }

    return (
      <FirewatchList
        size="small"
        field={orderBy.field}
        direction={orderBy.direction}
        {...{
          space,
          scope,
          pageType,
          data: edges,
          handleSortClick,
          loading,
          orderBy,
          disableSorting,
        }}
      />
    );
  };

  return (
    <HubCard
      {...{
        loading,
        title,
        icon,
        titleTo,
      }}
      sx={{ height: "100%", minHeight: 346 }}
    >
      {getContent()}
    </HubCard>
  );
}
