import * as React from "react";
import { ReactElement, useEffect } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SearchIcon } from "~/components/icons";
import { KeyboardHints } from "./KeyboardHints";

type BaseAutocompleteProps = {
  open: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLElement>;
  onSearchInputChange?: TextFieldProps["onChange"];
  placeholder?: string;
  showKeyboardHint?: boolean;
  renderContent?: () => ReactElement;
  onDropdownSearchFieldKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
};

export function BaseAutocomplete({
  onClose,
  open,
  anchorRef,
  onSearchInputChange,
  showKeyboardHint,
  placeholder,
  renderContent,
  onDropdownSearchFieldKeyUp,
}: BaseAutocompleteProps) {
  const handleEscapePress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscapePress);

    return () => {
      window.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  return (
    <Popper
      sx={{
        zIndex: 2,
        minWidth: "100%",
        "& > .MuiPaper-root": {
          maxWidth: 396,
        },
      }}
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      transition
      disablePortal
      modifiers={[{ name: "flip", enabled: false }]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
          <Paper
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
              borderTopLeftRadius: 0,
              mt: "-1px",
              backgroundColor: "background.default",
              "& .MuiPopper-root": {
                zIndex: 100,
              },
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Box sx={{ minWidth: "100px", zIndex: 2 }}>
                <TextField
                  data-testid="values-search-input"
                  autoComplete="off"
                  color="primary"
                  placeholder={placeholder}
                  size="small"
                  onChange={onSearchInputChange}
                  onKeyUp={onDropdownSearchFieldKeyUp}
                  sx={{
                    width: "100%",
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px !important",
                    },
                  }}
                  slotProps={{
                    input: {
                      sx: {
                        bgcolor: "code.background",
                        m: 3,
                        mb: 0,
                        px: 1,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ fontSize: "24px" }} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />

                {renderContent?.()}

                {showKeyboardHint && <KeyboardHints />}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
