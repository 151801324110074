import { Box, Breadcrumbs } from "@mui/material";

import { Header } from "~/components/DetailsPage/components/Header";
import { useSoftwareDetails } from "./hooks/useSoftwareDetails";
import { LoadingFailed, LoadingPage } from "~/components/loading";
import { SoftwareDescription } from "./SoftwareDescription";
import { SoftwareVulnerabilities } from "./SoftwareVulnerabilities";
import { SoftwareAdvisories } from "./SoftwareAdvisories";
import { PackageIcon } from "~/components/icons/mondoo/package";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareDetailsProps = {
  scope: SpaceOrWorkspaceScope;
};

export const SoftwareDetails = ({ scope }: SoftwareDetailsProps) => {
  const {
    assetMrn,
    breadcrumbs,
    software,
    stats,
    cves,
    advisories,
    setCvePageItems,
    setAdvisoryPageItems,
    state,
  } = useSoftwareDetails({ scope });

  if (state.isLoading) {
    return <LoadingPage what="Software" />;
  }

  if (state.hasError || !software) {
    return <LoadingFailed what="Software" />;
  }

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Header
        id="software-header"
        icon={
          <DynamicIcon
            iconId={software.iconIDEnum}
            defaultIcon={PackageIcon}
            size="40px"
          />
        }
        title={software.name}
        created={software.createdAt}
        lastModified={software.updatedAt}
      />
      <SoftwareDescription
        software={software}
        stats={stats}
        assetMrn={assetMrn}
      />
      <SoftwareVulnerabilities
        cves={cves}
        setCvePageItems={setCvePageItems}
        totalCount={software?.cveSummaries?.totalCount ?? 0}
      />
      <SoftwareAdvisories
        advisories={advisories}
        totalCount={software?.advisorySummaries?.totalCount ?? 0}
        setAdvisoryPageItems={setAdvisoryPageItems}
      />
    </Box>
  );
};
