import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import { OrgData, SpaceData } from "~/lib/types";
import {
  ArrowForwardIcon,
  NavDrawerFleetIcon,
  RadarIcon,
} from "~/components/icons";
import { Clamp } from "./clamp/clamp";
import { Flex } from "~/components/Flex";
import { getGroupIcon } from "./grouping-button";
import { numberKFormat } from "~/lib/number-k-formatting";
import { formatGroupNames } from "~/lib/format-asset-list-names";

type SpaceCardEntry = {
  id: string;
  href: string;
  org?: Pick<OrgData, "name">;
  space: Pick<
    SpaceData,
    | "assetGroupStats"
    | "description"
    | "id"
    | "name"
    | "stats"
    | "priorityFindings"
  >;
  shared?: boolean;
};

export type SpaceItemCardProps = {
  entry: SpaceCardEntry;
};

export function SpaceItemCard({ entry }: SpaceItemCardProps) {
  let title = entry.space.name || entry.space.id;
  if (entry.shared && entry.org) {
    title = `${entry.org.name} / ${title}`;
  }

  const noDescription = "No description provided for this space";
  const descriptionIsEmpty = entry.space.description.length === 0;
  const description = descriptionIsEmpty
    ? noDescription
    : entry.space.description;

  const assetGroups =
    entry.space.assetGroupStats
      .flatMap((ag) => (ag.count > 0 ? ag : []))
      .sort((a, b) => b.count - a.count) || [];
  const maxCount = 6;
  const firstAssetGroups = assetGroups.slice(0, maxCount);
  const remainingAssetGroups = assetGroups.slice(maxCount);

  const previewGroupTypes = ["k8s", "azure", "aws", "github"] as const;

  const assetCount = entry.space.stats?.riskdistribution.total || 0;
  const findingsCount = entry.space.priorityFindings;
  const statItems = [
    {
      icon: NavDrawerFleetIcon,
      label: "Asset count",
      value: assetCount,
    },
    {
      icon: RadarIcon,
      label: "Priority findings",
      value: findingsCount,
    },
  ] as const;

  return (
    <Box
      component={Paper}
      sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Button
        className="header"
        component={RouterLink}
        to={entry.href}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          m: -1,
          textTransform: "none",
        }}
      >
        <Typography fontWeight={700}>{title}</Typography>
        <ArrowForwardIcon sx={{ color: "primary.main" }} />
      </Button>

      <Box
        className="description"
        sx={{
          display: "flex",
          alignItems: descriptionIsEmpty ? "center" : "flex-start",
          justifyContent: descriptionIsEmpty ? "center" : "flex-start",
          minHeight: (theme) => theme.spacing(6),
        }}
      >
        <Clamp lines={2}>
          <Typography
            sx={{
              color: "text.secondary",
              opacity: descriptionIsEmpty ? 0.5 : 1,
            }}
          >
            {description}
          </Typography>
        </Clamp>
      </Box>

      <Flex className="group-summaries" sx={{ flexDirection: "column" }}>
        {assetGroups.length > 0 && (
          <Flex sx={{ flexWrap: "nowrap", mx: -1 }}>
            {firstAssetGroups
              .slice(
                0,
                remainingAssetGroups.length > 0 ? maxCount - 1 : maxCount,
              )
              .map((ag) => (
                <Tooltip
                  key={ag.groupType}
                  title={formatGroupNames(ag)}
                  arrow
                  placement="top"
                >
                  <Button
                    key={ag.groupType}
                    sx={{
                      flexDirection: "column",
                      minWidth: `calc(100% / ${maxCount})`,
                    }}
                    component={RouterLink}
                    to={`/space/inventory?spaceId=${entry.space.id}&groupType=${ag.groupType}`}
                  >
                    {getGroupIcon(ag.groupType, { fontSize: "24px" })}
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      {ag.count}
                    </Typography>
                  </Button>
                </Tooltip>
              ))}
            {remainingAssetGroups.length > 0 && (
              <Button
                component={RouterLink}
                to={`/space/inventory?spaceId=${entry.space.id}`}
                sx={{
                  flexDirection: "column",
                  minWidth: `calc(100% / ${maxCount})`,
                  color: "text.secondary",
                  bgcolor: "background.light",
                  "&:hover": { bgcolor: "background.lighter" },
                }}
              >
                +{remainingAssetGroups.length + 1}
              </Button>
            )}
          </Flex>
        )}
        {assetGroups.length === 0 && (
          <Button
            component={RouterLink}
            to={`/space/integrations/add?spaceId=${entry.space.id}`}
            sx={{
              flexDirection: "column",
              textTransform: "none",
              bgcolor: "background.light",
              "&:hover": { bgcolor: "background.lighter" },
            }}
          >
            <Flex>
              {previewGroupTypes.map((groupType) => (
                <Flex
                  key={groupType}
                  sx={{
                    bgcolor: "background.lightest",
                    borderRadius: "50%",
                    border: "1px solid",
                    borderColor: "background.light",
                    mx: -0.3,
                    width: 24,
                    height: 24,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getGroupIcon(groupType, { fontSize: "14px" })}
                </Flex>
              ))}
            </Flex>
            <Typography
              sx={{ fontSize: 12, fontWeight: 700, color: "text.disabled" }}
            >
              No integrations added yet
            </Typography>
          </Button>
        )}
      </Flex>

      <Box className="stat-summaries">
        {statItems.map((item) => (
          <Flex
            key={item.label}
            className="stat-item"
            sx={{ alignItems: "center", gap: 0.5 }}
          >
            <item.icon fontSize="inherit" sx={{ color: "text.secondary" }} />
            <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
              {item.label}
            </Typography>
            <Typography sx={{ fontWeight: 700, ml: "auto" }}>
              {numberKFormat.format(item.value)}
            </Typography>
          </Flex>
        ))}
      </Box>
    </Box>
  );
}
