import { Box, Typography, Button, useTheme, Link, Grid2 } from "@mui/material";
import { motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Code } from "~/components/code";
import { Command } from "~/components/guides/components";
import {
  ChevronRightIcon,
  NoPermissionsIcon,
  OpenInNewIcon,
} from "~/components/icons";
import { TokenCustomizer } from "~/components/token-customizer";
import { motionAccordionVariants } from "~/constants/motion";
import { Space } from "~/lib/types";
import { ToggleOption } from "~/pages/integrations/components/ToggleOption/ToggleOption";
import { useToken } from "~/providers/token";
import { AllowDenyLists } from "~/components/Form/components/AllowDenyLists";
import { IamActions } from "~/lib/iam";
import { EmptyState } from "~/pages/inventory/components/EmptyState";

type AzureAutomatedFormProps = {
  defaultIntegrationName: string;
  space: Space;
  setView: React.Dispatch<React.SetStateAction<"setup" | "add policies">>;
  hasAutomatedDeploymentPermissions?: boolean;
};

export function AzureAutomatedForm({
  defaultIntegrationName,
  space,
  setView,
  hasAutomatedDeploymentPermissions,
}: AzureAutomatedFormProps) {
  const theme = useTheme();
  const { token } = useToken({
    ...(hasAutomatedDeploymentPermissions && {
      roles: [{ mrn: IamActions.ROLE_DEPLOYMENT_MANAGER_MRN }],
    }),
  });
  const [customOptions, setCustomOptions] = useState("");

  const { control, watch } = useForm({
    defaultValues: {
      scanVirtualMachines: false,
      scanAllSubscriptions: true,
      allowDenyList: "allow",
      subscriptionsList: "",
    },
  });

  const [
    scanVirtualMachines,
    scanAllSubscriptions,
    subscriptionsList,
    allowDenyList,
  ] = watch([
    "scanVirtualMachines",
    "scanAllSubscriptions",
    "subscriptionsList",
    "allowDenyList",
  ]);

  useEffect(() => {
    handleCustomOptions();
  }, [
    scanVirtualMachines,
    scanAllSubscriptions,
    subscriptionsList,
    allowDenyList,
  ]);

  const splitAndTrim = (string: string): string[] => {
    const replaceNewlinesRegex = new RegExp(/\r?\n|\r/g);
    return string
      .replace(replaceNewlinesRegex, ",")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x !== "");
  };

  const handleCustomOptions = () => {
    let options = "";
    if (scanVirtualMachines) {
      options += " --scan-vms";
    }
    if (!scanAllSubscriptions && subscriptionsList) {
      let formattedList = splitAndTrim(subscriptionsList);
      options += ` --${allowDenyList} "${formattedList}"`;
    }
    setCustomOptions(options);
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Automatically create a Mondoo Azure integration by pasting a command
          in Azure Cloud Shell.
          <br />
          To learn about automated vs. manual setup options and permissions
          required, read the{" "}
          <Link
            href="https://mondoo.com/docs/platform/infra/cloud/azure/overview/"
            target="_blank"
            rel="noopener"
          >
            Mondoo documentation
          </Link>
          .
        </Typography>
      </Grid2>
      {/* Step 1*/}
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Command
            number={1}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Choose scan targets
          </Command>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
            Control what Mondoo scans. To learn more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/infra/cloud/azure/overview/"
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
        </Grid2>
        <Grid2 container size={12} sx={{ ml: 6 }}>
          <Grid2 size={12}>
            <ToggleOption
              title="Scan virtual machines"
              description="Scan Azure VMs using RunCommand"
              formOptions={{
                name: "scanVirtualMachines",
                control,
              }}
            />
          </Grid2>
          <Grid2 container size={12} sx={{ pb: 2 }}>
            <ToggleOption
              title="Scan all subscriptions connected to the Directory (tenant) ID"
              description="Allow Mondoo to scan all subscriptions. Turn off to define an allow or deny list."
              formOptions={{
                name: "scanAllSubscriptions",
                control,
              }}
            />
            <Grid2
              size={12}
              component={motion.div}
              variants={motionAccordionVariants}
              initial={false}
              animate={!scanAllSubscriptions ? "open" : "closed"}
              transition={{
                duration: 0.35,
                staggerChildren: 0.25,
              }}
              sx={{ overflow: "hidden", mt: 1, ml: 2 }}
            >
              <Controller
                name="subscriptionsList"
                {...{ control }}
                render={({ field }) => (
                  <AllowDenyLists
                    radioName="allowDenyList"
                    field={field}
                    control={control}
                    allowCaption="Give Mondoo access to scan only these subscriptions"
                    denyCaption="Block Mondoo from scanning these subscriptions"
                    textareaCaption=""
                    isAllowSelected={allowDenyList === "allow"}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      {/* Step 2 */}
      <Grid2 size={12} sx={{ pb: 1 }}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Copy the installation command
        </Command>

        <Typography variant="body2" color="text.secondary" sx={{ my: 3 }}>
          You can replace the string following --integration-name with your
          desired integration name if you wish.
        </Typography>

        {hasAutomatedDeploymentPermissions && (
          <Fragment>
            <Code className="bash" copyButton>
              {`bash -c "$(curl -sSL https://install.mondoo.com/sh)"\ncnspec login -t '${token.value}'\ncnspec integrate azure --space ${space.id} --integration-name "${defaultIntegrationName}"` +
                `${customOptions}`}
            </Code>
            <Box mt={-1}>
              <TokenCustomizer />
            </Box>
          </Fragment>
        )}
        {/* If a user does not have permissions for the automated token, we show a permissions issue */}
        {!hasAutomatedDeploymentPermissions && (
          <Grid2 size={12} sx={{ pb: 1 }}>
            <EmptyState
              headline="Additional privileges required"
              icon={<NoPermissionsIcon sx={{ fontSize: 48 }} />}
              textContent=" Please contact an administrator."
            />
          </Grid2>
        )}
      </Grid2>

      {/* Step 3 */}
      <Grid2 pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Paste it into your Azure Cloud Shell
        </Command>
        <Box>
          <Typography variant="body2" color="text.secondary" sx={{ my: 3 }}>
            Paste the command into your target Azure Cloud Shell.
            <br />
            To learn more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/infra/cloud/azure/overview/"
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>

          <Button
            href="https://shell.azure.com"
            target="_blank"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
          >
            AZURE CLOUD SHELL
          </Button>
        </Box>
      </Grid2>

      <Grid2 size={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={() => setView("add policies")}
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
        >
          start scanning
        </Button>
      </Grid2>
    </Grid2>
  );
}
