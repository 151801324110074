import {
  ConditionType,
  SelectionType,
} from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import {
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionRatingOperator,
  WorkspaceSelectionConditionStringField,
  WorkspaceSelectionConditionStringOperator,
  WorkspaceSelectionInput,
} from "~/operations";
import { WorkspacesSelection } from "~/pages/space/Workspaces/types";

export const isConditionComplete = (condition: ConditionType) => {
  const conditionKeySelected = !!condition.formKey;
  const isOperatorSelected = !!condition.operator;
  const isValueSelected =
    (condition.stringCondition?.values || []).length > 0 ||
    (condition.intCondition?.values || []).length > 0 ||
    (condition.ratingCondition?.values || []).length > 0;

  return conditionKeySelected && isOperatorSelected && isValueSelected;
};

export const getIsIntKeySelected = (
  key: string,
): key is WorkspaceSelectionConditionIntField =>
  Object.values(WorkspaceSelectionConditionIntField).includes(
    key as WorkspaceSelectionConditionIntField,
  );

export const getIsStringKeySelected = (
  key: string,
): key is WorkspaceSelectionConditionStringField =>
  Object.values(WorkspaceSelectionConditionStringField).includes(
    key as WorkspaceSelectionConditionStringField,
  );

export const getIsRatingKeySelected = (
  key: string,
): key is WorkspaceSelectionConditionRatingField =>
  Object.values(WorkspaceSelectionConditionRatingField).includes(
    key as WorkspaceSelectionConditionRatingField,
  );

// Used to initialize form from API values
export function mapQueryBuilderAPIToFormValues(
  selections: WorkspacesSelection,
): Array<SelectionType> {
  return selections.map((selection) => {
    return {
      ...selection,
      conditions: selection.conditions.map((condition) => {
        return {
          operator: condition.operator,
          formKey: "",
          ...(condition.condition.__typename ===
            "WorkspaceSelectionStringCondition" && {
            formKey: condition.condition.stringField,
            stringCondition: {
              field: condition.condition.stringField,
              operator: condition.condition.stringOperator,
              values: condition.condition.stringValues.map(
                (values) => values.value,
              ),
            },
          }),
          ...(condition.condition.__typename ===
            "WorkspaceSelectionIntCondition" && {
            formKey: condition.condition.intField,
            intCondition: {
              field: condition.condition.intField,
              operator: condition.condition.intOperator,
              values: condition.condition.intValues,
            },
          }),
          ...(condition.condition.__typename ===
            "WorkspaceSelectionRatingCondition" && {
            formKey: condition.condition.ratingField,
            ratingCondition: {
              field: condition.condition.ratingField,
              operator: condition.condition.ratingOperator,
              values: condition.condition.ratingValues,
            },
          }),
        };
      }),
    };
  });
}

// Used to transform form values into API structure
export function mapFormValuesToQueryBuilderAPI(
  selections: Array<SelectionType>,
): Array<WorkspaceSelectionInput> {
  return selections.map((selection) => {
    return {
      ...selection,
      conditions: selection.conditions.map((condition) => {
        return {
          operator: condition.operator,
          ...(getIsStringKeySelected(condition.formKey)
            ? {
                stringCondition: {
                  field:
                    condition.formKey as WorkspaceSelectionConditionStringField,
                  operator: condition.stringCondition
                    ?.operator as WorkspaceSelectionConditionStringOperator,
                  values: (condition.stringCondition?.values || []).map((v) =>
                    String(v),
                  ),
                },
              }
            : getIsIntKeySelected(condition.formKey)
              ? {
                  intCondition: {
                    field:
                      condition.formKey as WorkspaceSelectionConditionIntField,
                    operator: condition.intCondition
                      ?.operator as WorkspaceSelectionConditionNumericOperator,
                    values: (condition.intCondition?.values || []).map((v) =>
                      Number(v),
                    ),
                  },
                }
              : {
                  ratingCondition: {
                    field:
                      condition.formKey as WorkspaceSelectionConditionRatingField,
                    operator: condition.ratingCondition
                      ?.operator as WorkspaceSelectionConditionRatingOperator,
                    values: (condition.ratingCondition?.values || []).map(
                      (v) => v,
                    ),
                  },
                }),
        };
      }),
    };
  });
}
