import { FieldLabel } from "~/pages/space/Workspaces/components/FormFields/FieldLabel";
import { Grid2 as Grid, TextField } from "@mui/material";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { Validations } from "~/lib/validations";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormRegister } from "react-hook-form";
import {
  CreateFormInput,
  TargetType,
} from "~/pages/space/Workspaces/components/FormFields/types";

type DisplayNameFieldProps = {
  errors: FieldErrors<{ name: string }>;
  register: UseFormRegister<CreateFormInput>;
  disabled?: boolean;
  target: TargetType;
};

export const DisplayNameField = ({
  errors,
  register,
  disabled = false,
  target,
}: DisplayNameFieldProps) => {
  return (
    <Grid size={{ xs: 12 }} container gap={1}>
      <FieldLabel
        id="display-name-input"
        label="Display name"
        caption={`Define a user-friendly name for this ${target} to use within the UI.`}
      />
      <TextField
        id="display-name-input"
        fullWidth
        placeholder="Display name"
        variant="outlined"
        slotProps={{
          input: {
            sx: { backgroundColor: "code.background" },
          },
        }}
        error={Boolean(errors.name)}
        helperText={
          Boolean(errors.name) && <ValidationMessage error={errors.name} />
        }
        disabled={disabled}
        {...register("name", {
          validate: {
            required: (value) => {
              if (
                value == null ||
                !value.match(Validations.nameInvalidLengthRegex)
              ) {
                return Validations.nameInvalidLengthErrorMsg;
              } else if (!value.match(Validations.nameInvalidCharactersRegex)) {
                return Validations.nameInvalidCharactersErrorMsg;
              }

              return true;
            },
          },
        })}
      />
    </Grid>
  );
};
