import React, { Fragment, useEffect } from "react";
import {
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { useSpaceSettingsOutletContext } from "../space-settings";
import { IamActions } from "~/lib/iam";
import { useViewer } from "~/providers/viewer";
import { useSnackbar } from "notistack";
import { getGraphQLErrors } from "~/components/ui-library";
import { IdField } from "~/pages/space/Workspaces/components/FormFields/IdField";
import { DisplayNameField } from "~/pages/space/Workspaces/components/FormFields/DisplayNameField";
import { DescriptionField } from "~/pages/space/Workspaces/components/FormFields/DescriptionField";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateFormInput } from "~/pages/space/Workspaces/components/FormFields/types";
import { capitalize, Grid2 } from "@mui/material";
import {
  GetWorkspaceDocument,
  GetWorkspacesDocument,
  useUpdateWorkspaceMutation,
} from "~/operations";
import { useScope } from "~/hooks/useScope";

type Props = {};

export function SpaceNameSettings({}: Props) {
  const { activeScope } = useScope();

  const { enqueueSnackbar } = useSnackbar();
  const { targetType, availablePermissions } = useSpaceSettingsOutletContext();

  const { updateSpace } = useViewer();
  const [updateWorkspace] = useUpdateWorkspaceMutation({
    refetchQueries: [GetWorkspaceDocument, GetWorkspacesDocument],
  });
  const updateScope =
    activeScope?.type === "space" ? updateSpace : updateWorkspace;

  const updateSpacePermission = availablePermissions?.includes(
    IamActions.CAPTAIN_UPDATESPACE,
  );

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<CreateFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: activeScope?.name,
      description: activeScope?.description,
      id: activeScope?.id,
    },
  });

  useEffect(() => {
    reset({
      id: activeScope?.id,
      name: activeScope?.name,
      description: activeScope?.description,
    });
  }, [activeScope]);

  const handleBlur: SubmitHandler<CreateFormInput> = async (params) => {
    const { name, description } = params;
    if (!isDirty) return;

    try {
      await updateScope({
        variables: {
          input: {
            mrn: activeScope?.mrn || "",
            name,
            description,
          },
        },
      });

      enqueueSnackbar(`${capitalize(targetType)} successfully updated`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        getGraphQLErrors({
          error,
          errorMessage: `Failed to update ${targetType}.`,
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Fragment>
      <ConfigurationPaper>
        <ConfigurationList title="Name and Description">
          <Grid2
            component="form"
            onBlur={handleSubmit(handleBlur)}
            container
            p={2}
            gap={3}
          >
            {activeScope?.type === "space" && (
              <IdField
                errors={errors}
                register={register}
                disabled
                target="space"
              />
            )}

            <DisplayNameField
              errors={errors}
              register={register}
              disabled={!updateSpacePermission}
              target={targetType}
            />

            <DescriptionField
              register={register}
              disabled={!updateSpacePermission}
              target={targetType}
            />
          </Grid2>
        </ConfigurationList>
      </ConfigurationPaper>
    </Fragment>
  );
}
