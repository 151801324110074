import { SmallDonutCard, SmallDonutData } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";

export function VulnerabilitiesDonutChart({
  scope,
  vulnerableAssetsSeverity,
  isLoading,
}: DonutChartCardProps & {
  vulnerableAssetsSeverity: SmallDonutData;
  isLoading: boolean;
}) {
  return (
    <SmallDonutCard
      to={`affected-assets?${scope?.params?.toString()}`}
      data-testid="vulnerable-assets-donut-chart"
      loading={isLoading}
      data={vulnerableAssetsSeverity}
      title="Vulnerable Assets"
      icon={<NavDrawerVulnerabilitiesIcon />}
    />
  );
}
