import { useEffect, useState } from "react";
import { Box, Paper, TextField, useTheme } from "@mui/material";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Controller, useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { Flex } from "~/components/Flex";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { GitlabIssueFormInput } from "~/pages/integrations/hosted-integrations/forms/gitlab-issues/types";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  space: Space;
  showAutoCreateSection: boolean;
};

export const CaseSettingsGitlabIntegration = ({
  integration,
  space,
  showAutoCreateSection,
}: CaseSettingsIntegrationProps) => {
  const theme = useTheme();
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getFieldState,
    formState: { errors },
  } = useForm<GitlabIssueFormInput>({
    defaultValues: {
      defaultProject: "",
      autoCloseTickets: false,
      autoCreateCases: false,
    },
    mode: "onChange",
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: GitlabIssueFormInput) => {
    const gitlabTicketingConfigurationOptions = {
      baseUrl: data.baseUrl || "",
      defaultProject: data.defaultProject || "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateTickets: data.autoCreateCases,
      token: "",
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemGitlab,
          configurationOptions: {
            gitlabTicketingConfigurationOptions,
          },
        },
      },
    });
  };

  const [watchAutoCreate, watchAutoClose] = watch([
    "autoCreateCases",
    "autoCloseTickets",
  ]);

  useEffect(() => {
    isInitialized && handleSubmit(onSubmit)();
  }, [watchAutoClose, watchAutoCreate]);

  const handleTextFieldBlur = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "GitlabTicketingConfigurationOptions")
      return;

    reset({
      baseUrl: configOptions.gitlabBaseUrl || "",
      defaultProject: configOptions.gitlabDefaultProject || "",
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateTickets,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          showAutoCreateSection={showAutoCreateSection}
          autoCreateSubField={
            <Box
              component={motion.div}
              initial={false}
              transition={{
                duration: 0.35,
                staggerChildren: 0.25,
              }}
              sx={{ overflow: "hidden" }}
            >
              <SubField
                configuration="Select a drift group and project"
                caption="Specify the GitLab group and project in which to create an issue for each new drift case."
                disabled={!watchAutoCreate}
                field={
                  <Flex flex="1 0 auto" sx={{ pl: 2 }} alignItems="flex-end">
                    <Controller
                      name="defaultProject"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onBlur={handleTextFieldBlur}
                          fullWidth
                          disabled={!watchAutoCreate}
                          sx={{
                            background: theme.palette.code.background,
                            borderRadius: 1,
                            color: "text.primary",
                            ...helperTextStyles,
                          }}
                          placeholder={"your-group/your-project ..."}
                          error={
                            getFieldState("defaultProject").isTouched &&
                            Boolean(errors.defaultProject)
                          }
                          helperText={
                            Boolean(errors.defaultProject) &&
                            getFieldState("defaultProject").isTouched && (
                              <ValidationMessage
                                error={errors.defaultProject}
                              />
                            )
                          }
                        />
                      )}
                    />
                  </Flex>
                }
              />
            </Box>
          }
        />
      </Paper>
    </Box>
  );
};
