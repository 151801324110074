import React, { useState } from "react";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { Box, Paper } from "@mui/material";
import { SoftwareTable } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable";
import { PackageScoresOrder, useGetAssetPackagesQuery } from "~/operations";
import { AssetSoftwarePackagesType } from "~/pages/inventory/components/AssetSoftware/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type AssetSoftwareDataProps = {
  scope: SpaceOrWorkspaceScope;
  handleSortClick: (field: string) => void;
  orderBy: PackageScoresOrder;
  assetMrn: string;
  packageScores: AssetSoftwarePackagesType | null | undefined;
  fetchMore: ReturnType<typeof useGetAssetPackagesQuery>["fetchMore"];
};

export const AssetSoftwareData = ({
  scope,
  handleSortClick,
  orderBy,
  assetMrn,
  fetchMore,
  packageScores,
}: AssetSoftwareDataProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const packages =
    packageScores?.edges
      ?.slice(pageItems.from, pageItems.to)
      .flatMap((e) => e.node ?? []) || [];

  return (
    <Box>
      <Paper sx={{ my: 3 }}>
        <SoftwareTable
          scope={scope}
          packages={packages}
          onSort={handleSortClick}
          orderBy={orderBy}
          assetMrn={assetMrn}
        />
        <Pagination
          fetchMore={fetchMore}
          pageInfo={packageScores?.pageInfo}
          totalCount={packageScores?.totalCount ?? 0}
          setPageItems={setPageItems}
        />
      </Paper>
    </Box>
  );
};
