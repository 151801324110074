import { Flex } from "~/components/Flex";
import {
  AddSelectionRow,
  SelectionRow,
} from "~/pages/space/Workspaces/components/QueryBuilder/SelectionRow";
import { useQueryBuilder } from "~/pages/space/Workspaces/components/QueryBuilder/hooks";

export type QueryBuilderProps = {
  isReadonly?: boolean;
  showFormActions?: boolean;
};

export const QueryBuilder = ({
  isReadonly = false,
  showFormActions,
}: QueryBuilderProps) => {
  const {
    fields,
    handleRemoveSelection,
    handleAddNewSelection,
    canAddSelection,
  } = useQueryBuilder();

  return (
    <Flex flexDirection="column" gap={3}>
      {fields.map((_field, selectionIndex) => (
        <SelectionRow
          selectionIndex={selectionIndex}
          onRemove={() => handleRemoveSelection(selectionIndex)}
          showRemoveSelectionButton={selectionIndex !== 0 && !isReadonly}
          key={selectionIndex}
          isReadonly={isReadonly}
        />
      ))}
      {!isReadonly && (
        <AddSelectionRow
          onClick={handleAddNewSelection}
          disabled={!canAddSelection}
          showFormActions={showFormActions}
        />
      )}
    </Flex>
  );
};
