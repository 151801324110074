import { useLayoutEffect, useState } from "react";
import { alpha, Box, Button, Divider, Grid2 } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Code } from "~/components/code";
import { EmptyState } from "~/components/empty-state/empty-state";
import { AssetCell } from "~/components/FirewatchRow/AssetCell";
import { FirstFoundCell } from "~/components/FirewatchRow/FirstFoundCell";
import { GenericAccordionRow } from "~/components/GenericTable/GenericAccordionRow";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { ArrowForwardIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { TextTitle } from "~/components/ui-library";
import { Space } from "~/lib/types";
import {
  DataQueryOrderField,
  GetQueryDetectedAssetsQuery,
  useGetQueryDetectedAssetsQuery,
} from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { NetworkStatus } from "@apollo/client";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

export type DetectedAssets = Extract<
  NonNullable<GetQueryDetectedAssetsQuery["dataQueries"]>,
  { __typename: "DataQueriesConnection" }
>["edges"];

type DetectedAssetsEdge = NonNullable<DetectedAssets>[number];
export type DetectedAssetsNode = NonNullable<DetectedAssetsEdge>["node"];

export type QueryDetectedAssetsProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  queryMrn: string;
  excludedTags?: string[];
};

export function QueryDetectedAssets({
  space,
  scope,
  queryMrn,
  excludedTags = [],
}: QueryDetectedAssetsProps) {
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [hasAssets, setHasAssets] = useState(false);

  const { data, error, loading, fetchMore, networkStatus } =
    useGetQueryDetectedAssetsQuery({
      variables: {
        first: 10,
        entityMrn: scope.mrn,
        filter: {
          queryMrn: queryMrn,
          queryTerms: searchFilters,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  useLayoutEffect(() => {
    if (data?.dataQueries?.__typename === "DataQueriesConnection") {
      if (data.dataQueries.totalCount > 0) {
        setHasAssets(true);
      } else {
        setHasAssets(false);
      }
    }
  }, [data]);

  // Only trigger on the first load up
  const firstLoad = networkStatus === NetworkStatus.loading;
  // All of these statuses mean that we've already loaded the data once
  // and we are now just updating it
  const afterInitialLoad = [
    NetworkStatus.ready,
    NetworkStatus.setVariables,
    NetworkStatus.fetchMore,
  ].includes(networkStatus);
  // If we are querying, we have already loaded the initial data
  // so we can limit where our loading state is shown
  const isQuerying = networkStatus === NetworkStatus.setVariables;

  if (firstLoad) {
    return (
      <Flex center sx={{ py: 10 }}>
        <Loading what="detected assets" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex center sx={{ py: 10 }}>
        <LoadingFailed what="detected assets" />
      </Flex>
    );
  }

  const detectedAssets =
    data?.dataQueries?.__typename === "DataQueriesConnection"
      ? data.dataQueries
      : null;

  const detectedEdges =
    data?.dataQueries?.__typename === "DataQueriesConnection"
      ? data.dataQueries.edges
      : [];

  const { pageInfo, totalCount } = detectedAssets || {};

  const afterInitialLoadAndHasAssets = afterInitialLoad && hasAssets;
  const notQueryingAndNoAssets =
    !isQuerying && detectedEdges && detectedEdges.length === 0;
  const notQueryingAndHasAssets =
    !isQuerying && detectedEdges && detectedEdges.length > 0;

  return (
    <Grid2 container>
      <Grid2 size={12} className="section">
        <Flex center gap={3} my={4} className="section-header">
          <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
            Detected Assets
          </TextTitle>
          <Box width={1}>
            <Divider />
          </Box>
        </Flex>
      </Grid2>
      <Grid2 size={12} className="section-content">
        {afterInitialLoadAndHasAssets && (
          <FilterBar
            searchId="detected_assets"
            placeholder="detected_assets"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
          />
        )}

        {isQuerying && (
          <Flex center sx={{ py: 10 }}>
            <Loading what="detected assets" />
          </Flex>
        )}

        {notQueryingAndNoAssets && (
          <EmptyState contentType={"detected-assets"} />
        )}

        {notQueryingAndHasAssets && (
          <Fragment>
            <GenericTable
              space={space}
              items={detectedEdges?.length}
              tableName="DETECTED_ASSETS"
              validSortFields={[
                DataQueryOrderField.AssetName,
                DataQueryOrderField.LastUpdated,
              ]}
              paginationProps={{
                totalCount: totalCount || 0,
                fetchMore,
                pageInfo,
                setPageItems,
              }}
            >
              {detectedEdges.slice(pageItems.from, pageItems.to).map((edge) => {
                const node = edge.node;
                const { asset, id, iconId, tags, value, mrn } = node || {};
                const assetId = asset?.mrn.split("/").pop();
                // Link the user to the asset, filtered to the specific query
                const assetUrl = `/space/inventory/${assetId}/data-queries?${scope.params}&queryTerms=${mrn}&queryMrn=${mrn}`;
                const filteredTags = tags?.filter(
                  (tag) => !excludedTags.includes(tag.key),
                );
                return (
                  <GenericAccordionRow
                    key={id}
                    AccordionProps={{
                      open: false,
                      colSpan: 2,
                      backgroundColor: alpha("#9147FF1A", 0.1),
                    }}
                    rowContent={
                      <Fragment>
                        <AssetCell
                          title={asset?.name}
                          tags={filteredTags}
                          iconId={iconId}
                        />
                        <FirstFoundCell discoveryTime={node?.lastUpdated} />
                      </Fragment>
                    }
                    accordionContent={
                      <Box sx={{ my: 3, mx: 6 }}>
                        <Code copyButton className="shell">
                          {JSON.stringify(value, null, 2)}
                        </Code>
                        <Flex justifyContent="right">
                          <Button
                            component={RouterLink}
                            to={assetUrl}
                            variant="text"
                            sx={{
                              color: "primary.light",
                            }}
                            endIcon={<ArrowForwardIcon color="inherit" />}
                          >
                            Go to asset
                          </Button>
                        </Flex>
                      </Box>
                    }
                  />
                );
              })}
            </GenericTable>
          </Fragment>
        )}
      </Grid2>
    </Grid2>
  );
}
