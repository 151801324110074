import { Tooltip } from "@mui/material";
import { TrophyIcon } from "~/components/icons";
import { Maybe, ScoreSource } from "~/operations";

type firstToFindTrophyProps = {
  sourceKey: string;
  sourceValue: number | Maybe<string> | undefined;
  firstFound: ScoreSource;
};

//The FirstToFindTrophy component is used to display a trophy icon when the source is the first to detect a vulnerability

export function FirstToFindTrophy({
  sourceKey,
  sourceValue,
  firstFound,
}: firstToFindTrophyProps) {
  if (sourceKey !== "firstDetectedAt") {
    return null;
  }

  if (firstFound[sourceKey] !== sourceValue) {
    return null;
  }

  return (
    <Tooltip
      title="First source to detect this vulnerability"
      arrow
      placement="top"
    >
      <TrophyIcon fontSize="small" />
    </Tooltip>
  );
}
