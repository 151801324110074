import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { AvailableIntegration } from "~/components/integrations/cards/available-integration-cards";
import {
  IntegrationCardsList,
  IntegrationCardsListProps,
} from "~/components/integrations/integration-cards-list";
import { Space } from "~/lib/types";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { isFeatureEnabled } from "~/login/features";
import { FilterBar } from "../compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { NoSearchResults } from "../Search";
import { RequestIntegrationBanner } from "~/components/integrations/request-integration-banner";
import { Stretcher } from "~/components/Stretcher/Stretcher";

type Props = {
  space: Space;
};

export function AvailableIntegrations({ space }: Props) {
  const { handleFilterQuery, searchFilters } = useSearch();

  const filteredBy = useCallback(
    (integrations: AvailableIntegration[], searchFilters: string[]) => {
      if (searchFilters.length === 0) return integrations;
      return integrations.filter((integration) => {
        return searchFilters.every((filter) => {
          return integration.title.toLowerCase().includes(filter.toLowerCase());
        });
      });
    },
    [searchFilters],
  );

  const filteredIntegrationLists = useMemo(() => {
    return availableIntegrations.flatMap((ai) => {
      const key = `${ai.title}-integration`;
      const integrations = filteredBy(ai.integrations, searchFilters);
      return integrations.length > 0
        ? [
            <IntegrationCardsList
              key={key}
              space={space}
              title={ai.title}
              recommended={ai.recommended}
              integrations={integrations}
            />,
          ]
        : [];
    });
  }, [searchFilters]);

  return (
    <Box component={motion.div} {...fadeInOut} key="available-integrations">
      <FilterBar
        searchId="available-integrations"
        placeholder="available_integrations"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
        mb={5}
      />

      <Box sx={{ mb: 24 }}>
        {filteredIntegrationLists.length > 0 && filteredIntegrationLists}
        {filteredIntegrationLists.length === 0 && (
          <NoSearchResults
            searchQuery={searchFilters.join(" + ")}
            name={space.name}
          />
        )}
      </Box>

      {/* Bottom Banner */}
      <Stretcher>
        <RequestIntegrationBanner />
      </Stretcher>
    </Box>
  );
}

// Integration Card Data
const recommendedIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/aws",
    title: "AWS",
    description:
      "Continuously scan AWS organizations, accounts, and instances for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/setup",
    title: "Workstation",
    description:
      "Deploy cnspec on your workstation to scan remote targets, perform live queries, and generate reports about your assets.",
  },
  {
    id: "mondoo/kubernetes",
    title: "Kubernetes",
    description:
      "Deploy the Mondoo Kubernetes Operator to enable continuous workload & node security scanning with optional scanning of new deployments.",
  },
];

const workstationIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/setup",
    title: "Workstation",
    description:
      "Deploy cnspec on your workstation to scan remote targets, perform live queries, and generate reports about your assets.",
  },
];

const serverEndpointSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/redhat",
    title: "Red Hat/Fedora",
    description:
      "Deploy cnspec on your Red Hat Linux (RHEL) and Fedora Linux based hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/windows",
    title: "Windows",
    description:
      "Deploy cnspec on your Windows hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/ubuntu",
    title: "Ubuntu/Debian",
    description:
      "Deploy cnspec on your Ubuntu & Debian Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/amazonlinux",
    title: "Amazon Linux",
    description:
      "Deploy cnspec on your Amazon Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/suse",
    title: "SUSE/openSUSE",
    description:
      "Deploy cnspec on your SUSE/openSUSE Linux hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
  {
    id: "mondoo/macos",
    title: "macOS",
    description:
      "Deploy cnspec on your macOS hosts to continuously scan them for known vulnerabilities, misconfigurations, and policy compliance.",
    flags: null,
  },
];

const cloudSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/aws",
    title: "AWS",
    description:
      "Continuously scan AWS organizations, accounts, and instances for misconfigurations and vulnerabilities.",
    flags: null,
  },
  {
    id: "mondoo/azure",
    title: "Azure",
    description:
      "Continuously scan Microsoft Azure subscriptions and resources for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/gcp",
    title: "GCP",
    description:
      "Continuously scan Google GCP organizations and projects for misconfigurations and vulnerabilities.",
  },
  {
    id: "mondoo/kubernetes",
    title: "Kubernetes",
    description:
      "Deploy the Mondoo Kubernetes Operator to enable continuous workload & node security scanning with optional scanning of new deployments.",
    flags: null,
  },
  {
    id: "mondoo/vmware",
    title: "VMware",
    description:
      "Deploy the Mondoo VMware Appliance to scan VMware vCenter Server, vSphere API, ESXi, and virtual machines.",
    flags: null,
  },
  {
    id: "mondoo/oci",
    title: "Oracle Cloud Infrastructure",
    description:
      "Continuously scan Oracle Cloud Infrastructure (OCI) tenancies and resources for misconfigurations and vulnerabilities.",
    flags: null,
  },
];

const thirdPartyDataIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/defender",
    title: "Microsoft Defender for Cloud",
    description:
      "Import security findings and vulnerabilities discovered with Microsoft Defender for Cloud.",
    flags: null,
  },
];

if (isFeatureEnabled("SentinelOne Integration")) {
  thirdPartyDataIntegrations.push({
    id: "mondoo/sentinelone",
    title: "SentinelOne",
    description:
      "Continuously scan Sentinel One subscriptions and resources for misconfigurations and vulnerabilities.",
    flags: null,
  });
}

const softwareSupplyChainIntegrations: AvailableIntegration[] = [
  {
    id: "hashicorp/packer",
    title: "Packer",
    description:
      "Use the Mondoo HashiCorp Packer provisioner to scan for policy compliance and vulnerabilities during an image build.",
    flags: null,
  },
  {
    id: "hashicorp/terraform",
    title: "Terraform",
    description:
      "Use Mondoo to run a static analysis of HashiCorp Terraform (HCL) code before it's run, or run Mondoo as a post-provisioning step in your Terraform workflows.",
    flags: null,
  },
  {
    id: "mondoo/credentials",
    title: "Generate Long-Lived Credentials",
    description:
      "Integrate Mondoo into any custom automated workflow by generating a long-lived credential file.",
    flags: null,
  },
];

const saasIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/ms365",
    title: "Microsoft 365",
    description:
      "Continuously scan Microsoft 365 resources for security misconfigurations.",
    flags: null,
  },
  {
    id: "mondoo/okta",
    title: "Okta",
    description:
      "Continuously scan your Okta domain including users, groups, policies, rules, applications and more.",
    flags: null,
  },
  {
    id: "mondoo/google_workspace",
    title: "Google Workspace",
    description:
      "Continuously scan your Google Workspace environment for security misconfigurations.",
    flags: null,
  },
  {
    id: "mondoo/gitlab",
    title: "GitLab",
    description:
      "Continuously scan your GitLab groups for security misconfigurations",
  },
  {
    id: "mondoo/github",
    title: "GitHub",
    description:
      "Continuously scan your GitHub organizations and repositories for security misconfigurations",
  },
  {
    id: "mondoo/slack",
    title: "Slack",
    description:
      "Continuously scan your Slack Teams for security misconfigurations",
    flags: null,
  },
];

const networkSecurityIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/host",
    title: "Domain/IP Address",
    description:
      "Continuously scan endpoints to evaluate domain TLS, SSL, HTTP, and HTTPS security",
    flags: null,
  },
  {
    id: "mondoo/shodan",
    title: "Shodan",
    description:
      "Continuously assess external risk for domains and IP addresses",
    flags: null,
  },
];

const ticketIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/ticket_system_jira",
    title: "Atlassian Jira",
    description:
      "To incorporate Mondoo into your Jira workflow, automatically create a Jira issue when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_email",
    title: "Ticketing via email",
    description:
      "To incorporate Mondoo into your regular tracking workflow, send email to your ticketing system when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_zendesk",
    title: "Zendesk",
    description:
      "To incorporate Mondoo into your Zendesk workflow, automatically create a Zendesk ticket when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_github",
    title: "GitHub Issues",
    description:
      "To incorporate Mondoo into your GitHub issue tracking workflow, automatically create a GitHub issue when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_gitlab",
    title: "GitLab Issues",
    description:
      "To incorporate Mondoo into your GitLab issue tracking workflow, automatically create a GitLab issue when you create a new case in the Mondoo Console.",
    flags: null,
  },
  {
    id: "mondoo/ticket_system_azure_devops",
    title: "Azure DevOps",
    description:
      "To incorporate Mondoo into your Azure DevOps issue tracking workflow, automatically create a Azure DevOps issue when you create a new case in the Mondoo Console.",
    flags: null,
  },
];

const cicdIntegrations: AvailableIntegration[] = [
  {
    id: "cicd/circleci",
    title: "CircleCI",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your CirceCI projects.",
    flags: null,
  },
  {
    id: "cicd/github",
    title: "GitHub Actions",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your GitHub Actions.",
    flags: null,
  },
  {
    id: "cicd/gitlab",
    title: "GitLab CI/CD",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your GitLab CI/CD pipelines.",
    flags: null,
  },
  {
    id: "cicd/azure",
    title: "Azure Pipelines",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your Azure Pipelines.",
    flags: null,
  },
  {
    id: "cicd/jenkins",
    title: "Jenkins",
    description:
      "Prevent insecure changes from leaving your development environment by integrating Mondoo with your Jenkins pipelines.",
    flags: null,
  },
];

const exportIntegrations: AvailableIntegration[] = [
  {
    id: "mondoo/bigquery",
    title: "BigQuery",
    description:
      "Continuously share scan results with Google BigQuery for storage, analysis, and reporting.",
    flags: null,
  },
  {
    id: "mondoo/snowflake",
    title: "Snowflake",
    description:
      "Continuously share scan results with Snowflake for storage, analysis, and reporting.",
    flags: null,
  },
  {
    id: "mondoo/amazons3",
    title: "Amazon S3",
    description: "Continuously export data to Amazon S3 in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/amazon-s3-compatible",
    title: "S3 Compatible Service",
    description:
      "Continuously export data to Amazon S3-compatible services in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/postgres",
    title: "PostgreSQL",
    description: "Continuously export data to a PostgreSQL database",
    flags: null,
  },
  {
    id: "mondoo/gcs_bucket",
    title: "GCP Cloud Storage Bucket",
    description:
      "Continuously export data to GCP Cloud Storage in JSONL or CSV format",
    flags: null,
  },
  {
    id: "mondoo/azure_blob",
    title: "Azure Blob Storage",
    description:
      "Continuously export data to Azure Blob Storage in JSONL or CSV format",
    flags: null,
  },
  isFeatureEnabled("CloudSQL")
    ? {
        id: "mondoo/cloudsql-postgresql",
        title: "Cloud SQL PostgreSQL",
        description: "Continuously export data to GCP Cloud SQL for PostgreSQL",
        flags: null,
      }
    : null,
].flatMap((x) => (x !== null ? [x] : []));

const chatOpsIntegrations: AvailableIntegration[] = [
  {
    id: "chat/msteams",
    title: "Microsoft Teams",
    description:
      "Send alert messages and notifications from Mondoo directly to Microsoft Teams.",
    flags: null,
  },
  {
    id: "chat/slack",
    title: "Slack",
    description:
      "Send alert messages and notifications from Mondoo directly to a Slack channel.",
    flags: null,
  },
  {
    id: "chat/telegram",
    title: "Telegram",
    description:
      "Send alert messages and notifications from Mondoo directly to Telegram.",
    flags: null,
  },
  {
    id: "chat/httppost",
    title: "Outbound Webhook",
    description:
      "Send alert messages and notifications from Mondoo as JSON-encoded data to any HTTP endpoint.",
    flags: null,
  },
];

type AvailableIntegrationList = Pick<
  IntegrationCardsListProps,
  "title" | "recommended" | "integrations"
>;

const availableIntegrations: AvailableIntegrationList[] = [
  {
    recommended: true,
    integrations: recommendedIntegrations,
  },
  {
    title: "Security Engineering",
    integrations: workstationIntegrations,
  },
  {
    title: "Server & Endpoint Security",
    integrations: serverEndpointSecurityIntegrations,
  },
  {
    title: "Cloud Security",
    integrations: cloudSecurityIntegrations,
  },
  isFeatureEnabled("Defender Integration")
    ? {
        title: "Third-Party Data",
        integrations: thirdPartyDataIntegrations,
      }
    : null,
  {
    title: "Supply Chain",
    integrations: softwareSupplyChainIntegrations,
  },
  {
    title: "SaaS",
    integrations: saasIntegrations,
  },
  {
    title: "Network Security",
    integrations: networkSecurityIntegrations,
  },
  {
    title: "CI/CD",
    integrations: cicdIntegrations,
  },
  {
    title: "Exports",
    integrations: exportIntegrations,
  },
  {
    title: "Ticket Systems",
    integrations: ticketIntegrations,
  },
  {
    title: "Chat Ops",
    integrations: chatOpsIntegrations,
  },
].flatMap((x) => (x !== null ? [x] : []));
