import { useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AzureConfigurationOptions,
  ClientIntegration,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  useCreateClientIntegrationMutation,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Flex } from "~/components/Flex";
import { useSnackbar } from "notistack";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { UpdateFlowData } from "~/pages/integrations/types";
import useGenerateIntegrationName from "~/pages/integrations/utils/useGenerateIntegrationName";
import {
  helperTextStyles,
  ValidationMessage,
  GUID_PATTERN,
  MATCHING_ID_ERROR_MESSAGE,
  ID_PLACEHOLDER,
} from "~/pages/integrations/validations/helpers";

type CloudFormInput = {
  integrationName: string;
  tenantId: string;
  disableList: boolean;
  listToEnable: "subscriptionsWhitelist" | "subscriptionsBlacklist";
  subscriptionsWhitelist: string; //comma separated list
  subscriptionsBlacklist: string; //comma separated list
  clientId: string;
  pemKey: string | null;
  scanVms: AzureConfigurationOptions["scanVms"];
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  tenantId: "",
  disableList: true,
  listToEnable: "subscriptionsWhitelist",
  subscriptionsWhitelist: "",
  subscriptionsBlacklist: "",
  clientId: "",
  pemKey: null,
  scanVms: false,
};

type AzureAutomatedFormProps = {
  defaultIntegrationName: string;
  space: Space;
  setView: React.Dispatch<React.SetStateAction<"setup" | "add policies">>;
  updateFlow: UpdateFlowData | undefined;
  setCreatedIntegrationMrn: React.Dispatch<
    React.SetStateAction<ClientIntegration["mrn"] | undefined>
  >;
};

export function AzureManualForm({
  defaultIntegrationName,
  space,
  setView,
  updateFlow,
  setCreatedIntegrationMrn,
}: AzureAutomatedFormProps) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [tenantId, clientId, disableList, listToEnable, pemKey] = watch([
    "tenantId",
    "clientId",
    "disableList",
    "listToEnable",
    "pemKey",
    "scanVms",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.createClientIntegration.integration) {
        setCreatedIntegrationMrn(data.createClientIntegration.integration.mrn);
      }
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow?.integration.configurationOptions?.__typename !==
        "AzureConfigurationOptions"
      )
        return;
      const disableList = !Boolean(
        updateFlow.integration.configurationOptions?.subscriptionsWhitelist ||
          updateFlow.integration.configurationOptions?.subscriptionsBlacklist,
      );
      const listToEnable = Boolean(
        updateFlow.integration.configurationOptions?.subscriptionsBlacklist,
      )
        ? "subscriptionsBlacklist"
        : "subscriptionsWhitelist";

      const subscriptionsWhitelist =
        updateFlow.integration.configurationOptions?.subscriptionsWhitelist?.join(
          ", ",
        );

      const subscriptionsBlacklist =
        updateFlow.integration.configurationOptions?.subscriptionsBlacklist?.join(
          ", ",
        );

      reset({
        integrationName: updateFlow.integration.name,
        tenantId: updateFlow.integration.configurationOptions?.tenantId,
        clientId: updateFlow.integration.configurationOptions?.clientId,
        disableList,
        listToEnable,
        subscriptionsWhitelist,
        subscriptionsBlacklist,
        scanVms: updateFlow.integration.configurationOptions?.scanVms,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    trigger("clientId");
  }, [tenantId]);

  useEffect(() => {
    trigger("tenantId");
  }, [clientId]);

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    if (!updateFlow && !data.pemKey) {
      throw new Error("You must supply a valid .pem certificate");
    }

    const splitAndTrim = (string: string): string[] => {
      const replaceNewlinesRegex = new RegExp(/\r?\n|\r/g);
      return string
        .replace(replaceNewlinesRegex, ",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x !== "");
    };

    const azureConfigurationOptions = {
      tenantId: data.tenantId,
      clientId: data.clientId,
      subscriptionsWhitelist:
        data.listToEnable === "subscriptionsWhitelist" &&
        data.subscriptionsWhitelist
          ? splitAndTrim(data.subscriptionsWhitelist)
          : [],
      subscriptionsBlacklist:
        data.listToEnable === "subscriptionsBlacklist" &&
        data.subscriptionsBlacklist
          ? splitAndTrim(data.subscriptionsBlacklist)
          : [],
      ...(updateFlow ? {} : { certificate: data.pemKey }),
      scanVms: data.scanVms,
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Azure,
              configurationOptions: {
                azureConfigurationOptions,
              },
            },
          },
        });
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/azure/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Azure,
              longLivedToken: false,
              configurationOptions: {
                azureConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("pemKey", await files[0].text());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Step 1 */}
      <Box pb={4}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Create an app registration in Microsoft Entra
        </Command>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            To integrate your Microsoft account with Mondoo, you must create a
            new app registration and give it the needed permissions. To learn
            how, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/infra/cloud/azure/azure-integration-scan-subscription/"
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>

          <Button
            href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
            target="_blank"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
          >
            Go to Microsoft Entra
          </Button>
        </Box>
      </Box>
      {/* Step 2 */}
      <Box pb={4}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Choose an integration name
        </Command>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Use a descriptive name that lets you easily identify the integration
            later.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>

      {/* step 3 */}
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Enter the application (client) ID
        </Command>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            This is the name you gave the app registration you created for
            Mondoo. Find it in the app details in{" "}
            <Link
              href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
              target="_blank"
              rel="noopener"
            >
              the Microsoft Entra admin console
            </Link>
            .
          </Typography>
          <Controller
            name="clientId"
            control={control}
            rules={{
              required: true,
              pattern: GUID_PATTERN,
              validate: {
                matchingIds: (value: CloudFormInput["clientId"]) =>
                  value !== tenantId || MATCHING_ID_ERROR_MESSAGE,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={ID_PLACEHOLDER}
                error={
                  getFieldState("clientId").isTouched &&
                  Boolean(errors.clientId)
                }
                helperText={
                  Boolean(errors.clientId) &&
                  getFieldState("clientId").isTouched && (
                    <ValidationMessage error={errors.clientId} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>
      {/* step 4 */}
      <Box pb={4}>
        <Command
          number={4}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Enter the directory (tenant) ID
        </Command>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Find it in the app details in{" "}
            <Link
              href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
              target="_blank"
              rel="noopener"
            >
              the Microsoft Entra admin console
            </Link>
            .
          </Typography>
          <Controller
            name="tenantId"
            control={control}
            rules={{
              required: true,
              pattern: GUID_PATTERN,
              validate: {
                matchingIds: (value: CloudFormInput["tenantId"]) =>
                  value !== clientId || MATCHING_ID_ERROR_MESSAGE,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={ID_PLACEHOLDER}
                error={
                  getFieldState("tenantId").isTouched &&
                  Boolean(errors.tenantId)
                }
                helperText={
                  Boolean(errors.tenantId) &&
                  getFieldState("tenantId").isTouched && (
                    <ValidationMessage error={errors.tenantId} />
                  )
                }
              />
            )}
          />
        </Box>
        {/* Allow + Denylist */}
        <Grid container sx={{ pt: 3, px: 3 }}>
          <Grid item xs>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography color="text.secondary" fontWeight={700}>
                Scan all subscriptions connected to the directory (tenant) ID
              </Typography>
            </Box>
            <Typography
              color="text.secondary"
              sx={{ fontSize: (theme) => theme.spacing(1.5) }}
            >
              This lets Mondoo scan all subscriptions. Turn it off to define an
              allow list or a deny list.
            </Typography>
          </Grid>
          <Grid item>
            <Controller
              name="disableList"
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ pl: 3 }}>
            <Controller
              name="listToEnable"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="subscriptionsWhitelist"
                    control={<Radio />}
                    label="Allow list"
                    disabled={disableList}
                  />
                  {/* Allow */}
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ pl: 3.5, mt: -1, mb: 2 }}
                    >
                      Specify the subscriptions to give Mondoo access to. Type
                      each subscription name on a new line:
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: 1,
                          sm: "80%",
                          md: "70%",
                        },
                      }}
                    >
                      <Controller
                        name="subscriptionsWhitelist"
                        control={control}
                        render={({ field }) => (
                          <TextareaAutosize
                            aria-label="Allowlist"
                            {...field}
                            disabled={
                              disableList ||
                              listToEnable !== "subscriptionsWhitelist"
                            }
                            minRows={3}
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              minWidth: "100%",
                              padding: "16px",
                              background: theme.palette.code.background,
                              borderRadius: "4px",
                              color:
                                disableList ||
                                listToEnable !== "subscriptionsWhitelist"
                                  ? theme.palette.text.disabled
                                  : theme.palette.text.primary,
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  {/* Deny */}
                  <Box>
                    <FormControlLabel
                      value="subscriptionsBlacklist"
                      control={<Radio />}
                      label="Deny list"
                      disabled={disableList}
                    />

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ pl: 3.5, mt: -1, mb: 2 }}
                    >
                      Specify the subscriptions for Mondoo to skip. Type each
                      subscription name on a new line:
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: 1,
                          sm: "80%",
                          md: "70%",
                        },
                      }}
                    >
                      <Controller
                        name="subscriptionsBlacklist"
                        control={control}
                        render={({ field }) => (
                          <TextareaAutosize
                            aria-label="Denylist"
                            {...field}
                            disabled={
                              disableList ||
                              listToEnable !== "subscriptionsBlacklist"
                            }
                            minRows={3}
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              minWidth: "100%",
                              padding: "16px",
                              background: theme.palette.code.background,
                              borderRadius: "4px",
                              color:
                                disableList ||
                                listToEnable !== "subscriptionsBlacklist"
                                  ? theme.palette.text.disabled
                                  : theme.palette.text.primary,
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {/* When in the updateFlow, we do not want to update the certificate */}
      {/* step 5 */}
      {!updateFlow && (
        <Box pb={4}>
          <Command
            number={5}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Provide your certificate & private key
          </Command>
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, mt: 2 }}
            >
              Upload a valid PEM file containing your certificate and private
              key. To learn more, read the{" "}
              <Link
                href="https://mondoo.com/docs/platform/infra/cloud/azure/manual-reg-path/#step-f-upload-the-application-certificate"
                target="_blank"
                rel="noopener"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>
            <AnimatePresence mode="wait">
              {!pemKey ? (
                <Box key="dropzone" component={motion.div} {...fadeInOut}>
                  <Dropzone
                    className="mondoo-dropzone-area"
                    accept={{
                      "application/x-x509-ca-cert": [".pem"],
                      "application/x-x509-user-cert": [".pem"],
                      "application/x-pkcs12": [".pem"],
                      "application/x-pem-file": [".pem"],
                    }}
                    multiple={false}
                    showAlerts={["error"]}
                    onDropAccepted={onDropAccepted}
                  >
                    <DropzoneText>
                      Drag and drop your .pem file here.
                    </DropzoneText>
                  </Dropzone>
                </Box>
              ) : (
                <Box
                  key="success"
                  component={motion.div}
                  {...fadeInOut}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6">
                      Successfully loaded certificate
                    </Typography>
                    <GreenCheckCircleIcon fontSize="large" sx={{ ml: 2 }} />
                  </Box>

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setValue("pemKey", "")}
                  >
                    Remove certificate
                  </Button>
                </Box>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      )}
      <Box pb={4}>
        <Command
          number={6}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Scan VMs
        </Command>

        <Flex alignItems="center" justifyContent="space-between">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Scan Azure VMs using RunCommand.
          </Typography>
          <Controller
            name="scanVms"
            control={control}
            render={({ field }) => (
              <Switch {...field} checked={field.value || false} size="small" />
            )}
          />
        </Flex>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          // setValue doesn't trigger the required validation so we're doing it manually here
          disabled={updateFlow ? !isValid : !isValid || !pemKey}
        >
          {updateFlow ? "update configuration" : "start scanning"}
        </Button>
      </Box>
    </form>
  );
}
