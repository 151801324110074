import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link } from "@mui/material";
import { HomeIcon } from "~/components/icons";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  scope: SpaceOrWorkspaceScope;
  trail: ReactNode[];
};

export function CicdBreadcrumbs({ scope, trail = [] }: Props) {
  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    ...trail,
  ];

  return (
    <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
      {breadcrumbs}
    </Breadcrumbs>
  );
}
