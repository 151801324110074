import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ArrowForwardIcon } from "~/components/icons";
import { Clamp } from "../../../../../components/clamp/clamp";
import { Flex } from "~/components/Flex";
import { getGroupIcon } from "../../../../../components/grouping-button";
import { numberKFormat } from "~/lib/number-k-formatting";

export type WorkspaceCardStatItem = {
  icon: typeof SvgIcon;
  label: string;
  value: number;
};

export type WorkspaceCardAssetGroup = {
  href: string;
  groupType: string;
  count: number;
};

export type WorkspaceCardProps = {
  title: string;
  href: string;
  description?: string;
  assetGroups?: WorkspaceCardAssetGroup[];
  maxAssetGroups?: number;
  remainingAssetGroupsHref?: string;
  statItems?: WorkspaceCardStatItem[];
};

export function WorkspaceCard({
  title,
  href,
  description = "",
  assetGroups = [],
  maxAssetGroups = 6,
  remainingAssetGroupsHref,
  statItems = [],
}: WorkspaceCardProps) {
  const noDescription = "No description provided for this workspace";
  const descriptionIsEmpty = description.length === 0;
  const descriptionText = descriptionIsEmpty ? noDescription : description;

  const firstAssetGroups = assetGroups.slice(0, maxAssetGroups);
  const remainingAssetGroups = assetGroups.slice(maxAssetGroups);

  return (
    <Box
      className="workspace-card"
      component={Paper}
      sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Button
        className="header"
        component={RouterLink}
        to={href}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          m: -1,
          textTransform: "none",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography fontWeight={700}>{title}</Typography>
        <ArrowForwardIcon sx={{ color: "primary.main" }} />
      </Button>

      {assetGroups.length > 0 && (
        <Flex
          className="group-summaries"
          sx={{ flexDirection: "column", mt: -1 }}
        >
          <Flex sx={{ flexWrap: "nowrap", mx: -0.5 }}>
            {firstAssetGroups
              .slice(
                0,
                remainingAssetGroups.length > 0
                  ? maxAssetGroups - 1
                  : maxAssetGroups,
              )
              .map((ag) => (
                <IconButton
                  key={ag.groupType}
                  size="small"
                  sx={{
                    p: 0.5,
                  }}
                  component={RouterLink}
                  to={ag.href}
                >
                  {getGroupIcon(ag.groupType, { fontSize: "24px" })}
                </IconButton>
              ))}
            {remainingAssetGroups.length > 0 && (
              <IconButton
                size="small"
                {...(remainingAssetGroupsHref
                  ? {
                      component: RouterLink,
                      to: remainingAssetGroupsHref,
                    }
                  : {
                      disabled: true,
                    })}
                sx={{
                  minWidth: 32,
                  color: "text.secondary",
                  bgcolor: "background.light",
                  fontSize: 12,
                  "&:hover": { bgcolor: "background.lighter" },
                }}
              >
                +{remainingAssetGroups.length + 1}
              </IconButton>
            )}
          </Flex>
        </Flex>
      )}

      <Box
        className="description"
        sx={{
          display: "flex",
          alignItems: descriptionIsEmpty ? "center" : "flex-start",
          justifyContent: descriptionIsEmpty ? "center" : "flex-start",
          minHeight: (theme) => theme.spacing(6),
        }}
      >
        <Clamp lines={2}>
          <Typography
            sx={{
              color: "text.secondary",
              opacity: descriptionIsEmpty ? 0.5 : 1,
            }}
          >
            {descriptionText}
          </Typography>
        </Clamp>
      </Box>

      <Box className="stat-summaries">
        {statItems.map((item) => (
          <Flex
            key={item.label}
            className="stat-item"
            sx={{ alignItems: "center", gap: 0.5 }}
          >
            <item.icon fontSize="inherit" sx={{ color: "text.secondary" }} />
            <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
              {item.label}
            </Typography>
            <Typography sx={{ fontWeight: 700, ml: "auto" }}>
              {numberKFormat.format(item.value)}
            </Typography>
          </Flex>
        ))}
      </Box>
    </Box>
  );
}
