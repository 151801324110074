import { Route, Routes } from "react-router-dom";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { VulnerabilitiesPage } from "~/pages/vulnerabilities";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Software } from "~/pages/space/software/Software/Software";
import { VulnerabilitiesDashboard } from "~/pages/space/Dashboards/components/VulnerabilitiesDashboard";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";

export type VulnerabilitiesRoutesProps = {
  scope: SpaceOrWorkspaceScope;
  spaceScope: SpaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function VulnerabilitiesRoutes({
  scope,
  spaceScope,
  availablePermissions,
}: VulnerabilitiesRoutesProps) {
  return (
    <Routes>
      <Route
        element={
          <VulnerabilitiesPage availablePermissions={availablePermissions} />
        }
      >
        <Route
          index
          element={
            <VulnerabilitiesDashboard
              space={spaceScope}
              spaceScope={spaceScope}
              scope={scope}
            />
          }
        />

        <Route
          path="cves"
          element={
            <FirewatchPage
              {...{
                space: spaceScope,
                scope,
                pageType: AggregateScoreType.Vulnerability,
              }}
            />
          }
        />

        <Route
          path="advisories"
          element={
            <FirewatchPage
              {...{
                space: spaceScope,
                scope,
                pageType: AggregateScoreType.Advisory,
              }}
            />
          }
        />
        <Route
          path="affected-assets"
          element={
            <FirewatchPage
              {...{
                space: spaceScope,
                scope,
                pageType: AggregateScoreType.Asset,
              }}
            />
          }
        />

        <Route path="software">
          <Route
            index
            element={
              <FirewatchPage
                {...{
                  space: spaceScope,
                  scope,
                  pageType: AggregateScoreType.Software,
                }}
              />
            }
          />
          <Route
            path=":softwareId"
            element={<Software {...{ space: spaceScope, scope }} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}
