import { IamActions } from "~/lib/iam";
import {
  ClientIntegrationType,
  TestIamActionsQuery,
  useGetTicketingIntegrationsQuery,
  useLoadSpaceQuery,
  useProcessCaseEventsMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { Box } from "@mui/material";
import { CaseSettingsJiraIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsJiraIntegration";
import { CreateTicketingIntegration } from "~/components/cases/components/CasesPage/components";
import { isFeatureEnabled } from "~/login/features";
import { LoadingButton } from "~/components/loading-button";
import { Refresh } from "@mui/icons-material";
import { Flex } from "~/components/Flex";
import { useSnackbar } from "notistack";
import { CaseSettingsGeneral } from "~/components/cases/components/CaseSettings/CaseSettingsGeneral";
import { CaseSettingsIntegrationBox } from "~/components/cases/components/CaseSettings/CaseSettingsIntegrationBox";
import { mapIntegrationType } from "~/lib/integrations";
import { CaseSettingsEmailIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsEmailIntegration";
import { CaseSettingsZendeskIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsZendeskIntegration";
import { CaseSettingsGithubIntegration } from "./CaseSettingsGithubIntegration";
import { CaseSettingsGitlabIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsGitlabIntegration";
import { CaseSettingsAzureIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsAzureIntegration";

type CaseSettingsProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CaseSettings = ({
  space,
  availablePermissions,
}: CaseSettingsProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useLoadSpaceQuery({ variables: { spaceMrn: space.mrn } });

  const isAutoCreateEnabled =
    data?.space?.settings?.casesConfiguration.autoCreate;

  const hasIntegrationReadPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_LIST,
  );

  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasIntegrationReadPermission,
  });

  const [processCaseEvents, { loading: processCaseEventsLoading }] =
    useProcessCaseEventsMutation();

  const integrations = (
    ticketingIntegrationsData?.ticketingIntegrations?.integrations || []
  ).flatMap((a) => a ?? []);

  const handleProcessClick = async () => {
    if (!isFeatureEnabled("Developer Tools")) return;

    await processCaseEvents({
      variables: {
        input: {
          scopeMrn: space.mrn,
        },
      },
    });
    enqueueSnackbar("Successfully processed case events.", {
      variant: "success",
    });
  };

  if (integrations.length === 0) {
    return (
      <CreateTicketingIntegration
        space={space}
        hasIntegrationCreatePermission={hasIntegrationCreatePermission}
      />
    );
  }

  return (
    <Box>
      {isFeatureEnabled("Developer Tools") && (
        <Flex justifyContent="flex-end">
          <LoadingButton
            loading={processCaseEventsLoading}
            buttonText="Process Case Events"
            variant="contained"
            color="primary"
            startIcon={<Refresh />}
            onClick={handleProcessClick}
            disabled={processCaseEventsLoading}
            data-name="danger-zone-delete-button"
            sx={{ mb: 4 }}
          />
        </Flex>
      )}

      <Box mb={3}>
        <CaseSettingsGeneral space={space} />
      </Box>

      <Flex flexDirection="column" gap={3}>
        {[
          ClientIntegrationType.TicketSystemJira,
          ClientIntegrationType.TicketSystemEmail,
          ClientIntegrationType.TicketSystemZendesk,
          ClientIntegrationType.TicketSystemGithub,
          ClientIntegrationType.TicketSystemGitlab,
          ClientIntegrationType.TicketSystemAzureDevops,
        ]
          .flatMap((x) => x ?? [])
          .map((type) => {
            const integrationsByType = integrations.filter(
              (i) => i.type === type,
            );
            return (
              <Flex flexDirection="column" gap={3}>
                {integrationsByType.map((integration) => {
                  const href = `/space/integrations/${encodeURIComponent(
                    mapIntegrationType(integration?.type || ""),
                  )}/${integration?.mrn.split("/").pop()}?spaceId=${space.id}`;

                  return (
                    <CaseSettingsIntegrationBox
                      integrationName={integration.name}
                      href={href}
                      key={integration.mrn}
                    >
                      {integration.type ===
                        ClientIntegrationType.TicketSystemJira && (
                        <CaseSettingsJiraIntegration
                          key={integration.mrn}
                          integration={integration}
                          space={space}
                          showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                        />
                      )}
                      {integration.type ===
                        ClientIntegrationType.TicketSystemEmail &&
                        integration?.configurationOptions?.__typename ===
                          "EmailConfigurationOptions" && (
                          <CaseSettingsEmailIntegration
                            key={integration.mrn}
                            isRecipientsEmpty={
                              integration?.configurationOptions?.recipients
                                ?.length === 0
                            }
                            recipients={
                              integration?.configurationOptions?.recipients
                            }
                            integration={integration}
                            space={space}
                            showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                          />
                        )}
                      {integration.type ===
                        ClientIntegrationType.TicketSystemZendesk && (
                        <CaseSettingsZendeskIntegration
                          key={integration.mrn}
                          integration={integration}
                          space={space}
                          showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                        />
                      )}
                      {integration.type ===
                        ClientIntegrationType.TicketSystemGithub && (
                        <CaseSettingsGithubIntegration
                          key={integration.mrn}
                          integration={integration}
                          space={space}
                          showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                        />
                      )}
                      {integration.type ===
                        ClientIntegrationType.TicketSystemGitlab && (
                        <CaseSettingsGitlabIntegration
                          key={integration.mrn}
                          integration={integration}
                          space={space}
                          showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                        />
                      )}
                      {integration.type ===
                        ClientIntegrationType.TicketSystemAzureDevops && (
                        <CaseSettingsAzureIntegration
                          key={integration.mrn}
                          integration={integration}
                          space={space}
                          showAutoCreateSection={Boolean(isAutoCreateEnabled)}
                        />
                      )}
                    </CaseSettingsIntegrationBox>
                  );
                })}
              </Flex>
            );
          })}
      </Flex>
    </Box>
  );
};
