import { Fragment, useState } from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import {
  Box,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EmptySection } from "~/components/vulnerabilities";
import {
  OrderDirection,
  PackageScoresOrderField,
  RiskFactorStats,
  useGetPackageScoresQuery,
} from "~/operations";
import { DataTable } from "~/components/data-table";
import { Link as RouterLink } from "react-router-dom";
import { Impact } from "~/components/impact";
import { Clamp } from "~/components/clamp/clamp";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Format, FormatRelativeDate } from "~/lib/date";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { Loading } from "~/components/loading";
import { Flex } from "~/components/Flex";
import { trimSoftwareMrnVersion } from "~/pages/space/software/Software/utils";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { useAssetContextualLink } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type SoftwareAssetsProps = {
  scope: SpaceOrWorkspaceScope;
  findingMrn: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  version: string | null | undefined;
  softwareTitle: string | undefined;
};

export const SoftwareAssets = ({
  scope,
  findingMrn,
  riskFactors,
  version,
  softwareTitle,
}: SoftwareAssetsProps) => {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { data, error, loading, fetchMore } = useGetPackageScoresQuery({
    variables: {
      first: 10,
      orderBy: {
        direction: OrderDirection.Desc,
        field: PackageScoresOrderField.Score,
      },
      input: {
        findingMrn: version ? trimSoftwareMrnVersion(findingMrn) : findingMrn,
        entityMrn: scope.mrn,
        packageVersion: version,
      },
    },
  });

  const assets =
    data?.packageScores?.__typename === "PackageScores"
      ? data?.packageScores?.edges
      : undefined;

  const totalCount =
    data?.packageScores?.__typename === "PackageScores"
      ? data?.packageScores.totalCount
      : 0;

  const pageInfo =
    data?.packageScores?.__typename === "PackageScores"
      ? data?.packageScores.pageInfo
      : undefined;

  if (loading) {
    return (
      <Flex justifyContent="center" width="100%" mt={3}>
        <Loading what="Assets" />
      </Flex>
    );
  }

  return (
    <Grid id="software-assets" item xs={12}>
      <SectionHeading heading="Assets" />
      {(assets?.length || 0) > 0 && !error ? (
        <Fragment>
          <DataTable id="check-affected-assets">
            <TableHead>
              <TableRow>
                <TableCell>Risk Score</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Risk Factors</TableCell>
                <TableCell>Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets?.slice(pageItems.from, pageItems.to).map((asset) => {
                const assetId = (asset?.node?.assetMrn || "").split("/").pop();

                const { contextUrl } = useAssetContextualLink({
                  assetId: assetId || "",
                  scopeParams: scope.params,
                  type: AssetContextualLinkType.Software,
                  contextId: softwareTitle || "",
                });

                return (
                  <TableRow
                    key={asset?.node?.assetName}
                    component={RouterLink}
                    to={contextUrl}
                  >
                    <TableCell>
                      <Impact impact={asset?.node?.score || 0} />
                    </TableCell>
                    <TableCell>
                      <Clamp>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 14,
                            gap: 1,
                            fontWeight: 700,
                          }}
                        >
                          {asset?.node?.assetName}
                        </Typography>
                      </Clamp>
                      {asset?.node?.platform && (
                        <Box display="flex" gap={1} mt={1}>
                          <Box display="flex" gap={1} mt={1}>
                            <FirewatchChip
                              tag={{
                                key: "Platform",
                                value: `${asset?.node?.platform}`,
                                __typename: "Tag",
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      <RiskFactorsIcons
                        riskFactors={riskFactors}
                        shouldLimit={false}
                      />
                    </TableCell>
                    <TableCell>
                      {asset?.node?.lastUpdated && (
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Typography fontSize={14}>
                            {FormatRelativeDate(
                              String(asset?.node?.lastUpdated),
                            )}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            fontSize={12}
                          >
                            {Format(asset?.node?.lastUpdated)}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </DataTable>
          <Pagination
            fetchMore={fetchMore}
            totalCount={totalCount || 0}
            pageInfo={pageInfo}
            setPageItems={setPageItems}
          />
        </Fragment>
      ) : (
        <EmptySection
          id="software-assets-empty"
          text="There are currently no assets running this software"
        />
      )}
    </Grid>
  );
};
