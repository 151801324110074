import {
  AddConditionRow,
  ConditionRow,
} from "~/pages/space/Workspaces/components/QueryBuilder/Condition";
import { Flex } from "~/components/Flex";
import { ButtonProps, Paper, Typography } from "@mui/material";
import { SelectionRowHeader } from "~/pages/space/Workspaces/components/QueryBuilder/SelectionRow/SelectionRowHeader";
import { useSelectionRow } from "~/pages/space/Workspaces/components/QueryBuilder/SelectionRow/hooks";

type SelectionRowProps = {
  selectionIndex: number;
  onRemove: ButtonProps["onClick"];
  showRemoveSelectionButton: boolean;
  isReadonly: boolean;
};

export const SelectionRow = ({
  selectionIndex,
  onRemove,
  showRemoveSelectionButton,
  isReadonly,
}: SelectionRowProps) => {
  const {
    canAddCondition,
    handleAddCondition,
    handleRemoveCondition,
    fields,
    getConditionError,
  } = useSelectionRow({
    selectionIndex,
  });

  return (
    <Paper
      component={Flex}
      flexDirection="column"
      gap={2}
      sx={{
        p: 3,
        bgcolor: "background.light",
        pt: isReadonly ? 2 : 3,
      }}
    >
      <SelectionRowHeader
        showRemoveSelectionButton={showRemoveSelectionButton}
        selectionIndex={selectionIndex}
        onRemove={onRemove}
      />
      <Flex gap={4} flexWrap="wrap" flexDirection="column">
        {fields.map((field, conditionIndex) => {
          return (
            <Flex flexDirection="column" key={field.id}>
              <ConditionRow
                conditionIndex={conditionIndex}
                selectionIndex={selectionIndex}
                onRemove={() => handleRemoveCondition(conditionIndex)}
                isReadonly={isReadonly}
              />
              {getConditionError(conditionIndex) && (
                <Typography
                  variant="body2"
                  component="span"
                  color="error"
                  ml={12}
                  p={0.5}
                >
                  {getConditionError(conditionIndex)}
                </Typography>
              )}
            </Flex>
          );
        })}
      </Flex>
      {!isReadonly && (
        <AddConditionRow
          disabled={!canAddCondition}
          onClick={handleAddCondition}
        />
      )}
    </Paper>
  );
};
