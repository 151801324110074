import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
} from "@mui/material";
import { Command, DialogContainer, InfoText, TitleArea } from "../components";
import { Code } from "~/components/code";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";
import { useFindNewAwsAssetLazyQuery } from "~/operations";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  onClose: any;
  scope: SpaceOrWorkspaceScope | undefined;
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const StepFive = ({ nav, onClose, scope }: Props) => {
  const [failure, setFailure] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [findNewAwsAsset] = useFindNewAwsAssetLazyQuery({
    variables: { scopeMrn: scope?.mrn || "" },
    fetchPolicy: "network-only",
  });

  const determineReady = () => {
    setFailure(false);
    setIsPolling(true);
    findNewAwsAsset()
      .then((result) => {
        const hasAwsAsset =
          result?.data?.assets?.edges?.some(
            (edge) => edge.node?.platform?.name === "aws",
          ) ?? false;

        // adding a brief delay to make it feel like some heavy lifting is happening
        setTimeout(() => {
          if (hasAwsAsset) {
            nav.next();
          } else {
            setFailure(true);
            setIsPolling(false);
          }
        }, 3000);
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to contact mondoo`, { variant: "error" });
        setFailure(true);
        setIsPolling(false);
      });
  };

  return (
    <DialogContainer>
      <TitleArea title="AWS Setup" onClose={onClose} />

      <DialogContent sx={{ p: 0, mt: 4 }}>
        <Box display="flex" flexDirection="column" height={1}>
          <Box>
            <Command
              number={4}
              tooltip={
                <Link
                  href="https://mondoo.com/docs/cnspec/cnspec-aws/cnspec-aws-cloudshell/#step-4-scan"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo Docs - Assess AWS Security from AWS CloudShell
                </Link>
              }
            >
              Run your first scan
            </Command>
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            flex="1"
            justifyContent="center"
          >
            <Box sx={{ minWidth: "0" }}>
              <Code copyButton className="shell">
                cnspec scan aws
              </Code>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 0, pt: 0, justifyContent: "space-between" }}>
        <Button
          onClick={nav.prev}
          variant="outlined"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Back
        </Button>

        <Button
          disabled={isPolling}
          onClick={determineReady}
          variant="contained"
          color="secondary"
          endIcon={isPolling ? null : <ChevronRightIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          {isPolling ? (
            <Box
              px={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={25} color="secondary" />
            </Box>
          ) : failure ? (
            "Retry"
          ) : (
            "Next step"
          )}
        </Button>
      </DialogActions>
      {failure && (
        <InfoText
          sx={{ pb: 0, pt: 3 }}
          text={`We could not find your AWS asset yet. Make sure that the installation completed successfully and that you ran the command above. If you still encounter this problem, check out our troubleshooting page & our FAQs.`}
        />
      )}
    </DialogContainer>
  );
};
