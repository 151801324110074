import { Fragment, useEffect } from "react";
import {
  alpha,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  EmailConfigurationOptionsInput,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../../headers/integration-add-header";
import { UpdateFlowData } from "../../../types";
import useGenerateIntegrationName from "../../../utils/useGenerateIntegrationName";
import { Command } from "~/components/guides/components";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/email/AutoConfigurationSection";
import { EmailFormInput } from "~/pages/integrations/hosted-integrations/forms/email/types";
import {
  EMAIL_PATTERN,
  helperTextStyles,
  JIRA_HOST_PATTERN,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { Flex } from "~/components/Flex";
import { ChevronRightIcon } from "~/components/icons";
import { Close } from "@mui/icons-material";

const defaultValues: EmailFormInput = {
  integrationName: "",
  recipients: [
    {
      email: "",
      name: "",
    },
  ],
  autoCreateCases: false,
  defaultRecipient: "",
};

export function EmailIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  const [searchParams] = useSearchParams();
  const redirectToParam = searchParams.get("redirectTo");
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitSuccessful },
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const [watchAutoCreateOnDrift, watchRecipients] = watch([
    "autoCreateCases",
    "recipients",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      const configOptions = updateFlow.integration.configurationOptions;

      if (configOptions?.__typename !== "EmailConfigurationOptions") return;

      reset({
        integrationName: updateFlow.integration.name,
        recipients: configOptions?.recipients,
        autoCreateCases: configOptions?.autoCreateTickets,
        defaultRecipient: configOptions?.recipients.find((r) => r.isDefault)
          ?.name,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<EmailFormInput> = async (data) => {
    const defaultIntegrationName = data.defaultRecipient;

    const emailConfigurationOptions: EmailConfigurationOptionsInput = {
      autoCreateTickets: data.autoCreateCases,
      recipients: data.recipients.map((recipient) => ({
        ...recipient,
        isDefault:
          data.recipients.length === 1 ||
          recipient.name === defaultIntegrationName,
      })),
    };

    const integrationName =
      data.integrationName?.trim() || updateFlow?.integration.name;

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: integrationName,
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.TicketSystemEmail,
              configurationOptions: {
                emailConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow?.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/ticket_system_email/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.TicketSystemEmail,
              longLivedToken: false,
              configurationOptions: {
                emailConfigurationOptions,
              },
            },
          },
        });
      }
      if (redirectToParam) {
        navigate(redirectToParam);
      } else {
        navigate(`/space/integrations/ticket_system_email?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "Email · Integrations Setup · Mondoo";

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.TicketSystemEmail }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Provide information on the recipient(s)
            </Command>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Specify the ticket system listener or service you want to
                receive case details.
              </Typography>
              {fields.map((item, index) => {
                return (
                  <Flex
                    gap={2}
                    sx={{
                      flexDirection: "column",
                      width: "100%",
                      mt: 5,
                    }}
                    key={item.id}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Typography sx={{ fontWeight: 700 }}>
                        Recipient {index + 1}
                      </Typography>
                      <Button
                        variant="text"
                        sx={{
                          color: (theme) =>
                            alpha(theme.palette.error.main, 0.5),
                        }}
                        endIcon={<Close />}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Flex>
                    <Box>
                      <Typography fontWeight={700} sx={{ mb: 1 }}>
                        Name
                      </Typography>
                      <Controller
                        name={`recipients.${index}.name`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            sx={{
                              background: theme.palette.code.background,
                              borderRadius: 1,
                              color: "text.primary",
                              ...helperTextStyles,
                            }}
                            placeholder="Recipient Name..."
                            error={Boolean(errors?.recipients?.[index]?.name)}
                            helperText={
                              Boolean(errors?.recipients?.[index]?.name) && (
                                <ValidationMessage
                                  error={errors?.recipients?.[index]?.name}
                                />
                              )
                            }
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight={700} sx={{ mb: 1 }}>
                        Email address
                      </Typography>
                      <Controller
                        name={`recipients.${index}.email`}
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            isEmail: (value) =>
                              Boolean(value.match(EMAIL_PATTERN)) ||
                              "Not a valid email address",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            sx={{
                              background: theme.palette.code.background,
                              borderRadius: 1,
                              color: "text.primary",
                              ...helperTextStyles,
                            }}
                            placeholder="Recipient's email address..."
                            error={Boolean(errors?.recipients?.[index]?.email)}
                            helperText={
                              Boolean(errors?.recipients?.[index]?.email) && (
                                <ValidationMessage
                                  error={errors?.recipients?.[index]?.email}
                                />
                              )
                            }
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight={700} sx={{ mb: 1 }}>
                        Link to ticket system{" "}
                        <Typography
                          component="span"
                          color="text.secondary"
                          sx={{ fontStyle: "italic" }}
                        >
                          (Mondoo adds this to each case page to provide quick
                          access)
                        </Typography>
                      </Typography>
                      <Controller
                        name={`recipients.${index}.referenceUrl`}
                        control={control}
                        rules={{
                          validate: {
                            isDomain: (value) => {
                              if (!value) return true;

                              return (
                                Boolean(value.match(JIRA_HOST_PATTERN)) ||
                                "Not a valid domain"
                              );
                            },
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            sx={{
                              background: theme.palette.code.background,
                              borderRadius: 1,
                              color: "text.primary",
                              ...helperTextStyles,
                            }}
                            placeholder="Provide a link to the email destination..."
                            error={Boolean(
                              errors?.recipients?.[index]?.referenceUrl,
                            )}
                            helperText={
                              Boolean(
                                errors?.recipients?.[index]?.referenceUrl,
                              ) && (
                                <ValidationMessage
                                  error={
                                    errors?.recipients?.[index]?.referenceUrl
                                  }
                                />
                              )
                            }
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                );
              })}
              <Flex justifyContent="center" sx={{ mt: 5 }}>
                <Button
                  variant="text"
                  sx={{ color: (theme) => theme.palette.secondary.light }}
                  onClick={() => {
                    append({ name: "", email: "" });
                  }}
                >
                  Add a recipient
                </Button>
              </Flex>
            </Box>
          </Box>
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Configure preferences
            </Command>
            <Box mt={3}>
              <AutoConfigurationSection
                control={control}
                recipients={watchRecipients}
                disabled={!watchAutoCreateOnDrift}
                isRecipientsEmpty={watchRecipients.length === 0}
                showDefaultRecipientDropdown={true}
                showAutoCreateSection={true}
              />
            </Box>
          </Box>
          <Box width={1}>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
            <Button
              sx={{ textTransform: "uppercase" }}
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={!isValid}
            >
              {updateFlow ? "Update integration" : "Create integration"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
