enum DetectedByVendor {
  UNKNOWN = "Unknown",
  MONDOO = "Mondoo",
  MICROSOFT = "Microsoft",
  CROWDSTRIKE = "CrowdStrike",
  SENTINEL_ONE = "Sentinel One",
}

export function mapDetectedByVendor(source: string): string {
  return DetectedByVendor[source as keyof typeof DetectedByVendor] || source;
}
