import { Select } from "@mui/material";
import { KeyboardArrowDownIcon } from "~/components/icons";
import { ReactNode } from "react";

type BaseOperatorDropdownProps = {
  renderContent: () => ReactNode;
  defaultValue?: string;
  isReadonly?: boolean;
};

export const BaseOperatorDropdown = ({
  defaultValue,
  renderContent,
  isReadonly,
  ...rest
}: BaseOperatorDropdownProps) => {
  return (
    <Select
      readOnly={isReadonly}
      variant="outlined"
      IconComponent={isReadonly ? () => null : KeyboardArrowDownIcon}
      size="small"
      displayEmpty
      defaultValue={defaultValue || ""}
      sx={{
        // new color
        bgcolor: "background.light",
        "& .MuiSelect-icon": {
          opacity: 0.5,
        },
      }}
      slotProps={{
        input: {
          sx: {
            p: isReadonly ? "8px!important" : undefined,
          },
        },
      }}
      {...rest}
    >
      {renderContent()}
    </Select>
  );
};
