import { createSvgIcon } from "@mui/material";

export const ThirdPartyDataIcon = createSvgIcon(
  <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 29.7827C10.2189 23.3431 14.0236 17.8224 19.4479 14.948C19.5742 15.7492 19.8137 16.5141 20.1463 17.2264C15.7796 19.7001 12.6932 24.1705 12.1279 29.3936C11.3031 29.4135 10.5049 29.5466 9.75 29.7827ZM18.6031 44.0301C21.2404 45.9523 24.4863 47.0898 27.9983 47.0898C31.5102 47.0898 34.7594 45.9523 37.3967 44.0301C37.9521 44.6088 38.5874 45.1143 39.2858 45.5234C36.1762 47.9678 32.2552 49.4211 27.9983 49.4211C23.7414 49.4211 19.8236 47.9678 16.714 45.5234C17.4124 45.1143 18.0477 44.6088 18.6031 44.0301ZM43.8719 29.3936C43.3066 24.1705 40.2203 19.7001 35.8537 17.2264C36.1862 16.5141 36.4256 15.7492 36.5519 14.948C41.9729 17.8224 45.7809 23.3431 46.2498 29.7827C45.4949 29.5466 44.6967 29.4135 43.8719 29.3936Z"
      fill="url(#paint0_linear_2519_28254)"
    />
    <path
      d="M43.6642 45.0539C47.5303 45.0539 50.6645 41.9198 50.6645 38.0537C50.6645 34.1876 47.5303 31.0535 43.6642 31.0535C39.7981 31.0535 36.6641 34.1876 36.6641 38.0537C36.6641 41.9198 39.7981 45.0539 43.6642 45.0539Z"
      fill="url(#paint1_linear_2519_28254)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3431 31.0459C16.2062 31.0459 19.3427 34.186 19.3427 38.0491C19.3427 41.9121 16.2062 45.0485 12.3431 45.0485C8.48007 45.0485 5.34375 41.9121 5.34375 38.0491C5.34375 34.186 8.48007 31.0459 12.3431 31.0459ZM12.3431 33.3803C9.76775 33.3803 7.67822 35.4737 7.67822 38.0491C7.67822 40.6245 9.76775 42.7141 12.3431 42.7141C14.9185 42.7141 17.0082 40.6245 17.0082 38.0491C17.0082 35.4737 14.9185 33.3803 12.3431 33.3803Z"
      fill="url(#paint2_linear_2519_28254)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9994 6.57397C31.8624 6.57397 34.9989 9.71411 34.9989 13.5772C34.9989 17.4402 31.8624 20.5766 27.9994 20.5766C24.1363 20.5766 21 17.4402 21 13.5772C21 9.71411 24.1363 6.57397 27.9994 6.57397ZM27.9994 8.90835C25.424 8.90835 23.3345 11.0018 23.3345 13.5772C23.3345 16.1525 25.424 18.2422 27.9994 18.2422C30.5747 18.2422 32.6645 16.1525 32.6645 13.5772C32.6645 11.0018 30.5747 8.90835 27.9994 8.90835Z"
      fill="url(#paint3_linear_2519_28254)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2519_28254"
        x1="9.75"
        y1="14.948"
        x2="45.1176"
        y2="50.546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2519_28254"
        x1="36.6641"
        y1="31.0535"
        x2="51.0097"
        y2="44.6907"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2519_28254"
        x1="5.34375"
        y1="31.0459"
        x2="19.6914"
        y2="44.6816"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2519_28254"
        x1="21"
        y1="6.57397"
        x2="35.3477"
        y2="20.2096"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Third-party data",
);
