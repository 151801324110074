import { alpha, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Impact } from "~/components/impact";
import { CvssScore, EpssScore, RiskFactorStats } from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { ScoreLayout } from "./ScoreLayout";
import { ScoreImpact } from "./ScoreImpact";
import { ScoreBlastRadius } from "./ScoreBlastRadius";
import { ErrorBoundary } from "~/components/error-boundary";
import { formatEPSS } from "~/utils/formatter";
import { AggregateScoresNode } from "~/components/FirewatchPage";
import { useGetImpactColor } from "~/components/impact/use-get-impact-color";

type ScoreBlockProps = {
  mainScore?: number | null | undefined;
  blastRadius?:
    | NonNullable<AggregateScoresNode>["blastRadius"]
    | null
    | undefined;
  epssScore?: EpssScore | null | undefined;
  cvssScore?: Pick<CvssScore, "value"> | null | undefined;
  riskFactors?: Array<RiskFactorStats | null> | null | undefined;
  hasError: boolean;
  hasScore: boolean;
};

export const ScoreBlock = ({
  mainScore,
  epssScore,
  cvssScore,
  riskFactors,
  blastRadius,
  hasError,
  hasScore = true,
}: ScoreBlockProps) => {
  if (hasError) {
    return <LoadOrError type="error" />;
  }

  const color = useGetImpactColor({
    impact: mainScore || undefined,
    isRiskScore: true,
  });

  const scoreIsUnknown = !hasScore;

  return (
    <ErrorBoundary key="score-block">
      <Grid
        id="score-box"
        item
        xs={12}
        container
        component={Paper}
        sx={{
          display: "block",
          p: 5,
          pr: 5,
          width: {
            xs: "100%",
            sm: 360,
            md: "100%",
          },
          minHeight: 330,
          backgroundColor: alpha(color, 0.05),
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 3,
            textAlign: "center",
          }}
        >
          <Grid item xs>
            <Box mb={3}>
              <Impact
                impact={mainScore || cvssScore?.value || 0}
                options={{ fontSize: 24, size: 16, border: 2 }}
                {...(mainScore
                  ? { isRiskScore: true }
                  : cvssScore
                    ? { isCVSS: true }
                    : { isRiskScore: true })}
                isActive={hasScore}
                showText={hasScore}
                hideTooltip={!hasScore}
                centerDots
                boldFont
              />
              {!hasScore && (
                <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                  UNKNOWN
                </Typography>
              )}
            </Box>
            <Divider sx={{ backgroundColor: alpha(color, 0.1) }} />
          </Grid>
        </Grid>
        {scoreIsUnknown && <AdvisoryUnknownData />}
        {!scoreIsUnknown && (
          <Grid
            item
            container
            xs
            rowGap={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {cvssScore && (
              <ScoreLayout
                type="cvss-score"
                component={
                  <ScoreImpact
                    isCVSS
                    isActive={hasScore}
                    value={cvssScore.value || 0}
                  />
                }
                title="CVSS Score"
                amount={
                  <Typography sx={{ fontWeight: 700 }}>
                    {(cvssScore.value || 0) / 10}
                  </Typography>
                }
              />
            )}
            {epssScore && (
              <ScoreLayout
                type="epss-score"
                title="EPSS Score"
                amount={
                  <Typography sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                    {formatEPSS(epssScore)}
                  </Typography>
                }
              />
            )}
            {typeof riskFactors !== "undefined" && (
              <ScoreLayout
                type="risk-factors"
                title="Risk Factors"
                amount={
                  hasScore ? (
                    <Typography sx={{ fontWeight: 700 }}>
                      {riskFactors?.length || 0}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontWeight: 700 }}>N / A</Typography>
                  )
                }
              />
            )}
            {typeof blastRadius !== "undefined" && (
              <ScoreLayout
                type="blast-radius"
                title="Blast Radius"
                component={
                  <ScoreBlastRadius
                    blastRadius={blastRadius}
                    score={mainScore || 0}
                  />
                }
                amount={
                  hasScore ? (
                    blastRadius?.affected || 0
                  ) : (
                    <Typography sx={{ fontWeight: 700 }}>N / A</Typography>
                  )
                }
              />
            )}
          </Grid>
        )}
      </Grid>
    </ErrorBoundary>
  );
};

const LoadOrError = ({ type }: { type: "loading" | "error" }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pb: 3,
        mb: 3,
        textAlign: "center",
      }}
    >
      {type === "loading" && <Loading what="scores" vertical />}

      {type === "error" && <LoadingFailed what="scores" />}
    </Grid>
  );
};

const AdvisoryUnknownData = () => {
  return (
    <Grid item container xs rowGap={2}>
      <ScoreLayout
        type="cvss-score"
        component={<ScoreImpact isCVSS isActive={false} value={100} />}
        title="CVSS Score"
        amount={<Typography sx={{ fontWeight: 700 }}>N/A</Typography>}
      />

      <ScoreLayout
        type="epss-score"
        title="EPSS Score"
        component={<ScoreImpact isActive={false} value={100} />}
        amount={<Typography sx={{ fontWeight: 700 }}>N/A</Typography>}
      />

      <ScoreLayout
        type="risk-factors"
        title="Risk Factors"
        component={<ScoreImpact isActive={false} value={100} />}
        amount={<Typography sx={{ fontWeight: 700 }}>N/A</Typography>}
      />

      <ScoreLayout
        type="blast-radius"
        title="Blast Radius"
        component={
          <ScoreBlastRadius
            isActive={false}
            blastRadius={{
              __typename: "BlastRadius",
              indicator: "l",
              assets: 0,
              affected: 0,
              critical: 0,
              high: 0,
              medium: 0,
              low: 0,
              none: 0,
              snoozed: 0,
              disabled: 0,
            }}
            score={0}
          />
        }
        amount={<Typography sx={{ fontWeight: 700 }}>N/A</Typography>}
      />
    </Grid>
  );
};
