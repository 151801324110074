import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";
import {
  RegistrationTokenInput,
  RoleInput,
  TestIamActionsQuery,
  useGenerateRegistrationTokenMutation,
} from "~/operations";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import { selectedApiEndpoint } from "./viewer";

export type Token = {
  endTime: number;
  startTime: number;
  value: string;
};

export type ExchangedToken = {
  api_endpoint: string;
  certificate: string;
  mrn: string;
  private_key: string;
  space_mrn: string;
};

type TokenContextTypes =
  | {
      token: Token;
      exchangedToken: ExchangedToken | null;
      getExchangedToken: () => Promise<ExchangedToken>;
      genToken: (input: RegistrationTokenInput) => Promise<Token>;
      exchangeToken: (token: Token) => Promise<ExchangedToken>;
      tokenDescription: RegistrationTokenInput["description"];
      setTokenDescription: Dispatch<
        SetStateAction<RegistrationTokenInput["description"]>
      >;
      refreshToken: () => Promise<Token>;
      tokenTimeLeft: number;
      tokenTimeTotal: number;
      expiresIn: number;
      setExpiresIn: Dispatch<SetStateAction<number>>;
      autoRefresh: boolean;
      setAutoRefresh: Dispatch<SetStateAction<boolean>>;
      autoExchange: boolean;
      setAutoExchange: Dispatch<SetStateAction<boolean>>;
      setRoles: Dispatch<SetStateAction<RoleInput[]>>;
    }
  | undefined;

type TokenProviderProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  children: ReactNode;
};

const TokenContext = createContext<TokenContextTypes>(undefined);

export function TokenProvider({
  space,
  children,
  availablePermissions,
}: TokenProviderProps) {
  const ticker = useRef<ReturnType<typeof setInterval>>();
  const [expiresIn, setExpiresIn] = useState<number>(600);
  const [token, setToken] = useState<Token>(genLoadingToken(expiresIn));
  const [roles, setRoles] = useState<RoleInput[]>([]);
  const [exchangedToken, setExchangedToken] = useState<ExchangedToken | null>(
    null,
  );
  const [tokenDescription, setTokenDescription] =
    useState<RegistrationTokenInput["description"]>(null);
  const [tokenTimeTotal, setTokenTimeTotal] = useState(expiresIn);
  const [tokenTimeLeft, setTokenTimeLeft] = useState(expiresIn);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [autoExchange, setAutoExchange] = useState(false);
  const errorToken = { value: "Token unavailable", startTime: 0, endTime: 0 };

  const stopRefresh = () => {
    clearInterval(ticker.current);
  };

  const hasGenTokenPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_GENERATEREGISTRATIONTOKEN,
  );

  useEffect(() => {
    if (autoRefresh) refreshToken();
  }, [autoRefresh, expiresIn, tokenDescription]);

  useEffect(() => {
    if (autoRefresh && tokenTimeLeft <= 0) refreshToken();
  }, [autoRefresh, tokenTimeLeft]);

  useEffect(() => {
    if (!autoRefresh) {
      stopRefresh();
    }
  }, [autoRefresh]);

  useEffect(() => {
    return () => {
      stopRefresh();
    };
  }, []);

  const [genTokenMutation] = useGenerateRegistrationTokenMutation();

  const genToken = async ({
    spaceMrn,
    expiresIn,
    description,
  }: RegistrationTokenInput) => {
    if (!hasGenTokenPermission) return errorToken;
    try {
      const result = await genTokenMutation({
        variables: {
          input: { spaceMrn, expiresIn, description, roles },
        },
      });
      if (result.data) {
        const rawToken = result.data.generateRegistrationToken.token;
        const decodedToken = jwtDecode<JwtPayload>(rawToken);
        const startTime = Date.now();
        const endTime =
          (decodedToken.exp ? decodedToken.exp : expiresIn || 0) * 1000;
        const token = { value: rawToken, startTime, endTime, roles };
        return token;
      }
      return errorToken;
    } catch (error) {
      return errorToken;
    }
  };

  const exchangeToken = async (token: Token): Promise<ExchangedToken> => {
    const apiEndpoint = selectedApiEndpoint();
    const url = `${apiEndpoint}/AgentManager/ExchangeRegistrationToken`;
    return fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify({ token: token.value }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("network response was not ok: " + response.statusText);
    });
  };

  const refreshToken = async () => {
    // clear timer
    clearInterval(ticker.current);

    // set loading state token
    const loadingToken = genLoadingToken(expiresIn);
    setToken(loadingToken);
    tokenUpdateTicker(loadingToken);

    // generate and set new tokens
    const newToken = await genToken({
      spaceMrn: space.mrn,
      expiresIn,
      description: tokenDescription,
      roles,
    });
    setToken(newToken);

    if (autoExchange) {
      const exchangedToken = await exchangeToken(newToken);
      setExchangedToken(exchangedToken);
    }

    if (token.endTime > 0) {
      tokenUpdateTicker(newToken);
      ticker.current = setInterval(() => tokenUpdateTicker(newToken), 1000);
    }
    return newToken;
  };

  const tokenUpdateTicker = (token: Token) => {
    const { startTime, endTime } = token;
    const nextTokenTimeLeft = (endTime - Date.now()) / 1000;
    const nextTokenTimeTotal = (endTime - startTime) / 1000;
    setTokenTimeTotal(nextTokenTimeTotal);
    if (nextTokenTimeLeft !== tokenTimeLeft) {
      setTokenTimeLeft(Math.round(nextTokenTimeLeft));
    }
  };

  const getExchangedToken = async () => {
    const newToken = !token.value.includes("Refreshing")
      ? token
      : await refreshToken();
    const exchangedToken = await exchangeToken(newToken);
    setExchangedToken(exchangedToken);
    return exchangedToken;
  };

  return (
    <TokenContext.Provider
      value={{
        token,
        exchangedToken,
        genToken,
        exchangeToken,
        getExchangedToken,
        expiresIn,
        setExpiresIn,
        tokenTimeLeft,
        tokenTimeTotal,
        tokenDescription,
        setTokenDescription,
        refreshToken,
        autoRefresh,
        setAutoRefresh,
        autoExchange,
        setAutoExchange,
        setRoles,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
}

export type UseTokenProps = {
  autoRefresh?: boolean;
  autoExchange?: boolean;
  roles?: RoleInput[];
};

const defaultUseTokenProps = { autoRefresh: true, autoExchange: false };

export function useToken(props: UseTokenProps = defaultUseTokenProps) {
  const { autoRefresh, autoExchange, roles } = {
    ...defaultUseTokenProps,
    ...props,
  };
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error("useToken must be used within a TokenProvider");
  }

  useEffect(() => {
    context.setAutoRefresh(autoRefresh);
    return () => {
      context.setAutoRefresh(false);
    };
  }, [autoRefresh]);

  useEffect(() => {
    context.setAutoExchange(autoExchange);
    return () => {
      context.setAutoExchange(false);
    };
  }, [autoExchange]);

  useEffect(() => {
    if (roles) {
      context.setRoles(roles);
    }
  }, [roles]);

  return context;
}

const genLoadingToken = (expiresIn: number) => ({
  value: "Refreshing token...",
  startTime: Date.now(),
  endTime: Date.now() + expiresIn * 1000,
});
