import { useSort } from "~/pages/inventory/hooks/useSort";
import { GenericTableProps } from "./GenericTable";
import { getGenericTableHeaders } from "./GetGenericTableHeaders";
import { OrderDirection } from "~/operations";

type GenericSortOrder = {
  field: string;
  direction: OrderDirection;
};

type UseGenericTableProps = {
  tableName: GenericTableProps["tableName"];
  validSortFields: GenericSortOrder["field"][];
};

export function useGenericTable({
  tableName,
  validSortFields,
}: UseGenericTableProps) {
  const tableHeaders = getGenericTableHeaders(tableName);

  const { handleSortClick, orderBy } = useSort<GenericSortOrder>({
    defaultSort: {
      field: validSortFields[0],
      direction: OrderDirection.Asc,
    },
    validFields: validSortFields,
  });

  return { tableHeaders, handleSortClick, orderBy };
}
