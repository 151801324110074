import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { Flex } from "~/components/Flex";
import { Code } from "~/components/code";
import {
  GenerateApiTokenResponse,
  useGenerateApiTokenMutation,
} from "~/operations";
import { AddButton } from "~/components/add-button";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getError } from "~/lib/handle-error";
import { Org, Space } from "~/lib/types";

interface SpaceLevel {
  space: Space;
  org?: never;
}

interface OrgLevel {
  space?: never;
  org: Org;
}

type Props = SpaceLevel | OrgLevel;

type FormInput = {
  name: string;
  description: string;
  permissions: {
    viewer: boolean;
    editor: boolean;
    owner: boolean;
    agent: boolean;
  };
  apiToken: string;
};

const defaultValues = {
  name: "",
  description: "",
  permissions: {
    viewer: false,
    editor: false,
    owner: false,
    agent: false,
  },
  apiToken: "",
};

export function GenerateApiTokenForm({ space, org }: Props) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [generatedTokenData, setGeneratedTokenData] = useState<
    GenerateApiTokenResponse | undefined
  >(undefined);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormInput>({ defaultValues, mode: "onChange" });
  const scope = org ? "organization" : "space";

  useEffect(() => {
    if (generatedTokenData?.token) {
      setFormDisabled(true);
    }
  }, [generatedTokenData]);

  useLayoutEffect(() => {
    if (generatedTokenData?.token) {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    }
  }, [generatedTokenData]);

  const [generateApiToken] = useGenerateApiTokenMutation({
    onCompleted(data) {
      setGeneratedTokenData(data.generateAPIToken);
    },
  });

  const onSubmit = async (data: FormInput) => {
    try {
      const roles = Object.entries(data.permissions)
        .filter(([_role, value]) => value === true)
        .map(([role, _value]) => {
          return {
            mrn: `//iam.api.mondoo.app/roles/${role}`,
          };
        });

      await generateApiToken({
        variables: {
          input: {
            spaceMrn: space?.mrn || "",
            scopeMrn: org?.mrn,
            name: data.name,
            description: data.description,
            roles,
          },
        },
      });

      enqueueSnackbar("Successfully generated API Token", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const handleCloseForm = () => {
    if (space) {
      navigate(`/space/settings/api-tokens?spaceId=${space.id}`);
    } else if (org) {
      navigate(`/organization/settings/api-tokens?organizationId=${org.id}`);
    }
  };

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h5" fontWeight={700} textTransform="uppercase">
          Generate a new API TOKEN
        </Typography>
        <AddButton id="generate-form" onClick={handleCloseForm} close />
      </Flex>

      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography
            variant="h5"
            component="p"
            fontWeight={700}
            sx={{ mr: 3 }}
          >
            Information
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          component="fieldset"
          disabled={formDisabled}
          sx={{ m: 0, p: 0, border: "none" }}
        >
          <Box mb={3}>
            <Typography sx={{ ...formLabelStyle }}>Name</Typography>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={formDisabled}
                  placeholder="Give your token a unique name ..."
                  fullWidth
                  sx={(theme) => ({ ...formTextFieldStyle(theme) })}
                />
              )}
            />
          </Box>
          <Box mb={5}>
            <Typography sx={{ ...formLabelStyle }}>Description</Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={formDisabled}
                  placeholder="Describe what the token is used for ..."
                  fullWidth
                  multiline
                  rows={3}
                  sx={(theme) => ({ ...formTextFieldStyle(theme) })}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Grid container alignItems="center" mb={3}>
              <Grid item>
                <Typography
                  variant="h5"
                  component="p"
                  fontWeight={700}
                  sx={{ mr: 3 }}
                >
                  Permissions
                </Typography>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
            </Grid>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Controller
                name="permissions.viewer"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Viewer</Typography>}
                    description={`View most content in ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.editor"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Editor</Typography>}
                    description={`View, create, update, and delete assets, reports and members. Cannot delete the ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.owner"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Owner</Typography>}
                    description={`Full access to all data in ${scope}.`}
                  />
                )}
              />
              <Controller
                name="permissions.agent"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    {...{ field }}
                    disabled={formDisabled}
                    label={<Typography fontWeight={700}>Agent</Typography>}
                    description="Ability to fetch and run polices and querypacks and report its results"
                  />
                )}
              />
            </Grid>
          </Box>

          <Box
            sx={{
              mb: 5,
              pb: 5,
              textAlign: "right",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || !isDirty || formDisabled}
            >
              {formDisabled ? "API Token Generated " : "Generate API Token"}
            </Button>
          </Box>
        </Box>
      </form>
      <Box mb={5}>
        {generatedTokenData && (
          <Fragment>
            <Typography variant="h5" component="p" fontWeight={700}>
              API Token
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
              Use the following API Token to authenticate with the service of
              your choice.
            </Typography>

            <Code className="bash" copyButton>
              {generatedTokenData?.token}
            </Code>
            <Box mt={8.25} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleCloseForm}
              >
                Finalize
              </Button>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

const FormCheckbox = ({
  field,
  label,
  description,
  disabled,
}: {
  field: ControllerRenderProps<FormInput, any>;
  label: FormControlLabelProps["label"];
  description: string;
  disabled: boolean;
}) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <FormControlLabel
        control={
          <Checkbox {...field} disabled={disabled} checked={field.value} />
        }
        {...{ label }}
      />
      <Typography fontSize={12} color="text.secondary" sx={{ mt: -1, ml: 4 }}>
        {description}
      </Typography>
    </Grid>
  );
};

const formLabelStyle = { mb: 1, fontWeight: 700, color: "text.secondary" };
const formTextFieldStyle = (theme: Theme) => ({
  background: theme.palette.code.background,
  borderRadius: 1,
  color: "text.primary",
});
