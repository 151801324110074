import { useFieldArray, useFormContext } from "react-hook-form";
import {
  CreateWorkspaceFormInput,
  initialCondition,
} from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { isConditionComplete } from "~/pages/space/Workspaces/utils";

type UseSelectionRowProps = {
  selectionIndex: number;
};

export function useSelectionRow({ selectionIndex }: UseSelectionRowProps) {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<CreateWorkspaceFormInput>();
  const { fields, append, remove } = useFieldArray<CreateWorkspaceFormInput>({
    control,
    name: `selections.${selectionIndex}.conditions`,
  });

  const conditions = watch(`selections.${selectionIndex}.conditions`);

  const handleAddCondition = () => {
    append(initialCondition);
  };

  const handleRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);
  };

  const getConditionError = (conditionIndex: number) => {
    return (
      errors?.selections?.[selectionIndex]?.conditions?.[conditionIndex]
        ?.stringCondition?.values?.message ||
      errors?.selections?.[selectionIndex]?.conditions?.[conditionIndex]
        ?.intCondition?.values?.message
    );
  };

  return {
    canAddCondition: conditions.every(isConditionComplete),
    handleAddCondition,
    handleRemoveCondition,
    fields,
    getConditionError,
  };
}
